import { Controller } from 'stimulus';
import { userCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";

export default class extends Controller {
  connect() {
    $('.select-permission-option').on('change', function() {
      let selectedOption = $(this).val();
      if (selectedOption.length == 0) {
        selectedOption = false
      }
      $('.select-permission-option').not(this).prop('disabled', !!selectedOption);
    });
    userCountryCodeAndFlagForPhoneNumbers();
  }
}
