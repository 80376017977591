const createListSelectOptions = (optionsBox, options) => {
    var listitems = [];
    $.each(options, function(key, value) { 
        if (Array.isArray(value)) {
            listitems += '<option value="' + value[1] + '">' + value[0] + '</option>'; 
        } else {
            listitems += '<option value="' + value + '">' + value + '</option>'; 
        }
    });
    optionsBox.empty().append(listitems);
}

export { createListSelectOptions }