import { Modal } from "bootstrap";
import { disableBtns } from "../components/_data_disable_with";

// MODALS POUR EDIT -> Chargement automatique du contenu dur record a edit dans la modale
export function modalEventListener(btnsSelector, modalElementSelector, fts, context = document) {
	const editButtons = context.querySelectorAll(btnsSelector);
	const modalElement = document.querySelector(modalElementSelector);
	const modalBody = modalElement.querySelector('.modal-body');
	const modal = new Modal(modalElement);
	const csrfToken = document.querySelector('meta[name="csrf-token"]').content;
  const closeBtn = modalElement.querySelector(".btn-close")

	editButtons.forEach((button) => {
		button.addEventListener("click", (event) => {
			fetch(button.dataset.url, {
				method: "GET",
				headers: {
					"X-Requested-With": "XMLHttpRequest",
					"X-CSRF-Token": csrfToken,
					"Content-Type": "text/html",
					"Accept": "text/javascript"
				},
				credentials: "same-origin",
			})
      .then((response) => response.text())
      .then((html) => {
        modalBody.innerHTML = html; // Insert the HTML response into the modal body
        modal.show();
        $(".btn").removeClass("btn") // remove class .btn everywhere
        disableBtns();
        for (let thunk of fts) {
          thunk();
        }
      });
		});
	});

  if (closeBtn) {
    closeBtn.addEventListener("click", () =>{
      modal.hide()
    })
  }

	modalElement.addEventListener("hidden.bs.modal", function() {
		tinymce.remove();
	})


}
