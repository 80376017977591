const tagsSelectionToggleCss = (context = document) => {
  if (context.querySelector(".tags-columns-new") || (context.querySelector(".tags-columns-edit") && context.querySelector("#edit-meal-dates-page-for-js").dataset.userAllowedToModifyTags === "true"   )  ) {
    // const allergens = context.querySelectorAll(".allergens-columns-new");
    console.log("tagsSelectionToggleCss")

    context.querySelectorAll(".form-check-input").forEach((tag) => {
      if (tag.name == "tags[]") {
        tag.addEventListener('click', () => {
          console.log(tag)
          tag.parentElement.classList.toggle("selected-tag-box")
          tag.parentElement.querySelector(".container-text-tag-title").classList.toggle("selected-tag-text")
        })
    }
    })
  }
}

const tagsSelectionEdit = (context = document) => {
  if (context.querySelector(".tags-columns-edit")) {
    const tagsForEdit = context.querySelectorAll(".tags-columns-edit");
    console.log("tagsSelectionEdit")
    // const selectedTags = tagsForEdit[0].parentElement.parentElement.previousElementSibling.firstElementChild.value.split(" ")
    const selectedTags = context.querySelector('[name="tags_of_meal[]"]').value.split(", ")
    console.log(selectedTags)
    tagsForEdit.forEach((tag) => {
      const valueTag = tag.firstElementChild.value
      if (selectedTags.includes(valueTag)) {
        console.log(valueTag)
        tag.firstElementChild.checked = true
        console.log(tag)
        tag.classList.toggle("selected-tag-box")
        tag.querySelector(".container-text-tag-title").classList.toggle("selected-tag-text")
        console.log(tag)
      }
    })
  }
}

const pictoSelectionEdit = (context = document) => {
  // if (context.querySelector("#edit-meal-dates-page-for-js") || context.querySelector("#edit-meal-dates-multisite-page-for-js")) {
    if (context.querySelector(".container-icon-picto")) {
      const pictosForEdit = context.querySelectorAll(".container-icon-picto");
      console.log("PictoSelectionEdit")
      if (context.querySelector(".data-meal-item-picto") != null && context.querySelector(".data-meal-item-picto").dataset["picto"] != '' ) {
        let meal_picto_value = context.querySelector(".data-meal-item-picto").dataset["picto"]
        let selectedPicto = context.querySelector(`#${meal_picto_value}`)
        selectedPicto.checked = true
        console.log(selectedPicto)
      }
    }
  // }
}

export { tagsSelectionToggleCss, tagsSelectionEdit, pictoSelectionEdit};
