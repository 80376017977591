/** StartPage : Menu & Questionnaire: do not allow validation when title, and title_english are < 2 or > 50
 *  For StartPage#new and StartPage#edit */

const limitedCharactersForTitleStartPages = (form) => {

    if (form) {
        console.log('limitedCharactersForTitleStartPages');
        const btn = form.querySelector("[name='commit']");
        const allInputsLanguage = form.querySelectorAll("[id^='start_page_title']");

        form.addEventListener("keyup", (event) => {
            let allContentFilled = false
            const filledByLanguage = []
            let val = ""

            // Array pour savoir si tous les contents de chaque langue sont remplis+ afficher le message d'erreur
            allInputsLanguage.forEach((content_lang_input) => {
                // créer un array de boolean pour savoir si tous les elements ont été remplis ou pas
                val = content_lang_input.value.replaceAll(' ', '')
                filledByLanguage.push(val !== '' && val.length > 2 && val.length < 51 )

                // afficher le message d'erreur
                let msg = content_lang_input.parentElement.nextElementSibling;
                if (val.length < 2 || val.length > 50) {
                    msg.classList.remove("hidden")
                } else {
                    msg.classList.add("hidden")
                }
            })
            allContentFilled = filledByLanguage.includes(false) ? false : true

            //toggle btn of validation of the form
            if (allContentFilled) {
                btn.classList.remove("not-clickable");
                btn.disabled = "";
            } else {
                btn.classList.add("not-clickable");
                btn.disabled = "disabled";
            }

        })
    }
}

const notClickableBtnForCreateStartPage = () => {
    if (document.getElementById("new_start_page")) {
      console.log("createForm")
      limitedCharactersForTitleStartPages(document.getElementById("new_start_page"))
    }
}

const notClickableBtnForEditStartPage = () => {
    if (document.getElementById("new_start_page")) {
      console.log("editForm")
      const forms = document.querySelectorAll("[id^='edit_start_page_']")
      forms.forEach((form) => {
          limitedCharactersForTitleStartPages(form)
      })
  }
}

export {notClickableBtnForCreateStartPage, notClickableBtnForEditStartPage };
