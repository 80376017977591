// methode pour rajouter une date MealDate
function addDateField (context = document) {

  const allDateDiv = context.querySelector("#all_my_dates");
  if (allDateDiv) {
    console.log("addDateField")
    const divNbDate = context.querySelector('[data-nb-date]');
    console.log(divNbDate)
    divNbDate.addEventListener("click", function (event) {
      const nbDates = divNbDate.dataset.nbDate;
      const my_dates = `
        <div class='same_row'>
          <input type='date' name='start_date_${nbDates}' class='date_field_seed_new date_field_adding_margin'>
          <input type='date' name='end_date_${nbDates}' class='date_field_seed_new date_field_adding_margin'>
        </div>
        `
      allDateDiv.insertAdjacentHTML('beforeend', my_dates)

      divNbDate.dataset.nbDate = parseInt(nbDates) + 1
    });
  }
}

export { addDateField };
