import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    if (document.querySelector("#edit_categ_all_resto")) {
      console.log("edit_category_all");

      const form = document.querySelector("form #edit_category");
      const btn = document.querySelector("input.custom-btn.btn-of-modal.not-clickable");

      const allInputsLanguage = form.querySelectorAll("[id^='content_']");
      const allInputsLanguage2 = form.querySelectorAll("[id^='content_']:not(#content_before, #content_before_master)");
      const allInputsShortTitleLanguage = form.querySelectorAll("[id^='short_title_']");
      const mainLang = document.getElementById("cat-content-for-js").dataset.mainLang;
      const mainContent = form.querySelector(`#content_${mainLang}`);
      let allCategs = JSON.parse(document.getElementById("cat-content-for-js").dataset.allCategsMastercateg);
      allCategs = allCategs.filter((el) => el !== mainContent.value.toLowerCase() )
      const newMenuOuiNon = document.getElementById("cat-content-for-js").dataset.newMenuOuiNon === "true";
      const userAdminOuiNon = document.getElementById("edit-meal-all-page-for-js").dataset.typeUserAdmin === "true";

      ["click", "keyup"].forEach((action) => {

        form.addEventListener(action, (event) => {
          let allContentFilled = false
          const filledByLanguage = []
          let val = ""

          let allShortTitleFilled = false
          const filledShortTilteByLanguage = []
          let valShortTitle = ""

          let allContentValid = false
          const validContent = []

          // Array pour savoir si tous les contents de chaque langue sont remplis
          Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
            val = content_lang_input.value.replaceAll(' ', '')
            filledByLanguage.push(val !== '' && val.length > 2 )

            const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
            validContent.push(!isTrimmed);
          })
          allContentFilled = filledByLanguage.includes(false) ? false : true
          allContentValid = validContent.includes(false) ? false : true

          // si l'établissement a l'option new_menu_oui_non : Array pour savoir si tous les short_title de chaque langue sont remplis
          if (newMenuOuiNon) {
            console.log("newMenuOuiNon")
            Array.prototype.slice.call( allInputsShortTitleLanguage ).forEach((n) => {
              valShortTitle = n.value.replaceAll(' ', '')
              let true_or_false = valShortTitle !== '' && valShortTitle.length > 2
              filledShortTilteByLanguage.push(true_or_false)

            })
            // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés
            allShortTitleFilled = filledShortTilteByLanguage.includes(false) ? false : true

            // toggle les messages d'alert sur content et short_title
            const allInputs = Array.from(allInputsLanguage2).concat(Array.from(allInputsShortTitleLanguage))
            Array.prototype.slice.call( allInputs ).forEach((content_lang_input) => {
              const element = content_lang_input.value.replaceAll(' ', '')
              const lang = content_lang_input.id.match(/_(en|pt_br|fr|it|de|mn|es|ru|pt|ar|kz)$/)[1]
              const element_with_whitespaces = content_lang_input.value

              let msg1;
              let msg2;

              if (content_lang_input.id.includes("content_")) {
                msg1 = document.getElementById(`message_for_format_title_${lang}`)
                msg2 = document.getElementById(`message_for_whitespaces_title_${lang}`)
              } else {
                msg1 = document.getElementById(`message_for_format_short_title_${lang}`)
              }

              msg1.style.display = (element !== '' && element.length > 2) ? 'none' : 'flex';

              if (content_lang_input.id.includes("content_") && msg2) {
                msg2.style.display = (element_with_whitespaces.trim() == element_with_whitespaces) ? 'none' : 'flex';
              }
            })

          } else {
            // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés : Quand pas de menu_new, alors automatiquement à true
            allShortTitleFilled = true
          }

          // savoir si le content du main_language n'existe pas déjà
          const alreadyIncludedOuiNon = allCategs.includes(mainContent.value.toLowerCase())

          // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
          if (allContentFilled && (!alreadyIncludedOuiNon || userAdminOuiNon) && allShortTitleFilled && allContentValid) {
            btn.classList.remove("not-clickable");
            btn.setAttribute("type", "submit");
          } else {
            btn.classList.add("not-clickable");
            btn.removeAttribute("type");
          }

          // faire apparaitre message d'alerte aux admin quand on update une categ à un nom qui existe déjà (pour transférer tous les meal_item dans l'autre categ)
          if (allCategs.includes(mainContent.value.toLowerCase().replace(/\s/g, '')) && userAdminOuiNon) {
            console.log("msgAdmin if")
            const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
            if (!msgAdmin) {
              console.log("msgAdmin if 2")
              mainContent.parentElement.insertAdjacentHTML("beforeend", "<div id='msg-for-admin-categ-exist' class='msg-categ-for-admin'> <b> Attention : </b> Ce nom de catégorie existe déjà, vous êtes sur le point de migrer tous les produits de cette catégorie dans la catégorie existante.</div>")
            }
          } else {
            console.log("msgAdmin else2")
            const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
            if (msgAdmin) {
              msgAdmin.remove();
            }
          }

        })

      })

    }
  }
}
