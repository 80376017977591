import { Controller } from 'stimulus';
import $ from 'jquery';
import 'select2';

export default class extends Controller {

  connect() {
    this.initializeSelect2()
    this.setupFormValidation()
  }

  initializeSelect2() {
    $('.select2user-accreditations, .select2groupe-accreditations, .select2resto-accreditations').select2();
  }

  setupFormValidation() {
    $('form').submit(function() {
      const selectedGroupIds = $('[name="groupe_id[]"]').val()
      const selectedSousGroupeIds = $('[name="sous_groupe_id[]"]').val()
      const selectedRestaurantIds = $('[name="restaurant_id[]"]').val()

      if (selectedGroupIds.length      === 0 &&
          selectedSousGroupeIds.length === 0 &&
          selectedRestaurantIds.length === 0) {

        alert("Sélectionnez au moins un établissement.");
        return false;
      }
    });
  }
}
