const mobileDatepicker = () => {
  const datepicker = document.getElementById("form-date-mobile");
  const date = document.getElementById("date_mobile");
  const dateIcone = document.getElementById("icondate");
  console.log(navigator.userAgent);

  if (datepicker) {
    console.log('mobileDatepicker')
    date.addEventListener("input", (e) => {
      datepicker.submit();
    })
  }
}

export { mobileDatepicker };
