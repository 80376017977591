import { Controller } from "stimulus";

export default class extends Controller {

  connect() {
    const restaurantSelector = document.querySelector("#navbar-restaurant-selector-btn")
    const nameDisplay = restaurantSelector.querySelector(".group-name-for-js")
    const topbarLocalisation = restaurantSelector.querySelector(".topbar-localisation")
    const topbarArrow = restaurantSelector.querySelector(".topbar-arrowdown")
		
		// Display the chosen establishment name in the navbar while it loads again
		const restaurantName = document.querySelector(".data-current-group").dataset.restaurantName
		document.querySelector(".left.side-content").querySelector(".group-name-for-js").innerText = restaurantName;
		
    restaurantSelector.style.pointerEvents = "none"
    nameDisplay.style.opacity = "0.5"
    topbarArrow.style.opacity = "0.5"
    topbarLocalisation.style.opacity = "0.5"
  }

}
