const limitFileSizeUpload = () => {
    const uploadField = document.getElementById("product_img_create_meal");
    const uploadFieldEdit = document.getElementById("product_img_meal_edit");
    const uploadFieldAll = document.getElementById("product_img_create_meal_all");
    const uploadFieldEditAll = document.getElementById("product_img_edit_meal_all");

  if (uploadField) {
    console.log('limitFileSizeUpload')
    uploadField.onchange = function() {
        if(this.files[0].size > 300000){
           alert("Votre fichier est trop volumineux. Vous pouvez le réduire en vous rendant sur le site gratuit https://shortpixel.com/");
           this.value = "";
           document.getElementById("photo-preview-meal-new").src = "";
        };
    };
  }

  if (uploadFieldEdit) {
    console.log('limitFileSizeUpload')
    uploadFieldEdit.onchange = function() {
        if(this.files[0].size > 300000){
           alert("Votre fichier est trop volumineux. Vous pouvez le réduire en vous rendant sur le site gratuit https://shortpixel.com/");
           this.value = "";
           document.getElementById("photo-preview-meal-edit").src = "";
        };
    };
  }

  if (uploadFieldAll) {
    console.log('limitFileSizeUpload')
    uploadFieldAll.onchange = function() {
        if(this.files[0].size > 300000){
           alert("Votre fichier est trop volumineux. Vous pouvez le réduire en vous rendant sur le site gratuit https://shortpixel.com/");
           this.value = "";
           document.getElementById("photo-preview-meal-new-all").src = "";
        };
    };
  }

  if (uploadFieldEditAll) {
    console.log('limitFileSizeUpload')
    uploadFieldEditAll.onchange = function() {
        if(this.files[0].size > 300000){
           alert("Votre fichier est trop volumineux. Vous pouvez le réduire en vous rendant sur le site gratuit https://shortpixel.com/");
           this.value = "";
           document.getElementById("photo-preview-meal-edit-all").src = "";
        };
    };
  }

}

export { limitFileSizeUpload };
