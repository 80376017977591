import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="notification"
export default class extends Controller {
  connect() {
    if (!this.isPreview) {
      setTimeout(() => {
        this.element.classList.remove('hidden');
        this.element.classList.add('notif-transition-first');

        setTimeout(() => {
          this.element.classList.add('notif-transition-second');
        }, 100);
      }, 500);
    }

    // Auto-hide
    setTimeout(() => {
      this.close();
    }, 10000);
  }

  close() {
    // Remove with transition
    this.element.classList.remove('notif-transition-first');
    this.element.classList.remove('notif-transition-second');
    this.element.classList.add('notif-close-transition-first');

    // Trigger transition
    setTimeout(() => {
      this.element.classList.add('notif-close-transition-second');
    }, 100);

    // Remove element after transition
    setTimeout(() => {
      this.element.remove();
      this.element.parentNode.remove();
    }, 300);
  }

  get isPreview() {
    return document.documentElement.hasAttribute('data-turbolinks-preview');
  }
}
