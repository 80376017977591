function initializeHandleCmiPrice(context = document) {
  if (document.getElementById('photo-preview-meal-edit')) {
    handlePictoVisibility();
  }
  removeContainerOnClick()
  handleButtons();
  handleCheckboxChanges();
  handleCloneOnBtnClick();
  updateContainerOptions();


  const btnToAddUniquePrice = document.getElementById('unique-price');
  const inputEditUnique = document.getElementById('price-without-container');
  const inputAddUnique = document.getElementById('unique-price-input-div');
  const btnToAddContainers = document.getElementById('add-containers-btn');
  const containerAlreadyCreated = document.querySelectorAll(".all-prices-with-containers");
  if (btnToAddContainers) {
    btnToAddContainers.classList.add('noRadius');
  }
  if (btnToAddUniquePrice && containerAlreadyCreated.length === 0) {
    btnToAddUniquePrice.click();
  }
}

function handlePictoVisibility() {
    const photoPreview = document.getElementById('photo-preview-meal-edit');
    const removeTrash = document.getElementById('remove-trash-when-picto');
    const s3Path = 'https://aws-picture-gds.s3.eu-west-3.amazonaws.com/Pictogrammes_meal/';

    if (photoPreview.src.includes(s3Path)) {
        addClass([photoPreview, removeTrash], 'hide-picto');
    } else {
        removeClass([photoPreview, removeTrash], 'hide-picto');
    }
}

function handleButtons() {
  const btnToAddUniquePrice = document.getElementById('unique-price');
  const btnToAddContainers = document.getElementById('add-containers-btn');

  if (btnToAddUniquePrice) {
    handleButtonToggle({
      button: btnToAddUniquePrice,
      activeClassName: 'active',
      activeStyles: { background: '#5d78ff', color: 'white' },
      onClick: hideContainers,
      onRemoveClick: showContainers,
      otherButton: btnToAddContainers,
      otherAction: hideOtherContainerOptions
    });
  }

  handleButtonToggle({
    button: btnToAddContainers,
    activeClassName: 'active',
    activeStyles: { background: '#5d78ff', color: 'white' },
    onClick: showDropdown,
    otherButton: btnToAddUniquePrice,
    otherAction: removeUniquePriceInput
  });
}

function handleButtonToggle({
    button,
    activeClassName,
    activeStyles,
    onClick,
    otherButton = null,
    otherAction = null
  }) {
    if (button) {
      button.addEventListener('click', function (event) {
          event.preventDefault();
          const isActive = this.classList.toggle(activeClassName);
          this.style.background = isActive ? activeStyles.background : '';
          this.style.color = isActive ? activeStyles.color : '';

          if (isActive) {
              onClick();
              if (otherButton && otherButton.classList.contains(activeClassName)) {
                  otherButton.classList.remove(activeClassName);
                  otherButton.style.background = '';
                  otherButton.style.color = '';
                  otherAction && otherAction();
              }
          } else {
              removeUniquePriceInput();
              hideOtherContainerOptions();
          }
      });
    }
}

function handleCheckboxChanges() {
  const dropDownCmi = document.querySelector(".cmi-dropd");

  if(dropDownCmi) {
    const listItems = dropDownCmi.querySelectorAll("li");
    listItems.forEach(li => {
        li.addEventListener("click", function (event) {
            if(event.target !== this.querySelector(".my_input")) {
                event.preventDefault();
                event.stopPropagation();

                const checkbox = li.querySelector(".my_input");
                if(checkbox) {
                  checkbox.checked = !checkbox.checked;
                  updateButtonText();
                  toggleTargetDivVisibility(checkbox);
                }
            }
        });
    });
  }

  if(dropDownCmi) {
    const checkboxes = dropDownCmi.querySelectorAll(".my_input");
    checkboxes.forEach(checkbox => {
        checkbox.addEventListener("change", function (event) {
            event.stopPropagation();
            updateButtonText();
            toggleTargetDivVisibility(this);
        });
    });
  }
}


// Fonctions auxiliaires
function addClass(elements, className) {
  elements.forEach(element => element.classList.add(className));
}

function removeClass(elements, className) {
  elements.forEach(element => element.classList.remove(className));
}

function showDropdown() {
  const dropdownContainer = document.getElementById('dropdown-container');
  document.querySelectorAll(".all-prices-with-containers").forEach(el => el.classList.remove("hidden"));
  dropdownContainer.style.display = 'block';
}

function hideOtherContainerOptions() {
  document.getElementById('dropdown-container').style.display = 'none';
}

function updateButtonText() {
  const checkboxes = document.querySelectorAll('.my_input:checked');
  const buttonText = [];

  checkboxes.forEach(checkbox => {
      buttonText.push(checkbox.closest('.container-name').textContent.trim());
  });

  const button = document.querySelector('.contain-dpd-edit-meal');

  if (buttonText.length > 0) {
      button.textContent = buttonText.join(', ');
  } else {
      button.textContent = 'Choisissez un contenant';
  }
}

function hideContainers() {
  toggleClassForElements(".all-prices-with-containers", "hidden", true);
  document.querySelector("#container-div").classList.add("hidden");
  addUniquePriceInput();
}

function showContainers() {
  document.querySelectorAll(".all-prices-with-containers").classList.remove("hidden");
}

function addUniquePriceInput() {
  const inputEditUnique = document.getElementById('price-without-container');

  if(!inputEditUnique) {
    const priceContainer = document.querySelector(".modal-setting-title-tags.d-flex.flex-column");

    const newPriceDiv = document.createElement("div");
    newPriceDiv.className = "modal-setting-title";
    newPriceDiv.id = "unique-price-input-div";

    const newLabel = document.createElement("label");
    newLabel.innerText = "Prix";
    newPriceDiv.appendChild(newLabel);

    const newInput = document.createElement("input");
    newInput.className = "form-control numeric numeric required";
    newInput.type = "number";
    newInput.name = "new_unique_price";
    newInput.min = "0.0";
    newInput.step = "any";

    const inputGroup = document.createElement("div");
    inputGroup.className = "form-group numeric required container_meal_item_price form-group-valid";

    newPriceDiv.appendChild(inputGroup);
    newPriceDiv.appendChild(newInput);
    priceContainer.appendChild(newPriceDiv);
  }
}

function removeUniquePriceInput() {
  const uniquePriceDiv = document.querySelector("#unique-price-input-div");
  uniquePriceDiv && uniquePriceDiv.remove();
}

function toggleClassForElements(selector, className, add) {
  document.querySelectorAll(selector).forEach(el => el.classList.toggle(className, add));
}

function toggleTargetDivVisibility(checkbox) {
  const dataIdCt = checkbox.parentElement.dataset.idCt;
  const targetDiv = document.querySelector(`.add-container-price[data-id-ct-tab="${dataIdCt}"]`);
  const containerCreate = document.querySelector("#container-div");
  const containerAlreadyCreated = document.querySelectorAll(".all-prices-with-containers");
  const hideInput = document.querySelector("#price-without-container");

  const isChecked = checkbox.checked;
  containerAlreadyCreated.forEach(el => {
    let inputs = el.querySelectorAll("input, textarea");
    let selects = el.querySelectorAll("select");

    if(isChecked) {
        inputs.forEach(input => input.readOnly = true);
        selects.forEach(select => {
            select.querySelectorAll("option").forEach(option => {
                option.disabled = !option.selected;
            });
        });
    } else {
        inputs.forEach(input => input.readOnly = false);
        selects.forEach(select => {
            select.querySelectorAll("option").forEach(option => {
                option.disabled = false;
            });
        });
    }

    containerAlreadyCreated.forEach(el => {
      if (isChecked) {
        el.querySelector(".align-alert").classList.remove("hidden");
      } else {
        el.querySelector(".align-alert").classList.add("hidden");
      }
    });
  });

  if(hideInput) {
    const anyCheckboxChecked = [...document.querySelectorAll('.my_input')].some(checkbox => checkbox.checked);
    hideInput.classList.toggle("hidden", anyCheckboxChecked);
  }

  targetDiv.classList.toggle("hidden", !isChecked);
  const anyCheckboxChecked = [...document.querySelectorAll('.my_input')].some(checkbox => checkbox.checked);
  containerCreate.classList.toggle("hidden", !anyCheckboxChecked);
  if(hideInput) {
      let inputs = hideInput.querySelectorAll("input, textarea");
      let selects = hideInput.querySelectorAll("select");

      if(isChecked) {
          inputs.forEach(input => input.readOnly = true);
          selects.forEach(select => {
              select.querySelectorAll("option").forEach(option => {
                  option.disabled = !option.selected;
              });
          });
      } else {
          inputs.forEach(input => input.readOnly = false);
          selects.forEach(select => {
              select.querySelectorAll("option").forEach(option => {
                  option.disabled = false;
              });
          });
      }
  }
}

function handleCloneOnBtnClick() {
  let addContainer = document.querySelectorAll(".add-cmi-container");

  addContainer.forEach(btn => btn.addEventListener("click", function (event) {
    event.preventDefault();
    event.stopPropagation();

    const parentContainer = event.target.closest(".add-container-price");
    const elementToClone = parentContainer.querySelector(".div-of-all-inputs-containers");
    const clonesContainer = parentContainer.querySelector(".clones-container");

    const selectElement = elementToClone.querySelector("select");
    const numOptions = Array.from(selectElement.options).filter(option => !option.disabled).length;
    const numClones = parentContainer.querySelectorAll(".div-of-all-inputs-containers").length;

    if (numClones < numOptions) {

        const clonedElement = elementToClone.cloneNode(true);
        const newI = Math.floor(Math.random() * (1000 - 5 + 1)) + 5;

        clonedElement.setAttribute("data-i-for-js", newI);
        clonedElement.querySelector("select").name = `new_container_meal_items_${newI}[container_id]`;
        clonedElement.querySelector("input[type='number']").name = `new_container_meal_items_${newI}[price]`;
        clonedElement.querySelector("input[type='number']").id = `new_container_meal_items_${newI}_price`;
        clonedElement.querySelector("input[type='number']").value = "";
        clonedElement.querySelector('[data-name="ct-title"]').classList.add("hidden");
        clonedElement.querySelector('[data-price="ct-title-price"]').classList.add("hidden");
        clonedElement.querySelector('.ct-trash').style.marginTop = "0px";
        clonedElement.querySelector('.trash-icon').style.paddingLeft = "13px";
        clonedElement.querySelector('.trash-icon').classList.remove("not-clickable");
        clonesContainer.appendChild(clonedElement);

        updateContainerOptions();

        const addContainerClonedElem = clonedElement.querySelectorAll(".btn-add-containers");
        addContainerClonedElem.forEach(btn => btn.remove());

        const removeContainerClonedElems = clonedElement.querySelectorAll(".icon-update-delete-cmi");
        removeContainerClonedElems.forEach(removeBtn => removeBtn.addEventListener("click", function (event) {
            const parentContainer = event.target.closest(".div-of-all-inputs-containers");
            parentContainer.remove();
            const numClones = parentContainer.querySelectorAll(".div-of-all-inputs-containers").length;
        }));
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Nombre maximum de contenants atteint pour ce type de contenant'
        });
    }

  }));

}

function removeContainerOnClick() {
  const removeContainer = document.querySelectorAll(".container-created");

  removeContainer.forEach(btn => btn.addEventListener("click", function (event) {
      const parentContainer = event.target.closest(".ct-inputs");
      const grandparentContainer = event.target.closest(".all-prices-with-containers");
      parentContainer.remove();

      if(grandparentContainer.querySelectorAll('.ct-inputs').length === 1){
          grandparentContainer.remove();
      }
  }));
}

function updateContainerOptions() {

  // Edit meal item -> griser les options déjà utilisées pour le meal item
  const editSelects = document.querySelectorAll(".container-select.edit_select");

  if (editSelects) {
    editSelects.forEach(select => {
      const containerQuantitiesAlreadyUsed = JSON.parse(select.getAttribute("data-used"));

      select.querySelectorAll("option").forEach(option => {

        if (containerQuantitiesAlreadyUsed.includes(option.innerText.trim())) {
          option.classList.add("hidden");
        }
      });
    });
  }

  // Edit (New Container) && New (New Container) -> griser les options déjà utilisées pour le meal item
  const editNewSelects = document.querySelectorAll(".select-ct.edit_new_select");

  if (editNewSelects) {
    editNewSelects.forEach(select => {
      select.addEventListener("change", function (event) {
        const selectedQuantityOption = select.querySelector("option:checked");

        if (selectedQuantityOption) {
          const selectedQuantity = selectedQuantityOption.innerText.trim();
          const otherSelects = document.querySelectorAll(".select-ct.edit_new_select");

          otherSelects.forEach(otherSelect => {
            otherSelect.querySelectorAll("option").forEach(option => {
              if (option.innerText.trim() === selectedQuantity) {
                option.classList.add("hidden");
              }
            });
          });
        }
      });
    });
  }
}


export { initializeHandleCmiPrice }
