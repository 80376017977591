const validateBtnForEditPageSurvey = () => {

  if ( document.querySelector(".form-container-customer") && document.querySelector(".validate_btn_no_reward") && document.querySelector(".validate_btn_to_get_reward")) {
    console.log("validateBtnForEditPageSurvey")
    const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		const inputEmail = document.querySelector(".form-container-customer").querySelectorAll('input')[0]
		const btnValidateToGetReward = document.querySelector(".form-container-customer").querySelector('.validate_btn_to_get_reward')
		const btnValidateNoReward = document.querySelector(".form-container-customer").querySelector('.validate_btn_no_reward')
		btnValidateToGetReward.firstElementChild.setAttribute("disabled", "disabled");

		inputEmail.addEventListener('keyup', (event) => {
			if (inputEmail.value.length >= 4 && inputEmail.value.match(regex_email) !== null ) {
				btnValidateToGetReward.firstElementChild.classList.remove('not-clickable');
				btnValidateToGetReward.firstElementChild.classList.add('clickable-for-js');
				btnValidateToGetReward.firstElementChild.classList.add('btn-validate-for-js');
				btnValidateToGetReward.firstElementChild.removeAttribute("disabled");
				btnValidateNoReward.classList.add("hidden")
			}
			else {
				btnValidateToGetReward.firstElementChild.classList.add('not-clickable');
				btnValidateToGetReward.firstElementChild.classList.remove('clickable-for-js');
				btnValidateToGetReward.firstElementChild.classList.remove('btn-validate-for-js');
				btnValidateToGetReward.firstElementChild.setAttribute("disabled", "disabled");
				btnValidateNoReward.classList.remove("hidden")
			}
		})
  }

};

export { validateBtnForEditPageSurvey }
