
//Tableau des correspondances month_number => month_name
export const monthName = ["Jan", "Fev", "Mar", "Avr", "Mai", "Jun",
	"Jul", "Aou", "Sep", "Oct", "Nov", "Dec"
];

export const monthNameEn = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
	"Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const createLabelsArray = (dateTemp, format_evolution = null) => {
  const dateData = document.getElementById('date-data');
	// const dateTag = document.getElementById("date");

  if (dateData && dateData.dataset.timeframe != 'cumul') {
    return getGraphLabels(dateData)
  } else { // basically only the evolutions in e_reputation
    let dateLabels = [];

		let format = format_evolution || "cumul";
		console.log(format)

    for (let i = 1; i < 8; i++) {
      if (format === "Jour") {
        let dateString = dateTemp.getDate() + " " + (monthName[dateTemp.getMonth()]);
        dateLabels.push(dateString);
        dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 1));
      } else if (format === "Day") {
        let dateString = (monthNameEn[dateTemp.getMonth()]) + " " + dateTemp.getDate();
        dateLabels.push(dateString);
        dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 1));
      } else if (format === "Semaine") {
        // Patch for 'Rapport de scan'.
        if (dateTemp.getDay() == 1 && document.URL.includes("/scan_my_menu")) {
          dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 1));
        }

        let dateString = "S " + getMonday(dateTemp).getDate() + "/" + (getMonday(dateTemp).getMonth() + 1);
        dateLabels.push(dateString);
        dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 7));
      } else if (format === "Week") {
        // Patch for 'Rapport de scan'.
        if (dateTemp.getDay() == 1 && document.URL.includes("/scan_my_menu")) {
          dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 1));
        }
        let dateString = "W " + getMonday(dateTemp).getDate() + "/" + (getMonday(dateTemp).getMonth() + 1);
        dateLabels.push(dateString);
        dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 7));
      } else if (format === "Mois") {
        let dateString = monthName[(dateTemp.getMonth())] + " " + (dateTemp.getYear() - 100);
        dateLabels.push(dateString);
        if (dateTemp.getDate() > 28) {
          dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 4));
          dateTemp = new Date(dateTemp.setMonth(dateTemp.getMonth() - 1));
          console.log(dateTemp)
        }
        else {
          dateTemp = new Date(dateTemp.setMonth(dateTemp.getMonth() - 1));
        }
      } else if (format === "Month") {
        let dateString = monthNameEn[(dateTemp.getMonth())] + " " + (dateTemp.getYear() - 100);
        dateLabels.push(dateString);
        if (dateTemp.getDate() > 28) {
          dateTemp = new Date(dateTemp.setDate(dateTemp.getDate() - 4));
          dateTemp = new Date(dateTemp.setMonth(dateTemp.getMonth() - 1));
          console.log(dateTemp)
        }
        else {
          dateTemp = new Date(dateTemp.setMonth(dateTemp.getMonth() - 1));
        }
      } else {
				// console.log("dateTemp.getFullYear()", dateTemp.getFullYear())
        let dateString = dateTemp.getFullYear()
				// console.log("dateString", dateString)
        dateLabels.push(dateString);
        dateTemp = new Date(dateTemp.setYear(dateTemp.getFullYear() - 1));
      }
    }
    // const date = new Date(convertDate(dateTag.value));
    return dateLabels.reverse();
  }

};

export function convertDate(date) {
	let newDate = date
	if (newDate == date) {
		newDate = date.split('.').reverse().join('.');
	}
	return newDate

};

// Créer le tableau de labels pour evolutionSatisfaction
function getGraphLabels(dateData) {
  //console.log("dateData 2", dateData)
  const timeframe = dateData.getAttribute('data-timeframe');
  let rawRanges = dateData.getAttribute('data-ranges');
  let validJson = rawRanges
    .replace(/:start/g, '"start"')
    .replace(/:end/g, '"end"')
    .replace(/=>/g, ':')
    .replace(/(Mon|Tue|Wed|Thu|Fri|Sat|Sun, \d{2} [A-Z][a-z]{2} \d{4})/g, '"$1"')
    .replace(/"(Mon|Tue|Wed|Thu|Fri|Sat|Sun)", (\d{2} [A-Z][a-z]{2} \d{4})/g, '"$1 $2"');  // fix dates after start
  let ranges = JSON.parse(validJson);
  let labels = [];

  switch (timeframe) {
    case 'day':
      labels = ranges.map(range => {
        const date = new Date(range.start);
        return `${date.getDate()} ${date.toLocaleString('default', { month: 'short' })}`;
      });
      break;

    case 'week':
      labels = ranges.map(range => {
        const date = new Date(range.start);
        return `W ${date.getDate()}/${date.getMonth() + 1}`;
      });
      break;

    case 'month':
      labels = ranges.map(range => {
        const date = new Date(range.start);
        return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear().toString().substr(-2)}`;
      });
      break;

    case 'year':
      labels = ranges.map(range => {
        const date = new Date(range.start);
        return date.getFullYear();
      });
      break;

    default:
      labels = ranges.map(range => {
        const startDate = new Date(range.start);
        const endDate = new Date(range.end);
        return `${startDate.getDate()}/${startDate.getMonth() + 1}/${startDate.getFullYear().toString().substr(-2)} - ${endDate.getDate()}/${endDate.getMonth() + 1}/${endDate.getFullYear().toString().substr(-2)}`;
      });
  }

  return labels;
}

const getMonday = (d) => {
	d = new Date(d);
	var day = d.getDay(),
		diff = d.getDate() - day + (day == 0 ? -6 : 1); // adjust when day is sunday
	return new Date(d.setDate(diff));
};

