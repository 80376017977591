const manageFlagsMenu = () => {
  let arrayOfCheckFlagsList = ['flags_display_in_list_list_sp', 'flags_display_in_list_list_mm', 'flags_display_in_list_list_dmn'];
  let arrayOfCheckFlagsIcons = ['flags_display_in_list_icons_sp', 'flags_display_in_list_icons_mm', 'flags_display_in_list_icons_dmn'];
  const previewDiv = document.querySelector("iframe#preview");
  // Affichage des drapeaux en liste déroulante ou en icônes : simulation dans l'iframe
  arrayOfCheckFlagsIcons.forEach(function (flagOptionId) {
    const selectDisplayOptions = document.querySelectorAll(`#${flagOptionId}`)
    if (selectDisplayOptions) {
      selectDisplayOptions.forEach((selectDisplayOption) => {
        selectDisplayOption.addEventListener("click", (event) => {
          if (selectDisplayOption.checked == true) {
            previewDiv.contentDocument.querySelectorAll('.icon-flag').forEach((icon) => { icon.classList.remove("d-none") })
            previewDiv.contentDocument.querySelectorAll('.list-flag').forEach((list) => { list.classList.add("d-none") })
          }
        })
      })
    }
  })

  arrayOfCheckFlagsList.forEach(function (flagOptionId) {
    const selectDisplayLists = document.querySelectorAll(`#${flagOptionId}`)
    if (selectDisplayLists) {
      selectDisplayLists.forEach((selectDisplayList) => {
        selectDisplayList.addEventListener("click", (event) => {
          if (selectDisplayList.checked == true) {
            previewDiv.contentDocument.querySelectorAll('.icon-flag').forEach((icon) => { icon.classList.add("d-none") })
            previewDiv.contentDocument.querySelectorAll('.list-flag').forEach((list) => { list.classList.remove("d-none") })
          }
        })
      })
    }
  })

  // Section texte des flags : Faire apparaître la section que si l'option "Liste déroulante" est cochée
  arrayOfCheckFlagsList.forEach(function (flagOptionId) {
    const selectRightOption = document.querySelectorAll(`#${flagOptionId}`)
    if (selectRightOption) {
      selectRightOption.forEach((checkOption) => {
        if (checkOption.checked == true) {
          document.querySelectorAll('.section-text-flags').forEach((section) => { section.classList.remove("hidden") })
        }
        checkOption.addEventListener("click", (event) => {
          document.querySelectorAll('.section-text-flags').forEach((section) => { section.classList.remove("hidden") })
        })
      })
    }
  })

  arrayOfCheckFlagsIcons.forEach(function (flagOptionId) {
    const selectRightOption = document.querySelectorAll(`#${flagOptionId}`)
    if (selectRightOption) {
      selectRightOption.forEach((checkOption) => {
        if (checkOption.checked == true) {
          document.querySelectorAll('.section-text-flags').forEach((section) => { section.classList.add("hidden") })
        }
        checkOption.addEventListener("click", (event) => {
          document.querySelectorAll('.section-text-flags').forEach((section) => { section.classList.add("hidden") })
        })
      })
    }
  })

  // Changement de couleur du texte des flags : simulation dans l'Iframe
  const colorTextFlags = document.querySelectorAll("[data-for-text-flags]")
  if (colorTextFlags) {
    colorTextFlags.forEach((colorTextFlag) => {
      colorTextFlag.addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".text-flags").forEach((text) => {
          text.style.setProperty('color', event.currentTarget.value);
        })
      })
    })
  }

  // Changement de couleur de fond des flags : simulation dans l'Iframe
  const bkgColorFlags = document.querySelectorAll("[data-for-bkg-flags]")
  if (previewDiv) {
    const selectBkgColForJs = previewDiv.contentDocument.querySelector(".bkg-col-for-js")
  }
  //const selectBeforePseudoClass = window.getComputedStyle(selectBkgColForJs, ':before')
  if (bkgColorFlags) {
    bkgColorFlags.forEach((bkgColorFlag) => {
      bkgColorFlag.addEventListener("change", (event) => {
        console.log("hello")
        previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((elem) => {
          //elem.classList;
          //console.log(elem.classList)
          elem.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
        // console.log("rachel", selectBeforePseudoClass.backgroundColor)
        // selectBeforePseudoClass.backgroundColor = "#000000"
        // console.log("rachel", selectBeforePseudoClass.backgroundColor)
      })
    })
  }

  //Couleur de fond des flags : pouvoir mettre une couleur de fond ou la retirer

  // START PAGE
  const addColorPickerSpm = document.querySelector('.hide-bkg-col-flags-spm')
  let colPickerFlagsSpm = document.getElementById('colorpicker-spm')
  const selectColorPickerBkgFlagsSpm = document.getElementById('remove-colorpicker-bkg-list-spm-for-js')
  const displayColorPickerForFlagsSpm = document.getElementById('display_color_picker_bkg_flags_spm')
  // Supprimer la couleur de fond et cacher le color picker quand on clique sur la poubelle
  const trashBkgFlagsSpm = document.getElementById('remove-bkg-flags-spm')
  if (trashBkgFlagsSpm) {
    trashBkgFlagsSpm.addEventListener("click", () => {
      colPickerFlagsSpm.innerHTML = ""
      selectColorPickerBkgFlagsSpm.classList.add('hidden')
      addColorPickerSpm.classList.remove('hidden')
      previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((elem) => { elem.style.setProperty("background-color", "transparent", "important") })
      displayColorPickerForFlagsSpm.checked = false
    })
  }
  // Afficher l'input du color picker quand on clique sur la checkBox pour pouvoir mettre une couleur de fond
  if (displayColorPickerForFlagsSpm){
    displayColorPickerForFlagsSpm.addEventListener("click", () => {
      colPickerFlagsSpm.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_flags_display_in_list_menu' id='bkg_col_flags_display_in_list_menu'>")
      colPickerFlagsSpm.querySelector("input").addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
      selectColorPickerBkgFlagsSpm.classList.remove("hidden")
      addColorPickerSpm.classList.add('hidden')
    })
  }

  // MY MENU
  const addColorPickerMm = document.querySelector('.hide-bkg-col-flags-mm')
  let colPickerFlagsMm = document.getElementById('colorpicker-mm')
  const selectColorPickerBkgFlagsMm = document.getElementById('remove-colorpicker-bkg-list-mm-for-js')
  const displayColorPickerForFlagsMm = document.getElementById('display_color_picker_bkg_flags_mm')
  // Supprimer la couleur de fond et cacher le color picker quand on clique sur la poubelle
  const trashBkgFlagsMm = document.getElementById('remove-bkg-flags-mm')
  trashBkgFlagsMm.addEventListener("click", () => {
    colPickerFlagsMm.innerHTML = ""
    selectColorPickerBkgFlagsMm.classList.add('hidden')
    addColorPickerMm.classList.remove('hidden')
    previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((elem) => { elem.style.setProperty("background-color", "transparent", "important") })
    displayColorPickerForFlagsMm.checked = false
  })
  // Afficher l'input du color picker quand on clique sur la checkBox pour pouvoir mettre une couleur de fond
  displayColorPickerForFlagsMm.addEventListener("click", () => {
    colPickerFlagsMm.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_flags_display_in_list_menu_mm' id='bkg_col_flags_display_in_list_menu_mm'>")
    colPickerFlagsMm.querySelector("input").addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((btn) => {
        btn.style.setProperty('background-color', event.currentTarget.value, 'important');
      })
    })
    selectColorPickerBkgFlagsMm.classList.remove("hidden")
    addColorPickerMm.classList.add('hidden')
  })

  // DETAIL MENU
  const addColorPickerDm = document.querySelector('.hide-bkg-col-flags-dm')
  let colPickerFlagsDm = document.getElementById('colorpicker-dm')
  const selectColorPickerBkgFlagsDm = document.getElementById('remove-colorpicker-bkg-list-dm-for-js')
  const displayColorPickerForFlagsDm = document.getElementById('display_color_picker_bkg_flags_dm')
  // Supprimer la couleur de fond et cacher le color picker quand on clique sur la poubelle
  const trashBkgFlagsDm = document.getElementById('remove-bkg-flags-dm')
  if (trashBkgFlagsDm) {
    trashBkgFlagsDm.addEventListener("click", () => {
      colPickerFlagsDm.innerHTML = ""
      selectColorPickerBkgFlagsDm.classList.add('hidden')
      addColorPickerDm.classList.remove('hidden')
      previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((elem) => { elem.style.setProperty("background-color", "transparent", "important") })
      displayColorPickerForFlagsDm.checked = false
    })
  }
  // Afficher l'input du color picker quand on clique sur la checkBox pour pouvoir mettre une couleur de fond
  if (displayColorPickerForFlagsDm) {
    displayColorPickerForFlagsDm.addEventListener("click", () => {
      colPickerFlagsDm.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_flags_display_in_list_menu_dm' id='bkg_col_flags_display_in_list_menu_dm'>")
      colPickerFlagsDm.querySelector("input").addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".bkg-col-for-js").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
      selectColorPickerBkgFlagsDm.classList.remove("hidden")
      addColorPickerDm.classList.add('hidden')
    })
  }
}

export { manageFlagsMenu };
