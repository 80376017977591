import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["radioBtnContainer"]

  connect() {
    this.radioBtnContainerTargets.forEach(container => {
      const urlInput = container.querySelector('#redirection_url')
      const emailInput = container.querySelector('#redirection_email')

      container.addEventListener('change', (event) => {
        this.handleChange(event, urlInput, emailInput);
      })
    });
  }

  handleChange(event, urlInput, emailInput) {
    const selectedOption = event.target.value;

    if (selectedOption === 'url_selected') {
      urlInput.removeAttribute('disabled');
      emailInput.setAttribute('disabled', 'true');
    } else if (selectedOption === 'email_selected') {
      urlInput.setAttribute('disabled', 'true');
      emailInput.removeAttribute('disabled');
    }
  }
}
