const BtnsRadioNpsSurvey = () => {

  if (document.getElementById('reco_oui_non') ){
      console.log('BtnsRadioNpsSurvey')
      $(document).ready(function() {
        $("input[type=radio]").click(function() {

          // Get the storedValue
          const tags = document.querySelector(".mobile-device").querySelector(".tags-div-mobile");
          var previousValue = $(this).data('storedValue');
          // if previousValue = true then
          //     Step 1: toggle radio button check mark.
          //     Step 2: save data-StoredValue as false to indicate radio button is unchecked.
          if (previousValue) {
            $(this).prop('checked', !previousValue);
            $(this).data('storedValue', !previousValue);
            tags.classList.toggle("hidden");
          }
          // If previousValue is other than true
          //    Step 1: save data-StoredValue as true to for currently checked radio button.
          //    Step 2: save data-StoredValue as false for all non-checked radio buttons.
          else{
            $(this).data('storedValue', true);
            $("input[type=radio]:not(:checked)").data("storedValue", false);
          }
        });
      });
    }

}

export {BtnsRadioNpsSurvey};
