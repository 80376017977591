const uncheckFieldAll = () => {
  if(document.querySelector('.modif-field--container') || document.querySelector('.modif-field--container_mono')) {
    console.log('uncheckFieldAll');

    const fields = document.querySelectorAll('.modif-field--wrapper');
    const fieldsWithoutAll = [...fields].filter(field => field.id != "all");
    const fieldAll = document.getElementById('all');

    fieldsWithoutAll.forEach(field => 
      
      field.addEventListener('click', () => {
        console.log(field);
        if(fieldAll.checked && field.checked) {
          fieldAll.checked = false;
        }
      })
    );
    
    // Lorsque l'on clique sur 'all' tout les autres fields doivent uncheck
    fieldAll.addEventListener('click', () => {
      fieldsWithoutAll.forEach(field => field.checked = false);
    });
  }
};

export { uncheckFieldAll };