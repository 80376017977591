// Goal : Maintain drop-menu opened if there is at least one class 'current-open-menu' among the 'text-submenu' of the 'drop-menu'
// TODO :
// 1- Name the function
// 2- Define the CSS-SELECTOR element to interact with
// 3- Write the appropriate condition for each CSS-SELECTOR
//    if 'current-open-menu' selected == 'text-submenu'
//    'drop-menu' opened
//    else 'drop-menu' not opened
//
// 4- Define the event listener to react with
//    'click'
// 5- Define the event resulting
//    '.collapse in'

const navbarDropdownCollapseIn = () => {
  const tabActiveCollapse = document.querySelector('.current-open-menu');
  if (tabActiveCollapse) {
    const rapportDetailles = document.getElementById("collapseExample2");
    const parametres = document.getElementById("collapseExample3");
    const donneesClients = document.getElementById("collapseExample7");
    const emailing = document.getElementById("collapseExample5");
    const menuDigital = document.getElementById("collapseExample6");
    const eReputation = document.getElementById("collapseExample17");

    if (tabActiveCollapse.dataset.navbarIndicationCollapse === "rapport-detailles") {
      rapportDetailles.classList.add("in")
      rapportDetailles.classList.add("show")
    } else if (tabActiveCollapse.dataset.navbarIndicationCollapse === "parametres") {
      parametres.classList.add("in")
      parametres.classList.add("show")
    } else if (tabActiveCollapse.dataset.navbarIndicationCollapse === "emailing") {
      emailing.classList.add("in")
      emailing.classList.add("show")
    } else if (tabActiveCollapse.dataset.navbarIndicationCollapse === "donnees-clients") {
      donneesClients.classList.add("in")
      donneesClients.classList.add("show")
    } else if (tabActiveCollapse.dataset.navbarIndicationCollapse === "menu_digital") {
      menuDigital.classList.add("in")
      menuDigital.classList.add("show")
    } else if (tabActiveCollapse.dataset.navbarIndicationCollapse === "ereputation") {
      eReputation.classList.add("in")
      eReputation.classList.add("show")
    }
  };
};

export { navbarDropdownCollapseIn }
