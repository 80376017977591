// app/javascript/components/photo_preview.js
const previewImageOnFileSelectCategory = (context = document) => {
  //CREATION OF CATEGORY
  // we select the photo input
  const inputCat = context.querySelector('#cover_photo_create_category');
  if (inputCat) {
    console.log('previewImageOnFileSelectCategory method Create');
    // we add a listener to know when a new picture is uploaded
    inputCat.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreview(inputCat, context);
      if (inputCat.files[0]) {
        if (inputCat.files[0].size < 1000000) {
          context.querySelector('.clear-btn').classList.remove('hidden');
        }
      }
    });
  }

  //EDIT OF A CATEGORY
  const inputEditCat = context.querySelector('#photo-input-category-edit');
  if (inputEditCat) {
    console.log('previewImageOnFileSelectCategory method Edit', inputEditCat);
    // we add a listener to know when a new picture is uploaded
    inputEditCat.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreviewEdit(inputEditCat, context);
      console.log(inputEditCat.files[0]);
      if (inputEditCat.files[0]) {
        if (inputEditCat.files[0].size < 1000000) {
          context.querySelectorAll('.clear-btn-edit').forEach((btn) => {
            btn.classList.remove('hidden');
          });
        }
      }
    });
  }
};

const displayPreviewEdit = (input, context) => {
  if (input.files && input.files[0]) {
    console.log('displayPreviewEditCategory');
    const reader = new FileReader();
    reader.onload = (event) => {
      context.querySelector('#photo-preview-meal-new').src = event.currentTarget.result;
    };
    reader.readAsDataURL(input.files[0]);
    context.querySelector('#photo-preview-meal-new').classList.remove('hidden');
    if (context.querySelector('#photo-preview-category-edit old-picture-js')) {
      context.querySelector('#photo-preview-category-edit old-picture-js').classList.add('hidden');
    }
  }
};

const displayPreview = (input, context) => {
  if (input.files && input.files[0]) {
    console.log('displayPreviewCategoryCategory');
    const reader = new FileReader();
    reader.onload = (event) => {
      context.querySelector('#photo-preview-category-new').src = event.currentTarget.result;
    };
    reader.readAsDataURL(input.files[0]);
    context.querySelector('#photo-preview-category-new').classList.remove('hidden');
    if (context.querySelector('#photo-preview-category-new old-picture-js')) {
      context.querySelector('#photo-preview-category-new old-picture-js').classList.add('hidden');
    }
  }
};

export { previewImageOnFileSelectCategory };
