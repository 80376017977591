// Generic manager for preview of images when uploading images.
const previewImageOnUpload = () => {
  const createEtablissementInput = document.getElementById('img_create_etablissement_go');
  const surveyInput = document.getElementById('header_img_survey');

  if (createEtablissementInput) {
    console.log('previewImageOnUpload');
    createEtablissementInput.addEventListener('change', function(event) {
      handleImageInputChange(event, false);
    });
  }

  if (surveyInput) {
    console.log('previewImageOnUpload');
    handleDestroyImage(surveyInput)
    surveyInput.addEventListener('change', function(event) {
      handleImageInputChange(event, true);
    });
  }
};

// The iframe argument allows us to manage the custom_questionnaire case where the images are displayed inside an iframe.

  const handleImageInputChange = (event, iframe) => {
    const input = event.target;
    const previewDiv = getPreviewDiv(input);
    let fileName = input.files[0].name;
    if (iframe) {
      previewDiv.classList.add('avatar-new-survey-v2')
    }
    displayImage(input, previewDiv);
    displayDescription(input, fileName);
  }

const getPreviewDiv = (input) => {
  if (input.id === 'img_create_etablissement_go') {
    return document.getElementById('photo-preview-etablissement-new');
  } else if (input.id === 'header_img_survey') {
    return document.querySelector("iframe#preview").contentDocument.getElementById('header_img_survey_target_js');
  }
};

const displayImage = (input, previewDiv) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (event) => {
      previewDiv.src = event.target.result;
    };
    reader.readAsDataURL(input.files[0]);
    previewDiv.classList.remove('hidden');
    const oldPicture = document.getElementById('photo-preview-etablissement-new old-picture-js');
    if (oldPicture) {
      oldPicture.classList.add('hidden');
    }
  }
};

// For now specific to 'custom_menu'.
const displayDescription = (input, description) => {
  var imageDescription = input.nextElementSibling
  if (imageDescription.classList.value == 'header_img_survey_description') {
    if (description.length > 30) {
      imageDescription.textContent = description.substring(0, 30) + '...';
    } else {
      imageDescription.textContent = description;
    }
    $('.remove-logo-for-js').removeClass('hidden')
  }
}

const handleDestroyImage = (input) => {
  const removeBtn = $('.remove-logo-for-js')
  removeBtn.on('click', function() {
    let description = removeBtn.data('info-message')
    let previewDiv = getPreviewDiv(input)
    $('.remove-header-img').val(true)
    input.value = ''
    displayDescription(input, description);
    previewDiv.src = "";
    previewDiv.classList.remove('avatar-new-survey-v2')
    removeBtn.addClass('hidden')
  })
}

export { previewImageOnUpload };
