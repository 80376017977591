import Sortable from 'sortablejs';

const initSortableOnTagMeal = () => {
  const list = document.querySelector(".sortablejs-div-tags-menu");
  if (list) {
    console.log('initSortableOnTagMeal')
    Sortable.create(list, {
      group: "localStorage-example6",
      store: {
        /**
         * Get the order of elements. Called once during initialization.
         * @param   {Sortable}  sortable
         * @returns {Array}
         */
        get: function (sortable) {
          var order = localStorage.getItem(sortable.options.group.name);
          return order ? order.split('|') : [];
        },

        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: function (sortable) {
          var order = sortable.toArray();
          localStorage.setItem(sortable.options.group.name, order.join('|'));
        }
      }
    })
  }

}

const initSortableOnTagService = () => {
  const list = document.querySelector(".sortablejs-div-tags-service");
  if (list) {
    console.log('initSortableOnTagService')
    Sortable.create(list, {
      group: "localStorage-example8",
      store: {
        /**
         * Get the order of elements. Called once during initialization.
         * @param   {Sortable}  sortable
         * @returns {Array}
         */
        get: function (sortable) {
          var order = localStorage.getItem(sortable.options.group.name);
          return order ? order.split('|') : [];
        },

        /**
         * Save the order of elements. Called onEnd (when the item is dropped).
         * @param {Sortable}  sortable
         */
        set: function (sortable) {
          var order = sortable.toArray();
          localStorage.setItem(sortable.options.group.name, order.join('|'));
        }
      }
    })
  }
}

const initSortableOnTagCategory = () => {
  // array of all Theme Autre (ambiance, propreté...)
  if (document.getElementById('info-for-sortable-categ-mastcat-js')) {
    console.log('initSortableOnTagCategory')
    const arrayAllMasterCateg = eval(document.getElementById('info-for-sortable-categ-mastcat-js').dataset.allMasterCateg)

    if (arrayAllMasterCateg) {
      // array of all classes in html to get the right div (.sortablejs-div-tags-Ambiance...)
      const arrayOfClassSortableJs = []
      arrayAllMasterCateg.forEach((mastcat) => {
        arrayOfClassSortableJs.push(`.sortablejs-div-tags-categ-mastcat-${mastcat}`)
      })

      // array of number to create group "localStorage-example6" etc..
      const arrayI = Array(arrayAllMasterCateg.length).fill().map((x,i)=> 9+ i)

      // creating empty variable list6, list7 etc...
      arrayI.forEach((i) => {
        window["list"+i] = new Array();
      })


      // for each theme question, do localStorage for sortablejs to drag and drop tags theme autre
      var i
      for (i = 0; i <= arrayAllMasterCateg.length - 1; i++) {
        window["list"+i] = document.querySelector(arrayOfClassSortableJs[i])
        if (window["list"+i]) {
          Sortable.create(window["list"+i], {
            group: `localStorage-example${arrayI[i]}`,
          })
        }
      }
    }
  }
}

const initSortableOnMasterCateg = () => {
  const allLists = document.querySelectorAll("[class*=sortablejs-mastercateg]");
  allLists.forEach((list) => {
    if (list) {
      console.log('initSortableOnMasterCateg')

      Sortable.create(list, {
        onEnd: function(event, ui) {
            group: list.className;
            let i = 1
            event.to.querySelectorAll(".div-for-sortable-js-mastercat").forEach((div) => {
              div.querySelector('input').value = i
              div.querySelector('.mastercat-ordre').innerText = `${i} - `
              i += 1
            })
        }
      });
    }
  })
}

function initSortableOnMealitems() {
	const lists = document.querySelectorAll('.sortablejs-meal_items_all');
	if (document.querySelector('.sortablejs-meal_items_all') && document.getElementById("parametre-carte-multisite-for-js").dataset.userAdminOrLAdmin === "true") {
		console.log('initSortableOnMeal')
		lists.forEach((list) => {
			Sortable.create(list, {
				group: "localStorage-example",
			})
		})
	}


	const listsmeals = document.querySelectorAll('.sortablejs-meal_items');
	if (document.querySelector('.sortablejs-meal_items') ) {
		console.log('initSortableOnMeal')
		listsmeals.forEach((list) => {
			Sortable.create(list, {
				group: "localStorage-example",
			})
		})
	}
}


////////////////////////////////////////////////////////////////////////////////////////////

const setDragCursor = value => {
  const html = document.getElementsByTagName('html').item(0)
  html.classList.toggle('grabbing', value)
}

const initSortable = (listSelector, attributType, prefixOrder = null, modalId = null) => {

  const allLists = document.querySelectorAll(listSelector);
  const sortableInstances = [];

  allLists.forEach((list) => {

    const numberOfItems = list.querySelectorAll(`[${attributType}]`).length - list.querySelectorAll('.non-draggable').length

    if (list && numberOfItems > 1) {

      const sortableInstance = Sortable.create(list, {
        draggable: ".draggable",
        handle: '.handle',
        animation: 150,
        forceFallback: true,
        ghostClass: 'hide-dragging-div-js',

        onStart() {
          Sortable.ghost.style.opacity = 1;
          setDragCursor(true)
        },

        onEnd: function(event) {

          setDragCursor(false)

          // CHANGE LE NUMERO DANS l'INTITULÉ LORS DU DROP
          if (prefixOrder) {
            let i = 1
            event.to.querySelectorAll(".div-for-sortable-js-enumeration").forEach((div) => {
              div.querySelector('.question-title-ordre-js').innerText = `${prefixOrder}${i}`
              i += 1
            })
          }

          // AJAX POUR ENVOYER NOUVEL ORDRE DE L'ARRAY AU CONTROLLER
          const sortedArray = []
          const linesOrderAfterSort = list.querySelectorAll(`[${attributType}]`)

          linesOrderAfterSort.forEach((element) => {
            sortedArray.push(element.getAttribute(attributType))
          })

          $.ajax({
            method: 'PATCH',
            url: list.dataset.path,
            headers: { 'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') },

            data: { type: 'json', sorted_array: sortedArray },

            success: (data) => {
              console.log(data)
            },

            error: function () {
              console.log('error')
            }
          });
        }
      });
      sortableInstances.push(sortableInstance);

    }
  });

  // FIX PROBLEME INSTANCES DE SORTABLE CRÉÉES EN DOUBLE LORSQU'ON OUVRE UNE MODALE
  const modal = document.querySelector(modalId)

  if (modal) {
    $(modal).on('show.bs.modal', () => {
      sortableInstances.forEach((instance) => {
        instance.option('disabled', true);
      });
    });
  }

}

const initSortableOnEnquete = () => {
  initSortable(".sortablejs-enquetes", "data-enquete-id", null, "#myModal-edit-enquete")
}

const initSortableOnTags = () => {
  initSortable(".sortablejs-tags2", "data-tag-id", "Réponse n°")
}

const initSortableOnStartPage = () => {
  initSortable(".sortablejs-start-page", "data-start-button-id", "Lien n°")
}

const initSortableOnServeur = () => {
  initSortable(".sortablejs-employee", "data-employee-id")
}

const initSortableOnRewardsFid = () => {
  initSortable(".sortablejs-reward-fid", "data-fid-reward-id")
}

const initSortableOnRewardsDirect = () => {
  initSortable(".sortablejs-reward-direct", "data-direct-reward-id")
}

const initSortableOnGroupQuestions = () => {
  initSortable(".sortablejs-group-question-parameters", "data-groupe-question-sortable-id", null, "#myModal-edit-groupe-question")
}

const initSortableOnQuestions = () => {
  initSortable(".sortablejs-question-parameters", "data-question-sortable-id", null, "#myModal-edit-groupe-question")
}

const initSortableOnLinkedQuestions = () => {
  initSortable(".sortablejs-linked-questions", "data-link-question-sortable-id", null, "#myModal-edit-groupe-question")
}

const initSortableOnMealQuestions = () => {
  initSortable(".sortablejs-meal-questions", "data-meal-question-sortable-id", null, "#myModal-edit-groupe-question")
}

export {
  initSortableOnStartPage,
  initSortableOnEnquete,
  initSortableOnTags,
  initSortableOnRewardsFid,
  initSortableOnRewardsDirect,
  initSortableOnServeur,
  initSortableOnGroupQuestions,
  initSortableOnQuestions,
  initSortableOnLinkedQuestions,
  initSortableOnMealQuestions,
	initSortableOnMealitems,
  // DRAG AND DROP RESTANTS A MAJ
  initSortableOnMasterCateg,
  initSortableOnTagMeal,
  initSortableOnTagService,
  initSortableOnTagCategory,
};
