import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["search"];

  connect() {
    console.log("IndexRestaurantSearchBar Controller Connecté");
  }

  searchBar () {
  
    const searchItem = document.querySelector('#research-search-bar');
        if (searchItem) {
            console.log('searchItem')
            searchItem.addEventListener('keyup', () => {
                if (searchItem.value === "") {
                    const url_string = window.location.href
                    const url = new URL(url_string);
                    const redirectionPath = url.pathname
                    location.href = redirectionPath
                }
            })
        }
    }
}