// app/javascript/controllers/indicators_filters_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "chevron", "tag", "tagsCount", 'surveyMainCheckbox', 'surveyCheckbox', 'indicatorCheckbox'];

  connect() {
    console.log("Le contrôleur IndicatorsFilters est connecté");
    this.checkSelectAllState();
    this.openTagListsIfNeeded();
    this.updateTagsCount()
  }

  // CheckBox "Tout sélectionner"
  toggleAll(event) {
    const isChecked = event.target.checked;
    const checkboxes = this.element.querySelectorAll('.indicator-checkbox, .survey-checkbox, .tag-checkbox, .enquete-checkbox');
    checkboxes.forEach(checkbox => {
      checkbox.checked = isChecked;
    });
    this.checkSelectAllState();
    this.updateTagsCount();
  }

  // Rotate chevron au clic
  toggleChevron(event) {
      const chevron = event.currentTarget.querySelector('.fa-chevron-right');
      if (chevron) {
        chevron.style.transition = 'transform 0.2s ease'
        chevron.classList.toggle('rotate-90');
      }
  }

  // Indicators avec tags
  checkAllTags(event) {
    const indicatorCheckbox = event.target;
    const indicatorTheme = indicatorCheckbox.dataset.indicator;
    const tagCheckboxes = this.element.querySelectorAll(`.tag-checkbox[data-indicator='${indicatorTheme}']`);
    tagCheckboxes.forEach(checkbox => {
      checkbox.checked = indicatorCheckbox.checked;
    });
    this.updateTagsCount();
  }

  checkTag(event) {
    const tagElement = event.target;
    const indicatorTheme = tagElement.dataset.indicator;
    const checkbox = this.element.querySelector(`.indicator-checkbox[name='indicators[${indicatorTheme}]']`);

    if (checkbox) {
      checkbox.checked = Array.from(this.element.querySelectorAll(`.tag-checkbox[data-indicator='${indicatorTheme}']`))
                             .some(tag => tag.checked);
    }
    this.updateTagsCount();
  }

  // Enquêtes
  checkAllSurvey() {
    const isChecked = this.surveyMainCheckboxTarget.checked;
    this.surveyCheckboxTargets.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });

    this.updateTagsCount();
  }

  checkSurvey() {
    let isOneChecked = false;
    this.surveyCheckboxTargets.forEach((checkbox) => {
      if (checkbox.checked) isOneChecked = true
    });
    this.surveyMainCheckboxTarget.checked = isOneChecked;

    this.updateTagsCount();
  }

  //  Other functions
  checkSelectAllState() {
    const allCheckboxes = Array.from(this.element.querySelectorAll('.indicator-checkbox, .survey-checkbox, .tag-checkbox'));
    const selectAllCheckbox = this.element.querySelector('#select-all');
    const allChecked = allCheckboxes.every(checkbox => checkbox.checked);
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = allChecked;
    }
  }

  openTagListsIfNeeded() {
    const lists = this.element.querySelectorAll('.options-list');
    lists.forEach((list) => {
      const checkboxes = list.querySelectorAll('.tag-checkbox, .enquete-checkbox');
      const anyChecked = Array.from(checkboxes).some(checkbox => checkbox.checked);
      if (anyChecked) {
        list.closest('div').classList.add('show')
        const chevron = list.closest("li").querySelector(".fa-solid.fa-chevron-right");
        chevron.classList.add('rotate-90')
      };
    });
  }

  updateTagsCount() {
    const formCheckboxes = this.indicatorCheckboxTargets.concat(this.surveyCheckboxTargets);
    const filteredCheckedInputs = formCheckboxes.filter(checkbox => checkbox.checked);
    this.tagsCountTarget.innerHTML = filteredCheckedInputs.length
  }
}
