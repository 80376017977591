import { Controller } from "stimulus";

export default class extends Controller {
  connect() {

    this.element.querySelectorAll(".card-admin-rest-select-sous-groupe").forEach((parentSousGroup) => {
        parentSousGroup.addEventListener("click", () => this.toggleChildSousGroups(parentSousGroup));
    });

    this.hideDescendantsIfCountGreaterThan8();

    const blueBorderTh = this.element.querySelector('th.blue-border.results-fixed-top.specific-z-index');
    if (blueBorderTh) {
      blueBorderTh.addEventListener('click', () => {
        this.hideDescendantsIfCountGreaterThan8();
        this.element.querySelectorAll(`.card-admin-rest-select-resto`).forEach((restaurant) => {
          setTimeout(() => {
            restaurant.classList.toggle("hidden", true);
          }, 400);
          const restaurantRow = restaurant.closest("tr");
          restaurantRow.classList.add("smoothcb");
          restaurantRow.classList.remove("visible");
          setTimeout(() => {
            restaurantRow.style.display = "none";
          }, 400);
        });
        this.element.querySelectorAll(".fa-chevron-down, .fa-chevron-up").forEach((c) => {
          c.classList.remove("fa-chevron-up");
          c.classList.add("fa-chevron-down");
        });

      });
    }
  }

  hideDescendantsIfCountGreaterThan8() {
    const sousGroupes = this.element.querySelectorAll(".card-admin-rest-select-sous-groupe");
    sousGroupes.forEach((sg) => {
      const rootId = sg.dataset.rootId;
      const sgId = sg.dataset.sgId;
      const count = parseInt(sg.dataset.restaurantsCount);

      if (count > 8 && rootId !== sgId) {
        sg.classList.add("hidden"); // hide descendants
      }

      // Hide child child_sg elements
      const childSousGroupes = this.element.querySelectorAll(`.card-admin-rest-select-sous-groupe[data-parent-id="${sgId}"]`);
      childSousGroupes.forEach((childSg) => {
        childSg.closest("tr").classList.add("smoothcb");
        childSg.closest("tr").classList.remove("visible");
        setTimeout(() => {
          childSg.closest("tr").style.display = "none";
        }, 400);
        childSg.classList.add("hidden");
      });
    });
  }


  toggleChildSousGroups(parentSousGroup, to_hide = null) {
    console.log("click !");
    const sousGroupId = parentSousGroup.getAttribute("data-sg-id");
    const elementsToToggle = this.element.querySelectorAll(`[data-parent-id="${sousGroupId}"]`);

    // If to_hide is not explicitly provided, determine based on the current visibility of direct children
    if (to_hide === null && elementsToToggle.length > 0) {
      to_hide = !elementsToToggle[0].classList.contains("hidden");
    }

    console.log(to_hide);

    const hideOrShowDescendants = (parentId, hide) => {
      const childElements = this.element.querySelectorAll(`[data-parent-id="${parentId}"]`);
      childElements.forEach((child) => {
        const childRow = child.closest("tr");
        if (hide) {
          childRow.classList.add("smoothcb");
          childRow.classList.remove("visible");
          setTimeout(() => {
            childRow.style.display = "none";
            child.classList.add("hidden");
          }, 400);
        } else {
          child.classList.remove("hidden");
          childRow.classList.add("visible");
          childRow.classList.remove("smoothcb");
          childRow.style.display = "";
        }

        // Hide or show associated restaurants and QR codes
        const childSgId = child.getAttribute("data-sg-id");
        this.element.querySelectorAll(`.card-admin-rest-select-resto.sg-${childSgId}`).forEach((restaurant) => {
          setTimeout(() => {
            restaurant.classList.toggle("hidden", hide);
          }, 400);
          const restaurantRow = restaurant.closest("tr");
          if (hide) {
            restaurantRow.classList.add("smoothcb");
            restaurantRow.classList.remove("visible");
            setTimeout(() => {
              restaurantRow.style.display = "none";
            }, 400);
          } else {
            restaurantRow.style.display = "";
            restaurantRow.classList.remove("smoothcb");
            restaurantRow.classList.add("visible");
          }
        });
        this.element.querySelectorAll(`.sousgroupe-${childSgId}.custom-btn.icon-qr-code-container`).forEach((qrCode) => {
          qrCode.classList.toggle("hidden", hide);
        });

        // Recursively hide or show the descendants
        hideOrShowDescendants(childSgId, hide);
      });

      // Update chevron icon of the parent element
      const parentChevronIcon = this.element.querySelector(`.card-admin-rest-select-sous-groupe[data-sg-id="${parentId}"] .fa-chevron-down, .maintable2[data-sg-id="${parentId}"] .fa-chevron-up`);
      if (parentChevronIcon) {
        if (hide) {
          parentChevronIcon.classList.remove("fa-chevron-up");
          parentChevronIcon.classList.add("fa-chevron-down");
        } else {
          parentChevronIcon.classList.remove("fa-chevron-down");
          parentChevronIcon.classList.add("fa-chevron-up");
        }
      }
    };


    if (to_hide) {
      // Hide all descendants when clicking on a parent child_sg
      hideOrShowDescendants(sousGroupId, true);
    }

    elementsToToggle.forEach((element) => {
      const elementRow = element.closest("tr");
      if (to_hide) {
        elementRow.classList.remove("visible");
        elementRow.classList.add("smoothcb");
        setTimeout(() => {
          elementRow.style.display = "none";
          element.classList.add("hidden");
        }, 400);
        // Update chevron icon for hidden elements
        const chevronIcon = element.querySelector(".fa-chevron-down, .fa-chevron-up");
        if (chevronIcon) {
          chevronIcon.classList.remove("fa-chevron-up");
          chevronIcon.classList.add("fa-chevron-down");
        }
      } else {
        element.classList.remove("hidden");
        elementRow.classList.add("visible");
        elementRow.classList.remove("smoothcb");
        elementRow.style.display = "";
      }

      const parentRow = element.closest("tr");
      if (parentRow) {
        if (to_hide) {
          parentRow.classList.add("smoothcb");
          parentRow.classList.remove("visible");
          setTimeout(() => {
            parentRow.style.display = "none";
          }, 400);
        } else {
          parentRow.style.display = "";
          parentRow.classList.remove("smoothcb");
          parentRow.classList.add("visible");
        }
      }
    });

    // Update chevron icon based on visibility of child elements
    const chevronIcon = parentSousGroup.querySelector(".fa-chevron-down, .fa-chevron-up");
    if (chevronIcon) {
      if (to_hide) {
        chevronIcon.classList.remove("fa-chevron-up");
        chevronIcon.classList.add("fa-chevron-down");
      } else {
        chevronIcon.classList.remove("fa-chevron-down");
        chevronIcon.classList.add("fa-chevron-up");
      }
    }
  }
}
