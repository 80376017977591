import { Controller } from 'stimulus';

export default class extends Controller {

  connect() {
    const context = document
    const allPicto = context.querySelectorAll(".container-icon-picto, .for_uncheck_radio_js")
    if (allPicto.length != 0) {
      console.log("uncheckRadioBtns")
      const checkboxPictos = context.querySelector(".checkbox-edit-meal-all-pictos")
      allPicto.forEach((picto) => {
        picto.addEventListener('mousedown', () => {
          console.log("l'event m'entend")
          if (!checkboxPictos || checkboxPictos.checked === true) {
            if (picto.firstElementChild.checked) {
              picto.firstElementChild.checked = false
              picto.firstElementChild.removeAttribute("checked")
              console.log("le checked est a true, donc on entre dans le if et on passe le checked à false")
            } else {
              console.log("le checked est à false, donc on entre dans le else et on passe le checked à true")
              picto.firstElementChild.checked = true
            }
          }
        })
      })
    }
  }
}
