const chooseTemplate = () => {
	const reviewAnswer = document.getElementById("review-answer");
	const tabcontentAnswer = document.querySelector(".tabcontent-answer");

	const dropdownBtn1 = document.querySelectorAll(".modal-templates-btn");


	Array.prototype.forEach.call(dropdownBtn1, function(btn) {
		btn.addEventListener('click', () => {
			console.log("First part -- open modal")
			// tab-pane review-answer--wrapper active
			btn.querySelector(".fa-bars").classList.toggle("hidden")
			btn.querySelector(".fa-bars").id = "bars-active"
			btn.querySelector(".fa-times").classList.toggle("hidden")
			btn.querySelector(".fa-times").id = "times-active"

			if (reviewAnswer) {
				let estabID = document.querySelector(".tab-pane.review-answer--wrapper.active").querySelector(".estab_id").innerText
				let estabTemplates = document.getElementById(`templates_for_${estabID}`)
				if (estabTemplates) {
					console.log(estabTemplates);
					estabTemplates.classList.toggle("hidden")
				}
				if (reviewAnswer.classList.contains("template-choose-answer")) {
					setTimeout(() => {
						reviewAnswer.classList.remove("template-choose-answer-opacity");
						reviewAnswer.classList.remove("template-choose-answer");
					}, 280);
				} else {
					reviewAnswer.classList.add("template-choose-answer-opacity");
					reviewAnswer.classList.add("template-choose-answer");
				}
			} else if (tabcontentAnswer) {
				let object = tabcontentAnswer.querySelector(".card-answer-object")//.querySelector(".input-answer-object")
				let body = tabcontentAnswer.querySelector(".tox.tox-tinymce")
				console.log(object)
				if (object.classList.contains("template-choose-answer")) {
					setTimeout(() => {
						object.classList.remove("template-choose-answer-opacity");
						object.classList.remove("template-choose-answer");
						object.setAttribute("style", '')
						body.classList.remove("template-choose-answer-opacity");
						body.classList.remove("template-choose-answer");
					}, 280);
				} else {

					object.setAttribute("style", 'position: relative; background-color: white')
					object.classList.add("template-choose-answer-opacity")
					object.classList.add("template-choose-answer")
					body.classList.add("template-choose-answer-opacity")
					body.classList.add("template-choose-answer")
				}

			}

				// reviewAnswer.classList.toggle("template-choose-answer-opacity");
		})
	})

	const templateCards = document.querySelectorAll(".template-card.cursor-pointer");
	if (reviewAnswer) {
		Array.prototype.forEach.call(templateCards, function(card) {
			card.addEventListener("click", () => {
				let span = document.querySelector(".tab-pane.review-answer--wrapper.active").querySelector(".textarea.message_span")
				let message_text = card.querySelector(".template-text").innerText
				let client_names = document.getElementById((span.id).replace("message_", "rating_")).querySelector(".client-name").innerText.split(' ')
				let client_first_name = client_names[0]
				client_names.shift()
				let client_last_name = client_names.join(' ')
				console.log( message_text)
				message_text = message_text.trim()
				message_text = message_text.replaceAll("$prenomClient", client_first_name).replaceAll("$nomClient", client_last_name).replaceAll("$nomEtablissement",  document.getElementById(`estab_name_${span.id}`).innerText)
				message_text = message_text.replaceAll("$customerFirstName", client_first_name).replaceAll("$customerLastName", client_last_name).replaceAll("$establishmentName",  document.getElementById(`estab_name_${span.id}`).innerText)

				span.innerText = span.innerText + message_text
				document.getElementById(`input_${span.id}`).setAttribute('value', span.innerText);
				document.getElementById(`draft_${span.id}`).setAttribute('value', span.innerText);
				if (span.innerText == '') {
					document.getElementById(`submit-btn-send-provider_${span.id}`).classList.remove("submit-btn-color")
					document.getElementById(`submit-tag-send-provider_${span.id}`).classList.add("hidden")
				} else {
					document.getElementById(`submit-btn-send-provider_${span.id}`).classList.add("submit-btn-color")
					document.getElementById(`submit-tag-send-provider_${span.id}`).classList.remove("hidden")
				}
				document.getElementById(`submit-tag-save-draft-provider_${span.id}`).click();
				document.querySelector(".modal-template").querySelector(".btn-close").click();
				setTimeout(() => {
					reviewAnswer.classList.remove("template-choose-answer-opacity");
					reviewAnswer.classList.remove("template-choose-answer");
					}, 280);
			})
		})
	} else if (tabcontentAnswer) {
		Array.prototype.forEach.call(templateCards, function(templateCard) {
			templateCard.addEventListener('click', () => {
				let corps = document.querySelector('#corps_ifr').contentDocument.getElementById("tinymce");
				let object = document.querySelector(".input-answer-object").querySelector("input")
				let ed = tinyMCE.get('corps');
				let templateText = templateCard.querySelector('.template-text').innerHTML.trim();
        if (ed) {
				  ed.insertContent(`<pe><pre style="font-family: inherit">${templateText}</pre></pe>`);
        }
				object.value = templateCard.querySelector('.template-object').textContent.trim()
				document.querySelector(".btn-send-reponse-commentaire").setAttribute("type", "submit");
				document.querySelector(".btn-send-reponse-commentaire").classList.remove("not-clickable");
				document.querySelector(".modal-template").querySelector(".btn-close").click();
			});
		});
	}

	var modalTemplate = document.getElementById('modalTemplates')
	if (modalTemplate) {
		modalTemplate.addEventListener('hidden.bs.modal', function () {
			if (reviewAnswer) {
				let estabID = document.querySelector(".tab-pane.review-answer--wrapper.active").querySelector(".estab_id").innerText
				let estabTemplates = document.getElementById(`templates_for_${estabID}`)
				if (estabTemplates) {
					estabTemplates.classList.add("hidden")
				}
				reviewAnswer.classList.remove("template-choose-answer-opacity");
				reviewAnswer.classList.remove("template-choose-answer");
			} else if (tabcontentAnswer) {
				let object = tabcontentAnswer.querySelector(".card-answer-object")//.querySelector(".input-answer-object")
				let body = tabcontentAnswer.querySelector(".tox.tox-tinymce")
				object.classList.remove("template-choose-answer-opacity");
				object.classList.remove("template-choose-answer");
				object.setAttribute("style", '')
				body.classList.remove("template-choose-answer-opacity");
				body.classList.remove("template-choose-answer");
			}
			console.log("Close BS modale template")
      if (document.getElementById("bars-active")) {
			  document.getElementById("bars-active").classList.remove("hidden");
      }
      if (document.getElementById("bars-active")) {
			  document.getElementById("bars-active").id = '';
      }

      if (document.getElementById("times-active")) {
        document.getElementById("times-active").classList.add("hidden");
        document.getElementById("times-active").id = '';
      }
		})
	}

}

export {chooseTemplate};
