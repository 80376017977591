const display_logos_in_modal = () => {
  var chooseLogoLink = document.getElementById("choose-logo-link");
  var chooseLogoModal = document.getElementById("choose-logo-modal");
  var closeModalBtn = document.getElementById("close-modal-btn");
  var logos = document.querySelectorAll(".logo-restaurant-modified");
  var existantLogo = document.getElementById("existant-logo");
  var restaurantLogo = document.getElementById("restaurant_logo");
  var logoContainer = document.getElementById("container");
  var backgroundModal = document.getElementById("background-modal");
	
	if (document.getElementById("empty-logos") && chooseLogoLink) {
		chooseLogoLink.classList.add("d-none")
	}


  if (chooseLogoLink) {
    chooseLogoLink.addEventListener("click", function() {
      chooseLogoModal.style.display = "block";
      logoContainer.style.display = "flex";
      backgroundModal.classList.remove("hidden");
    });
  }

  if (closeModalBtn) {
    closeModalBtn.addEventListener("click", function() {
			closeModale(chooseLogoModal, logoContainer, backgroundModal)
    });
  }

  logos.forEach(function(logo) {
    logo.addEventListener("click", function() {
      var logoSrc = logo.src;
      var restaurantId = logo.dataset.restaurantId;
      existantLogo.src = logoSrc;
      restaurantLogo.value = restaurantId;

      chooseLogoModal.style.display = "none";
      logoContainer.style.display = "none";
      backgroundModal.classList.add("hidden");
    });
  });

	if (backgroundModal) {
		backgroundModal.addEventListener('click', (event) => {
			if (event.target === backgroundModal) {
				closeModale(chooseLogoModal, logoContainer, backgroundModal)
			}
		})
	}

}

function closeModale(chooseLogoModal, logoContainer, backgroundModal) {
  chooseLogoModal.style.display = "none";
	logoContainer.style.display = "none";
	backgroundModal.classList.add("hidden");
}


const clear_uploaded_logo = () => {

  // Supprimer le logo téléchargé par l'utilisateur afin qu'il puisse choisir un logo existant sans reloader la page
  if (document.getElementById('img_create_etablissement_go')) {

    document.getElementById('img_create_etablissement_go').addEventListener('change', function(event) {
      var img = document.getElementById('photo-preview-etablissement-new');
      var clearBtn = document.getElementById('clear-file');

      img.src = URL.createObjectURL(event.target.files[0]);
      clearBtn.classList.remove('hidden');
    });
  };

  if (document.getElementById('clear-file')) {
    document.getElementById('clear-file').addEventListener('click', function() {
      var img = document.getElementById('photo-preview-etablissement-new');
      var fileInput = document.getElementById('img_create_etablissement_go');

      img.src = '';
      fileInput.value = '';
      this.classList.add('hidden');
    });
  };
}

const clear_chosen_logo = () => {
  // Supprimer le logo existant choisi par l'utilisateur afin qu'il puisse télécharger un logo sans reloader la page

  const clearButton = document.getElementById('clear-files');
  const hiddenField = document.getElementById('restaurant_logo');
  const logoImage = document.getElementById('existant-logo');
  // Add event listener to clear button

  if (clearButton) {
    clearButton.addEventListener('click', () => {
      // Clear the hidden field value
      hiddenField.value = '';

      // Clear the image source

      logoImage.src = '';

      // Hide the clear button
      clearButton.classList.add('hidden');
    });

    // Show or hide the clear button based on the selected logo
    const selectableLogos = document.querySelectorAll('.logo-selectable');

    if (selectableLogos) {
        selectableLogos.forEach((logo) => {
          logo.addEventListener('click', () => {
            // Update the hidden field value with the selected logo
            hiddenField.value = logo.dataset.restaurantId;

            // Update the image source with the selected logo
            logoImage.src = logo.src;

            // Show the clear button
            clearButton.classList.remove('hidden');
          });
        });
      };
    };
  }

export {display_logos_in_modal, clear_uploaded_logo, clear_chosen_logo};
