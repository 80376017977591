import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js"
import monthSelect from 'flatpickr/dist/plugins/monthSelect';
import "flatpickr/dist/plugins/monthSelect/style.css";
import { yearSelect, hideFlatpickrYearSelect, cleanClassesOn, yearSelectActivated } from '../plugins/year_select_flatpickr';
import { engineMonthSelect, handleClassesOnSelectMonth, addClassEndRangeOnYearChange } from '../plugins/month_select_flatpickr_extension';
import { setTimeframe, addClassesToCell } from '../plugins/shared_flatpickr';

export default class extends Controller {

  previousDates = [];
	static targets = ["calendar", "activeButton", "dateDisplay", "beginEndBtns"];

  connect() {
    const timeframe = this.calendarTarget.dataset.timeframe
    const startDate = this.calendarTarget.dataset.startDate;
		const	endDate = this.calendarTarget.dataset.endDate;
    const monthSelect = this.monthSelectActivated({timeframe: timeframe, startDate: startDate, endDate: endDate})
    const yearSelect = yearSelectActivated({timeframe: timeframe, startDate: startDate, endDate: endDate})
    const options = this.setOptions(monthSelect)

    this.initializeFlatpickr({
      startDate: startDate,
      endDate: endDate,
      yearSelect: yearSelect,
      options: options,
    });

    if (monthSelect === true) {
      addClassesToCell(this.fp.selectedDates[1].getMonth(), ["endRange"]);
    }

    this.element.querySelector(".submit-btn-date").addEventListener("click", this.handleSubmit.bind(this));
    this.updateActiveButton()
  }

	handleSubmit(event) {
    let selectedDates = this.fp.selectedDates

    if (selectedDates.length === 0) {
      this.setCalendarByDefault()
    }

    this.updateDateDisplay();
  }

  initializeFlatpickr(args) {
		var options = {
      mode: "range",
			inline: "true",
      monthSelectorType: "static",
      plugins: args.options.plugins,
      onMonthChange: args.options.onMonthChange,
      onChange: args.options.onChange,
      onYearChange: args.options.onYearChange,
      defaultDate: this.setDefaultDate(args.startDate, args.endDate)
		};

		if (this.element.dataset.locale === "fr") {
			options.locale = French;
		}

    this.fp = flatpickr(this.calendarTarget, options);

    this.initializeSwitcher()

    hideFlatpickrYearSelect()

    if(args.yearSelect) {
      yearSelect({fp: this.fp})
    }

    this.previousDates = [...this.fp.selectedDates];
  }

  initializeSwitcher() {
    const switcher = document.querySelector('.flatpickr-current-month')
    const selectMonthLoadedPresent = this.fp.loadedPlugins.includes('monthSelect')
    const emptyRange = [null, null]

    switcher.addEventListener('click', () => {
      if (selectMonthLoadedPresent) {
        this.setThisMonth({range: emptyRange})
      } else {
        this.setThisYear({range: emptyRange})
        setTimeout(function() {
          cleanClassesOn(document.querySelectorAll('.flatpickr-monthSelect-month'))
        }, 0)
      }
    })
  }

  resetFlatpickr(args) {
    this.fp.destroy()

    setTimeframe(args.timeframe)

    this.initializeFlatpickr({
      startDate: args.datesRange[0],
      endDate: args.datesRange[1],
      yearSelect: args.yearSelect,
      options: args.options,
    })

    this.clearActiveButtons()
    this.setActiveButton(args.activeBtn)
  }

  getThisMonthRange(args) {
    const startDate = new Date(args.day.getFullYear(), args.day.getMonth(), 1);
    const endDate = new Date(args.day.getFullYear(), args.day.getMonth() + 1, 0);
    const result = [startDate, endDate]

    return result
  }

	updateDateDisplay() {
		const selectedDates = this.fp.selectedDates;
		const locale = this.element.dataset.locale == 'fr' ? 'fr-FR' : 'en-US' // norm is BCP 47

    if (!selectedDates.length) {
			// Case 4: No range is given
			this.dateDisplayTarget.textContent = "Cumul";
		}
		else if (selectedDates.length == 1 || this.isOneDay(selectedDates)) {
			// Case 1: Single date
			this.dateDisplayTarget.textContent = this.formatDate(selectedDates[0]);
		}
		else if (selectedDates.length == 2) {
			if (this.isWholeMonth(selectedDates)) {
				// Case 2: Whole month
				const options = { month: 'long', year: 'numeric' };
				this.dateDisplayTarget.textContent = selectedDates[0].toLocaleDateString(locale, options);
			}
			else if (this.isWholeYear(selectedDates)) {
				// Case 3: Whole year
				this.dateDisplayTarget.textContent = selectedDates[0].getFullYear().toString();
			}
			else {
				// Case 5: Custom range
				this.dateDisplayTarget.textContent = `${this.formatDate(selectedDates[0])} - ${this.formatDate(selectedDates[1])}`;
			}
		}
	}

	formatDate(date) {
		const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
		return date.toLocaleDateString(undefined, options);
	}

	isOneDay(dates) {
		const [start, end] = dates;
		return (start.getDate() == end.getDate() && start.getMonth() == end.getMonth() && start.getFullYear() == end.getFullYear())
	}

	isWholeMonth(dates) {
		const [start, end] = dates;
		return (start.getDate() == 1 && end.getDate() == new Date(start.getFullYear(), start.getMonth() + 1, 0).getDate() && start.getMonth() == end.getMonth() && start.getFullYear() == end.getFullYear()) ;
	}

	isWholeYear(dates) {
		const [start, end] = dates;
		return start.getDate() == 1 && start.getMonth() == 0 && end.getDate() == 31 && end.getMonth() == 11 && start.getFullYear() == end.getFullYear();
	}

	setToday() {
    const timeframe = 'day'
    const range = this.fetchDefaultRangeFor(timeframe)

    this.resetFlatpickr({
      timeframe: timeframe,
      datesRange: range,
      activeBtn: 'today',
      yearSelect: false,
      options: this.setOptions(false, timeframe),
    })
    this.beginEndBtnsTarget.classList.add('not-clickable-item')
  }

  setThisWeek() {
    const timeframe = 'week'
    const range = this.fetchDefaultRangeFor(timeframe)

    this.resetFlatpickr({
      timeframe: timeframe,
      datesRange: range,
      activeBtn: 'this-week',
      yearSelect: false,
      options: this.setOptions(false, timeframe),
    })
    this.beginEndBtnsTarget.classList.add('not-clickable-item')
  }

  setThisMonth(args) {
    const timeframe = 'month'
    let range = this.fetchDefaultRangeFor(timeframe)
    if (args.range) {
      range = args.range
    }

    this.resetFlatpickr({
      timeframe: timeframe,
      datesRange: range,
      activeBtn: 'this-month',
      yearSelect: false,
      options: this.setOptions(false, timeframe),
    })
    this.beginEndBtnsTarget.classList.add('not-clickable-item')
  }

  setThisYear(args) {
    const timeframe = 'year'
    let range = this.fetchDefaultRangeFor(timeframe)
    if (args.range) {
      range = args.range
    }

    this.resetFlatpickr({
      timeframe: timeframe,
      datesRange: range,
      activeBtn: 'this-year',
      yearSelect: false,
      options: this.setOptions(true, timeframe),
    })

    addClassesToCell(11, ["endRange"]) // 11 cell is december
    this.beginEndBtnsTarget.classList.add('not-clickable-item')
  }

	setCumul(event) {
    const timeframe = 'cumul'
    const range = this.fetchDefaultRangeFor(timeframe)

    this.resetFlatpickr({
      timeframe: timeframe,
      datesRange: range,
      activeBtn: 'all',
      yearSelect: true,
      options: this.setOptions(false, timeframe),
    })
    this.beginEndBtnsTarget.classList.add('not-clickable-item')
  }

  setPersonalise(event) {
    event.preventDefault();

		this.resetFlatpickr({
      timeframe: 'custom',
      datesRange: [null, null],
      activeBtn: 'custom',
      yearSelect: false,
      options: this.setOptions(false, null),
    })
    cleanClassesOn(document.querySelectorAll('.flatpickr-day'))
    this.beginEndBtnsTarget.classList.remove('not-clickable-item')
  }

	navigateToStartDate(event) {
		event.preventDefault();

		// Fetch the current selected date range from Flatpickr
		const selectedDates = this.fp.selectedDates;

		// If a start date is selected, navigate to it
		if (selectedDates && selectedDates.length > 0) {
			const startDate = selectedDates[0];
			this.fp.jumpToDate(startDate);
		}
    handleClassesOnSelectMonth(this.fp, selectedDates[0])
	}

	navigateToEndDate(event) {
		event.preventDefault();

		// Fetch the current selected date range from Flatpickr
		const selectedDates = this.fp.selectedDates;

		// If an end date is selected, navigate to it
		if (selectedDates && selectedDates.length > 1) {
			const endDate = selectedDates[1];
			this.fp.jumpToDate(endDate);
		}
    handleClassesOnSelectMonth(this.fp, selectedDates[1])
	}

	// After setting or clearing dates, call this method to update button states
  updateActiveButton() {
    const selectedDates = this.fp.selectedDates;
    this.clearActiveButtons();

    if (this.isCumul(selectedDates)) {
			this.setActiveButton('all');
		} else if (this.isToday(selectedDates)) {
			this.setActiveButton('today');
      setTimeframe('day')
		} else if (this.isThisWeek(selectedDates)) {
			this.setActiveButton('this-week');
		} else if (this.isThisMonth(selectedDates)) {
			this.setActiveButton('this-month');
		} else if (this.isThisYear(selectedDates)) {
			this.setActiveButton('this-year');
      setTimeframe('year')
    } else {
			this.setActiveButton('custom');
      setTimeframe('custom') // enable personalise mode on setOnChangeOptions
		}
	}

	clearActiveButtons() {
		const buttons = this.element.querySelectorAll('button.date-shortcuts');
		buttons.forEach(button => button.classList.remove('active'));
	}

	setActiveButton(range) {
		const button = this.element.querySelector(`[data-range="${range}"]`);
		if (button) {
			button.classList.add('active');
		}
	}

	isToday(dates) {
		const today = new Date();
		return ((dates.length === 1 && dates[0].toDateString() === today.toDateString()) ||
						(dates.length === 2 && dates[0].toDateString() === today.toDateString() && dates[1].toDateString() === today.toDateString()))
	}

	isThisWeek(dates) {
		const now = new Date();
		const firstDayOfWeek = this.fp.l10n.firstDayOfWeek;

		// Reset the time components for accurate comparison
		now.setHours(0, 0, 0, 0);

		// Determine the start of the current week
		const startOfWeek = new Date(now);
		const daysSinceFirstDay = (now.getDay() - firstDayOfWeek + 7) % 7;
		startOfWeek.setDate(now.getDate() - daysSinceFirstDay);

		// Calculate the end of the week
		const endOfWeek = new Date(startOfWeek);
		endOfWeek.setDate(startOfWeek.getDate() + 6);

		// Check if the provided dates match the calculated start and end dates
		return dates.length === 2 && dates[0].getTime() === startOfWeek.getTime() && dates[1].getTime() === endOfWeek.getTime();
	}

	isThisMonth(dates) {
		const today = new Date();
		const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
		const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
		return dates.length === 2 && dates[0].toDateString() === startOfMonth.toDateString() && dates[1].toDateString() === endOfMonth.toDateString();
	}

	isThisYear(dates) {
		const today = new Date();
		const startOfYear = new Date(today.getFullYear(), 0, 1);
		const endOfYear = new Date(today.getFullYear(), 11, 31);
		return dates.length === 2 && dates[0].toDateString() === startOfYear.toDateString() && dates[1].toDateString() === endOfYear.toDateString();
	}

  // Since we get the data from Google Analytics 48 hours late, we need to change the range of dates shown in the evolution graph for scan_my_menu view.
  patchForSetDate(format) {
    let result = new Date()

    if (!document.URL.includes("/scan_my_menu")) {
      return result
    }

    if (format == "day") {
      result.setDate(new Date().getDate() - 2);
    } else {
      result.setDate(new Date().getDate() - 1);
    }

    return result
  }

  monthSelectActivated(args) {
    let result = false
    const currentSelection = new Date(args.endDate) - new Date(args.startDate);
    const month = 31 * 86400000; // A day has 86400000 milliseconds
    const moreThanOneMonthSelected = currentSelection > month
    const lastDayOfMonth = new Date(new Date(args.endDate).getFullYear(), new Date(args.endDate).getMonth() + 1, 0).getDate();
    const isLastDayOfMonth = new Date(args.endDate).getDate() === lastDayOfMonth;

    result = (args.timeframe == 'year' || (args.timeframe == 'custom_less_than_year' && moreThanOneMonthSelected && isLastDayOfMonth))
    return result
  }

  setPlugins(monthSelectCondition) {
    let result = []
    if (monthSelectCondition === true) {
      result = [
        new monthSelect({
          shorthand: true, // defaults to false
          dateFormat: "Y-m-d", // defaults to "F Y"
          altFormat:  "F j, Y", // defaults to "F Y"
          theme: "light" // defaults to "light"
        })
      ]
    }
    return result
  }

  setOnMonthChangeOptions() {
    let result;

    result = function(selectedDates, dateStr, instance) {
      let timeframe = document.querySelector("#calendar").dataset.timeframe

      if (timeframe === 'month') {
        const startOfMonth = new Date(this.currentYear, this.currentMonth, 1);
        const endOfMonth = new Date(this.currentYear, this.currentMonth + 1, 0); // 0th day of the next month is the last day of this month
        instance.setDate([startOfMonth, endOfMonth]);
      }

      if (timeframe === 'day') {
        let newDate = new Date(instance.currentYear, instance.currentMonth, selectedDates[0].getDate());
        newDate.setDate(Math.min(newDate.getDate(), new Date(instance.currentYear, instance.currentMonth + 1, 0).getDate()));

        if (newDate.getMonth() != instance.currentMonth) {
          newDate = new Date(instance.currentYear, instance.currentMonth + 1, 0)
        }

        instance.setDate([newDate, newDate]);
      }
    };

    return result
  }

  setOptions(monthSelect) {
    let result = {};

    result.onMonthChange = this.setOnMonthChangeOptions()
    result.plugins = this.setPlugins(monthSelect)
    result.onChange = this.setOnChangeOptions(monthSelect)
    result.onYearChange = this.setOnYearChangeOptions(monthSelect)

    return result;
  }

  setOnChangeOptions(monthSelect) {
    let result = []
    let controller = this

    result = function(selectedDates, dateStr, instance) {
      if (monthSelect === true) {
        engineMonthSelect(instance, selectedDates)
      }

      if (selectedDates.length === 2) {
      }
      controller.updateActiveButton()

      if (monthSelect === false) {
        controller.handleDayClick()
      }

      controller.previousDates = [...controller.fp.selectedDates];
    }

    return result
  }

  setOnYearChangeOptions(monthSelect) {
    let result;

    result = function(selectedDates, dateStr, instance) {
      let timeframe = document.querySelector("#calendar").dataset.timeframe

      if (timeframe == 'year') {
        const startOfYear = new Date(this.currentYear, 0, 1);
        const endOfYear = new Date(this.currentYear, 11, 31);
        instance.setDate([startOfYear, endOfYear]);
        addClassesToCell(11, ["endRange"]) // cell 11 is december cell
      }

      if (monthSelect && selectedDates.length === 2) {
        handleClassesOnSelectMonth(instance)
        addClassEndRangeOnYearChange(instance) // Patch.
      }
    }

    return result
  }

  setDefaultDate(startDate, endDate) {
    let result;

    if (startDate && endDate) {
			result = [startDate, endDate];
		} else if (startDate) {
			result = [startDate, startDate];
		}

    return result;
  }

  fetchDefaultRangeFor(timeframe) {
    const today = this.patchForSetDate(timeframe)
    let startDate;
    let endDate;

    if (timeframe == 'cumul') {
      const selectYearPartial = document.querySelector('#flatpickr-year-select')
      startDate = new Date(selectYearPartial.getAttribute('data-year-select-start'), 0, 1)
      endDate = new Date(selectYearPartial.getAttribute('data-year-select-end'), 11, 31)
    }

    if (timeframe == 'year') {
      startDate = new Date(today.getFullYear(), 0, 1);
      endDate = new Date(today.getFullYear(), 11, 31);
    }

    if (timeframe == 'month') {
      startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0); // 0th day of the next month is the last day of this month
    }

    if (timeframe == 'week') {
      const firstDayOfWeek = this.fp.l10n.firstDayOfWeek;
      startDate = new Date(today);
      startDate.setDate(today.getDate() - ((7 + today.getDay() - firstDayOfWeek) % 7));
      startDate.setHours(0, 0, 0, 0);

      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      endDate.setHours(0, 0, 0, 0);
    }

    if (timeframe == 'day') {
      startDate = today
      endDate = today
    }

    let result = [startDate, endDate]

    return result
  }

  setCalendarByDefault() {
    const default_timeframe = this.calendarTarget.dataset.rangeDefault

    if (default_timeframe == 'day') {
      this.setToday()
    }
    if (default_timeframe == 'week') {
      this.setThisWeek()
    }
    if (default_timeframe == 'month') {
      this.setThisMonth({})
    }
    if (default_timeframe == 'year') {
      this.setThisYear({})
    }
    if (default_timeframe == 'cumul') {
      this.setCumul()
    }
  }

  isCumul(selectedDates) {
    let cumulDates = this.fetchDefaultRangeFor('cumul')
    let result = false

    result = (selectedDates[0].toLocaleDateString() === cumulDates[0].toLocaleDateString()) && (selectedDates[1].toLocaleDateString() === cumulDates[1].toLocaleDateString())

    return result
  }

  // Allows to extend current selection.
  handleDayClick(args) {
    const clickedDate = new Date(event.target.dateObj); // the clicked date
		const selectedDates = this.previousDates; // the current selected range

		const currentMonth = this.fp.currentMonth; // used to redraw the fp later
  	const currentYear = this.fp.currentYear; // used to redraw the fp later

    if (selectedDates.length === 2) {
			//if the clicked date is either dates of the current selection, we re-select the other one
			if (clickedDate.getTime() === selectedDates[0].getTime()) {
				this.setDateAndResetView(selectedDates[1], currentMonth, currentYear);
			} else if (clickedDate.getTime() === selectedDates[1].getTime()) {
        this.setDateAndResetView(selectedDates[0], currentMonth, currentYear);
			}
		}
  }

  setDateAndResetView(date, month, year) {
		this.fp.setDate(date);
		this.fp.changeMonth(month - this.fp.currentMonth);
		this.fp.currentYear = year;
		this.fp.redraw();
	}
}
