// app/javascript/components/photo_preview.js
const previewImageOnFileSelectMeal = (context = document) => {
  // we select the photo input
  const input = context.querySelector('#product_img_create_meal_go');
  if (input) {
    console.log('previewImageOnFileSelectMeal')
    // we add a listener to know when a new picture is uploaded
    input.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input, context);
      if (input.files[0]) {
        if (input.files[0].size < 1000000){
          context.querySelector('.clear-btn').classList.remove('hidden');
        }
      }
    })
  }

  const inputAll = context.querySelector('#product_img_create_meal_all');
  if (inputAll) {
    console.log('previewImageOnFileSelectMeal')
    // we add a listener to know when a new picture is uploaded
    inputAll.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreviewAll(inputAll, context);
      if (inputAll.files[0]) {
        if (inputAll.files[0].size < 1000000){
          context.querySelector('.clear-btn-new-all').classList.remove('hidden');
        }
      }
    })
  }

  const inputEdit = context.querySelector('#product_img_meal_edit');
  if (inputEdit) {
    console.log('previewImageOnFileSelectMeal')
    // we add a listener to know when a new picture is uploaded
    inputEdit.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreviewEdit(inputEdit, context);
      if (inputEdit.files[0]) {
        if (inputEdit.files[0].size < 1000000){
          context.querySelectorAll('.clear-btn-edit').forEach((btn) => {
            btn.classList.remove('hidden');
          })
        }
      }
    })
  }

  const inputEditAll = context.querySelector('#product_img_edit_meal_all');
  if (inputEditAll) {
    console.log('previewImageOnFileSelectMeal')
    // we add a listener to know when a new picture is uploaded
    inputEditAll.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreviewEditAll(inputEditAll, context);
      if (inputEditAll.files[0]) {
        if (inputEditAll.files[0].size < 1000000){
          context.querySelectorAll('.clear-btn-edit-all').forEach((btn) => {
            btn.classList.remove('hidden');
          })
        }
      }
    })
  }
}

const displayPreviewEdit = (input, context) => {
  if (input.files && input.files[0]) {
    console.log('displayPreviewEdit')
    const reader = new FileReader();
    reader.onload = (event) => {
      context.querySelector('#photo-preview-meal-edit').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    context.querySelector('#photo-preview-meal-edit').classList.remove('hidden');
    context.querySelector('#photo-preview-meal-edit').classList.remove('hide-picto');
    context.querySelector('#remove-trash-when-picto').classList.remove('hide-picto');
  }
}

const displayPreviewEditAll = (input, context) => {
  if (input.files && input.files[0]) {
    console.log('displayPreviewEditAll')
    const reader = new FileReader();
    reader.onload = (event) => {
      context.querySelector('#photo-preview-meal-edit-all').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    context.querySelector('#photo-preview-meal-edit-all').classList.remove('hidden');
    context.querySelector('#photo-preview-meal-edit-all').parentElement.classList.value = '';
  }
}

const displayPreview = (input, context) => {
  if (input.files && input.files[0]) {
    console.log('displayPreview')
    const reader = new FileReader();
    reader.onload = (event) => {
			if (context.querySelector('#photo-preview-meal-new')) {
				context.querySelector('#photo-preview-meal-new').src = event.currentTarget.result;
			}
      context.querySelector('#photo-preview-meal-new-go').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
		if (context.querySelector('#photo-preview-meal-new')) {
    	context.querySelector('#photo-preview-meal-new').classList.remove('hidden');
		}
    context.querySelector('#photo-preview-meal-new-go').classList.remove('hidden');
    if (context.querySelector('#photo-preview-meal-new old-picture-js')){
      context.querySelector('#photo-preview-meal-new old-picture-js').classList.add('hidden');
    }
  }
}

const displayPreviewAll = (input, context) => {
  if (input.files && input.files[0]) {
    console.log('displayPreviewAll')
    const reader = new FileReader();
    reader.onload = (event) => {
      context.querySelector('#photo-preview-meal-new-all').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    context.querySelector('#photo-preview-meal-new-all').classList.remove('hidden');
  }
}

export { previewImageOnFileSelectMeal };
