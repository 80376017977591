const collapseEditMasterCateg = () => {
  if (document.querySelector(".nav-item-theme-for-js")) {
    console.log("collapseEditMasterCateg")
    let arrayCollapsedIn = [];
    const arrowCollapse = document.querySelectorAll(".arrow-collapse-master-inactif")
    arrowCollapse.forEach((el) => {
      el.addEventListener("click", (event) => {
        if (!el.parentElement.parentElement.parentElement.parentElement.nextElementSibling.classList.contains('in') && document.querySelector(".collapse.in")) {
          document.querySelector(".collapse.in").classList.remove("in")
          arrayCollapsedIn.push("ouvert")
        } else {
          arrayCollapsedIn.splice(0, 1)
        }
        console.log(arrayCollapsedIn)
      })
    })
  }
}

export { collapseEditMasterCateg }
