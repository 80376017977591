const previousImgs = {};

function toggleEmojiFilter() {
  const radioButtons = document.querySelectorAll('.smiley-container input[type="radio"]');

  if (radioButtons) {
    radioButtons.forEach((radioButton) => {
      radioButton.addEventListener('click', function () {
        const questionId = this.closest('.smiley-container').getAttribute('data-question_id');

        if (previousImgs[questionId]) {
          previousImgs[questionId].style.filter = "";
        }

        const img = this.nextElementSibling.querySelector('img');
        img.style.filter = "invert(100%)";

        previousImgs[questionId] = img;
      });
    });
  }
}

export { toggleEmojiFilter };
