const sendDraftOnInput = () => {
	const messageSpans = document.querySelectorAll(".message_span");

	Array.prototype.forEach.call(messageSpans, function(span) {

		span.addEventListener('keyup', () => {
			document.getElementById(`submit-tag-save-draft-provider_${span.id}`).click()
		})

		span.addEventListener('input', () => {
			document.getElementById(`submit-tag-save-draft-provider_${span.id}`).click()
		})

		$(`#draft_form_${span.id}`).on('ajax:complete', function(event, data, status, xhr) {

			let tag = document.getElementById(`draft_tag_${span.id}`)

			if (span.innerText == '') {
				tag.classList.add('hidden')
			} else {
				tag.classList.remove('hidden')
			}

		});

	})
}

export {sendDraftOnInput};
