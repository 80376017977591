export function setTimeframe(timeframe) {
  const calendar = document.querySelector("#calendar");
  calendar.dataset.timeframe = timeframe
}

export function addClassesToCell(monthSelected, classNames) {
  setTimeout(function() {
    const cell = document.querySelectorAll('.flatpickr-monthSelect-month')[monthSelected];
    classNames.forEach(className => {
      cell.classList.add(className);
    })
    cell.classList.remove("inRange");
  }, 0);
}
