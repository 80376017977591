const checkboxFilter = () => {
  const oui = document.getElementById("all-oui");
  const non = document.getElementById("all-non");
  const ouiCustom = document.getElementById("all-oui-custom");
  const nonCustom = document.getElementById("all-non-custom");

  if (oui) {
    console.log('checkboxFilter')
    oui.addEventListener("click", () => {
      if (non.checked) {
        non.checked = false
      }
      if (oui.checked) {
        document.querySelector('form#oui').submit();
      } else {
        document.querySelector('form#oui').submit();
      }
    })

    non.addEventListener("click", () => {
      if (oui.checked) {
        oui.checked = false
      }
      if (non.checked) {
        document.querySelector('form#oui').submit();
      } else {
        document.querySelector('form#oui').submit();
      }
    });
  }

  if (ouiCustom) {
    console.log('checkboxFilter')
    ouiCustom.addEventListener("click", () => {
      console.log('hoooo')
      if (nonCustom.checked) {
        nonCustom.checked = false
      }
      if (ouiCustom.checked) {
        document.querySelector('form#oui').submit();
      } else {
        document.querySelector('form#oui').submit();
      }
    })

    nonCustom.addEventListener("click", () => {
      if (ouiCustom.checked) {
        ouiCustom.checked = false
      }
      if (nonCustom.checked) {
        document.querySelector('form#oui').submit();
      }

    })
  }
}

const checkboxFilterRestaurants = () => {

  const truers = document.querySelectorAll(".r-true");
  const fakers = document.querySelectorAll(".r-fake");
  const cardsRestaurants = document.querySelectorAll('.nom-resto');
  const cardsRestaurantsFake = document.querySelectorAll('.nom-resto-fake');

  if (document.querySelector('.nom-resto')) {
    console.log('checkboxFilterRestaurants')
    Array.prototype.forEach.call(truers, function(truer) {
    truer.addEventListener('click', () => {
      console.log("x")
      if (fakers.checked) {
        fakers.checked = false
      }
      if (truer.checked) {
        Array.prototype.forEach.call(cardsRestaurants, function(card) {
          card.parentElement.classList.remove('hidden');
          card.parentElement.nextElementSibling.classList.remove('hidden');
        })
        Array.prototype.forEach.call(cardsRestaurantsFake, function(card) {
          card.parentElement.classList.add('hidden');
          card.parentElement.nextElementSibling.classList.add('hidden');
        })
      } else {
        Array.prototype.forEach.call(cardsRestaurantsFake, function(card) {
          card.parentElement.classList.remove('hidden');
          card.parentElement.nextElementSibling.classList.remove('hidden');
        })
      }
    })
  })

    Array.prototype.forEach.call(fakers, function(faker) {
    faker.addEventListener('click', () => {
      console.log("x")

      if (truers.checked) {
        truers.checked = false
      }
      if (faker.checked) {
        Array.prototype.forEach.call(cardsRestaurantsFake, function(card) {
          card.parentElement.classList.remove('hidden');
          card.parentElement.nextElementSibling.classList.remove('hidden');
        })
        Array.prototype.forEach.call(cardsRestaurants, function(card) {
          card.parentElement.classList.add('hidden');
          card.parentElement.nextElementSibling.classList.add('hidden');
        })
      } else {
        Array.prototype.forEach.call(cardsRestaurants, function(card) {
          card.parentElement.classList.remove('hidden');
          card.parentElement.nextElementSibling.classList.remove('hidden');
        })
      }
    })

   })
  }
}

export {checkboxFilter, checkboxFilterRestaurants};
