const uncheckStars = () => {
  if (document.querySelector('.star-checkbox')) {
    console.log('uncheckStars');

    const stars = [...document.getElementsByClassName('star-checkbox')];

    if (document.querySelector('.questionnaire-star-js')) {

      let starQuestionAddi = stars.filter(star => star.parentNode.parentNode.parentNode.classList.contains('question_addi'));
      uncheckAllOthersAfterCheck(starQuestionAddi);

      let starQuestionInitiale = stars.filter(star => star.parentNode.parentNode.parentNode.classList.contains('question_initiale'));
      uncheckAllOthersAfterCheck(starQuestionInitiale);

    } else {
      uncheckAllOthersAfterCheck(stars);
    }
  }
};

function uncheckAllOthersAfterCheck(array_of_stars) {
  array_of_stars.forEach(star => {
    star.addEventListener('click', () => {
      let starUnchecked = array_of_stars.filter(input => ((input.dataset.question_id == star.dataset.question_id) && (input.value != star.value)));
      starUnchecked.forEach(s => s.checked = false);
    });
  });
}

export { uncheckStars };