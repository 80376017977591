const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

const alert = () => {
  const but = document.querySelectorAll(".filter");
  Array.prototype.forEach.call(but, function(e) {
    e.classList.remove("over");
  });
}

const changeArrow = () => {
	const hideShowIconsArrow = document.querySelectorAll(".custom-btn.btn-link.menu-btn-style");
  Array.prototype.forEach.call(hideShowIconsArrow, function(e) {
    e.addEventListener("click", (event) => {
      Array.prototype.forEach.call(e.querySelectorAll(".arrow"), function(icon) {
      icon.classList.toggle("hidden");
      const but = document.querySelectorAll(".filter");
      Array.prototype.forEach.call(but, function(b) {
        b.classList.add("over");
      });
      setTimeout(alert, 500);
      })
    });
	});
}
export { changeArrow };
