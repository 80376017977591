// highlight_search_nav_bar_controller.js
import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["search"];

  connect() {
    console.log("HighlightSearchGroupsShow controller connecté");
  }

  highlightSearch() {
    clearTimeout(this.timeout); // Clear any existing timeout to reset the timer

    this.timeout = setTimeout(() => {
      const query = this.searchTarget.value.toLowerCase();
      const options = {
          element: "span",
          className: "highlight-search",
          separateWordSearch: false,
      };

        $('.card-admin-rest-modified, .card-admin-resto, .panel-group').removeClass('hidden');

        $('.card-admin-rest-modified, .card-admin-resto').unmark({
            done: () => {
                $('.card-admin-rest-modified, .card-admin-resto').mark(query, options);

                if (query.length > 0) {
                    const firstHighlight = $('.highlight-search').first();
                    if (firstHighlight.length) {
                        const elementTop = firstHighlight.offset().top;
                        const viewportTop = $(window).scrollTop();
                        const viewportBottom = viewportTop + $(window).height();

                        if (elementTop < viewportTop || elementTop > viewportBottom) {
                            $('html, body').scrollTop(elementTop - 100); // Scroll to the first highlighted element
                        }
                    } else {
                      $('#no-results-message').removeClass('hidden');
                      this.pageInitialState();
                      $('.card-admin-rest-modified').each(function() {
                        const count = parseInt($(this).data('count'));
                        if (count > 8) {
                          $('.panel-group').addClass('hidden');
                          $('.card-admin-resto').addClass('hidden');
                        }
                      });
                    }
                } else {
                  $('#no-results-message').addClass('hidden');
                  this.pageInitialState();
                  $('.card-admin-rest-modified').each(function() {
                    const count = parseInt($(this).data('count'));
                    if (count > 8) {
                      $('.panel-group').addClass('hidden');
                      $('.card-admin-resto').addClass('hidden');
                    }
                  });

                }
            }
        });
    }, 220);
  }

  pageInitialState() {
    const sousGroupes = document.querySelectorAll(".card-admin-rest-modified");
    sousGroupes.forEach(sg => {
        const rootId = sg.dataset.rootId;
        const sgId = sg.dataset.sgId;
        const count = parseInt(sg.dataset.count);
        console.log(count)

        if ( count > 8 && rootId !== sgId) {
            sg.classList.add("hidden"); // hide descendants
        }
    });
}

}
