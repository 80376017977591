import { disableBtn } from "../_toggle_btn"
import { createListSelectOptions } from "./_create_list_select_options"

const translateFilters = (filterForm) => {

    const languageFilter = filterForm.find('.select-box-language')
    if (languageFilter) {
        var submitBtn = filterForm.find('.download-btn')

        languageFilter.on('change', function(){
            const formulaire = $(this).closest('form')
            const languageSelected = $(this).val();
            $.ajax({
                method: 'GET',
                url: 'get_group_mastercategories_translated',
                data: { type: 'json', language: languageSelected },
                success: function(response) {
                    disableBtn(submitBtn)
                    createListSelectOptions($(formulaire).find('#filters-for-select'), response.mastercategories)
                },
            })
        })
    }
}

export { translateFilters }