const makeItGray = () => {
  const gray = document.querySelectorAll(".color-gray")

  Array.prototype.forEach.call(gray, function(g) {
    g.querySelector(".alert-content").innerText = "";
    g.parentElement.removeAttribute("href");
  })
}

export {makeItGray};
