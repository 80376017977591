var bannerTimer;

function showBanner(message, type) {
	console.log(message)
  var banner = $(".alert.flash-banner");
  banner.find(".flash-content").html(message);
  banner.removeClass("hidden success info error");
  banner.addClass(type);

  // Clear any existing timeout
  clearTimeout(bannerTimer);

  // Set a new timeout to hide the banner after 5 seconds
  bannerTimer = setTimeout(function() {
    banner.addClass("hidden");
    banner.removeClass("success info error");
  }, 3000);
}

function setBanner() {
	const $alertBanner = $(".alert.flash-banner");
	const $banner = $alertBanner.length ? $alertBanner.find(".error-messages") : undefined;
	return $banner
}

export {showBanner, setBanner};