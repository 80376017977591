const renderInactiveMeals = (categorySecondId) => {

    const divInactiveMeals = $('.inactive-mealitems-box')
    
    if (divInactiveMeals) {
        const btnToggleInactiveMeals = $('.toggle-inactive-meals')
        let loaderDiv = $('.dots-loader-inactive-meals')
        let loader = loaderDiv.find('.dots-container')
        
        btnToggleInactiveMeals.one('click', function(e){
            e.preventDefault()

            loaderDiv.removeClass('hidden')
            loader.removeClass('hidden')
            
            let input = $('#search-input')
            let newCategorySecondId = categorySecondId || new URLSearchParams(window.location.search).get('cat_sec_id')
            
            $.ajax({
                method: 'GET',
                url: 'render_partial_for_meals',
                data: { type: 'json', cat_sec_id: newCategorySecondId, query: input.val(), case: 'render-inactive-meals' },
                success: function(response) {
                    loader.addClass('hidden')
                    divInactiveMeals.html(response.html)
                },
                error: function (XMLHttpRequest, textStatus, errorThrown) {
                    console.log('Error on render_inactive_meals')
                    console.log("Status: " + textStatus + ". Error: " + errorThrown); 
                }
            })
        })
    }
}

export { renderInactiveMeals }