// tutorial_cliking_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.modalPositions = {
      'index_gerant': [
        'pos-1', 'pos-2', 'pos-3', 'pos-4', 'pos-5',
        'pos-6', 'pos-7', 'pos-8', 'pos-9'
      ],
      'detail_groupe_question': [
        'pos-10', 'pos-11', 'pos-12', 'pos-13', 'pos-14',
        'pos-15', 'pos-16', 'pos-17'
      ],
      'nps_index_gerant': [
        'pos-18', 'pos-19', 'pos-20', 'pos-21', 'pos-22', 'pos-23', 'pos-24'
      ]
    };
    this.action = document.querySelector("#modal-container").dataset.paramsAction;
    this.actionClass = this.action;
    this.loadingDotsContainer = document.querySelector(".dots-container");
    this.userAdmin = document.querySelector("#modal-container").dataset.userAdmin;
    this.clients = document.querySelector("#modal-container").dataset.clients;
    this.checkboxDiv = `<div style="position: absolute; top: -2px; right: 7px; margin-bottom: 10px">
    <button id="close-modal" aria-label="Fermer" style="border: 0px; color: white; background: #5C78FF; font-size: 15px; text-decoration: underline;">Fermer le tutoriel <i class="fa-solid fa-xmark"></i></button>
  </div>`;
    this.modalContent = JSON.parse(document.querySelector("#modal-container").dataset.contents);

    this.displayModals();
    this.setRedirections();
  }

  handleAmpouleClick() {
    if (localStorage.getItem('tutorialClikingDone') === 'true') {
      localStorage.setItem('tutorialClikingDone', 'false');
      for (let i = 1; i <= this.modalContent.length; i++) {
        let modal = this.generateModal(i, this.actionClass);
        $('#modal-container').append(modal);
        this.adjustModalBackdrop(`#tutoModalToggle${i}`);
        this.configureModalSpecificActions(`#tutoModalToggle${i}`, this.actionClass);
        this.attachCheckboxEventHandlers(`#tutoModalToggle${i}`);
      }

      $('#tutoModalToggle1').modal('show');
    }
  }

  triggerLoadingDots(container) {
    if (container) {
      container.classList.remove("hidden")
      document.querySelector("body").classList.add("background-gray");
    }
  }

  generateModal(index, actionClass) {

    var globalIndex = index;
    var isFirstModal = globalIndex === 1;
    var isLastModal = globalIndex === this.modalContent.length;
    var positionClass = this.modalPositions[actionClass][index - 1];
    var totalModals = this.modalContent.length;

    if (actionClass == 'index_gerant') {
      var counter = index
    } else {
      var counter = index + 9
    }

    if (counter === 1) {
      var buttonText = 'Lancer le tutoriel';
    } else if (counter === 15) {
      var buttonText = 'Terminer';
    } else {
      var buttonText = 'Suivant';
    }

    let nextBtn = globalIndex + 1;
    let previousBtn = globalIndex - 1;

    if (actionClass == 'index_gerant' && globalIndex == 4) {
        if (this.userAdmin == 'true') {
            nextBtn = globalIndex + 2;
        }
    } else if (actionClass != 'index_gerant' && globalIndex == 1) {
        if (this.clients == 'true') {
            nextBtn = globalIndex + 2;
        }
    } else if (actionClass != 'index_gerant' && globalIndex == 3) {
        if (this.clients == 'true') {
            nextBtn = globalIndex + 2;
        }

    } else if (actionClass == 'index_gerant' && globalIndex == 6) {
        if (this.userAdmin == 'true') {
          previousBtn = globalIndex - 2;
        }
    } else if (actionClass != 'index_gerant' && globalIndex == 3) {
        if (this.clients == 'true') {
          previousBtn = globalIndex - 2;
        }
    } else if (actionClass != 'index_gerant' && globalIndex == 5) {
        if (this.clients == 'true') {
          previousBtn = globalIndex - 2;
        }
    }

    var modalHTML = `
      <div class="modal fade ${actionClass} ${positionClass}" id="tutoModalToggle${globalIndex}" data-bs-backdrop="static" aria-hidden="true" aria-labelledby="tutoModalToggleLabel${globalIndex}" tabindex="-1">
        <div class="modal-dialog modal-dialog-centered" style="width: 520px;">
          <div class="modal-content" style="background: #5C78FF; color: white; border-radius: 30px; box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.5);">
            <div class="modal-body" style="margin-bottom: 20px; padding: 30px; font-weight: 500; margin-top: 20px;">
              ${this.modalContent[index - 1]}
            </div>
            <div class="modal-footer" style="display:flex; justify-content: space-between; align-items: center; border-top: 0px;">
              <div>
                <p style="font-size: 15px; padding: 30px;">${counter} / 15 </p>
              </div>
              <div>
                ${globalIndex > 1 ? `<button class="tutorial-btn" data-bs-target="#tutoModalToggle${previousBtn}" data-bs-toggle="modal">Retour</button>` : ''}
                <button class="tutorial-btn" data-bs-target="#tutoModalToggle${nextBtn}" data-bs-toggle="modal">${buttonText}</button>
              </div>
              ${this.checkboxDiv}
            </div>
          </div>
        </div>
      </div>
    `;
    return modalHTML;
  }

  configureModalSpecificActions(modalId, action) {
    $(modalId).on('show.bs.modal', function () {

      if (action === 'index_gerant' && modalId === '#tutoModalToggle3') {
        $('.navbar_v6_content').addClass('tuto-highlight');
      } else if (action === 'index_gerant' && modalId === '#tutoModalToggle4') {
        $('#language-btn').addClass('tuto-highlight-with-margin');
      } else if (action === 'index_gerant' && modalId === '#tutoModalToggle5') {
        $('#navbar-restaurant-selector-btn').click();
        $('.dd-container.dropdown').addClass('tuto-highlight-with-margin');
      } else if (action === 'index_gerant' && modalId === '#tutoModalToggle6') {
        $('.filter-pill').css('margin-right', '0px');
        $('.indicator-filter-btn').click();
        $('.indicator-filter-btn').addClass('tuto-highlight-no-relative');
      } else if (action === 'index_gerant' && modalId === '#tutoModalToggle7') {
        $('.filter-pill').css('margin-right', '0px');
        $('.date-filter-btn').click();
        $('.date-filter-btn').addClass('tuto-highlight-no-relative');
      } else if (action === 'index_gerant' && modalId === '#tutoModalToggle8') {
        $('#sidebar-wrapper').addClass('tuto-highlight-with-margin');
      } else if (action === 'index_gerant' && modalId === '#tutoModalToggle9') {
        $('.main-item.collapsible:eq(1)').addClass('tuto-highlight-with-margin');
      } else if (action != 'index_gerant' && modalId === '#tutoModalToggle2') {
        $('.main-item.collapsible:eq(1)').click();
        $('.main-item.collapsible:eq(2)').addClass('tuto-highlight-with-margin');
      } else if (action != 'index_gerant' && modalId === '#tutoModalToggle3') {
        $('.main-item.collapsible:eq(3)').addClass('tuto-highlight-with-margin');
      } else if (action != 'index_gerant' && modalId === '#tutoModalToggle4') {
        $('.main-item.collapsible:eq(4)').addClass('tuto-highlight-with-margin');
      } else if (action != 'index_gerant' && modalId === '#tutoModalToggle5') {
        $('.main-item.collapsible:eq(5)').addClass('tuto-highlight-with-margin');
      } else if (action != 'index_gerant' && modalId === '#tutoModalToggle6') {
        $('.main-item.collapsible:eq(6)').addClass('tuto-highlight-with-margin');
      }
    });

    $(modalId).on('hide.bs.modal', function () {

      $('.tuto-highlight').removeClass('tuto-highlight');
      $('.tuto-highlight-with-margin').removeClass('tuto-highlight-with-margin');
      $('.indicator-filter-btn').removeClass('tuto-highlight-no-relative');
      $('.date-filter-btn').removeClass('tuto-highlight-no-relative');
      $('.filter-pill').css('margin-right', '10px');
    });
  }

  attachCheckboxEventHandlers(modalId) {
    $('#modal-container').on('click', '#close-modal', function() {
        localStorage.setItem('tutorialClikingDone', 'true');
        $(modalId).modal('hide');
    });
  }

  adjustModalBackdrop(modalId) {
    $(modalId).on('show.bs.modal', function () {
      $('body').addClass('tutorial-modal-open');
    });
  }

  displayModals() {
     if (localStorage.getItem('tutorialClikingDone') !== 'true') {
      for (var i = 1; i <= this.modalContent.length; i++) {
        var modal = this.generateModal(i, this.actionClass);
        $('#modal-container').append(modal);
        this.adjustModalBackdrop('#tutoModalToggle' + i);
        this.configureModalSpecificActions('#tutoModalToggle' + i, this.actionClass);
        this.attachCheckboxEventHandlers('#tutoModalToggle' + i);
      }

      $('#tutoModalToggle1').modal('show');
    }
  }

  setRedirections() {
    if(this.actionClass == 'index_gerant') {
      let url;
      if ($('.sidebar-nested-item:eq(1) a').attr('href').includes('base_client')) {
        url = $('.sidebar-nested-item:eq(0) a').attr('href');
      } else {
        url = $('.sidebar-nested-item:eq(1) a').attr('href');
      }
      $('#tutoModalToggle9').on('hidden.bs.modal', function () {
        window.location.href = url;
        this.triggerLoadingDots(loadingDotsContainer);
      });
    }

    if (this.actionClass != 'index_gerant') {
      const url = $('.main-item.collapsible:eq(0) a').attr('href');
      $('#tutoModalToggle6').on('hidden.bs.modal', function () {
        window.location.href = url;
        this.triggerLoadingDots(loadingDotsContainer);
      });
    }
  }

}
