const closeFlashes = () => {
  const close = document.getElementById("close");
  const alert = document.querySelector('.alert');

  if (close) {
    console.log('closeFlashes')
    close.addEventListener('click', () => {
      alert.classList.add('hidden');
    })

  }
}

export { closeFlashes };
