import { showBanner, setBanner } from './_flash_banner';
import { tagPictureCropper } from './_handleCropper';
// DIV POUR FAIRE APPARAITRE FORMULAIRE CREATE QUESTION INITIALE

const displayCardCreateQuestion = () => {
  // faire apparaitre le questionnaire du bon theme question quand on appuie sur 'ajouter une question' pour un thème
  // on ajoute les id du Groupe Question Id , et de la question Id si c'est une question additionnelle => pour le reprendre en Back dans les params
  const createQuestionBtns = document.querySelectorAll(".btn-all-question-au-theme-js")
  createQuestionBtns.forEach((btn) => {
    if ( !btn.classList.contains("not-clickable")) {
      btn.addEventListener('click', (event) => {
        const cardNewQuestion = event.currentTarget.parentElement.nextElementSibling
        if (cardNewQuestion.classList.contains("hidden") === true && event.currentTarget.classList.contains("hidden") === false ) {
          cardNewQuestion.classList.remove("hidden")
          event.currentTarget.classList.add('hidden')
        }
        if (event.currentTarget.dataset.questionIdForAdditionnelle){
          cardNewQuestion.querySelectorAll('input')[3].value = event.currentTarget.dataset.questionIdForAdditionnelle
        }
      })
    }
  })
}


const removeCardCreateQuestion = () => {
  // faire disparaitre le questionnaire et reapparaitre le btn "+ ajouter une nouvelle question"
  const removeCardCreateQuest = document.querySelectorAll(".hide-card-new-question")
  removeCardCreateQuest.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const groupeQuestionId = event.currentTarget.dataset.gqId
      const btnAddQuestion = document.querySelector(".btn-all-question-au-theme-js" + "[class*='" + parseInt(groupeQuestionId) + "']")
      const cardNewQuestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement
      if (btnAddQuestion.classList.contains("hidden") === true && cardNewQuestion.classList.contains("hidden") === false ) {
        cardNewQuestion.classList.add('hidden')
        btnAddQuestion.classList.remove("hidden")
      }
    })
  })
}

// DIV POUR FAIRE APPARAITRE FORMULAIRE CREATE QUESTION INITIALE DE LIAISON LINK_KEY

const displayCardCreateQuestionLinkKey = () => {
  // faire apparaitre le questionnaire du bon theme question quand on appuie sur 'ajouter une question' pour un thème
  // on ajoute les id du Groupe Question Id , et de la question Id si c'est une question additionnelle => pour le reprendre en Back dans les params
  const createQuestionBtns = document.querySelectorAll(".btn-all-question-link-key-js")
  createQuestionBtns.forEach((btn) => {
    if ( !btn.classList.contains("not-clickable")) {
      btn.addEventListener('click', (event) => {
        const cardNewQuestion = event.currentTarget.parentElement.nextElementSibling
        if (cardNewQuestion.classList.contains("hidden") === true && event.currentTarget.classList.contains("hidden") === false ) {
          cardNewQuestion.classList.remove("hidden")
          event.currentTarget.classList.add('hidden')
        }
        if (event.currentTarget.dataset.questionIdForAdditionnelle){
          cardNewQuestion.querySelectorAll('input')[3].value = event.currentTarget.dataset.questionIdForAdditionnelle
        }
      })
    }
  })
}

const removeCardCreateQuestionLinkKey = () => {
  // faire disparaitre le questionnaire et reapparaitre le btn "+ ajouter une nouvelle question"
  const removeCardCreateQuest = document.querySelectorAll(".hide-card-new-question")
  removeCardCreateQuest.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const groupeQuestionId = event.currentTarget.dataset.gqId
      const btnAddQuestion = document.querySelector(".btn-all-question-link-key-js" + "[class*='" + parseInt(groupeQuestionId) + "']")
      const cardNewQuestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement
      if (btnAddQuestion.classList.contains("hidden") === true && cardNewQuestion.classList.contains("hidden") === false ) {
        cardNewQuestion.classList.add('hidden')
        btnAddQuestion.classList.remove("hidden")
      }
    })
  })
}

// DIV POUR FAIRE APPARAITRE FORMULAIRE DE LA QUESTION COMMENTAIRE EN FIN DE THEME : REVOIR CES 2 MÉTHODES POUR REFACTO
// CAR EXACTEMENT LA MEME CHOSE QU ECELLE DU DESSUS AVEC LA CLASSE btn-add-question-remarque-js

const displayCardCreateQuestionRemarque = () => {
  // faire apparaitre le questionnaire du bon theme question quand on appuie sur 'ajouter une remarque' pour un thème
  // on ajoute les id du Groupe Question Id , et de la question Id si c'est une question additionnelle => pour le reprendre en Back dans les params
  const createQuestionBtns = document.querySelectorAll(".btn-add-question-remarque-js")
  createQuestionBtns.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      if (!btn.classList.contains("not-clickable")) {
        const cardNewQuestion = event.currentTarget.parentElement.nextElementSibling
        if (cardNewQuestion.classList.contains("hidden") === true && event.currentTarget.classList.contains("hidden") === false ) {
          cardNewQuestion.classList.remove("hidden")
          event.currentTarget.classList.add('hidden')
        }
      }
    })
  })
}

const removeCardCreateQuestionRemarque = () => {
  // faire disparaitre le questionnaire et reapparaitre le btn "+ ajouter une remarque"
  const removeCardCreateQuest = document.querySelectorAll(".hide-card-new-question")
  removeCardCreateQuest.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const groupeQuestionId = event.currentTarget.dataset.gqId
      const btnAddQuestion = document.querySelector(".btn-add-question-remarque-js" + "[class*='" + parseInt(groupeQuestionId) + "']")
      const cardNewQuestion = event.currentTarget.parentElement.parentElement.parentElement.parentElement
      if (btnAddQuestion && cardNewQuestion )
        if (btnAddQuestion.classList.contains("hidden") === true && cardNewQuestion.classList.contains("hidden") === false ) {
          cardNewQuestion.classList.add('hidden')
          btnAddQuestion.classList.remove("hidden")
        }
    })
  })
}

// DROPDOWN MENU, FAIRE APPARAITRE/ DISPARAITRE LE TYPE DE QUESTION CHOISI POUR QUESTION INITIALE

const toggleDifferentTypeQuestionWhileDropdown = () => {
  var englishLocaleOuiNon = window.location.href.match(/\/en\//)

   // quand on appuie sur le dropdown du type de question souhaité, l'image adequate de la question s'affiche, et une simulation click() se fait sur la radio concernée.
   // methode doublé par array (l.172) pour faire ca sur la création des questions initiales et sur les questions link_key (question de liaison)
  let themeQuestion = ""
  document.querySelectorAll(".dropdown-for-question-js").forEach((btn_dropdown) => {
    btn_dropdown.addEventListener("click", (event) => {
      themeQuestion = event.currentTarget.dataset.question

      if (themeQuestion !== "") {
        [[".type_question.", ".image-type-question."]].forEach((class_to_change) => {
          const dropDownTypeQuestionBtns = document.querySelectorAll(class_to_change[0] + themeQuestion)
          dropDownTypeQuestionBtns.forEach((btn) => {
            btn.addEventListener('click', (event) => {
              // event.preventDefault(); => pour eviter que la page remonte en haut mais quand on fait ca la simulation du click ne marche plus en dessous, et du coup en back on re recupere pus le params type_question A REVOIR
              event.preventDefault()

              //simuler le click
              event.currentTarget.firstElementChild.firstElementChild.checked = true;
              // remettre toutes les images en hidden
              document.querySelectorAll(class_to_change[1] + themeQuestion).forEach((typeQuestion) => {
                typeQuestion.classList.add("hidden")
              })
              // et enlever le hidden sur la bonne image
              const typeQuestion = event.currentTarget.dataset.type
              document.querySelector(class_to_change[1] + themeQuestion + "." + typeQuestion).classList.toggle("hidden")

              // changer le texte 'type question' du btn du dropdown par le nom du type_question séléctionné
              let typeQuestionToShow = ''
              if (typeQuestion === 'note-un-a-dix') {
                typeQuestionToShow = englishLocaleOuiNon ? '1 to 10 Notation' : 'Note Un à Dix'
              } else if (typeQuestion == 'satisfaction') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Very satisfied...' : 'Très Satisfait...'
              } else if (typeQuestion == 'important') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Very important...' : 'Très Important...'
              } else if (typeQuestion == 'oui-non') {
                typeQuestionToShow = englishLocaleOuiNon ? 'YES / NO' : 'OUI / NON'
              } else if (typeQuestion == 'agree-or-not') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Agree...' : 'D\'accord...'
              } else if (typeQuestion == 'tag-unique') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Single checkbox answer' : 'Case unique à cocher'
              } else if (typeQuestion == 'tag-multiple') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Multiple checkbox answers' : 'Choix multiples neutres'
              } else if (typeQuestion == 'tag-positif') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Multiple positive answers' : 'Choix multiples positifs'
              } else if (typeQuestion == 'tag-negatif') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Multiple negative answers' : 'Choix multiples négatifs'
              } else if (typeQuestion == 'review') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Text zone' : 'Zone de texte'
              } else if (typeQuestion == 'emojis') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Smileys' : 'Émoticônes'
              } else if (typeQuestion == 'etoiles') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Stars' : 'Étoiles'
							} else if (typeQuestion == 'nationality') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Nationality' : 'Nationalité'
							} else if (typeQuestion == 'classement') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Ranking' : 'Classement'
              }
              btn_dropdown.innerHTML = `${typeQuestionToShow}`

            })
          })


        })
      }

    })
  })

	document.querySelectorAll(".dropdown-for-question-link-key-js").forEach((btn_dropdown) => {
    btn_dropdown.addEventListener("click", (event) => {
      themeQuestion = event.currentTarget.dataset.question

      if (themeQuestion !== "") {
        [[".type_question_link_key.", ".image-type-question-link-key."]].forEach((class_to_change) => {
          const dropDownTypeQuestionBtns = document.querySelectorAll(class_to_change[0] + themeQuestion)
          dropDownTypeQuestionBtns.forEach((btn) => {
            btn.addEventListener('click', (event) => {
              // event.preventDefault(); => pour eviter que la page remonte en haut mais quand on fait ca la simulation du click ne marche plus en dessous, et du coup en back on re recupere pus le params type_question A REVOIR
              event.preventDefault()

              //simuler le click
              event.currentTarget.firstElementChild.firstElementChild.checked = true;
              // remettre toutes les images en hidden
              document.querySelectorAll(class_to_change[1] + themeQuestion).forEach((typeQuestion) => {
                typeQuestion.classList.add("hidden")
              })
              // et enlever le hidden sur la bonne image
              const typeQuestion = event.currentTarget.dataset.type
              document.querySelector(class_to_change[1] + themeQuestion + "." + typeQuestion).classList.toggle("hidden")

              // changer le texte 'type question' du btn du dropdown par le nom du type_question séléctionné
              let typeQuestionToShow = ''
              if (typeQuestion === 'note-un-a-dix') {
                typeQuestionToShow = englishLocaleOuiNon ? '1 to 10 Notation' : 'Note Un à Dix'
              } else if (typeQuestion == 'satisfaction') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Very satisfied...' : 'Très Satisfait...'
              } else if (typeQuestion == 'important') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Very important...' : 'Très Important...'
              } else if (typeQuestion == 'oui-non') {
                typeQuestionToShow = englishLocaleOuiNon ? 'YES / NO' : 'OUI / NON'
              } else if (typeQuestion == 'agree-or-not') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Agree...' : 'D\'accord...'
              } else if (typeQuestion == 'tag-unique') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Single checkbox answer' : 'Case unique à cocher'
              } else if (typeQuestion == 'tag-multiple') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Multiple checkbox answers' : 'Choix multiples neutres'
              } else if (typeQuestion == 'tag-positif') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Multiple positive answers' : 'Choix multiples positifs'
              } else if (typeQuestion == 'tag-negatif') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Multiple negative answers' : 'Choix multiples négatifs'
              } else if (typeQuestion == 'review') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Text zone' : 'Zone de texte'
              } else if (typeQuestion == 'emojis') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Smileys' : 'Émoticônes'
              } else if (typeQuestion == 'etoiles') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Stars' : 'Étoiles'
              } else if (typeQuestion == 'nationality') {
                typeQuestionToShow = englishLocaleOuiNon ? 'Nationality' : 'Nationalité'
              }
              btn_dropdown.innerHTML = `${typeQuestionToShow}`

            })
          })


        })
      }

    })
  })

}

// AFFICHER LES OPTIONS DES REPONSES
const selectedAnswerOptions = (selectedOption) =>{

  const options = document.querySelectorAll(selectedOption)

  options.forEach(option => {
    option.addEventListener("click", ()=>{
      const imageSize = document.querySelectorAll(".image-size")
      const optionsModal = option.closest(".answer-options")
      const listTags = optionsModal.nextElementSibling
      const restrictionInput = listTags.querySelector(".restriction-max-nb-answers")
      const typeTagAnswer = listTags.closest("form").querySelector("#type-tag-answer")
      optionsModal.classList.add("hidden")
      listTags.classList.remove("hidden")

      if(selectedOption === ".option-text") {

        imageSize.forEach(element => {
          element.classList.add("hidden")
        });

        listTags.setAttribute('data-selected-option', 'text');
        editTextOptionWidthJs (listTags)
        typeTagAnswer.value = "text"
        
      }else {
        typeTagAnswer.value = "both"
        createCheckbox(listTags, typeTagAnswer)
        optionBoth(listTags)
      }
      if(restrictionInput && restrictionInput.classList.contains("restriction-max-nb-answers")) {
        restrictionInput.classList.toggle("hidden")
        const restrictionCheckbox = restrictionInput.querySelector("input[type='checkbox']")
        const restrictionNumber = restrictionInput.querySelector("input[type='number']")
        const description_message = restrictionInput.querySelector(".description_message")
        restrictionCheckbox.addEventListener("change", ()=>{
					if (restrictionCheckbox.checked) {
						restrictionNumber.disabled = false
						description_message.classList.remove("d-none")
					} else {
						restrictionNumber.disabled = true
						description_message.classList.add("d-none")
					}
        })
        if(restrictionNumber.disabled) {
          restrictionNumber.value = 0
        }
      }
    })
  });
}

const createCheckbox = (listTags, typeTagAnswer) => {
  // Création de la case à cocher
  listTags.setAttribute('data-selected-option', 'both')
  var checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.checked = true; // Case cochée par défaut
  checkbox.style.transform = 'scale(1.4)';
  // Création de l'élément de texte
  var labelText = document.createTextNode(listTags.parentNode.querySelector("[data-show-image-questionnaire]").dataset.showImageQuestionnaire);
  var label = document.createElement("label")
  label.appendChild(labelText)
  label.classList.add("indicator-checkbox-label")
  // Création du conteneur pour la case à cocher et le texte
  var container = document.createElement('div');

  // Ajout de la case à cocher et du texte au conteneur
  container.appendChild(checkbox);
  container.appendChild(label);

  // Ajout du conteneur à côté de l'élément listTags
  listTags.parentNode.insertBefore(container, listTags.nextElementSibling);
  checkbox.addEventListener('change', function() {
    if (checkbox.checked) {
      typeTagAnswer.value = "both"
    } else {
      typeTagAnswer.value = "image"
    }
  });
}


const optionBoth = (listTags)=>{
  const tagsContainer = listTags.querySelectorAll(".tag-picture-input-container")
  const questionTextInput = listTags.querySelector(".question-text-input")
  const inputs = document.querySelectorAll(".inputs-tag-answer")
  // afficher le tag container
  tagsContainer.forEach(element => {
    element.classList.remove("hidden")
  });
  if(questionTextInput) {
    questionTextInput.style.display = "block"
  }
  inputs.forEach(input => {
    input.addEventListener("change", (event)=>{
      tagPictureCropper(event, true)
    })
  });


  btnOtherTagOuiNon(listTags);
}

function editTextOptionWidthJs (listTags) {
  // a refaire plus tard, cette fonction est pour régler le probleme front new tags (option texte )
  if(listTags.dataset.selectedOption === "text") {
    listTags.querySelector(".tags-container").style.flexDirection = "column"
    listTags.querySelector(".tags-container").style.gap = "0px"
    listTags.querySelectorAll(".new-question-add-tag-card").forEach(tagCard => {
      tagCard.querySelectorAll(".tags-multi-language").forEach(multiLangDiv => {
        multiLangDiv.style.width = "280px"
      })
      tagCard.style.width = "fit-content"
      tagCard.style.flexDirection = "row"
      tagCard.style.justifyContent = "space-between"
      tagCard.style.boxShadow = "none"
    });
  }

}

// CHOIX UNIQUE - QUESTION INITIALE
const btnPlusMoinsOnTagsChoixUnique = () => {

  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                          "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                          "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                          },
                    "en" : {"textFrench": 'Enter a possible answer',
                          "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                          "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                          }
                  }

  // Btn + pour tag choix unique:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  const btnPlus = document.querySelectorAll('.tag-unique-plus')
  btnPlus.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const closestPlaceholder = btn.closest('.list-tags-unique').nextElementSibling.nextElementSibling

      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = document.querySelector('.radio.list-tags-unique.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-choix-unique-${theme}-${numberTags}">
                                            <img id="preview-image-choix-unique-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-choix-unique-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }


      let inputDiv = "";
      let checkboxTag = "";
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en";
        checkboxTag = `<input type='checkbox' id='checkbox_tag_unique' class='checkbox_tag_unique hidden' name='checkbox_tag_unique_${numberTags}'>`

        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>");
        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en";

          checkboxTag = `<input type='checkbox' id='checkbox_tag_unique' class='checkbox_tag_unique position-multilangue hidden' name='checkbox_tag_unique_${numberTags}'>`
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'>  <input type='text' placeholder='" + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>");
          }else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>");
          }
        });
      }


      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + checkboxTag + "</div> </div>"

      if (listTags.dataset.selectedOption === "text") {
        if (languageInfo.englishOuiNon === "false") {
          listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
        } else {
          listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
        }

        if (i > 8 && closestPlaceholder.classList.contains('hidden') ) {
          // show placeholder div
          closestPlaceholder.classList.remove('hidden')
          closestPlaceholder.nextElementSibling.classList.remove('hidden')
        }
      } else {
        if (i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          }
        }
      }
      if(i < 12){
        i += 1;
      };

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-choix-unique-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    });
  });


  // Btn + pour tag choix unique LINK KEY:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  let j = 1;//number of tags
  if (document.querySelector('.tag-unique-plus-link-key')) {
    const btnPlusLinkKey = document.querySelectorAll('.tag-unique-plus-link-key');
    btnPlusLinkKey.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const themeLinkKey = event.currentTarget.dataset.theme
        const closestPlaceholderLink = btn.closest('.list-tags-unique-link-key').nextElementSibling.nextElementSibling
        event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1;
        let numberTagsLinkKey = event.currentTarget.dataset.numberTags;
        const listTagsLinkKey = document.querySelector('.radio.list-tags-unique-link-key.' + themeLinkKey);

        // creation of tag picture input container with js
        let tagPictureInputContainer = null;
        if (listTagsLinkKey.dataset.selectedOption === "text") {
          tagPictureInputContainer = "";
        }else{
          tagPictureInputContainer = `<div class="tag-picture-input-container ${themeLinkKey}">
                                            <label for="image-upload-link-choix-unique-${themeLinkKey}-${numberTagsLinkKey}">
                                              <img id="preview-image-link-choix-unique-${themeLinkKey}-${numberTagsLinkKey}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                            </label>
                                            <input type="file" name="images[]" multiple="multiple" id="image-upload-link-choix-unique-${themeLinkKey}-${numberTagsLinkKey}" class="inputs-tag-answer hidden">
                                          </div>`
        }

        let inputDiv = "";
        let checkboxTag = "";
        if (allLanguages.length === 1) {
          const lang = allLanguages[0];
          checkboxTag = `<input type='checkbox' id='checkbox_tag_unique' class='checkbox_tag_unique hidden' name='checkbox_tag_unique_${numberTagsLinkKey}'>`
          const language = lang === "fr" ? "fr" : "en";
          if(listTagsLinkKey.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + textFrench + "' name='tag_unique_" + lang + numberTagsLinkKey + "' id='tag_unique_" + lang + numberTagsLinkKey + "' ></div>");
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture_${numberTagsLinkKey}' type='text' placeholder='` + textFrench + "' name='tag_unique_"  + lang + numberTagsLinkKey + "' id='tag_unique_" + lang + numberTagsLinkKey + "' ></div>");
          }
        } else {
          allLanguages.forEach((lang) => {
            const language = lang === "fr" ? "fr" : "en";
            checkboxTag = `<input type='checkbox' id='checkbox_tag_unique' class='checkbox_tag_unique position-multilangue hidden' name='checkbox_tag_unique_${numberTagsLinkKey}'>`

            if(listTagsLinkKey.dataset.selectedOption !== "image") {
              inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'>  <input type='text' placeholder='" + textFrench + "' name='tag_unique_" + lang + numberTagsLinkKey + "' id='tag_unique_" + lang + numberTagsLinkKey + "' ></div>");
            } else {
              inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTagsLinkKey}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_unique_"  + lang + numberTagsLinkKey + "' id='tag_unique_" + lang + numberTagsLinkKey + "' ></div>");
            }
          });
        }

        const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTagsLinkKey.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + checkboxTag + "</div> </div>"

          if(listTagsLinkKey.dataset.selectedOption === "text") {
            if (languageInfo.englishOuiNon === "false") {
              listTagsLinkKey.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
            } else {
              listTagsLinkKey.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
            }

            if (j > 8 && closestPlaceholderLink.classList.contains('hidden')) {
              // show placeholder div
              closestPlaceholderLink.classList.remove('hidden')
              closestPlaceholderLink.nextElementSibling.classList.remove('hidden')
            }

          } else {
            if( j < 12) {
              if (languageInfo.englishOuiNon === "false") {
                listTagsLinkKey.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
              } else {
                listTagsLinkKey.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
              }
            }

          }

          j += 1
        // add eventListener for the new input after inseting it inside the dom
        const newInput = document.getElementById(`image-upload-link-choix-unique-${themeLinkKey}-${numberTagsLinkKey}`)
        if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
        editTextOptionWidthJs (listTagsLinkKey)
      })
    })
  }

  // Btn - pour tag choix unique: enlever le dernier input
  const btnMoins = document.querySelectorAll('.tag-unique-moins')
  btnMoins.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const closestPlaceholder = btn.closest('.list-tags-unique').nextElementSibling.nextElementSibling

      const theme = event.currentTarget.dataset.theme
      const listTags = document.querySelector('.radio.list-tags-unique.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
        if (i <= 8 && !closestPlaceholder.classList.contains('hidden')) {
          // hide placeholder div
          closestPlaceholder.classList.add('hidden')
          closestPlaceholder.nextElementSibling.classList.add('hidden')
        }
      }
    })
  })

  // Btn - pour tag choix unique LINK KEY: enlever le dernier input
  if (document.querySelector('.tag-unique-moins-link-key')) {
    const btnMoinsLinkKey = document.querySelectorAll('.tag-unique-moins-link-key')
    btnMoinsLinkKey.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const themeLinkKey = event.currentTarget.dataset.theme
        const closestPlaceholderLink = btn.closest('.list-tags-unique-link-key').nextElementSibling.nextElementSibling
        const listTagsLinkKey = document.querySelector('.radio.list-tags-unique-link-key.' + themeLinkKey)
        if (listTagsLinkKey.querySelector(".tags-container").children.length > 1) {
          listTagsLinkKey.querySelector(".tags-container").lastElementChild.remove();
          if(i > 1){
            j -= 1;
          }
          if (j <= 8 && !closestPlaceholderLink.classList.contains('hidden')) {
            closestPlaceholderLink.classList.add('hidden')
            closestPlaceholderLink.nextElementSibling.classList.add('hidden')
          }
        }
      })
    })
  }

}

// CHOIX MULTIPLE - QUESTION INITIALE

const btnPlusMoinsOnTagsChoixMultiple = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                          "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                          "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                          },
                    "en" : {"textFrench": 'Enter a possible answer',
                          "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                          "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                          }
                  }

  // Btn + pour tag choix multiple:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_multiple1, tag_multiple2 etc...)
  const btnPlusMultiple = document.querySelectorAll('.tag-multiple-plus')
  btnPlusMultiple.forEach((btn) => {
    btn.addEventListener('click', (event) => {
			const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = document.querySelector('.checkbox.list-tags-multiple.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-choix-multiple-${theme}-${numberTags}">
                                            <img id="preview-image-choix-multiple-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-choix-multiple-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>")
        }else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

			if ( i < 12) {
				listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
			}

      // if(listTags.dataset.selectedOption === "text"){
      //   if ( i < 12) {
      //     if (languageInfo.englishOuiNon === "false") {
      //       listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
      //     } else {
      //       listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
      //     }
      //   }
      // }else {
      //   if ( i < 12) {
      //     if (languageInfo.englishOuiNon === "false") {
      //       listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
      //     } else {
      //       listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
      //     }
      //   }
      // }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-choix-multiple-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag choix multiple: enlever le dernier input
  const btnMoinsMultiple = document.querySelectorAll('.tag-multiple-moins')
  btnMoinsMultiple.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const listTags = event.currentTarget.querySelector('.checkbox.list-tags-multiple.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

	const btnPlusMultipleLinkKey = document.querySelectorAll('.tag-multiple-plus-link-key')
  btnPlusMultipleLinkKey.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = document.querySelector('.checkbox.list-tags-multiple-link-key.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-link-choix-multiple-${theme}-${numberTags}">
                                            <img id="preview-image-link-choix-multiple-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-link-choix-multiple-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div style='display:flex; justify-content: center'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>")
        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"

          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"


      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          }
        }
      }else {
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-link-choix-multiple-${theme}-${numberTags}`)
      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)

    })
  })

  // Btn - pour tag choix multiple: enlever le dernier input
  const btnMoinsMultiplelinkKey = document.querySelectorAll('.tag-multiple-moins-link-key')
  btnMoinsMultiplelinkKey.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const listTags = document.querySelector('.checkbox.list-tags-multiple-link-key.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

}

// TAG POSITIF - QUESTION INITIALE

const btnPlusMoinsOnTagsPositif = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                          "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                          "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                          },
                    "en" : {"textFrench": 'Enter a possible answer',
                          "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                          "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                          }
                  }


  // Btn + pour tag positif:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  const btnPlusPositif = document.querySelectorAll('.tag-positif-plus')
  btnPlusPositif.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = document.querySelector('.checkbox.list-tags-positif.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-tag-positif-${theme}-${numberTags}">
                                            <img id="preview-image-tag-positif-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-tag-positif-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>")

        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'>  <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'"  + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }else {
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-tag-positif-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag  positif: enlever le dernier input
  const btnMoinsPositif = document.querySelectorAll('.tag-positif-moins')
  btnMoinsPositif.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const listTags = document.querySelector('.checkbox.list-tags-positif.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })
}

// Tag Négatif - QUESTION INITIALE

const btnPlusMoinsOnTagsNegatif = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                          "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                          "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                          },
                    "en" : {"textFrench": 'Enter a possible answer',
                          "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                          "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                          }
                  }

  // Btn + pour Tag Négatif:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  const btnPlusNegatif = document.querySelectorAll('.tag-negatif-plus')
  btnPlusNegatif.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = document.querySelector('.checkbox.list-tags-negatif.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-tag-negatif-${theme}-${numberTags}">
                                            <img id="preview-image-tag-negatif-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-tag-negatif-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>")
        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image"){
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'>  <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>")
          }else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }else {
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-tag-negatif-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag  negatif: enlever le dernier input
  const btnMoinsNegatif = document.querySelectorAll('.tag-negatif-moins')
  btnMoinsNegatif.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const listTags = document.querySelector('.checkbox.list-tags-negatif.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })
}

const btnOtherTagOuiNon = (listTags) => {
  if(listTags.parentNode.classList.contains("oui-non")){
    listTags.parentNode.querySelectorAll("img").forEach(image =>{
      image.style.setProperty("width", "280px", "important")
    })
    // mettre le button en flex end en css
    listTags.style.setProperty("align-items", "end", "important");
    // afficher le tag inputs partial
    if(listTags.closest(".oui-non")){
      const ouiNonOtherTagDiv = listTags.closest(".oui-non").querySelector("#oui-non-other-tag-new-question")
      ouiNonOtherTagDiv.firstElementChild.classList.remove("hidden")
      // TODO ajouter une class pour plus tard
      ouiNonOtherTagDiv.style.boxShadow = "0px 0px 2px 0px rgb(118 118 118)"
      ouiNonOtherTagDiv.style.padding = "20px"
      ouiNonOtherTagDiv.querySelector("label").style.setProperty("width", "auto", "important");
    }
  }
}

// QUESTION INITIALE & QUESTION ADDITIONNELLE : CRÉER UN TAG DANS UNE CARD 'CREATED QUESTION' & 'CREATED ADDITIONAL QUESTION' : TOGGLE BTN
// A SUPPR QUAND LES CLASS form-create-tag-in-created-questioncard-js auront toutes été remplacée par form-create-tag-in-created-questioncard-js2
const toggleBtnCreateTagInCreatedQuestionAndAddi = () => {

  const createTagsForms = document.querySelectorAll(".form-create-tag-in-created-questioncard-js2")
  createTagsForms.forEach((form) => {

    // add eventlistener for the input type file to add picture to a tag
    const tagInput = form.querySelector(".inputs-tag-answer")
    if(tagInput){
      tagInput.addEventListener("change", (event)=>{
        tagPictureCropper(event, true)
        const questionOption = form.querySelector(".tag-picture-input-container").dataset.questionOption
        const submitBtn = form.querySelector("input[type='submit'].submit-btn-create-tag-js");
        if(questionOption === "image") {
          // activate submit btn when image uploaded
          const previewImage = form.querySelector(".preview-image-tag-cropped")
          previewImage.addEventListener("load", ()=> {
            submitBtn.setAttribute('type', 'submit');
            submitBtn.classList.remove("not-clickable")
            submitBtn.classList.add("btn-add-tag-survey")
          })
        }
      })
    }

    // check if all content_question of all languages are filled
    form.addEventListener('keyup', () => {
      checkConditionAfterImageUploadToAddTag(form);
    });

    // check if all content_question of all languages are filled
    form.addEventListener('click', () => {
      checkConditionAfterImageUploadToAddTag(form);
    });

  })
}

const checkConditionAfterImageUploadToAddTag = (form) => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)
  let allContentFilled = false
  const filledByLanguage= []
  let val = ""
  allLanguages.forEach((lang) => {
    val  = form.querySelector(`#content_${lang}`).value.replaceAll(' ', '')
    filledByLanguage.push(val !== '' && val.length > 2 )
  })
  allContentFilled = filledByLanguage.includes(false) ? false : true

  const mainLanguage = form.querySelector(".main_lang").value.toLowerCase().replaceAll(' ', '')

  const btnSubmit = form.querySelector('input.submit-btn-create-tag-js')
  let allTagsOfQuestion = []
  if (form.parentElement.dataset.allTagsOfQuestion) {
    let allTagsQuest = JSON.parse(form.parentElement.dataset.allTagsOfQuestion)
    allTagsQuest.forEach((tag) => {allTagsOfQuestion.push(tag.toLowerCase().replaceAll(' ', ''))})
  }
  let allTagsOfQuestionAddi = []
  if (form.parentElement.dataset.allTagsOfQuestionAddi) {
    let allTagsAddi = JSON.parse(form.parentElement.dataset.allTagsOfQuestionAddi)
    allTagsAddi.forEach((tag) => {allTagsOfQuestionAddi.push(tag.toLowerCase().replaceAll(' ', ''))})
  }
  // Récupération de l'élément input de type file et de l'option de la question
  const questionOption = form.querySelector(".tag-picture-input-container").dataset.questionOption
  const fileInput = form.querySelector('input[type="file"]');
  let isFileSelected = true
  // Vérification si un fichier est sélectionné
  if(questionOption !== "text") {
    isFileSelected = fileInput.files.length > 0
  }
  // Vérification des conditions pour activer le bouton de soumission
  const isSubmissionEnabled = allContentFilled &&
                              !allTagsOfQuestion.includes(mainLanguage) &&
                              !allTagsOfQuestionAddi.includes(mainLanguage) &&
                              isFileSelected;

  // Attribution du type et des classes du bouton de soumission en fonction des conditions
  if (isSubmissionEnabled) {
      btnSubmit.setAttribute('type', 'submit');
      btnSubmit.classList.add("btn-add-tag-survey");
      btnSubmit.classList.remove("not-clickable");
  } else {
      btnSubmit.setAttribute('type', 'button');
      btnSubmit.classList.remove("btn-add-tag-survey");
      btnSubmit.classList.add("not-clickable");
      btnSubmit.style.border = 'none';
      btnSubmit.style.color = 'white';
      btnSubmit.style.borderRadius = '3px';
  }
}

// QUESTION ADDITIONNELLE !

// DIV POUR FAIRE APPARAITRE FORMULAIRE CREATE QUESTION ADDITIONNELLE

const displayCardCreateQuestionAddi = () => {
  // faire apparaitre le questionnaire additionnel
  const allOrangeBtnAdditionals = document.querySelectorAll(".custom-btn.btn-add-question.btn-color-orange")
  allOrangeBtnAdditionals.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const cardCreationQuestAddi = event.currentTarget.parentElement.nextElementSibling
      if (cardCreationQuestAddi.classList.contains("hidden") === true && event.currentTarget.classList.contains("hidden") === false ) {
        cardCreationQuestAddi.classList.remove("hidden")
        event.currentTarget.classList.add('hidden')
      }

    })
  })
}

const removeCardCreateQuestionAddi = () => {
  // faire disparaitre le questionnaire additionnel et reapparaitre le btn "+ une question conditionnelle"
  const removeCardCreateQuest = document.querySelectorAll(".hide-card-new-question-addi")
  removeCardCreateQuest.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const questionId = event.currentTarget.dataset.questionId
      const btnAddQuestion = document.querySelector(".custom-btn.btn-add-question.btn-color-orange" + "[class*='" + parseInt(questionId) + "']")
      const cardCreationQuestAddi = event.currentTarget.parentElement.parentElement.parentElement
      if (btnAddQuestion.classList.contains("hidden") === true && cardCreationQuestAddi.classList.contains("hidden") === false ) {
        cardCreationQuestAddi.classList.add('hidden')
        btnAddQuestion.classList.remove("hidden")
      }
    })
  })
}

// DROPDOWN MENU, FAIRE APPARAITRE/ DISPARAITRE LE TYPE DE QUESTION CHOISI POUR QUESTION ADDI

const toggleDifferentTypeQuestionAddiWhileDropdown = () => {
  var englishLocaleOuiNon = window.location.href.match(/\/en\//)

  // Dropdown menu : faire apparaitre/disparaitre le type de question en fonction du type de question choisi
  let themeAdditionalQuestion = ""
  document.querySelectorAll(".dropdown-for-additional-question-js").forEach((btn_dropdown) => {
    btn_dropdown.addEventListener("click", (event) => {
      themeAdditionalQuestion = event.currentTarget.dataset.theme
      const questionId = event.currentTarget.dataset.questionId
      if (themeAdditionalQuestion !== "") {
        const dropDownTypeQuestionBtns = document.querySelectorAll('.type_question-additionnal.' + themeAdditionalQuestion)
        dropDownTypeQuestionBtns.forEach((btn) => {
          btn.addEventListener('click', (event) => {

            event.preventDefault()

            //simuler le click
            event.currentTarget.firstElementChild.firstElementChild.checked = true;
            // remettre toutes les images en hidden
            document.querySelectorAll(".image-type-additional-question." + themeAdditionalQuestion + "[class*='" + parseInt(questionId) + "']").forEach((typeQuestion) => {
							typeQuestion.classList.add("hidden")
						})
						// et enlever le hidden sur la bonne image
						const typeQuestion = event.currentTarget.dataset.type;
            document.querySelector(".image-type-additional-question." + themeAdditionalQuestion + "." + typeQuestion + "[class*='" + parseInt(questionId) + "']").classList.toggle("hidden")
            // document.querySelector(".image-type-additional-question." + themeAdditionalQuestion + ".[data-question-id=`${questionId}`]" ).classList.toggle("hidden")

            // changer le texte 'type question' du btn du dropdown par le nom du type_question séléctionné
            let typeQuestionToShow = ''
            if (typeQuestion === 'note-un-a-dix') {
              typeQuestionToShow = englishLocaleOuiNon ? '1 to 10 Notation' : 'Note Un à Dix'
            } else if (typeQuestion == 'satisfaction') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Very satisfied...' : 'Très Satisfait...'
            } else if (typeQuestion == 'oui-non') {
              typeQuestionToShow = englishLocaleOuiNon ? 'YES / NO' : 'OUI / NON'
            } else if (typeQuestion == 'agree-or-not') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Agree...' : 'D\'accord...'
            } else if (typeQuestion == 'tag-unique') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Single checkbox answer' : 'Case unique à cocher'
            } else if (typeQuestion == 'tag-multiple') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Multiple checkbox answers' : 'Choix multiples neutres'
            } else if (typeQuestion == 'tag-positif') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Multiple positive answers' : 'Choix multiples positifs'
            } else if (typeQuestion == 'tag-negatif') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Multiple negative answers' : 'Choix multiples négatifs'
            } else if (typeQuestion == 'review') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Text zone' : 'Zone de texte'
            } else if (typeQuestion == 'emojis') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Smileys' : 'Émoticônes'
            } else if (typeQuestion == 'etoiles') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Stars' : 'Étoiles'
            } else if (typeQuestion == 'important') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Very important...' : 'Très Important...'
            } else if (typeQuestion == 'nationality') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Nationality' : 'Nationalité'
            } else if (typeQuestion == 'classement') {
              typeQuestionToShow = englishLocaleOuiNon ? 'Ranking' : 'Classement'
            }
            btn_dropdown.innerHTML = `${typeQuestionToShow}`

          })
        })

      }

    })
  })
}


// CHOIX UNIQUE QUESTION ADDITIONNELLE

const btnPlusMoinsOnTagsChoixUniqueAddi = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                        "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                        "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                        },
                  "en" : {"textFrench": 'Enter a possible answer',
                        "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                        "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                        }
                }

  // Btn + pour tag choix unique:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  const btnPlusAdditional = document.querySelectorAll('.tag-unique-plus-additional')
  btnPlusAdditional.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const questionId = event.currentTarget.dataset.questionId
      const listTags = document.querySelector('.radio.list-tags-unique-additional.' + theme + "[class*='" + parseInt(questionId) + "']")

      const closestPlaceholderAddi = btn.closest('.list-tags-unique-additional').nextElementSibling.nextElementSibling

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-addi-choix-unique-${theme}-${numberTags}">
                                            <img id="preview-image-addi-choix-unique-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-addi-choix-unique-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      let checkboxTag = '';
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        checkboxTag = `<input type='checkbox' id='checkbox_tag_unique' class='checkbox_tag_unique hidden' name='checkbox_tag_unique_${numberTags}'>`
        if(listTags.dataset.selectedOption !=="image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"]  + `- ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>")
        }else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture_${numberTags}' type='text' placeholder='` + textFrench + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"

          checkboxTag = `<input type='checkbox' id='checkbox_tag_unique' class='checkbox_tag_unique position-multilangue hidden' name='checkbox_tag_unique_${numberTags}'>`
          if(listTags.dataset.selectedOption !=="image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_unique_"  + lang + numberTags + "' id='tag_unique_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + checkboxTag + "</div> </div>"


        if (languageInfo.englishOuiNon === "false") {
          listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
        } else {
          listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
        }
        if(i < 12){
        i += 1;
      }

      if (i > 8 && closestPlaceholderAddi.classList.contains('hidden')) {
        // show placeholder div
        closestPlaceholderAddi.classList.remove('hidden')
        closestPlaceholderAddi.nextElementSibling.classList.remove('hidden')
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-addi-choix-unique-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag choix unique: enlever le dernier input
  const btnMoinsAdditionnal = document.querySelectorAll('.tag-unique-moins-additional')
  btnMoinsAdditionnal.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const questionId = event.currentTarget.dataset.questionId

      const closestPlaceholderAddi = btn.closest('.list-tags-unique-additional').nextElementSibling.nextElementSibling

      const listTags = document.querySelector('.radio.list-tags-unique-additional.' + theme +  "[class*='" + parseInt(questionId) + "']")
      if (listTags.querySelector(".tags-container").children.length > 2) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
        if (i <= 8 && !closestPlaceholderAddi.classList.contains('hidden')) {
          closestPlaceholderAddi.classList.add('hidden')
          closestPlaceholderAddi.nextElementSibling.classList.add('hidden')
        }
      }
    })
  })
}

// CHOIX MULTIPLE QUESTION ADDITIONNELLE
const btnPlusMoinsOnTagsChoixMultipleAddi = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                        "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                        "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                        },
                  "en" : {"textFrench": 'Enter a possible answer',
                        "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                        "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                        }
                }

  // Btn + pour tag choix multiple:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_multiple1, tag_multiple2 etc...)
  const btnPlusMultipleAdditional = document.querySelectorAll('.tag-multiple-plus-additional')
  btnPlusMultipleAdditional.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const questionId = event.currentTarget.dataset.questionId
      const listTags = document.querySelector('.checkbox.list-tags-multiple-additional.' + theme + "[class*='" + parseInt(questionId) + "']")

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-addi-choix-multiple-${theme}-${numberTags}">
                                            <img id="preview-image-addi-choix-multiple-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-addi-choix-multiple-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>")
        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"]  + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_multiple_"  + lang + numberTags + "' id='tag_multiple_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }else {
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-addi-choix-multiple-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag choix multiple: enlever le dernier input
  const btnMoinsMultipleAdditional = document.querySelectorAll('.tag-multiple-moins-additional')
  btnMoinsMultipleAdditional.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const questionId = event.currentTarget.dataset.questionId

      const listTags = document.querySelector('.checkbox.list-tags-multiple-additional.' + theme +  "[class*='" + parseInt(questionId) + "']")
      if (listTags.querySelector(".tags-container").children.length > 2) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

}

// TAG POSITIF QUESTION ADDITIONNELLE

const btnPlusMoinsOnTagsPositifAddi = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                        "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                        "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                        },
                  "en" : {"textFrench": 'Enter a possible answer',
                        "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                        "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                        }
                }

  // Btn + pour tag positif:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  const btnPlusPositifAdditionnal = document.querySelectorAll('.tag-positif-plus-additional')
  btnPlusPositifAdditionnal.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const questionId = event.currentTarget.dataset.questionId
      const listTags = document.querySelector('.checkbox.list-tags-positif-additional.' + theme  + "[class*='" + parseInt(questionId) + "']")

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-addi-tag-positif-${theme}-${numberTags}">
                                            <img id="preview-image-addi-tag-positif-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-addi-tag-positif-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>")

        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_positif_"  + lang + numberTags + "' id='tag_positif_" + lang + numberTags + "' ></div>");
          }
        })

      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'"  + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (document.getElementById("english-or-not-for-js").dataset.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }else {
        if ( i < 12) {
          if (document.getElementById("english-or-not-for-js").dataset.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-addi-tag-positif-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag  positif: enlever le dernier input
  const btnMoinsPositifAdditional = document.querySelectorAll('.tag-positif-moins-additional')
  btnMoinsPositifAdditional.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const questionId = event.currentTarget.dataset.questionId

      const listTags = document.querySelector('.checkbox.list-tags-positif-additional.' + theme +  "[class*='" + parseInt(questionId) + "']")
      if (listTags.querySelector(".tags-container").children.length > 2) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

}

// Tag Négatif QUESTION ADDITIONNELLE

const btnPlusMoinsOnTagsNegatifAddi = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                          "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                          "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                          },
                    "en" : {"textFrench": 'Enter a possible answer',
                          "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                          "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                          }
                  }

  // Btn + pour Tag Négatif:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_unique1, tag_unique2 etc...)
  const btnPlusNegatifAdditional = document.querySelectorAll('.tag-negatif-plus-additional')
  btnPlusNegatifAdditional.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const questionId = event.currentTarget.dataset.questionId
      const listTags = document.querySelector('.checkbox.list-tags-negatif-additional.' + theme + "[class*='" + parseInt(questionId) + "']")

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-addi-tag-negatif-${theme}-${numberTags}">
                                            <img id="preview-image-addi-tag-negatif-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-addi-tag-negatif-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>")
        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'>  <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='tag_negatif_"  + lang + numberTags + "' id='tag_negatif_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'"  + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (document.getElementById("english-or-not-for-js").dataset.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }else {
        if ( i < 12) {
          if (document.getElementById("english-or-not-for-js").dataset.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message)
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-addi-tag-negatif-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag  negatif: enlever le dernier input
  const btnMoinsNegatifAdditionnal = document.querySelectorAll('.tag-negatif-moins-additional')
  btnMoinsNegatifAdditionnal.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const questionId = event.currentTarget.dataset.questionId

      const listTags = document.querySelector('.checkbox.list-tags-negatif-additional.' + theme + "[class*='" + parseInt(questionId) + "']")
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

}

//TOGGLE BUTTON CLICKABLE - NOT CLICKABLE

const checkTagPictureNewQuestion = (form) => {
  let tagPictureCheck = true;
  const listTags = form.querySelector('[data-selected-option]:not(.hidden)');
  let typeOfQuestion = ""
  if(form.querySelector("input[type='radio'][name='type_question']:checked")) {
    typeOfQuestion = form.querySelector("input[type='radio'][name='type_question']:checked").value
  }
  if(["Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Oui Non"].includes(typeOfQuestion)) {
    if (listTags) {
      tagPictureCheck = false;
      const selectedOption = listTags.dataset.selectedOption;
      if (selectedOption !== "text") {
        if ([...listTags.querySelectorAll(".preview-image-tag-cropped")].every((element) => {
          return element.src !== "https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328";
        })) {
          tagPictureCheck = true;
          // checker si le collape de other oui non est active si oui, checker si une photo est uploadée
          const otherOuiNon = listTags.parentNode.querySelector(".collapse.show")
          if(otherOuiNon){
            if(otherOuiNon.classList.contains("show")) {
              tagPictureCheck = otherOuiNon.querySelector(".preview-image-tag-cropped").src !== "https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328"
            }else {
              tagPictureCheck = false;
            }

          }
        }
      } else {
        tagPictureCheck = true;
      }
    } else {
      tagPictureCheck = true;
    }

  }
  return tagPictureCheck
}

//QUESTION INITIALE

const notAllowedOuiNon = (questionTitle) => {
  const notAllowedWordsForQuestionTitle = ['emoji'];
  return !(notAllowedWordsForQuestionTitle.includes(questionTitle))
}

const btnToggleClickableQuestion = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  // clickable / not-clickable on form create new Question Initiale
  document.querySelectorAll("button.custom-btn.btn-add-question.new-question.not-clickable").forEach((btn) => {

    btn.setAttribute("disabled", "disabled");
    const form = btn.parentElement.previousElementSibling
    const theme = form.querySelector("input#question_theme")

    const typeQuestionRadioAll = form.querySelectorAll("input[type='radio']")
    let allThemesOfGQ = []
    if (form.dataset.allThemesOfGq) {
      let allThemes = JSON.parse(form.dataset.allThemesOfGq)
      allThemes.forEach((theme) => { allThemesOfGQ.push(theme.toLowerCase().replaceAll(' ',''))})
    }

    // Quand on remplit un input
    form.addEventListener('keyup', (event) => {
      const questionTitle = form.querySelector('#question_theme').value.toLowerCase()
      // check if all content_question of all languages are filled
      let allContentFilled = false
      const filledByLanguage= []
      let val = ""
      allLanguages.forEach((lang) => {
        val  = form.querySelector(`#question_content_question_${lang}`).value
        filledByLanguage.push(val !== '')
      })
      allContentFilled = filledByLanguage.includes(false) ? false : true

      let TagTypeQuestionOuiNon = false
      let inputTagType = ""
      let inputTag = null
      let allTagFilled = false
      if (form.querySelector("input[type='radio'][name='type_question']:checked") && ["Oui Non", "Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Classement"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)) {
        TagTypeQuestionOuiNon = ["Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Classement"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)
        inputTagType = form.querySelector("input[type='radio']:checked").parentElement.parentElement.dataset.type.replaceAll('-','_')
      }

      // si on a choisi une question de Type Tag (pour permettre le clickable seulement si au moins 1 Tag a été rempli)
      if (TagTypeQuestionOuiNon ) {

        // check if at least one tag has been completely filled
        const tagfilledByLanguage= []
        let valTag = ""
        allLanguages.forEach((lang) => {
          valTag  = form.querySelector(`#${inputTagType}_${lang}`).value
          tagfilledByLanguage.push(valTag !== '')
        })
        allTagFilled = tagfilledByLanguage.includes(false) ? false : true

        if (theme.value !== "" && allContentFilled && !allThemesOfGQ.includes(theme.value.toLowerCase().replaceAll(' ',''))
          && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1
          && (allTagFilled )
          && notAllowedOuiNon(questionTitle) && checkTagPictureNewQuestion(form)
        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      // si on a choisi une question autre que de type Tag
      } else {
        if (theme.value !== "" && allContentFilled && !allThemesOfGQ.includes(theme.value.toLowerCase().replaceAll(' ',''))
          && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1 && notAllowedOuiNon(questionTitle) && checkTagPictureNewQuestion(form)
        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      }
    })

    // Quand on click sur un btn
    form.addEventListener('click', (event) => {
      const questionTitle = form.querySelector('#question_theme').value.toLowerCase()
      // check if all content_question of all languages are filled
      let allContentFilled = false
      const filledByLanguage= []
      let val = ""
      allLanguages.forEach((lang) => {
        val  = form.querySelector(`#question_content_question_${lang}`).value
        filledByLanguage.push(val !== '')
      })
      allContentFilled = filledByLanguage.includes(false) ? false : true

      let TagTypeQuestionOuiNon = false
      let inputTagType = ""
      let inputTag = null
      let allTagFilled = false
      if (form.querySelector("input[type='radio'][name='type_question']:checked") && ["Oui Non", "Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Classement"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)) {
        TagTypeQuestionOuiNon = ["Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Classement"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)
        inputTagType = form.querySelector("input[type='radio'][name='type_question']:checked").parentElement.parentElement.dataset.type.replaceAll('-','_')
      }

      // si on a choisi une question de Type Tag (pour permettre le clickable seulement si au moins 1 Tag a été rempli)
      if (TagTypeQuestionOuiNon) {
        // check if at least one tag has been completely filled if ["Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif"]
        const tagfilledByLanguage= []
        let valTag = ""
        allLanguages.forEach((lang) => {
          valTag  = form.querySelector(`#${inputTagType}_${lang}`).value
          tagfilledByLanguage.push(valTag !== '')
        })
        allTagFilled = tagfilledByLanguage.includes(false) ? false : true

        if (theme.value !== "" && allContentFilled && !allThemesOfGQ.includes(theme.value.toLowerCase().replaceAll(' ',''))
          && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length === 1
          && (allTagFilled )
          && notAllowedOuiNon(questionTitle) && checkTagPictureNewQuestion(form)
        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      // si on a choisi une question autre que de type Tag
      } else {
        if (theme.value !== "" && allContentFilled  && !allThemesOfGQ.includes(theme.value)
          && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length === 1
          && notAllowedOuiNon(questionTitle) && checkTagPictureNewQuestion(form)
        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled")
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      }

    })

  })
}

// QUESTION ADDITIONNELLE
const btnToggleClickableQuestionAddi = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  // clickable / not-clickable on form create new Question Additionnelle
  document.querySelectorAll("button.custom-btn.btn-add-question.new-question-addi.not-clickable").forEach((btn) => {
    btn.setAttribute("disabled", "disabled");
    const form = btn.closest("form")
    const typeQuestionRadioAll = form.querySelectorAll("input[type='radio']")
    const typeQuestionCheckboxAll = form.querySelectorAll(".container-question-addition input[type='checkbox']")
    const conditionNoteOuiNon = form.querySelector(".condition_note_un_dix")
    const conditionTypeOuiNonActivated = form.querySelector(".condition_oui_non_unique") //si on veut cocher qu'une seule condition quand on crée une question addi, pas utilisé finalement
    const typeQuestionNationality = form.querySelectorAll(".box-condition-nationality")

    form.addEventListener('keyup', (event) => {
      // check if all content_question of all languages are filled
      let allContentFilled = false
      const filledByLanguage= []
      let val = ""
      allLanguages.forEach((lang) => {
				val  = form.querySelector(`#question_content_question_${lang}`).value
        filledByLanguage.push(val !== '')
      })
      allContentFilled = filledByLanguage.includes(false) ? false : true
			let radio_plus_check = [...typeQuestionRadioAll].filter(input => input.checked && !input.classList.contains('original_emojis_class') && !input.classList.contains('new_emojis_class')).length + ([...typeQuestionCheckboxAll].filter(input => input.checked)).length
      let TagTypeQuestionOuiNon = false
      let inputTagType = ""
      let inputTag = null
      let allTagFilled = false
      if (form.querySelector("input[type='radio'][name='type_question']:checked") && ["Oui Non", "Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Classement"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)) {
				TagTypeQuestionOuiNon = ["Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif", "Classement"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)
        inputTagType = form.querySelector("input[type='radio'][name='type_question']:checked").parentElement.parentElement.dataset.type.replaceAll('-','_')
        inputTag = form.querySelector("input#" + inputTagType )
      }

      // si on a choisi une question de Type Tag (pour permettre le clickable seulement si au moins 1 Tag a été remplit)
      if (TagTypeQuestionOuiNon) {

				// check if at least one tag has been completely filled
        // let allTagFilled = false
        const tagfilledByLanguage= []
        let valTag = ""
        allLanguages.forEach((lang) => {
          valTag  = form.querySelector(`#${inputTagType}_${lang}`).value
          tagfilledByLanguage.push(valTag !== '')
        })
        allTagFilled = tagfilledByLanguage.includes(false) ? false : true

        if (allContentFilled
          // && ((!conditionNoteOuiNon && !conditionTypeOuiNonActivated && form.querySelectorAll("input[type='checkbox']:checked").length  >= 1 && form.querySelectorAll("input[type='radio']:checked").length  === 1 ) || (conditionNoteOuiNon && form.querySelectorAll("input[type='radio']:checked").length  === 3) || (conditionTypeOuiNonActivated && form.querySelectorAll("input[type='radio']:checked").length  === 2))
          && (([...typeQuestionCheckboxAll].filter(input => input.checked).length >= 1) || (conditionNoteOuiNon && ( radio_plus_check >= 3 ) && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) )
          && (TagTypeQuestionOuiNon && allTagFilled) && checkTagPictureNewQuestion(form)
        ) {

          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      // si on a choisi une question autre que de type Tag
		} else if (typeQuestionNationality.length > 0) {

        let selectedOptions = form.querySelectorAll(".box-condition-nationality .select2-selection--multiple li")
        if (checkTagPictureNewQuestion(form) && allContentFilled && selectedOptions.length > 1 && ( (conditionNoteOuiNon && (radio_plus_check >= 3 ) && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) || (!conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) || (conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 3))) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      } else {

        if (checkTagPictureNewQuestion(form) && allContentFilled
          && (
						(conditionNoteOuiNon && (radio_plus_check >= 3 ) && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1)
						|| (!conditionNoteOuiNon && [...typeQuestionCheckboxAll].filter(input => input.checked).length >= 1 && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1)
						|| (conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 3)
					)

        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      }

    })

    form.addEventListener('click', (event) => {

      // check if all content_question of all languages are filled
      let allContentFilled = false
      const filledByLanguage= []
      let val = ""
      allLanguages.forEach((lang) => {
        val  = form.querySelector(`#question_content_question_${lang}`).value
        filledByLanguage.push(val !== '')
      })
      allContentFilled = filledByLanguage.includes(false) ? false : true

			let radio_plus_check = [...typeQuestionRadioAll].filter(input => input.checked && !input.classList.contains('original_emojis_class') && !input.classList.contains('new_emojis_class')).length + ([...typeQuestionCheckboxAll].filter(input => input.checked)).length

      let TagTypeQuestionOuiNon = false
      let inputTagType = ""
      let inputTag = null
      let allTagFilled = false
      if (form.querySelector("input[type='radio'][name='type_question']:checked") && ["Oui Non", "Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)) {
        TagTypeQuestionOuiNon = ["Tag Choix Simple", "Tag Choix Multiple", "Tag Positif" , "Tag Négatif"].includes(form.querySelector("input[type='radio'][name='type_question']:checked").value)
        inputTagType = form.querySelector("input[type='radio'][name='type_question']:checked").parentElement.parentElement.dataset.type.replaceAll('-','_')
        inputTag = form.querySelector("input#" + inputTagType )
      }

      // si on a choisi une question de Type Tag (pour permettre le clickable seulement si au moins 1 Tag a été remplit)
      if (TagTypeQuestionOuiNon) {

        // check if at least one tag has been completely filled
        const tagfilledByLanguage= []
        let valTag = ""
        allLanguages.forEach((lang) => {
          valTag  = form.querySelector(`#${inputTagType}_${lang}`).value
          tagfilledByLanguage.push(valTag !== '')
        })
        allTagFilled = tagfilledByLanguage.includes(false) ? false : true

        if (checkTagPictureNewQuestion(form) && allContentFilled
          && (([...typeQuestionCheckboxAll].filter(input => input.checked).length >= 1) || (conditionNoteOuiNon && (radio_plus_check >= 3) && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) )
          && (TagTypeQuestionOuiNon && allTagFilled)
        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      } else if (typeQuestionNationality.length > 0) {
        let selectedOptions = form.querySelectorAll(".box-condition-nationality .select2-selection--multiple li")
        if (checkTagPictureNewQuestion(form) && allContentFilled && selectedOptions.length > 1 && ( (conditionNoteOuiNon && (radio_plus_check >= 3) && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) || (!conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) || (conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 3))){
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
        // si on a choisi une question autre que de type Tag
      } else {
        if (checkTagPictureNewQuestion(form) && allContentFilled
          && ( (conditionNoteOuiNon && (radio_plus_check >= 3) && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) || (!conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 1) || (conditionNoteOuiNon && form.querySelectorAll("input[type='radio'][name='type_question']:checked").length  === 3))

        ) {
          btn.removeAttribute("disabled")
          btn.classList.add("btn-color-green")
          btn.classList.remove("not-clickable")
        } else {
          btn.setAttribute("disabled", "disabled");
          btn.classList.remove("btn-color-green")
          btn.classList.add("not-clickable")
        }
      }
    })

  })
}

//POUR MEAL
//simulation en front des radio 'appliquer à tous les produits' et 'appliquer à une liste de produits'
// pour pouvoir cacher le radio_button
const simulateRadioOnBtnSelectionMeal = () => {
  if (document.getElementById("btn-select-all-meals")){
    const btnAllMeals = document.getElementById("btn-select-all-meals")
    const btnSomeMeals = document.getElementById("btn-select-some-meals")

    const radioAllMeals = btnAllMeals.firstElementChild
    const radioSomeMeals = btnSomeMeals.querySelector("input")

    btnAllMeals.addEventListener('click', (event) => {
      if (radioAllMeals.checked === false) {
        radioAllMeals.checked = true
        event.currentTarget.classList.remove("btn-selection-meals-not-selected")
        event.currentTarget.classList.add("btn-selection-meals-selected")
        radioSomeMeals.checked = false
        btnSomeMeals.classList.remove("btn-selection-meals-selected")
        btnSomeMeals.classList.add("btn-selection-meals-not-selected")
      }
    })

    btnSomeMeals.addEventListener('click', (event) => {
      if (radioSomeMeals.checked === false) {
        radioSomeMeals.checked = true
        event.currentTarget.classList.remove("btn-selection-meals-not-selected")
        event.currentTarget.classList.add("btn-selection-meals-selected")
        radioAllMeals.checked = false
        btnAllMeals.classList.remove("btn-selection-meals-selected")
        btnAllMeals.classList.add("btn-selection-meals-not-selected")
      }
    })
  }
}

/**
 * Gère l'affichage des containers lorsque l'on choisit entre le placeholder
 * et le pre_defined_tag
 */
const choosePlaceholderOrPreDefinedTag = () => {
  if (document.getElementById('choose-option')) {
    const allPlaceholderInputs = document.querySelectorAll('#choose_placeholder')
    const allPreDefinedTagsInputs = document.querySelectorAll('#choose_pre_defined_tags')

    allPlaceholderInputs.forEach(input => {
      input.addEventListener('click', () => {
        const closestpreDefinedTagInput = input.closest('.row').querySelector('#choose_pre_defined_tags')
        const placeholderContainer = input.closest('.text-zone-survey.separator').querySelector('.placeholder-container')

        // Si l'autre check_boxes est déjà cochée -> décoche et hide le container lié
        if (input.checked && closestpreDefinedTagInput.checked) {
          closestpreDefinedTagInput.checked = false
          closestpreDefinedTagInput.closest('.text-zone-survey.separator').querySelector('.pre-defined-tag-container').classList.add('hidden')
          input.closest('.text-zone-survey.separator').closest('.tag-unique').querySelectorAll('.checkbox_tag_unique').forEach(check => {
            if(check.checked) {
              check.checked = false
            }
            check.classList.add('hidden')
          })
        }

        // Gère l'affichage (ou non) du container lié
        input.checked ? placeholderContainer.classList.remove('hidden') : placeholderContainer.classList.add('hidden')
      })
    })

    allPreDefinedTagsInputs.forEach(input => {
      input.addEventListener('click', () => {
        const closestPlaceholderInput = input.closest('.row').querySelector('#choose_placeholder')
        const preDefinedTagContainer = input.closest('.text-zone-survey.separator').querySelector('.pre-defined-tag-container')

        // Si l'autre check_boxes est déjà cochée -> décoche et hide le container lié
        if (input.checked && closestPlaceholderInput.checked) {
          closestPlaceholderInput.checked = false
          closestPlaceholderInput.closest('.text-zone-survey.separator').querySelector('.placeholder-container').classList.add('hidden')
        }

        // Gère l'affichage (ou non) du container lié
        input.checked ? preDefinedTagContainer.classList.remove('hidden') : preDefinedTagContainer.classList.add('hidden')

        if (!input.checked) {
          input.closest('.text-zone-survey.separator').closest('.tag-unique').querySelectorAll('.checkbox_tag_unique').forEach(check => {
            if(check.checked) {
              check.checked = false
            }
          })
        }
      })
    })
  }
}

/**
 * When checking #choose_pre_defined_tags button
 * make all '.checkbox_tag_unique' check_boxes appear
 */
const handleCheckboxTagUniqueDisplay = () => {
  const allChoosePreDefinedCheck = document.querySelectorAll('#choose_pre_defined_tags')
  allChoosePreDefinedCheck.forEach(checkbox => {
    checkbox.addEventListener('click', () => {
      const allInputs = checkbox.closest('.text-zone-survey.separator').closest('.tag-unique').querySelectorAll('.checkbox_tag_unique')

      if (checkbox.checked) {
        allInputs.forEach(input => input.classList.remove('hidden'))
      } else {
        allInputs.forEach(input => input.classList.add('hidden'))
      }
    })
  })
}

/**
 * Make sure that only one '.checkbox_tag_unique' is checked
 */
const onlyOneCheckBoxChecked = () => {
  let allCheckboxes;
  const allButtonPlus = document.querySelectorAll("[class*='tag-unique-plus']")
  const allButtonMinus = document.querySelectorAll("[class*='tag-unique-moins']")

  // Bouton +
  allButtonPlus.forEach(button => {
    button.addEventListener('click', () => {
      // return allCheckboxes
      allCheckboxes = button.closest("[class*='list-tags-unique']").querySelectorAll('.checkbox_tag_unique')
      allCheckboxes.forEach(checkbox => {
        if(checkbox.classList.contains('hidden') && button.closest('.tag-unique').querySelector('#choose_pre_defined_tags').checked) {
          checkbox.classList.remove('hidden')
        }
      })

      handleCheckForPreDefinedTags(allCheckboxes)
    })
  })

  // Bouton -
  allButtonMinus.forEach(button => {
    button.addEventListener('click', () => {
      // return allCheckboxes
      allCheckboxes = button.closest("[class*='list-tags-unique']").querySelectorAll('.checkbox_tag_unique')

      // Hide all checkboxes and uncheck #choose_pre_defined_tags button if number of tags < 9
      if (allCheckboxes.length < 9) {
        button.closest('.tag-unique').querySelector('#choose_pre_defined_tags').checked = false
        allCheckboxes.forEach(check => {
          if(check.checked) {
            check.checked = false
          }
          check.classList.add('hidden')
        })
      }

      handleCheckForPreDefinedTags(allCheckboxes)
    })
  })
}

const handleCheckForPreDefinedTags = (allCheckboxes) => {
  allCheckboxes.forEach(checkbox => {
    checkbox.addEventListener('click', () => {

      let isAlreadyChecked = [...allCheckboxes].filter(check => check.checked)

      if(isAlreadyChecked.length > 0) {
        // Uncheck all
        isAlreadyChecked.forEach(alreadyChecked => alreadyChecked.checked = false)
        checkbox.checked = true
      }
    })
  })
}

const scrollToActiveTab = () => {
  const activeTab = document.querySelector(".width-tabs > .active")
  if(activeTab) {
    activeTab.scrollIntoView({behavior: "smooth", block: "end"})
  }
}

const getCollapseIdFromUrl = () => {
  const url = window.location.href
  let collapseValue = null
  if(url.split('?') && url.split('?')[1] && url.split('?')[1].split('&') ){
    const paramsArrays = url.split('?')[1].split('&')
    paramsArrays.forEach(params => {
      let param = params.split('=')
      if (param[0] === "collapse") {
        collapseValue = param[1]
      }
    })
  }

  return collapseValue
}

const scrollToEditedQuestion = () => {
  setTimeout(() => {
    const questionId = getCollapseIdFromUrl()
    if(questionId) {
      let question = document.getElementById(`id-quest-${questionId}`) || document.querySelector(`[data-meal-question-sortable-id="${questionId}"]`)
      if(question && !question.classList.contains("card-new-question")) {
        question = question.closest(".card-new-question")
      }
      question.querySelector(".theme-new-survey").click()
      setTimeout(() => {
        question.scrollIntoView({ behavior: "smooth", block: "start" });

      }, 500);
    }

  }, 1000);
}

const ajaxInfoMessages = () => {
	$(document).on('ajax:success', function(event) {
		const $banner = setBanner()
    if ($banner) {
		  showBanner($banner.attr("data-edit-success"), "success");
    }
	})
}


// CLASSEMENTS

const btnPlusMoinsOnClassement = () => {
  const allLanguages = JSON.parse(document.getElementById("groupe_question_index_parametre_for_js").dataset.allLanguages)

  let i = 1 //number of tags

  const languageInfo = document.getElementById("english-or-not-for-js").dataset

  if (window.location.href.match(/\/en\//)) {
    var textFrench = 'Enter a possible answer'
    var textFrenchMultilingue = `Enter a possible answer - ${languageInfo.mainLanguage}`
    var textEnglishMultilingue = `Enter a possible answer - ${languageInfo.secondaryLanguage}`
  } else {
    var textFrench = 'Saisissez un choix de réponse'
    var textFrenchMultilingue = 'Saisissez un choix de réponse en FR'
    var textEnglishMultilingue = 'Saisissez un choix de réponse en EN'
  }

  const flags = JSON.parse(document.getElementById("english-or-not-for-js").dataset.urlFlags)

  const allText = { "fr": {"textFrench": 'Saisissez un choix de réponse',
                          "textFrenchMultilingue": 'Saisissez un choix de réponse en FR',
                          "textEnglishMultilingue": 'Saisissez un choix de réponse en EN'
                          },
                    "en" : {"textFrench": 'Enter a possible answer',
                          "textFrenchMultilingue": `Enter a possible answer - ${languageInfo.mainLanguage}`,
                          "textEnglishMultilingue": `Enter a possible answer - ${languageInfo.secondaryLanguage}`
                          }
                  }

  // Btn + pour tag choix multiple:  pour rajouter une nouvelle div à chaque fois qu'on veut rajouter un tag, et que ca rajoute aussi un nouveau params (tag_multiple1, tag_multiple2 etc...)
  const btnPlusMultiple = document.querySelectorAll('.classement-plus')
  btnPlusMultiple.forEach((btn) => {
    btn.addEventListener('click', (event) => {
			const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = event.currentTarget.closest('.checkbox.list-classement.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-classement-${theme}-${numberTags}">
                                            <img id="preview-image-classement-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-classement-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div class='tags-multi-language'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>")
        }else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"
          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <span>" + `${i + 1}` + " - </span> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"

			console.log("listTags", listTags)
      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
						listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
					} else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
					}
				}
			} else {
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
						listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
					} else {
						console.log(message)
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-classement-${theme}-${numberTags}`)

      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)
    })
  })

  // Btn - pour tag choix multiple: enlever le dernier input
  const btnMoinsMultiple = document.querySelectorAll('.classement-moins')
  btnMoinsMultiple.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
			const listTags = event.currentTarget.closest('.checkbox.list-classement.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

	const btnPlusMultipleLinkKey = document.querySelectorAll('.classement-plus-link-key')
  btnPlusMultipleLinkKey.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      event.currentTarget.dataset.numberTags = parseInt(event.currentTarget.dataset.numberTags) + 1
      let numberTags = event.currentTarget.dataset.numberTags
      const listTags = document.querySelector('.checkbox.list-classement-link-key.' + theme)

      // creation of tag picture input container with js
      let tagPictureInputContainer = null;
      if (listTags.dataset.selectedOption === "text") {
        tagPictureInputContainer = "";
      }else{
        tagPictureInputContainer = `<div class="tag-picture-input-container ${theme}">
                                          <label for="image-upload-link-classement-${theme}-${numberTags}">
                                            <img id="preview-image-link-classement-${theme}-${numberTags}" src="https://aws-picture-dev-preprod.s3.eu-west-3.amazonaws.com/590_tag_picture_description_909278094328" class="preview-image-tag-cropped">
                                          </label>
                                          <input type="file" name="images[]" multiple="multiple" id="image-upload-link-classement-${theme}-${numberTags}" class="inputs-tag-answer hidden">
                                        </div>`
      }

      let inputDiv = ""
      if (allLanguages.length === 1) {
        const lang = allLanguages[0]
        const language = lang === "fr" ? "fr" : "en"
        if(listTags.dataset.selectedOption !== "image") {
          inputDiv = inputDiv.concat("<div style='display:flex; justify-content: center'> <input type='text' placeholder='" + allText[language]["textFrench"] + `- ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>")
        } else {
          inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + `- ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>");
        }
      } else {
        allLanguages.forEach((lang) => {
          const language = lang === "fr" ? "fr" : "en"

          if(listTags.dataset.selectedOption !== "image") {
            inputDiv = inputDiv.concat("<div class='tags-multi-language'><img src='" + flags[lang] + "' class='flags-menu-new-inputs'> <input type='text' placeholder='" + allText[language]["textFrench"] + ` - ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>")
          } else {
            inputDiv = inputDiv.concat(`<div style='display:none'> <input value='aws_picture${numberTags}' type='text' placeholder='` + allText[language]["textFrench"] + ` - ${lang}` + "' name='classement_"  + lang + numberTags + "' id='classement_" + lang + numberTags + "' ></div>");
          }
        })
      }

      const message = "<div class='tag-inputs-container'> <div class='question-text-input new-question-add-tag-card'" + `${(listTags.dataset.selectedOption) ? "style= 'display:flex; flex-direction: column; justify-content: flex-end'" : ""}>` + tagPictureInputContainer + inputDiv + "</div> </div>"


      if(listTags.dataset.selectedOption === "text"){
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          }
        }
      }else {
        if ( i < 12) {
          if (languageInfo.englishOuiNon === "false") {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          } else {
            listTags.querySelector(".tags-container").insertAdjacentHTML("beforeend", message);
          }
        }
      }
      if(i < 12){
        i += 1;
      }

      // add eventListener for the new input after inseting it inside the dom
      const newInput = document.getElementById(`image-upload-link-classement-${theme}-${numberTags}`)
      if(newInput) {newInput.addEventListener("change", (event)=>{tagPictureCropper(event, true)})}
      editTextOptionWidthJs (listTags)

    })
  })

  // Btn - pour tag choix multiple: enlever le dernier input
  const btnMoinsMultiplelinkKey = document.querySelectorAll('.classement-moins-link-key')
  btnMoinsMultiplelinkKey.forEach((btn) => {
    btn.addEventListener('click', (event) => {
      const theme = event.currentTarget.dataset.theme
      const listTags = document.querySelector('.checkbox.list-classement-link-key.' + theme)
      if (listTags.querySelector(".tags-container").children.length > 1) {
        listTags.querySelector(".tags-container").lastElementChild.remove();
        if(i > 1){
        i -= 1;
      }
      }
    })
  })

}


const allJsForGroupeQuestionParametreQuestionVariable = () => {
  if (document.getElementById("groupe_question_index_parametre_for_js")) {

    // CREATED QUESTION
    // btnCreateNewTagFromCreatedQuestionCard();

    // QUESTION INITIALE
    displayCardCreateQuestion();
    removeCardCreateQuestion();
    displayCardCreateQuestionRemarque();
    removeCardCreateQuestionRemarque();
    toggleDifferentTypeQuestionWhileDropdown();
    selectedAnswerOptions(".option-text")
    // selectedAnswerOptions(".option-picture")
    selectedAnswerOptions(".option-both")

    btnPlusMoinsOnTagsChoixUnique();
    btnPlusMoinsOnTagsChoixMultiple();
    btnPlusMoinsOnTagsPositif();
    btnPlusMoinsOnTagsNegatif();
		btnPlusMoinsOnClassement();
    // btnPlusMoinsOnTagsPosAndNeg();
    btnToggleClickableQuestion();
    toggleBtnCreateTagInCreatedQuestionAndAddi();
    simulateRadioOnBtnSelectionMeal(); //pour Meal
    displayCardCreateQuestionLinkKey(); //pour link_key
    removeCardCreateQuestionLinkKey(); // pour link_key

    // QUESTION ADDITIONNELLE
    displayCardCreateQuestionAddi();
    removeCardCreateQuestionAddi();
    toggleDifferentTypeQuestionAddiWhileDropdown();
    btnPlusMoinsOnTagsChoixUniqueAddi();
    btnPlusMoinsOnTagsChoixMultipleAddi();
    btnPlusMoinsOnTagsPositifAddi();
    btnPlusMoinsOnTagsNegatifAddi();
    // btnPlusMoinsOnTagsPosAndNegAddi();
    btnToggleClickableQuestionAddi();
    // btnToggleClickableQuestionAddiForMeal();

    // 3 TYPES OF QUESTIONS (INI / ADDI / LINK)
    choosePlaceholderOrPreDefinedTag()
    handleCheckboxTagUniqueDisplay()
    onlyOneCheckBoxChecked()
    scrollToActiveTab();
    scrollToEditedQuestion();
		//AJAX Info
		ajaxInfoMessages()
  }
}

export {allJsForGroupeQuestionParametreQuestionVariable, getCollapseIdFromUrl}
