import Cropper from 'cropperjs';

/**
 * Convert base64 to blob
 * We need it to create the new file that will be added in files (doesn't work with base64)
 */
const  b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, {type: contentType});
  return blob;
}

/**
 * Replace old cropper input:file by the new one
 */
function saveCroppedImage(cropper, modal, backgroundImageDiv, input) {

  let croppedCanvas = cropper.getCroppedCanvas();
  if (croppedCanvas === null) {
    console.log('No cropped canvas to save');
    return;
  }
  let croppedImage = croppedCanvas.toDataURL('image/png');
  // Separate base64 string
  const block = croppedImage.split(";")
  const contentType = block[0].split(":")[1]
  const realData = block[1].split(",")[1]
  const blob = b64toBlob(realData, contentType);

  // Display cropped image in iframe
  if (backgroundImageDiv) {
    backgroundImageDiv.style.backgroundImage = `url(${croppedImage})`
  }

  const fileInput = input
  // Create new image file with blob
  const myFile = new File([blob], "my_image.png", { type: "image/png" })

  const dataTransfer = new DataTransfer()
  dataTransfer.items.add(myFile);
  // Replace input file by new image file created
  fileInput.files = dataTransfer.files

  // Close the modal
  if (!modal.classList.contains('hidden')) {
    modal.classList.add('hidden')
  }
  // show logo inside the form after save
  let logoFormImg = document.getElementById("form-image")
  if (logoFormImg) {
    logoFormImg.src = `${croppedImage}`
    let logoDiv = document.querySelector(".logo-div")
    if (logoDiv.classList.contains("hidden")) {
      logoDiv.classList.remove("hidden")
    }
    // Empty value of previsualisation group logo
    let logoPrev = document.getElementById("logo-prev")
    if(logoPrev) {
      logoPrev.src = ''
      logoPrev.parentNode.style.backgroundImage = "none"
    }
  }
}

/**
 * Handle Cropper modal
 */
const displayCropper = (input, backgroundImageDiv) => {
  if(backgroundImageDiv){
    const urlOldBackgroundImage = backgroundImageDiv.style.backgroundImage
  }

  const modal = document.getElementById('mount')

  // Show modal
  modal.classList.remove('hidden')

  let image = document.getElementById('crop')
  let file  = input.files[0]
  let url = window.URL.createObjectURL(file) // Get blob url to show image in Cropper
  image.src = url

  // Remove precedent cropper if there is one
  if (image.cropper) {
    image.cropper.destroy()
  }

  // Create new cropper
  const cropper = new Cropper(image, {
    aspectRatio: 0,

  })


  const previewCrop = document.getElementById('preview-crop')

  if (previewCrop) {
    // Preview of cropped image on iframe without saving it
    previewCrop.addEventListener('click', () => {
      // Get cropped image with base64
      let croppedCanvas = cropper.getCroppedCanvas();
      if (croppedCanvas === null) {
        console.log('No cropped canvas to save');
        return;
      }
      let croppedImage = croppedCanvas.toDataURL('image/png');
      // Display cropped image in iframe or in group logo prev div
      if (backgroundImageDiv) {
        backgroundImageDiv.style.backgroundImage = `url(${croppedImage})`
      }
    })
  }

  // Preview cropped image on previsualisation div without clicking on the button
  let mount = document.getElementById("mount")
  let logoPrev = document.getElementById("logo-prev")
  if (logoPrev) {
    if (!mount.classList.contains("hidden")) {

      // get promize to get the crop div
      function getCropBox() {
        return new Promise((resolve) => {
          setTimeout(() => {
            // initialize previsualisation image
            logoPrev.src = `${cropper.getCroppedCanvas().toDataURL('image/png')}`
            const cropBox = document.querySelector(".cropper-crop-box");
            resolve(cropBox);
          }, 100);
        });
      }

      // Utilisation de la promesse
      getCropBox().then((cropBox) => {
        // Utilisez la valeur de cropBox ici
        cropBox.addEventListener("click", () => {
          let croppedCanvas = cropper.getCroppedCanvas();
          if (croppedCanvas === null) {
            console.log('No cropped canvas to save');
            return;
          }
          let croppedImage = croppedCanvas.toDataURL('image/png');
          logoPrev.src = `${croppedImage}`
        })

      });

    }
  }
  // Save the new cropper and close modal
  document.getElementById('cropImageBtn').addEventListener('click', () => {
    saveCroppedImage(cropper, modal, backgroundImageDiv, input)
  })

  // close the modal without saving the new cropper
  document.getElementById('close-modal-cropper').addEventListener('click', () => {
    if (!modal.classList.contains('hidden')) {
      modal.classList.add('hidden')
      // Put back previous background image
      if(backgroundImageDiv) {
        backgroundImageDiv.style.backgroundImage = urlOldBackgroundImage
      }
      // Empty value of file input so that the one we we're testing is not saved
      input.value = ''

      // Empty value of previsualisation group logo
      if(logoPrev) {
        logoPrev.src = ''
        // hide cancelled cropped image
        let logoDiv = document.querySelector(".logo-div")
        if (!logoDiv.classList.contains("hidden")) {
          logoDiv.classList.add("hidden")
        }
      }
    }
  })
}

function saveTagImage(cropper, modal, event, input, simulateClick) {

  let croppedCanvas = cropper.getCroppedCanvas();
  if (croppedCanvas === null) {
    console.log('No cropped canvas to save');
    return;
  }
  let croppedImage = croppedCanvas.toDataURL('image/png');
  // Separate base64 string
  const block = croppedImage.split(";")
  const contentType = block[0].split(":")[1]
  const realData = block[1].split(",")[1]
  const blob = b64toBlob(realData, contentType);

  const fileInput = input
  // Create new image file with blob
  const myFile = new File([blob], "my_image.png", { type: "image/png" })

  const dataTransfer = new DataTransfer()
  dataTransfer.items.add(myFile);
  // Replace input file by new image file created
  fileInput.files = dataTransfer.files

  // Close the modal
  if (!modal.classList.contains('hidden')) {
    modal.classList.add('hidden')
  }

  const image = event.target.parentNode.querySelector("img")
  image.src = `${croppedImage}`
  image.classList.remove("hidden")


  // simuler un click sur le fomulaire pour declanger l'evet qui verifie les inputs
  if(simulateClick) {
    event.target.parentNode.click()
  }

}

const tagPictureCropper = (event, simulateClick) => {
  const fileSize = (event.target.files[0].size) / 1000
  // image size can't be more than 300ko
  if (fileSize > 300) {
    // Get url to see if the page is in french or english
    const url = new URL(window.location.href).toString()
    const isEnglishRegex = /(\/en\/)/
    const isEnglish = url.match(isEnglishRegex) ? true : false

    if (isEnglish) {
      alert("Your image's size can't exceed 300ko. You can reduce it on this free website https://shortpixel.com/")
    } else {
      alert("Votre image ne peut dépasser 300ko. Vous pouvez la réduire en vous rendant sur le site gratuit https://shortpixel.com/")
    }
    return;
  }

  const modal = document.getElementById('crop-answer-picture-modal')
  const input = event.target
  // Show modal
  modal.classList.remove('hidden')

  let image = document.getElementById('crop')
  let file  = input.files[0]
  let url = window.URL.createObjectURL(file) // Get blob url to show image in Cropper
  image.src = url

  // Remove precedent cropper if there is one
  if (image.cropper) {
    image.cropper.destroy()
  }

  // Create new cropper
  const cropper = new Cropper(image, {
    aspectRatio: 16 / 7,

  })


  // Preview cropped image on previsualisation div without clicking on the button

  let logoPrev = document.getElementById("logo-prev")
  if (logoPrev) {
    if (!modal.classList.contains("hidden")) {

      // get promize to get the crop div
      function getCropBox() {
        return new Promise((resolve) => {
          setTimeout(() => {
            // initialize previsualisation image
            logoPrev.src = `${cropper.getCroppedCanvas().toDataURL('image/png')}`
            const cropBox = modal.querySelector(".cropper-crop-box");
            resolve(cropBox);
          }, 100);
        });
      }

      // Utilisation de la promesse
      getCropBox().then((cropBox) => {
        // Utilisez la valeur de cropBox ici
        cropBox.addEventListener("click", () => {

          let croppedCanvas = cropper.getCroppedCanvas();
          if (croppedCanvas === null) {
            console.log('No cropped canvas to save');
            return;
          }
          let croppedImage = croppedCanvas.toDataURL('image/png');
          logoPrev.src = `${croppedImage}`
        })

      });

    }
  }
  // Save the new cropper and close modal
  document.getElementById('cropImageBtn').addEventListener('click', () => {
    saveTagImage(cropper, modal, event, input, simulateClick)
  })

  // close the modal without saving the new cropper
  const closeBtn = document.getElementById('close-crop-answers-picture-modal')
  closeBtn.addEventListener('click', () => {
    if (!modal.classList.contains('hidden')) {
      modal.classList.add('hidden')

      // Empty value of file input so that the one we we're testing is not saved
      input.value = ''
    }
  })


}


export { displayCropper, tagPictureCropper, b64toBlob }
