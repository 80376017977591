import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["mainContainer"]

  connect() {
    this.mainContainerTargets.forEach((mainContainer) => {
      const parentCheckbox = mainContainer.querySelector(".parent-checkbox-for-js")
      const childCheckboxes = Array.from(mainContainer.querySelectorAll(".child-checkbox-for-js"))

      if (childCheckboxes.map(x => x.checked).includes(false)) {
        parentCheckbox.checked = false
      } else {
        parentCheckbox.checked = true
      }
    })
  }

  toggleChildren(e) {
    const parentContainer = e.currentTarget.closest("[data-permissions--checkbox-select-all-target='mainContainer']")
    const childCheckboxes = Array.from(parentContainer.querySelectorAll(".child-checkbox-for-js"))
    const childContainers = Array.from(parentContainer.querySelectorAll(".nested-container-for-js"))

    parentContainer.querySelector('.list-item').classList.remove("border-bottom")
    parentContainer.querySelectorAll('.list-nested-item').forEach(x => x.classList.remove('border-bottom'))
    childContainers.forEach(x => x.classList.remove('hidden'))

    if (e.currentTarget.checked) {
      childCheckboxes.map(x => x.checked = true)
    } else {
      childCheckboxes.map(x => x.checked = false)
    }
  }
}
