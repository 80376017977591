const templatesSetVariables = () => {

	const draftTemplateTitle = document.getElementById("draft_template_title");
	const draftTemplateMessage = document.getElementById("draft_template_message");
	const submitBtn = document.getElementById("new-template-btn");

	if (draftTemplateTitle && draftTemplateMessage && submitBtn) {
		draftTemplateTitle.addEventListener('keyup', () => {
			if ((draftTemplateTitle.value.length > 2) && (draftTemplateMessage.value.length > 2) && submitBtn.disabled == false) {
				submitBtn.classList.remove('not-clickable')
			} else {
				submitBtn.classList.add('not-clickable')
			}
		})

		draftTemplateMessage.addEventListener('keyup', () => {
			if ((draftTemplateTitle.value.length > 2) && (draftTemplateMessage.value.length > 2)) {
				submitBtn.classList.remove('not-clickable')
			} else {
				submitBtn.classList.add('not-clickable')
			}
		})

	}

	const allVariablesTemplates = [
		["nom-client-message", "$nomClient"],
		["prenom-client-message", "$prenomClient"],
		["nom-groupe-message", "$nomsGroupe"],
		["nom-etablissement-message", "$nomEtablissement"]
	]

	Array.prototype.forEach.call(allVariablesTemplates, function(variable) {
		let variableDivTemplate = document.getElementById(variable[0])
    if (variableDivTemplate) {
      variableDivTemplate.addEventListener('click', () => {
        const message_div = document.querySelector('#draft_template_message')
				let message_value = message_div.value
        message_div.value = message_value + variable[1]
      })
    }
  })

	const templateCards = document.querySelectorAll('.template-card');

	// Array.prototype.forEach.call(templateCards, function(card) {
	// 	card.addEventListener('click', () => {
	// 		let activeCard = document.querySelector(".template-card.active")
	// 		if (activeCard) {
	// 			activeCard.classList.remove('active');
	// 			card.classList.add('active');
	// 		}
	// 	})
	// })

	// const getMessages = document.querySelectorAll(".get-message")

	const templateFocuses = document.querySelectorAll(".template-focus--wrapper")

	Array.prototype.forEach.call(templateFocuses, function(focus) {
		// focus.querySelector(".edit-template-btn").addEventListener('click', () => {
			// focus.querySelector(".get-message").classList.toggle("hidden")
			// focus.querySelector(".edit-message").classList.toggle("hidden")
			console.log(focus);
		// })
		Array.prototype.forEach.call(allVariablesTemplates, function(variable) {
			let variableDivTemplate = focus.querySelector(`.${variable[0]}`)
			if (variableDivTemplate) {
				console.log("raise");
				variableDivTemplate.addEventListener('click', () => {
					let message_div = focus.querySelector('textarea.new-template-input')
					let message_value = message_div.value
					message_div.value = message_value + variableDivTemplate.innerText
				})
			}
		})

	})
}

export {templatesSetVariables}
