import { Controller } from "stimulus";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

export default class extends Controller {
    static targets = ["startDatePicker", "endDatePicker", "startDate", "endDate", "deleteAllSurveys", "deleteSurveysByDate", "btnValider", "datePickerContainer"]

    connect() {
        this.flatpickrOptions = {
            dateFormat: "Y-m-d",
            locale: "fr",
            altInput: true,
            altFormat: "j F, Y",
            enableTime: false,
            wrap: false,
            onReady: (selectedDates, dateStr, instance) => {
                instance.altInput.setAttribute('placeholder', instance.input.getAttribute('placeholder'));
            },
            onChange: (selectedDates, dateStr, instance) => {
                if (instance.input.id === 'startDatePicker') {
                    if (selectedDates.length > 0) {
                        this.startDateTarget.value = this.formatDate(selectedDates[0]);
                    }
                } else if (instance.input.id === 'endDatePicker') {
                    if (selectedDates.length > 0) {
                        this.endDateTarget.value = this.formatDate(selectedDates[0]);
                    }
                }
                this.updateBtnValiderState();
            }
        };

        this.startDatePicker = flatpickr(this.startDatePickerTarget, this.flatpickrOptions);
        this.endDatePicker = flatpickr(this.endDatePickerTarget, this.flatpickrOptions);

        this.toggleDatePickers();
    }

    formatDate(date) {
        const offset = date.getTimezoneOffset();
        const adjustedDate = new Date(date.getTime() - (offset * 60 * 1000));
        return adjustedDate.toISOString().split('T')[0];
    }

    toggleDatePickers() {
        if (this.deleteSurveysByDateTarget.checked) {
            this.datePickerContainerTarget.classList.remove('hidden');
            this.startDatePickerTarget.disabled = false;
            this.endDatePickerTarget.disabled = false;
            this.updateBtnValiderState();
        } else {
            this.datePickerContainerTarget.classList.add('hidden');
            this.startDatePickerTarget.disabled = true;
            this.endDatePickerTarget.disabled = true;

            if (!this.deleteAllSurveysTarget.checked) {
                this.btnValiderTarget.disabled = true;
                this.btnValiderTarget.classList.add('not-clickable');
            }
        }
    }

    areDatesSelected() {
        return this.startDateTarget.value && this.endDateTarget.value;
    }

    updateBtnValiderState() {
        if (this.deleteAllSurveysTarget.checked || (this.deleteSurveysByDateTarget.checked && this.areDatesSelected())) {
            this.btnValiderTarget.disabled = false;
            this.btnValiderTarget.classList.remove('not-clickable');
        } else {
            this.btnValiderTarget.disabled = true;
            this.btnValiderTarget.classList.add('not-clickable');
        }
    }

    handleDeleteAllSurveysChange() {
        if (this.deleteAllSurveysTarget.checked) {
            this.deleteSurveysByDateTarget.checked = false;
        }
        this.updateBtnValiderState();
        this.toggleDatePickers();
    }

    handleDeleteSurveysByDateChange() {
        if (this.deleteSurveysByDateTarget.checked) {
            this.deleteAllSurveysTarget.checked = false;
        }
        this.updateBtnValiderState();
        this.toggleDatePickers();
    }

    handleBtnValiderClick() {
        if (this.deleteAllSurveysTarget.checked) {
            if (confirm("Êtes-vous sûrs de vouloir supprimer toutes les enquêtes ?")) {
                this.element.querySelector("#deleteForm").submit();
            }
        } else if (this.deleteSurveysByDateTarget.checked) {
            if (this.areDatesSelected()) {
                if (confirm("Êtes-vous sûrs de vouloir supprimer ces enquêtes ?")) {
                    this.element.querySelector("#deleteByDateForm").submit();
                }
            } else {
                alert('Veuillez sélectionner une plage de dates.');
            }
        }
    }
}
