export function yearSelect(args) {
  let fp = args.fp
  let startDate = fp.selectedDates[0]
  let endDate = fp.selectedDates[1]

  showFlatpickrYearSelect()
  setRange({
    fp: fp,
    startDate: startDate,
    endDate: endDate,
  })

  engine(fp)
}

function formatRange(args) {
  let result = []
  const yearsRange = [args.startDate.getFullYear(), args.endDate.getFullYear()]
  yearsRange.sort()
  const startDate = new Date(yearsRange[0], 0, 1)
  const endDate = new Date(yearsRange[1], 11, 31)

  result = [startDate, endDate]

  return result
}

function displayCurrentYearSelection(range) {
  let to_print = ""
  const headerDiv = document.querySelector('.current-selection-for-js')
  const startYear = range[0].getFullYear()
  const endYear = range[1].getFullYear()

  if (startYear == endYear) {
    to_print = startYear
  } else {
    to_print = `${startYear} - ${endYear}`
  }

  headerDiv.innerText = to_print
}

function setInRangeClass(years) {
  years.sort()
  const yearsBetween = []
  const startYear = years[0]
  const endYear = years[1]

  $('.inRange').removeClass('inRange');

  for (let i = startYear + 1; i < endYear; i++) {
    yearsBetween.push(i)
  }

  yearsBetween.forEach(year => {
    document.querySelector(`#year${year}`).classList.add('inRange')
  })
}

function switchOnInRangeHover(startDate) {
  let cells = $('.flatpickr-monthSelect-month')
  let startYear = startDate.getFullYear()
  let endYear;

  cells.each(function() {
    $(this).hover(
      function() {
        endYear = $(this).data('date')
        setInRangeClass([startYear, endYear])
      }
    );
  });
}

function switchOffInRangeHover() {
  let cells = $('.flatpickr-monthSelect-month')
  cells.off('mouseenter');
  cells.off('mouseleave');
}

export function cleanClassesOn(selectYearDivs) {
  selectYearDivs.forEach(item => {
    item.classList.remove('endRange')
    item.classList.remove('startRange')
    item.classList.remove('inRange')
    item.classList.remove('selected')
  })
}

function setRange(args) {
  const fp = args.fp
  const cells = document.querySelectorAll('.flatpickr-monthSelect-month')
  const range = formatRange({startDate: args.startDate, endDate: args.endDate})
  const startYear = range[0].getFullYear()
  const endYear = range[1].getFullYear()
  const startDateDiv = document.querySelector(`#year${startYear}`)
  const endDateDiv = document.querySelector(`#year${endYear}`)

  cleanClassesOn(cells)
  fp.setDate(range)
  setInRangeClass([startYear, endYear])
  displayCurrentYearSelection(range)
  switchOffInRangeHover()
  startDateDiv.classList.add('startRange')
  endDateDiv.classList.add('endRange')
}

function setSingleDate(args) {
  let startDateDiv = document.querySelector(`#year${args.startDate.getFullYear()}`)
  cleanClassesOn(args.cells)
  startDateDiv.classList.add('startRange')
  switchOnInRangeHover(args.startDate)
}

// Handle behaviour in the yearSelect view.
function engine(fp) {
  let cells = document.querySelectorAll('.flatpickr-monthSelect-month')
  let startDate = fp.selectedDates[0]
  let endDate = fp.selectedDates[1]

  cells.forEach(cell => {
    cell.addEventListener('click', () => {
      let year = parseInt(cell.getAttribute('data-date'))

      if (startDate != null && endDate == null ) {
        endDate = new Date(year, 11, 31)
        setRange({
          fp: fp,
          startDate: startDate,
          endDate: endDate,
        })
      } else if (startDate != null && endDate != null ) {
        endDate = null
        startDate = new Date(year, 0, 1)
        setSingleDate({
          startDate: startDate,
          cells: cells,
        })
      }

    })
  })
}

function showFlatpickrYearSelect() {
  document.querySelector('.flatpickr-calendar').classList.add('hidden')
  document.querySelector('#flatpickr-year-select').classList.remove('hidden')
}

export function hideFlatpickrYearSelect() {
  document.querySelector('.flatpickr-calendar').classList.remove('hidden')
  document.querySelector('#flatpickr-year-select').classList.add('hidden')
}

export function yearSelectActivated(args) {
  let result = false
  let timeframe = args.timeframe
  let startDate = args.startDate
  let endDate = args.endDate

  if (timeframe == 'cumul') {
    result = true
  }

  let firstDayOfYear = new Date(new Date(startDate).getFullYear(), 0, 1).toLocaleDateString()
  let isFirstDayOfYear = new Date(startDate).toLocaleDateString() === firstDayOfYear
  let lastDayOfYear = new Date(new Date(endDate).getFullYear(), 11, 31).toLocaleDateString()
  let isLastDayOfYear = new Date(endDate).toLocaleDateString() === lastDayOfYear

  if (timeframe == 'custom_more_than_year' && isFirstDayOfYear && isLastDayOfYear) {
    result = true
  }

  return result
}
