import { manageFonts } from './custom_questionnaire/manageFonts';
import { displayCropper } from './_handleCropper'
import { logoBkgColor } from './custom_questionnaire/logoBkgColor';

//METHODE utilisée dans customQuestionnaire() : pour afficher l'image chargé en background_image dans BEGIN: methode utilisé dans customQuestionnaire
const displayPreview = (input) => {
  console.log(input)
  if (input.files && input.files[0]) {
    console.log('displayPreview')
    const reader = new FileReader();
    reader.onload = (event) => {
      console.log("from page begin questionnaire or new_meal")
      if (input.id === "bkg_img_sp") {
        previewDiv.contentDocument.querySelector(".brand-container").style.setProperty('background-color', "transparent", 'important');
      }
      previewDiv.contentDocument.querySelector(".container-color-for-js").style.backgroundImage = `url(${event.currentTarget.result})` ;
    }
    reader.readAsDataURL(input.files[0])
  }
}


// METHODE utilisée dans customQuestionnaire() : faire la simulation sur la partie de gauche du font style sur "I, B, S" pour montrer quand c'est coché/décoché
const simulateFontStyleLeftPart = (btn) => {
  let previewDiv = document.querySelector("iframe#preview")

  // partie gauche: le btn a un fond gris quand il est clické + changer en true/false la valeur du hidden_field_tag associé pour le back
  btn.classList.toggle("style-selected")
  let hidden_fiel_tag_for_back = btn.nextElementSibling
  console.log(hidden_fiel_tag_for_back)
  hidden_fiel_tag_for_back.value = hidden_fiel_tag_for_back.value === 'false'

  // si c'est un btn I,B,S pour l'ensemble des texte "all" => l'appliquer dans ce cas egalement à tous les I,B,S spécifique de la page
  if (btn.classList.contains("style-font-police-for-all-texts")) {
    // mettre tous les I,B,S existants sur la page pour les elements specifc également en coché/décoché, sauf le event.currentTarget
    document.querySelectorAll(`.style-font-police-for-specific-texts.${btn.dataset.page}#${btn.id}`).forEach((btnStyle) => {
      if (btnStyle.dataset.page === btn.dataset.page) {
        console.log("same page for")
        console.log(btnStyle)
        if (btn.classList.contains("style-selected")) {
          btnStyle.classList.add("style-selected")
        } else {
          btnStyle.classList.remove("style-selected")
        }

        btnStyle.nextElementSibling.value = hidden_fiel_tag_for_back.value
      }
    })
  }

  // partie gauche: pour les btn BOLD: il faut que ca décoche automatiquement un autre si on en coche un nouveau
  if (btn.id.includes("weight")) {
    document.querySelectorAll("#weight-regular, #weight-medium, #weight-bold").forEach((bold_style) => {
      if (btn.id !== bold_style.id ) {
        bold_style.classList.remove("style-selected")
        bold_style.nextElementSibling.value = "false"
      }
      previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((link) => {
        console.log(bold_style.id)
        link.classList.remove(`${bold_style.id}`)
      })
    })
  }
}


const customQuestionnaire = () => {
  if (document.getElementById("custom-questionnaire-for-js")) {
    console.log("customQuestionnaire")
    const previewDiv = document.querySelector("iframe#preview")
    console.log("Check si la definission de la preview marche",previewDiv)
    //BACKGROUND IMAGE : photo à charger en background dans start_page_menu et my_menu
    const inputs = document.querySelectorAll('#bkg_img_begin, #bkg_img_newmeal, #bkg_img_notemeal, #bkg_img_survey, #bkg_img_edit, #bkg_img_newborne, #bkg_img_sp');
    if (inputs !== []) {
      console.log('previewImageOnFileSelect')
      inputs.forEach((input) => {

        // Remove old value of input
        input.addEventListener('click', () => {
          if (input.value) {
            input.value = ''
          }
        })

        // we add a listener to know when a new picture is added in the files
        input.addEventListener('change', () => {
          const fileSize = (input.files[0].size) / 1000

          // image size can't be more than 400ko
          if (fileSize > 400) {
            // Get url to see if the page is in french or english
            const url = new URL(window.location.href).toString()
            const isEnglishRegex = /(\/en\/)/
            const isEnglish = url.match(isEnglishRegex) ? true : false

            if (isEnglish) {
              alert("Your image's size can't exceed 400ko. You can reduce it on this free website https://shortpixel.com/")
            } else {
              alert("Votre image ne peut dépasser 400ko. Vous pouvez la réduire en vous rendant sur le site gratuit https://shortpixel.com/")
            }
          } else {
            let backgroundImageDiv;
            switch (input.id) {
              case "bkg_img_begin":
                // store url of background image before it's changed
                backgroundImageDiv = previewDiv.contentDocument.querySelector(".container-color-for-js")
                // custom survey page
                displayCropper(input, backgroundImageDiv);
                break;
              case "bkg_img_newmeal":
                const backgroundImageDivs = previewDiv.contentDocument.querySelectorAll(".background-style")
                backgroundImageDivs.forEach((div) => { displayCropper(input, div) });
                break;
              case "bkg_img_survey":
                backgroundImageDiv = previewDiv.contentDocument.querySelector(".container-color-for-js")
                displayCropper(input, backgroundImageDiv);
                break;
              case "bkg_img_notemeal":
                backgroundImageDiv = previewDiv.contentDocument.querySelector(".container-color-for-js")
                displayCropper(input, backgroundImageDiv);
                break;
              case "bkg_img_edit":
                backgroundImageDiv = previewDiv.contentDocument.querySelector(".container-color-for-js")
                displayCropper(input, backgroundImageDiv);
                break;
              case "bkg_img_sp":
                backgroundImageDiv = previewDiv.contentDocument.querySelector(".links-menu-banner.container-color-for-js")
                if (backgroundImageDiv) {
                  displayCropper(input, backgroundImageDiv);
                }
                break;
              case "bkg_img_newborne":
                backgroundImageDiv = previewDiv.contentDocument.querySelector(".container-color-for-js")
                displayCropper(input, backgroundImageDiv);
                break;
              default:
                // custom new_meal
                displayPreview(input);
                break;
            }
          }
        })
      })
    }

    // BACKGROUND COLOR : simulate color on background of the page
    const colorInputs = document.querySelectorAll("[class^='colorWell']")
    colorInputs.forEach((colorInput) => {
      colorInput.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        const divToChange = `.${event.currentTarget.dataset.forBackground}`
        previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
          console.log(div)
          console.log(event.currentTarget.classList)
          console.log(event.currentTarget.classList.contains("start_page"))
          if ( event.currentTarget.classList.contains("start_page")  ) {
            div.style.setProperty('background-color', event.currentTarget.value, 'important');
            if (previewDiv.contentDocument.querySelector(".brand-container")) {
              previewDiv.contentDocument.querySelector(".brand-container").style.setProperty('background-color', event.currentTarget.value, 'important');
              div.style.backgroundImage = ""
            }
          } else if ( !(event.currentTarget.classList.contains("edit_page")) ) {
            div.style.backgroundImage = "" //quand c'est begin il faut enlever le background-image si on veut mettre un background color a la place
            div.style.backgroundColor = event.currentTarget.value
          } else {
             div.style.setProperty('background-color', event.currentTarget.value, 'important');
          }

        })
      })
    })

    // Simulate change of color on progression bar.
    const colorInputsProgressBar = document.querySelectorAll("[class*='custom-progress-bar']")
    colorInputsProgressBar.forEach((colorInput) => {
      colorInput.addEventListener("change", (event) => {
        const divToChange = `.${event.currentTarget.dataset.forBackground}`
        previewDiv.contentDocument.querySelectorAll(divToChange).forEach((div) => {
          div.style.setProperty('background-image', `linear-gradient(${event.currentTarget.value} 0%, ${event.currentTarget.value} 100%)`, 'important');
        })
      })
    })

    // TOUT CE QUI EST RELATIF AUX INPUTS "POLICE" DU PARAMETRAGE QUESTIONNAIRE
    manageFonts('questionnaire');

    //FONT_STYLE FOR ALL TEXT OF PAGE: "les textes" font style => style sur l'ensemble des textes (I, B, S => italic, bold, underlined)
    const styleBtns = document.querySelectorAll(".style-font-police-for-all-texts");
    styleBtns.forEach((btn) => {
      btn.addEventListener('click', (event) => {

        simulateFontStyleLeftPart(event.currentTarget)

        // simulation sur iframe
        if (event.currentTarget.id === "italic") {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((link) => {
            console.log(link)
            console.log(link.classList)
            if (event.currentTarget.classList.contains("style-selected")) {
              link.classList.add("italic-style")
            } else {
              link.classList.remove("italic-style")
            }

          })
        } else if (event.currentTarget.id === "weight-regular") {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((link) => {
            ["bold-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            if (event.currentTarget.classList.contains("style-selected")) {
              link.classList.add("regular-style")
            } else {
              link.classList.remove("regular-style")
            }

          })
        } else if (event.currentTarget.id === "weight-medium") {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((link) => {
            ["regular-style", "bold-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            if (event.currentTarget.classList.contains("style-selected")) {
              link.classList.add("medium-style")
            } else {
              link.classList.remove("medium-style")
            }

          })
        } else if (event.currentTarget.id === "weight-bold") {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((link) => {
            ["regular-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            if (event.currentTarget.classList.contains("style-selected")) {
              link.classList.add("bold-style")
            } else {
              link.classList.remove("bold-style")
            }

          })
        } else if (event.currentTarget.id === "underline") {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((link) => {
            console.log("coucuo")
            if (event.currentTarget.classList.contains("style-selected")) {
              link.classList.add("underline-style")
            } else {
              link.classList.remove("underline-style")
            }

          })
        }

      })
    })


    // FONT_COLOR FOR ALL TEXT OF PAGE:: font color of all text of the page:  pour changer tous les fontColor de tous les textes de la page
    // + la valeur des couleurs des elements specifiques
    document.querySelectorAll(".colorFontAll").forEach((colorInput) => {
      colorInput.addEventListener('change', (event) => {
        // simulate iframe
        if (previewDiv.contentDocument.getElementById("page-new-meal-for-custom-for-js") || previewDiv.contentDocument.getElementById("page-edit-for-custom-for-js") || previewDiv.contentDocument.getElementById("page-begin-for-custom-for-js")) {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((btn) => {
            //btn.setAttribute('style', `color: ${event.currentTarget.value} !important`)
            btn.style.setProperty('color', event.currentTarget.value, 'important');
          })
        } else {
          previewDiv.contentDocument.querySelectorAll(".text-to-modify-js").forEach((btn) => {
            btn.style.color = event.currentTarget.value;
          })
        }
        console.log("to change value color input")

        // change value of specific color input
        if (colorInput.classList.contains("begin-page-js")) {
          document.querySelectorAll("#font_col_text1_begin, #font_col_text2_begin, #font_col_text3_begin").forEach((specificColorInput) => {
            specificColorInput.value =  event.currentTarget.value;
          })
        } else if (colorInput.classList.contains("new-survey-v2-page-js")) {
          document.querySelectorAll("#font_col_title_survey, #font_col_question_survey, #font_col_title_multiple_question_survey, #font_col_satisfaction_tag_survey").forEach((specificColorInput) => {
            specificColorInput.value =  event.currentTarget.value;
          })
        } else if (colorInput.classList.contains("new-meal-page-js")) {
          document.querySelectorAll("#font_col_title_newmeal, #font_col_question_survey, #font_col_question_newmeal").forEach((specificColorInput) => {
            specificColorInput.value =  event.currentTarget.value;
          })
        } else if (colorInput.classList.contains("note-meal-page-js")) {
          document.querySelectorAll("#font_col_title_notemeal, #font_col_question_notemeal, #font_col_title_multiple_question_survey").forEach((specificColorInput) => {
            specificColorInput.value =  event.currentTarget.value;
          })
        } else if (colorInput.classList.contains("edit-survey-v2-page-js")) {
          document.querySelectorAll("#font_col_msg_reward_edit, #font_col_details_contact_edit, #font_col_cgu_edit").forEach((specificColorInput) => {
            specificColorInput.value =  event.currentTarget.value;
          })
        } else if (colorInput.classList.contains("new-borne-page-js")) {
          console.log("new-borne")
          document.querySelectorAll("#font_col_text4_newborne, #font_col_text5_newborne, #font_col_text6_newborne").forEach((specificColorInput) => {
            specificColorInput.value =  event.currentTarget.value;
          })
        }

      })
    })

    // Changer la couleur de la description des questions dans le custom_questionnaire
    document.getElementById("font_col_title_multiple_question_survey").addEventListener('change', (event) => {
      previewDiv.contentDocument.querySelectorAll(".col-title-multiple-question-js").forEach((color) => {
        color.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // Changer la couleur de "noter un autre plat" dans le custom_questionnaire
    document.getElementById("font_col_other_product").addEventListener('change', (event) => {
      previewDiv.contentDocument.querySelectorAll(".btn-other-product-for-js").forEach((color) => {
        color.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // Changer la couleur du text "Passer à la question suivante" dans le custom_questionnaire
    document.getElementById("font_col_next_question").addEventListener('change', (event) => {
      previewDiv.contentDocument.querySelectorAll(".btn-next-question-for-js").forEach((color) => {
        color.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // Changer la couleur des 'Textes degré de satisfaction' des questionnaires
    document.getElementById("font_col_satisfaction_tag_survey").addEventListener('change', (event) => {
      previewDiv.contentDocument.querySelectorAll(".col-satisfaction-tag-js").forEach((color) => {
        color.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // Changer la couleur des 'Contact Input Text' de la page edit
    document.getElementById("font_col_contact_input_edit").addEventListener('change', (event) => {
      previewDiv.contentDocument.querySelectorAll(".col-contact-input-js").forEach((color) => {
        color.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // changer la couleur bouton submit de la page new-borne
    const inputBtnSubNb = document.getElementById("font_col_validate_questionnaire_newborne")
    if (inputBtnSubNb) {
      inputBtnSubNb.addEventListener('change', (event) => {
        previewDiv.contentDocument.querySelectorAll(".text-btn-newsurvey-ton-inspect-js, .btn-validate-for-js a, .fake-btn-validate").forEach((btn) => {
          btn.style.setProperty('color', event.currentTarget.value, 'important');
        })
      })
    }


    // Changer la couleur du texte des boutons submit
    document.getElementById("font_col_submit_btns").addEventListener('change', (event) => {
      previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js, .btn-validate-for-js a, .fake-btn-validate").forEach((btn) => {
        btn.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    //BASIC COLORS : medium, good, bad, neutral, simulation dans l'iframe
    const basicColors = document.querySelectorAll(".neutral_col_questionnaire, .good_col_questionnaire, .medium_col_questionnaire, .bad_col_questionnaire")

    basicColors.forEach((color) => {
      color.addEventListener('change', (event) => {
        function btnStatisticsColor(className) {
          const selectColor = event.currentTarget.value
          previewDiv.contentDocument.querySelectorAll(`.${className}`).forEach((btn) => {
            btn.addEventListener('click', () => {
              btn.style.setProperty('background-color', selectColor)
              btn.addEventListener('mouseout', () => {
                btn.style.setProperty('background-color', selectColor)
                btn.style.setProperty('color', 'white')
              })
            })
            btn.addEventListener('mouseover', () => {

              btn.style.setProperty('background-color', selectColor)
              btn.style.setProperty('color', 'white')
              if (btn.style.backgroundColor === selectColor) {
                btn.addEventListener('mouseout', () => {
                  btn.style.setProperty('background-color', selectColor)
                  btn.style.setProperty('color', 'white')
                })
              } else {
                btn.addEventListener('mouseout', () => {
                  btn.style.setProperty('background-color', '')
                  btn.style.setProperty('color', 'black')
                })
              }
            })
          })
        }
        if (color.id === "neutral_col_questionnaire") {
          btnStatisticsColor("statistics-neutre")
        } else if (color.id === "good_col_questionnaire") {
          btnStatisticsColor("btn-survey-good")
          btnStatisticsColor("statistics-good")
        } else if (color.id === "medium_col_questionnaire") {
          btnStatisticsColor("btn-survey-medium")
          btnStatisticsColor("statistics-medium")
        } else if (color.id === "bad_col_questionnaire") {
          btnStatisticsColor("btn-survey-bad")
          btnStatisticsColor("statistics-bad")
        }
      })
    })

    // BUTTON LINK & VALIDATE - BEGIN AND START_PAGE: changer couleur des btn (btn de validation pour begin, et btn des liens pour start_page) => ca s'applique ensuite a tous les btn 'validate' du survey
    const colorBtnsLinks = document.querySelectorAll('#bkg_col_validate_questionnaire, #bkg_col_link_sp')
    colorBtnsLinks.forEach((colorBtnsLink) => {
      console.log(colorBtnsLink)
      colorBtnsLink.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        console.log(previewDiv);
        previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
					btn.querySelector("a").style.setProperty('background-color', event.currentTarget.value, 'important');
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
    })

    // BUTTON LINK & VALIDATE - NEW_BORNE: changer couleur des btn (btn de validation pour newborne)
    const colorBtnsLinksNewBorne = document.querySelectorAll('#bkg_col_submit_btns_newborne')
    colorBtnsLinksNewBorne.forEach((colorBtnsLinkNewBorne) => {
      console.log(colorBtnsLinkNewBorne)
      colorBtnsLinkNewBorne.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        console.log(previewDiv);
        previewDiv.contentDocument.querySelectorAll(".text-btn-newsurvey-ton-inspect-js").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
    })

    // BUTTON "Noter un autre plat": changer la couleurs de fond des btns pour new_meal
    const colorBtnsOtherProducts = document.querySelectorAll('#bkg_col_other_product')
    colorBtnsOtherProducts.forEach((colorBtnsOtherProduct) => {
      console.log(colorBtnsOtherProduct)
      colorBtnsOtherProduct.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".btn-other-product-for-js").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
    })

    // BUTTON "Passer à la question suivante": changer la couleurs de fond des btns pour new_meal
    const colorBtnsNextQuestions = document.querySelectorAll('#bkg_col_next_question')
    colorBtnsNextQuestions.forEach((colorBtnsNextQuestion) => {
      console.log(colorBtnsNextQuestion)
      colorBtnsNextQuestion.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".btn-next-question-for-js").forEach((btn) => {
          console.log(event.currentTarget.value)
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
    })

    // BUTTON Category: changer la couleurs de police des btns pour new_meal
    const colorFontCategoryBtns = document.querySelectorAll('#font_col_btn_category')
    colorFontCategoryBtns.forEach((colorFontCategoryBtn) => {
      console.log(colorFontCategoryBtn)
      colorFontCategoryBtn.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".tab-master-categories, .tab-other-category").forEach((btn) => {
          console.log(event.currentTarget.value)
          btn.style.setProperty('color', event.currentTarget.value, 'important');
        })
      })
    })

    // BUTTON Category: changer la couleurs de fond des btns categories pour new_meal
    const bkgColCategoryBtns = document.querySelectorAll('#bkg_col_btn_category')
    bkgColCategoryBtns.forEach((bkgColCategoryBtn) => {
      console.log(bkgColCategoryBtn)
      bkgColCategoryBtn.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".tab-master-categories, .tab-other-category").forEach((btn) => {
          console.log(event.currentTarget.value)
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
    })

    // BUTTON Category: changer la couleurs de police des btns sous-categories pour new_meal
    const colorFontSsCategoryBtn = document.getElementById('font_col_btn_ss_category')
    colorFontSsCategoryBtn.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelectorAll(".tab-ss-category").forEach((btn) => {
        btn.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // BUTTON Category: changer la couleurs de fond des btns sous-categories pour new_meal
    const bkgColorSsCategoryBtn = document.getElementById('bkg_col_btn_ss_category')
    bkgColorSsCategoryBtn.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelectorAll(".tab-ss-category").forEach((btn) => {
        btn.style.setProperty('background-color', event.currentTarget.value, 'important');
      })
    })

    // Background Questions Conditionnelles: changer la couleurs de fond de la section questions conditionnelles note_meal/new_survey_v2
    const bkgColQuestionAddis = document.querySelectorAll('#bkg_col_question_addi')
    bkgColQuestionAddis.forEach((bkgColQuestionAddi) => {
      console.log(bkgColQuestionAddi)
      bkgColQuestionAddi.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(".question-addi-for-js").forEach((question) => {
          console.log(event.currentTarget.value)
          question.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
    })

    // Couleur de fond et du texte de la sous-catégorie sélectionnée (active) pour new_meal (les couleurs doivent être l'inverse de la liste des sous-catégories)
    const liCategory = previewDiv.contentDocument.getElementById("li-category")
    const catCol = document.getElementById("font_col_btn_ss_category").value
    const catBkgCol = document.getElementById("bkg_col_btn_ss_category").value
    if (liCategory != null && (liCategory.classList.contains("active"))) {
      liCategory.childNodes[1].style.setProperty('color', catCol, 'important');
      liCategory.childNodes[1].style.setProperty('background-color', catBkgCol, 'important');
    }

    if (document.getElementById('bkg_col_btn_ss_category').classList.contains("hidden")) {
      console.log("je ne suis plus caché")
    }

    // BUTTON Sélectionnez votre choix : changer la couleur du texte
    const colorFontSelectMeal = document.getElementById('font_col_dropdown_select_meal')
    colorFontSelectMeal.addEventListener("change", (event) => {
      console.log(event.currentTarget.value);
      console.log(previewDiv.contentDocument.querySelector(".up-form-menu-start-mobile select"));
      previewDiv.contentDocument.querySelectorAll(".up-form-menu-start-mobile select").forEach((option) => {
        option.style.setProperty('color', event.currentTarget.value, 'important');
      })
    })

    // BUTTON Sélectionnez votre choix : changer la couleur de fond
    const bkgColSelectMeal = document.getElementById('bkg_col_dropdown_select_meal')
    bkgColSelectMeal.addEventListener("change", (event) => {
      console.log(event.currentTarget.value);
      console.log(previewDiv.contentDocument.querySelector(".up-form-menu-start-mobile select"));
      previewDiv.contentDocument.querySelectorAll(".up-form-menu-start-mobile select").forEach((option) => {
        option.style.setProperty('background-color', event.currentTarget.value, 'important');
      })
    })

    // Gift on Begin page: changer la couleur des noeuds du cadeau
    document.getElementById('col_gift_1').addEventListener("change", (event) => {
      previewDiv.contentDocument.getElementById("gift-col-1").style.setProperty('fill', event.currentTarget.value, 'important');
    })

    // Gift on Begin page: changer la couleur des côtés bas du cadeau
    document.getElementById('col_gift_2').addEventListener("change", (event) => {
      previewDiv.contentDocument.getElementById("gift-col-2").style.setProperty('fill', event.currentTarget.value, 'important');
    })

    // Gift on Begin page: changer la couleur des côtés haut du cadeau
    document.getElementById('col_gift_3').addEventListener("change", (event) => {
      previewDiv.contentDocument.getElementById("gift-col-3").style.setProperty('fill', event.currentTarget.value, 'important');
    })

    // Gift on Begin page: changer la couleur du milieu haut du cadeau
    document.getElementById('col_gift_4').addEventListener("change", (event) => {
      previewDiv.contentDocument.getElementById("gift-col-4").style.setProperty('fill', event.currentTarget.value, 'important');
    })

    // Gift on Begin page: changer la couleur du milieu bas du cadeau
    document.getElementById('col_gift_5').addEventListener("change", (event) => {
      previewDiv.contentDocument.getElementById("gift-col-5").style.setProperty('fill', event.currentTarget.value, 'important');
    })

    //BUTTON LINK & VALIDATE - BEGIN AND START_PAGE: changer couleur des btn (btn de validation pour begin, et btn des liens pour start_page): checkbox for border on btns validate => ca s'applique ensuite a tous les btn 'validate' du survey
    const checkboxBorders = document.querySelectorAll("#border");
    checkboxBorders.forEach((checkboxBorder) => {
      const borderCss = document.getElementById(`css-for-border-${checkboxBorder.dataset.page}`)
      checkboxBorder.addEventListener("change", (event) => {
        console.log(event.currentTarget.checked)
        if (event.currentTarget.checked) {
          console.log("if")
          borderCss.classList.remove("hidden")
          previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
            btn.style.border = "1px solid black"
          })
        } else {
          borderCss.classList.add("hidden")
          previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
             console.log("else")
            btn.style.border = "none"
            btn.style.borderRadius = "30px"
          })
        }
      })
    })

    //BUTTON LINK & VALIDATE - BEGIN AND START_PAGE: changer couleur des btn (btn de validation pour begin, et btn des liens pour start_page) : color on border of btn validate => ca s'applique ensuite a tous les btn 'validate' du survey
    const colorBorders = document.querySelectorAll('#border_col_validate_questionnaire, #border_col_link_sp')
    colorBorders.forEach((colorBorder) => {
      colorBorder.addEventListener('change', (event) => {
        previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
          btn.style.borderColor = event.currentTarget.value
        })
      })
    })

    //BUTTON LINK & VALIDATE - BEGIN AND START_PAGE: changer couleur des btn (btn de validation pour begin, et btn des liens pour start_page) : weight of  border of btn validate => ca s'applique ensuite a tous les btn 'validate' du survey
    const weightBorders = document.querySelectorAll('#border_weight_validate_questionnaire, #border_weight_link_sp')
    weightBorders.forEach((weightBorder) => {
      weightBorder.addEventListener('change', (event) => {
        console.log("change border width")
        previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
          btn.style.borderWidth = `${event.currentTarget.value}px`
        })
      })
    })

    //BUTTON LINK & VALIDATE - BEGIN AND START_PAGE: changer couleur des btn (btn de validation pour begin, et btn des liens pour start_page) : border radius of btns: chose one of the 2 options => ca s'applique ensuite a tous les btn 'validate' du survey
    const radiusBorders = document.querySelectorAll("[id^='border_radius_validate_questionnaire']")
    console.log(radiusBorders)
    radiusBorders.forEach((radio) => {
      radio.addEventListener('change', (event) => {
        console.log(event.currentTarget)
        const checkedOne = document.querySelector("[id^='border_radius_validate_questionnaire']:checked").value
        previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
          btn.style.setProperty('border-radius', checkedOne, 'important');
        })
      })
    })

    const radiusBordersSp = document.querySelectorAll("[id^='border_radius_link_sp']")
    console.log(radiusBordersSp)
    radiusBordersSp.forEach((radio) => {
      radio.addEventListener('change', (event) => {
        console.log(event.currentTarget)
        const checkedOne = document.querySelector("[id^='border_radius_link_sp']:checked").value
        previewDiv.contentDocument.querySelectorAll(".btn-validate-for-js").forEach((btn) => {
          btn.style.setProperty('border-radius', checkedOne, 'important');
        })
      })
    })

    //EDIT : content text area on the end page for CGU 1 with multiple language: simulate change of text in the iframe directly
		const rewardArray = ['content-text-reward-edit','content-text-game-edit', 'content-text-fid-edit', 'content-text-no-reward-edit' ]

		const rewardTextToChange= document.getElementById('msg-reward-to-inspect-js')
		rewardArray.forEach((reward_string) => {
			const rewardTextArea = document.querySelectorAll(`[data-${reward_string}]`)
			if (rewardTextToChange && rewardTextArea) {
				rewardTextArea.forEach((text) => {
					text.addEventListener('keyup', (event) => {
						const newText = text.value
						previewDiv.contentDocument.querySelector('.msg-reward-to-inspect-js').innerText = newText
					})
				})
			}
		})

		const contactTextToChange= document.getElementById('msg-leave-details-to-inspect-js')
		const contactTextArea = document.querySelectorAll(`[data-content-text-2-edit]`)
			if (contactTextToChange && contactTextArea) {
				contactTextArea.forEach((text) => {
					text.addEventListener('keyup', (event) => {
						const newText = text.value
						previewDiv.contentDocument.querySelector('.msg-leave-details-to-inspect-js').innerText = newText
					})
				})
			}

    const cgu1TextToChange = document.getElementById('cgu1-to-inspect-js')
    const selectCguTextArea = document.querySelectorAll("[data-for-text-area-content3]")
    if (cgu1TextToChange) {
      selectCguTextArea.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newText = text.value
          previewDiv.contentDocument.querySelector('.cgu1-to-inspect-js').innerText = newText
        })
      })
    }
    //EDIT : content text area on the end page for CGU 2 and 3 with multiple language: simulate change of text in the iframe directly And hide section and text if there is no text
    function cgusToModify(textToChange, textArea, cguTab) {
      const cguTextToChange = document.getElementById(textToChange)
      const selectCguTextArea = document.querySelectorAll(textArea)
      if (cguTextToChange) {
        selectCguTextArea.forEach((text) => {
          text.addEventListener('keyup', (event) => {
            const selectCguTab = previewDiv.contentDocument.getElementById(cguTab)
            selectCguTab.classList.remove('hidden')
            if (text.value === '') {
              selectCguTab.classList.add('hidden')
            }
            const newText = text.value
            previewDiv.contentDocument.querySelector(`.${textToChange}`).innerText = newText
          })
        })
      }
    }


    cgusToModify('cgu2-to-inspect-js', "[data-for-text-area-content4]", 'cgu-tab-2')
    cgusToModify('cgu3-to-inspect-js', "[data-for-text-area-content5]", 'cgu-tab-3')

    // // EDIT : fonction pour les boutons en fonction des rewards
    // function btnRewardToCustomize(btnToChange, btnArea) {
    //   const btnTextToChange = document.getElementById(btnToChange)
    //   const selectBtnTextArea = document.querySelectorAll(btnArea)
    //   console.log(btnTextToChange)
    //   console.log(selectBtnTextArea)

    //   if (btnTextToChange) {
    //     console.log('ok')
    //     selectBtnTextArea.forEach((text) => {
    //       console.log(text)
    //       text.addEventListener('keyup', (event) => {
    //         const newText = text.value
    //         previewDiv.contentDocument.querySelector(`.${btnToChange}`).innerText = newText
    //       })
    //     })
    //   }
    // }

    // btnRewardToCustomize('btn-with-concours-js', "[data-for-text-area-content-text-validate-with-concours-edit]")
    // btnRewardToCustomize('btn-without-concours-js', "[data-for-text-area-content-text-validate-without-concours-edit]")
    // btnRewardToCustomize('btn-with-fid-js', "[data-for-text-area-content-text-validate-with-fid-edit]")
    // btnRewardToCustomize('btn-without-fid-js', "[data-for-text-area-content-text-validate-without-fid-edit]")
    // btnRewardToCustomize('btn-with-recompense-js', "[data-for-text-area-content-text-validate-with-recompense-edit]")
    // btnRewardToCustomize('btn-without-recompense-js', "[data-for-text-area-content-text-validate-without-recompense-edit]")


    //BEGIN : content text area on the begin page with multiple language: simulate change of text in the iframe directly
    const textToChange = document.getElementById('text-1-ton-inspect-js')
    const selectTextArea = document.querySelectorAll("[data-for-text-area]")

    if (textToChange) {
      selectTextArea.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newText = text.value
          previewDiv.contentDocument.querySelector(".text-1-ton-inspect-js").innerText = newText
        })
      })
    }

    //BEGIN - Signature text : content text area on the begin page with multiple language: simulate change of text in the iframe directly
    const signatureTextToChange = document.getElementById('text-signature-ton-inspect-js')
    const selectTextAreaSignature = document.querySelectorAll("[data-for-text-area-signature]")
    if (signatureTextToChange) {
      selectTextAreaSignature.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newTextSignature = text.value
          if (previewDiv.contentDocument.querySelector(".text-signature-ton-inspect-js")) {
          previewDiv.contentDocument.querySelector(".text-signature-ton-inspect-js").innerText = newTextSignature
          }
        })
      })
    }

    //Note Meal - Not concerned text : content text area on the note meal page with multiple language: simulate change of text in the iframe directly
    const notConcernedTextToChange = document.getElementById('text-not-concerned-js')
    const selectTextAreaNotConcerned = document.querySelectorAll("[data-for-text-area-not-concerned]")
    if (notConcernedTextToChange) {
      selectTextAreaNotConcerned.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newTextNotConcerned = text.value
          if (previewDiv.contentDocument.querySelector(".text-not-concerned-js")) {
           previewDiv.contentDocument.querySelector(".text-not-concerned-js").innerText = newTextNotConcerned
          }
        })
      })
    }

    //New Survey - Not concerned text : content text area on the note meal page with multiple language: simulate change of text in the iframe directly
    const newSurveyNotConcernedTextToChange = document.getElementById('new-survey-text-not-concerned-js')
    const selectTextAreaNotConcernedNewSurvey = document.querySelectorAll("[data-for-new-survey-text-area-not-concerned]")
    if (newSurveyNotConcernedTextToChange) {
      selectTextAreaNotConcernedNewSurvey.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newTextNotConcerned = text.value
          previewDiv.contentDocument.querySelector(".new-survey-text-not-concerned-js").innerText = newTextNotConcerned
        })
      })
    }

    // Edit - Fonction pour simuler le changement de texte dans l'iframe directement
    function addKeyUpListener(textAreaSelector, buttonClass) {
      const textAreas = document.querySelectorAll(textAreaSelector);
      const buttons = previewDiv.contentDocument.querySelectorAll(buttonClass);
      textAreas.forEach((textArea) => {
        textArea.addEventListener('keyup', (event) => {
          const newText = textArea.value;
          buttons.forEach((button) => {
            button.value = newText;
          });
        });
      });
    }

    // Edit - On appel la fonction "addKeyUpListener" pour chaque bouton en fonction des rewards
    previewDiv.addEventListener('load', function() {
      // On vérifie si on est sur la page 'edit'
      var pageEditElement = previewDiv.contentDocument.querySelector('#page-edit-for-custom-for-js');
      if (pageEditElement) {
        const validateDiv = previewDiv.contentDocument.querySelector('.validate');
        const isJeuConcours = validateDiv.querySelectorAll('.jeu-concours');
        const isDirecte = validateDiv.querySelectorAll('.directe');
        const isCarteFid = validateDiv.querySelectorAll('.carte-fid');
        const isNothing = validateDiv.querySelectorAll('.btn-with-nothing-js');

        // Si le reward est carte fidélité, on appelle la fonction pour les boutons avec carte fidélité
        if(isCarteFid.length > 0) {
          addKeyUpListener("[data-for-text-area-content-text-validate-with-fid-edit]", ".btn-with-fid-js");
          addKeyUpListener("[data-for-text-area-content-text-validate-without-fid-edit]", ".btn-without-fid-js");
        }

        // Si le reward est récompense, on appelle la fonction pour les boutons avec récompense
        if(isDirecte.length > 0) {
          addKeyUpListener("[data-for-text-area-content-text-validate-with-recompense-edit]", ".btn-with-recompense-js");
          addKeyUpListener("[data-for-text-area-content-text-validate-without-recompense-edit]", ".btn-without-recompense-js");
        }

        // Si le reward est jeu concours, on appelle la fonction pour les boutons avec jeu concours
        if(isJeuConcours.length > 0) {
          addKeyUpListener("[data-for-text-area-content-text-validate-with-concours-edit]", ".btn-with-concours-js");
          addKeyUpListener("[data-for-text-area-content-text-validate-without-concours-edit]", ".btn-without-concours-js");
        }

        // Si il n'y a pas de reward, on appelle la fonction pour le bouton sans reward
        if(isNothing.length > 0) {
          addKeyUpListener("[data-for-text-area-content-text-validate-with-nothing-edit]", ".btn-with-nothing-js");
        }
      }
    });

    //NEW BORNE - content_text_1_newborne  : content text area page with multiple language: simulate change of text in the iframe directly
    const contentTextNewBorne = document.getElementById('text-4-ton-inspect-js')
    const selectTextAreaNewBorne = document.querySelectorAll("[data-for-text-area-content-text-new-borne]")
    if (contentTextNewBorne) {
      selectTextAreaNewBorne.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newTextNewBorne = text.value
          previewDiv.contentDocument.querySelector(".text-4-ton-inspect-js").innerText = newTextNewBorne
        })
      })
    }

     //NEW BORNE - content_text_5_newborne  : content text area page with multiple language: simulate change of text in the iframe directly
     const contentText5NewBorne = document.getElementById('text-5-ton-inspect-js')
     const selectText5AreaNewBorne = document.querySelectorAll("[data-for-text-area-content-text-5-new-borne]")
     if (contentText5NewBorne) {
       selectText5AreaNewBorne.forEach((text) => {
         text.addEventListener('keyup', (event) => {
           const newTextNewBorne = text.value
           console.log(newTextNewBorne)
           previewDiv.contentDocument.querySelector(".text-5-ton-inspect-js").innerText = newTextNewBorne
         })
       })
     }

    // NEW BORNE - content_text_submit_btn_newborne : content text area page with multiple language: simulate change of text in the iframe directly
    const contentTextSubmitBtnNewBorne = document.getElementsByClassName('text-btn-newsurvey-ton-inspect-js')
    const selectTextAreaSubmitBtnNewBorne = document.querySelectorAll("[data-for-text-area-content-text-submit-btn-new-borne]")
    if (contentTextSubmitBtnNewBorne) {
      selectTextAreaSubmitBtnNewBorne.forEach((text) => {
        text.addEventListener('keyup', (event) => {
          const newTextSubmitBtnNewBorne = text.value
          previewDiv.contentDocument.querySelector(".text-btn-newsurvey-ton-inspect-js").innerText = newTextSubmitBtnNewBorne
        })
      })
    }

    //New survey - Sweet alert : change the color of the icon border : simulate change of text in the iframe directly
    const borderColIconWarning = document.getElementById('border_col_icon_warning')
    borderColIconWarning.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelector(".alert-no-note-icon").style.setProperty('border-color', event.currentTarget.value, 'important');
    })

    //New survey - Sweet alert : change the icon color (!): simulate change of text in the iframe directly
    const colIconWarning = document.getElementById('col_icon_warning')
    colIconWarning.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelector(".swal2-icon-content").style.setProperty('color', event.currentTarget.value, 'important');
    })

    //New survey - Sweet alert : change the icon color (!): simulate change of text in the iframe directly
    const bkgColBtnLeaveNote = document.getElementById('bkg_col_btn_leave_note')
    bkgColBtnLeaveNote.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelector(".swal2-confirm").style.setProperty('background-color', event.currentTarget.value, 'important');
    })

    //New survey - Sweet alert : change the icon color (!): simulate change of text in the iframe directly
    const bkgColBtnValidateAnyway = document.getElementById('bkg_col_btn_validate_anyway')
    bkgColBtnValidateAnyway.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelector(".swal2-cancel").style.setProperty('background-color', event.currentTarget.value, 'important');
    })

    //New survey - Sweet alert : change the font color of the text: simulate change of text in the iframe directly
    const inputFontColAlert = document.getElementById('font_col_alert_newsurvey')
    console.log(inputFontColAlert)
    inputFontColAlert.addEventListener("change", (event) => {
      previewDiv.contentDocument.getElementById('swal2-html-container').style.setProperty('color', event.currentTarget.value, 'important');
      previewDiv.contentDocument.getElementById('swal2-title').style.setProperty('color', event.currentTarget.value, 'important');
    })

    // CHANGE FONT COLOR OF A SPECIFIC TEXT
    const colorOfTexts = document.querySelectorAll("#font_col_title_newmeal, #font_col_text1_begin, #font_col_text2_begin, #font_col_text3_begin, #font_col_signature_begin, #font_col_text4_newborne, #font_col_text5_newborne, #font_col_text6_newborne, #font_col_question_newmeal, #font_col_title_notemeal, #font_col_question_notemeal, #font_col_title_survey, #font_col_title_multiple_question_survey, #font_col_question_survey, #font_col_msg_reward_edit, #font_col_details_contact_edit, #font_col_cgu_edit, #font_col_not_concerned, #font_col_flags_display_in_list")
    colorOfTexts.forEach((colorText) => {
      colorText.addEventListener("change", (event) => {
        console.log(event.currentTarget.value);
        previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forTitle}`).forEach((btn) => {
          btn.style.setProperty('color', event.currentTarget.value, 'important');
        })
      })
    })

    // CHANGE FONT_FAMILY OF A SPECIFIC TEXT
    var selects = document.querySelectorAll("[class^='select']")
    selects.forEach((select) => {
      //NEW_MEAL : pour tous les selecteur de fontFamily pour un texte en particulier: eventlistener pour simuler le changement de font (family) sur le texte en question sur la preview
      select.addEventListener("change", (event) => {
        console.log(event.currentTarget.value)
        const data = `.${select.dataset.forTitle}`
        previewDiv.contentDocument.querySelectorAll(data).forEach((btn) => {
          btn.style.fontFamily = event.currentTarget.value
        })
      })
    })

    //CHANGE FONT_STYLE OF A SPECIFIC TEXT => style sur l'ensemble des textes (I, B, S => italic, bold, underlined)
    const styleFontTexts = document.querySelectorAll(".style-font-police-for-specific-texts");
    styleFontTexts.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        console.log(event.currentTarget)
        simulateFontStyleLeftPart(event.currentTarget)

        console.log(event.currentTarget.id)
        console.log("coucoucoucoucuo")
        // simulation sur iframe
        if (event.currentTarget.id === "italic") {
          previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forText}`).forEach((link) => {
            console.log(link)
            console.log(link.classList)
            link.classList.toggle("italic-style")
          })
        } else if (event.currentTarget.id === "weight-regular") {
          previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forText}`).forEach((link) => {
            ["bold-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("regular-style")
          })
        } else if (event.currentTarget.id === "weight-medium") {
          console.log("iihihihihih")
          console.log(previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forText}`))
          previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forText}`).forEach((link) => {
            ["regular-style", "bold-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("medium-style")
          })
        } else if (event.currentTarget.id === "weight-bold") {
          previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forText}`).forEach((link) => {
            ["regular-style", "medium-style"].forEach((bold_style) => {
              link.classList.remove(bold_style)
            })
            link.classList.toggle("bold-style")
          })
        } else if (event.currentTarget.id === "underline") {
          previewDiv.contentDocument.querySelectorAll(`.${event.currentTarget.dataset.forText}`).forEach((link) => {
            link.classList.toggle("underline-style")
          })
        }

      })
    })

    // NAVIGATION : submit a page: simulate navigation in iFrame when validate customisation_form
    document.querySelectorAll('#submit_begin_page, #submit_new_meal_page, #submit_note_meal_page, #submit_new_survey_page, #submit_edit_page, #submit_new_borne_page, #submit_edit_page, #submit_start_page').forEach((submit) => {
     submit.addEventListener('click', (event) => {
      if (event.currentTarget.id === "submit_edit_page" || event.currentTarget.id === "submit_start_page" ) {
				document.querySelector("iframe#preview").contentDocument.querySelector(".btn-to-redirect-for-js").click();
      } else if (event.currentTarget.id === "submit_new_borne_page") {
        console.log("new-borne")
      }
      else {
				document.querySelector("iframe#preview").contentDocument.querySelector(".btn-to-redirect-for-js").firstElementChild.click();
      }
     })
    })

    logoBkgColor();

    // METTRE A JOUR LES INPUTS "COULEURS DE BASE" LORSQU'ON CLIQUE SUR LE BOUTON "SUIVANT"

    $(document).on('ajax:success', function(event) {
      const form = event.target;

    // Si il y à des couleurs de base dans le questionnaire on peut les mettre à jour avec les inputs
    if (form.querySelector('#neutral_col_questionnaire')) {
      const newNeutralColor = form.querySelector('#neutral_col_questionnaire').value;
      const neutralInputs = document.querySelectorAll('.neutral_col_questionnaire');
      updateColorInputs(newNeutralColor, neutralInputs);

      const newGoodColor = form.querySelector('#good_col_questionnaire').value;
      const goodInputs = document.querySelectorAll('.good_col_questionnaire');
      updateColorInputs(newGoodColor, goodInputs);

      const newMediumColor = form.querySelector('#medium_col_questionnaire').value;
      const mediumInputs = document.querySelectorAll('.medium_col_questionnaire');
      updateColorInputs(newMediumColor, mediumInputs);

      const newBadColor = form.querySelector('#bad_col_questionnaire').value;
      const badInputs = document.querySelectorAll('.bad_col_questionnaire');
      updateColorInputs(newBadColor, badInputs);
    }

    });

    function updateColorInputs(value, colorInputs) {
      Array.prototype.forEach.call(colorInputs, (input) => {
        input.value = value;
      });
    }
  }

};

export { customQuestionnaire }
