
// CAPITALIZE ELEMENT
function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

// RETURN TRUE SI LE CONTENT CONTIENT UN CARACTÈRE SPÉCIAL
const punctuationValidation = (content) => {
  const punctuation = /[();,/:&@"”“!?'\\"^|<>{}*%#$]/;
  let punctuationOuiNon = false

  content.value.split('').forEach((character) => {
    if (punctuation.test(character)) {
      punctuationOuiNon = true
    }
  })
  return punctuationOuiNon;
}


//////////////////////////  VALIDATIONS FOR GROUPE QUESTIONS  //////////////////////////

const validationsButtonAndAlertsForGq = (modal, btn, groupeQuestions, content) => {
  const allInputsLanguage = modal.querySelectorAll("[id^='groupe_question_visible_title_']");

  modal.addEventListener("keyup", () => {

    // Array pour savoir si tous les contents de chaque langue sont remplis
    let allContentFilled = false
    const filledByLanguage = []
    let val = ""

    Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
      val = content_lang_input.value.replaceAll(' ', '')
      filledByLanguage.push(val !== '' && val.length > 2 )
    })

    allContentFilled = filledByLanguage.includes(false) ? false : true

    // Savoir si le content du main_language n'existe pas dans un autre Groupe Question
    const alreadyUsedOuiNon = groupeQuestions.includes(content.value.toLowerCase())

    // On ne veut pas que les gq aient les noms suivants
    const notAllowedWords = ['client', 'service', 'meal', 'produits', 'thème', 'carte', 'nps'];
    const notAllowedOuiNon = notAllowedWords.includes(content.value.toLowerCase())

    // Ni de ponctuation
    const punctuationOuiNon = punctuationValidation(content)

    // Alerte envoyée à l'utilisateur en fonction de l'erreur de validation
    const alertGqContainer = modal.querySelector('.alert-gq-name-wrapper')
    const AlertGqText = modal.querySelector('.alert-gq-name')
    const notAllowedAlert = alertGqContainer.dataset.alerts.split(",")[0]
    const alreadyUsedAlert = alertGqContainer.dataset.alerts.split(",")[1]
    const punctuationsAlert = alertGqContainer.dataset.alerts.split(",")[2]

    if (notAllowedOuiNon) {
      alertGqContainer.classList.remove('hidden')
      AlertGqText.innerHTML = `<span style='font-weight: 700'>${capitalize(content.value)}</span> ${notAllowedAlert}`

    } else if (alreadyUsedOuiNon) {
      alertGqContainer.classList.remove('hidden')
      AlertGqText.innerHTML = alreadyUsedAlert

    } else if (punctuationOuiNon) {
      alertGqContainer.classList.remove('hidden')
      AlertGqText.innerHTML = punctuationsAlert

    } else {
      alertGqContainer.classList.add('hidden')
    }

    // Toggle validation bouton
    if (allContentFilled && !alreadyUsedOuiNon && !notAllowedOuiNon && !punctuationOuiNon) {
      btn.classList.remove("not-clickable");
      btn.setAttribute("type", "submit");
    } else {
      btn.classList.add("not-clickable");
      btn.removeAttribute("type");
    }
  })
}

const validationsForNewGroupQuestions = () => {
  if (document.querySelector("#myModal-gqn")) {
    const modaleNewGroupQuestion = document.querySelector("#myModal-gqn").querySelector(".modal-content");
    const modaleNewGroupQuestionBtn = modaleNewGroupQuestion.querySelector(".custom-btn.btn-of-modal");
    const namesGroupeQuestions = JSON.parse(document.getElementById("groupe_question_title_index_parametre_for_js").dataset.allGqContent);
    const mainGroupeQuestionsContent = modaleNewGroupQuestion.querySelector(`#groupe_question_visible_title_${document.getElementById("english-or-not-for-js").dataset.mainLang}`);

    validationsButtonAndAlertsForGq(modaleNewGroupQuestion, modaleNewGroupQuestionBtn, namesGroupeQuestions, mainGroupeQuestionsContent);
  }
}

const validationsForEditGroupQuestions = () => {
  if (document.querySelector("#myModal-edit-groupe-question")) {
    var modaleEditGroupQuestion = document.querySelector("#myModal-edit-groupe-question").querySelector(".modal-content");
    var modaleEditGroupQuestionBtn = modaleEditGroupQuestion.querySelector("input.custom-btn.btn-of-modal");
    var namesGroupQuestionEdit = JSON.parse(document.getElementById("groupe_question_title_index_parametre_for_js").dataset.allGqContent);
    var mainGroupeQuestionsContentEdit = modaleEditGroupQuestion.querySelector(`#groupe_question_visible_title_${document.getElementById("english-or-not-for-js").dataset.mainLang}`);
    namesGroupQuestionEdit = namesGroupQuestionEdit.filter((e) => e !== mainGroupeQuestionsContentEdit.value.toLowerCase() )

    validationsButtonAndAlertsForGq(modaleEditGroupQuestion, modaleEditGroupQuestionBtn, namesGroupQuestionEdit, mainGroupeQuestionsContentEdit);
  }
}


//////////////////////////  VALIDATIONS FOR ENQUETES  //////////////////////////

const validationsButtonAndAlertsForEnquetes = (modal, btn, enquetes, content) => {
  modal.addEventListener('keyup', () => {
    // Contient au moins deux caractères
    const moreThanTwoCharactersOuiNon = content.value.length > 1 ? true : false;

    // N'a pas le même nom qu'une autre catégorie
    const enqueteLowerCase = enquetes.map( (enquete) =>  enquete.toLowerCase() )
    const alreadyUsedOuiNon = enqueteLowerCase.includes(content.value.toLowerCase());

    // Ne doit pas contenir de ponctuation
    const punctuationOuiNon = punctuationValidation(content);

    // Alerte envoyée à l'utilisateur en fonction de l'erreur de validation
    const alertGqContainer = modal.querySelector('.alert-gq-name-wrapper')
    const AlertGqText = modal.querySelector('.alert-gq-name')
    const alreadyUsedAlert = alertGqContainer.dataset.alerts.split(",")[0]
    const punctuationsAlert = alertGqContainer.dataset.alerts.split(",")[1]

    if (alreadyUsedOuiNon) {
      alertGqContainer.classList.remove('hidden')
      AlertGqText.innerHTML = alreadyUsedAlert

    } else if (punctuationOuiNon) {
      alertGqContainer.classList.remove('hidden')
      AlertGqText.innerHTML = punctuationsAlert

    } else {
      alertGqContainer.classList.add('hidden')
    }

    // Toggle validation bouton
    if (moreThanTwoCharactersOuiNon && !alreadyUsedOuiNon && !punctuationOuiNon) {
      btn.classList.remove("not-clickable");
      btn.setAttribute("type", "submit");
    } else {
      btn.classList.add("not-clickable");
      btn.removeAttribute("type");
    }
  })
}

const validationsForNewSurvey = () => {
  if (document.querySelector('#myModal-new-enquete')) {
    const modalNewEnquete = document.querySelector("#myModal-new-enquete").querySelector(".modal-content");
    const modalNewEnqueteBtn = modalNewEnquete.querySelector("input.custom-btn.btn-of-modal");
    const namesNewEnquete = JSON.parse(document.getElementById("enquetes_index_parametre_for_js").dataset.enqueteNames);
    const modalNewEnqueteContent = modalNewEnquete.querySelector('#enquete_name')

    validationsButtonAndAlertsForEnquetes(modalNewEnquete, modalNewEnqueteBtn, namesNewEnquete, modalNewEnqueteContent)
  }
}

const validationsForEditSurvey = () => {
  if (document.querySelector('#myModal-edit-enquete')) {
    var modalEditEnquete = document.querySelector("#myModal-edit-enquete").querySelector(".modal-content");
    var modalEditEnqueteBtn = modalEditEnquete.querySelector("input.custom-btn.btn-of-modal");
    var namesEditEnquete = JSON.parse(document.getElementById("enquetes_index_parametre_for_js").dataset.enqueteNames);
    var modalEditEnqueteContent = modalEditEnquete.querySelector('#enquete_name')
    namesEditEnquete = namesEditEnquete.filter((e) => e !== modalEditEnqueteContent.value )

    validationsButtonAndAlertsForEnquetes(modalEditEnquete, modalEditEnqueteBtn, namesEditEnquete, modalEditEnqueteContent)
  }
}

export { validationsForNewGroupQuestions , validationsForEditGroupQuestions, validationsForNewSurvey, validationsForEditSurvey }
