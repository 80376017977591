import { Controller } from 'stimulus';
import 'select2';

export default class extends Controller {
  connect() {
    const mySelect2Element = $('#main-language-selector');

    mySelect2Element.on('change', function() {
      const redirectionUrl = $(this).val();
      window.location.href = redirectionUrl;
    });

    this.add_styles_to_language_selector()

  }

  add_styles_to_language_selector() {
    const main_language_selector = $(".selector-main-language")

    if (main_language_selector) {
      $(".selector-main-language").select2({
        templateResult: this.generate_flag_div,
        templateSelection: this.generate_flag_div,
        selectOnClose: true,
        minimumResultsForSearch: Infinity,
      });
    }
  }

  generate_flag_div (opt) {
    if (!opt.id) {
      return opt.text;
    }
    var optimage = $(opt.element).attr('data-image');
    if(!optimage){
      return opt.text;
    } else {
      var $opt = $(
        '<span><img src="' + optimage + '" width="20px" style="margin-bottom: 2px; margin-right: 10px;" />' + opt.text + '</span>'
      );
      return $opt;
    }
  };
}
