import { disableBtn } from "../_toggle_btn";
import { enableBtn } from "../_toggle_btn";
import { translateFilters } from "./_translate_filters";

const toggleSubmitBtnForFilters = () => {

    const filterForm = $('.filter-form')
    if (filterForm) {

        var filtersForSelect = $('.filter-form #filters-for-select')
        translateFilters(filterForm)

        filtersForSelect.on('change', function(){
          var submitBtn = $(this).closest('form').find('button');
          const filterSelected = $(this).val();

          if (filterSelected) {
                enableBtn(submitBtn);
            } else {
                disableBtn(submitBtn);
            }
        })
    }
}

export { toggleSubmitBtnForFilters };