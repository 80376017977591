// creation customer
// phone_number field: to get flag and country code used thanks to id='phone' ou id='client_phone' with the intl-tel-input library (cf in application.html)
// utilisé dans cumul_fidelite and new_client_base

const getCountryCodeAndFlagForPhoneNumbers = () => {
  if (document.getElementById("create-customer-for-js")){
    console.log("customerGetCountryCodeAndFlagForPhoneNumbers")
    const phoneInputField = document.querySelector("#phone");
    const phoneInput = window.intlTelInput(phoneInputField, {
      preferredCountries: ["fr"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      formatOnDisplay: false,
    });

    const indicatif = `+${phoneInput["s"]["dialCode"]}`
    document.querySelector("#phone").value = indicatif
    document.querySelector("#phone").innerText = indicatif
  }
}

const editCountryCodeAndFlagForPhoneNumbers = () => {
  if (document.getElementById("id-to-load-flag-for-js")){
    console.log("customerEditCountryCodeAndFlagForPhoneNumbers")
    const phoneInputField = document.querySelector("#client_phone");

    if (phoneInputField) {
      const phoneInput = window.intlTelInput(phoneInputField, {
        preferredCountries: ["fr"],
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });
      const phoneNumber = document.querySelector("#client_phone").dataset.phoneCustomer
      document.querySelector("#client_phone").value = phoneNumber
    }
  }
}

const newCountryCodeAndFlagForPhoneNumbers = () => {
  if (document.getElementById("new-customer-id-to-load-flag-for-js")){
    console.log("customerEditCountryCodeAndFlagForPhoneNumbers")
    const phoneInputField = document.querySelector("#phone");
    console.log('input phone:', phoneInputField)

    if (phoneInputField) {
      const phoneInput = window.intlTelInput(phoneInputField, {
        preferredCountries: ["fr"],
        utilsScript:
          "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      });
      const phoneNumber = document.querySelector("#phone").dataset.phoneCustomer
      console.log('phone number:', phoneNumber)
      document.querySelector("#phone").value = "+33"
    }
  }
}

const userCountryCodeAndFlagForPhoneNumbers = () => {
  console.log("new user backoffice")
  if (document.getElementById("myUser-new")){
    const phoneInputField = document.querySelector("#user_phone");
    const phoneInput = window.intlTelInput(phoneInputField, {
      preferredCountries: ["fr"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
  });

    const indicatif = `+${phoneInput["s"]["dialCode"]}`
    document.querySelector("#user_phone").value = indicatif
    document.querySelector("#user_phone").innerText = indicatif
  }
}

const userEditCountryCodeAndFlagForPhoneNumbers = () => {
  if (document.getElementById("myUser-edit")){
    console.log("userEditCountryCodeAndFlagForPhoneNumbers")
    const phoneInputField = document.querySelector("#user_phone");
    const phoneInput = window.intlTelInput(phoneInputField, {
      preferredCountries: ["fr"],
      utilsScript:
        "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
      formatOnDisplay: false,
  });

    const telNumber = document.querySelector("#user_phone").dataset.phoneUser
    document.querySelector("#user_phone").value = telNumber
  }
}

export {getCountryCodeAndFlagForPhoneNumbers};
export {editCountryCodeAndFlagForPhoneNumbers};
export {userCountryCodeAndFlagForPhoneNumbers};
export {userEditCountryCodeAndFlagForPhoneNumbers};
export {newCountryCodeAndFlagForPhoneNumbers};
