import flatpickr from "flatpickr"
import "flatpickr/dist/flatpickr.min.css"
import "flatpickr/dist/l10n/fr.js"// Note this is important!

flatpickr(".datepicker", {
  dateFormat: "d.m.Y",
  mode: "single",
  maxDate: "today",
  locale: {
    firstDayOfWeek: 1,
    weekdays: {
      shorthand: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
      longhand: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    },
    months: {
      shorthand: ["janv","févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc",],
      longhand: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    },
  },
  disableMobile: "true",
});

flatpickr(".datepickerform", {
  dateFormat: "d.m.Y",
  mode: "single",
  defaultDate: "today",
  locale: {
    firstDayOfWeek: 1,
    weekdays: {
      shorthand: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
      longhand: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    },
    months: {
      shorthand: ["janv","févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc",],
      longhand: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    },
  },

});

flatpickr(".datepickerformedit", {
  format: "d-m-Y",
  altFormat: "d-m-Y",
  altInput: true,
  mode: "single",
  locale: {
    firstDayOfWeek: 1,
    weekdays: {
      shorthand: ["dim", "lun", "mar", "mer", "jeu", "ven", "sam"],
      longhand: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    },
    months: {
      shorthand: ["janv","févr", "mars", "avr", "mai", "juin", "juil", "août", "sept", "oct", "nov", "déc",],
      longhand: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
    },
  },
});

// NEW: datepicker avec des intervalles (utilisé dans MealItem pour créer les dates de programmation (meal_date) => dans MealItem.new)
flatpickr("#range_start", {
  altInput: true,
  mode: "range",
  dateFormat: "d-m-Y",
  minDate: new Date(new Date().getTime() + 86400000).toLocaleDateString() // pas possible de créer une intervalle de date qui commencerait avant demain
});

// EDIT MONOSITE:  Méthode utilisé dans MealItem#edit pour avoir les informations des dates de programmation déjà existantes (meal_dates)
// pour pouvoir griser les dates déjà existantes.
// les infos des dates sont récupérer en json via info_meal_dates.json
function getMealDatesOfMeal(id) {
  console.log("getMealDatesOfMeal")
  fetch(`/meal_items/${id}/info_meal_dates.json`)
  .then(response => response.json())
  .then((data) => {
    console.log(data)

    // datepicker avec des intervalles et les dates déjà prise en grisé : (utilisé dans MealItem#edit pour mdoifier les dates de programmation (meal_date) => dans MealItem.edit)
    flatpickr("#range_start_edit", {
      altInput: true,
      mode: "range",
      dateFormat: "d-m-Y",
      disable: data,
      minDate: new Date(new Date().getTime() + 86400000).toLocaleDateString() // pas possible de créer une intervalle de date qui commencerait avant demain
    });
  });
}

// Appelle de la méthode getMealDatesOfMeal quand on est sur la page MealItem#edit (monosite)
const edit_meal_page_for_meal_dates = document.getElementById("edit-meal-dates-page-for-js")
if (edit_meal_page_for_meal_dates) {
  const meal_id = parseInt(edit_meal_page_for_meal_dates.dataset.mealItemId)
  getMealDatesOfMeal(meal_id)
}

// EDIT MULTISITE: Méthode utilisé dans MealItem#edit pour avoir les informations des dates de programmation déjà existantes (meal_dates)
// pour pouvoir griser les dates déjà existantes.
// les infos des dates sont récupérer en json via info_meal_dates.json
function getMealDatesOfMealMultisite(sec_id, group_id) {
  console.log("getMealDatesOfMealMultisite")
  fetch(`/meal_items/info_meals_dates_multisite.json?sec_id=${sec_id}&g=${group_id}`)
  .then(response => response.json())
  .then((data) => {
    console.log(data)
    const checkboxDatesDeprog = document.querySelector('.checkbox-edit-meal-all-dates-prog')
    if (checkboxDatesDeprog) {
      const deprogramBtn = document.querySelector("#uncheck-date")
      const programBtn = document.querySelector("#check-date")
      checkboxDatesDeprog.addEventListener('click', (event) => {
        if (event.currentTarget.checked) {
          if (deprogramBtn) {deprogramBtn.disabled = false}
          if (programBtn) {programBtn.disabled = false}
        } else {
          if (deprogramBtn) {deprogramBtn.disabled = true}
          if (programBtn) {programBtn.disabled = true}
				}
      })
    }
  });
}

// Appelle de la méthode getMealDatesOfMeal quand on est sur la page MealItem#edit_meal_all_resto (multisite)
const edit_meal_page_multisite_for_meal_dates = document.getElementById("edit-meal-dates-multisite-page-for-js")
if (edit_meal_page_multisite_for_meal_dates) {
  const meal_sec_id = parseInt(edit_meal_page_multisite_for_meal_dates.dataset.mealItemSecId)
  const group_id = parseInt(edit_meal_page_multisite_for_meal_dates.dataset.groupId)
  getMealDatesOfMealMultisite(meal_sec_id, group_id)
}
