const date = document.getElementById('date');

if (date) {
  const form = document.querySelector('form.date-resize');
  date.addEventListener('change', () => {
    form.submit();
  });
}



