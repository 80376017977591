//MealItem : new et edit  pour créer et supprimer les dates de programmation d'un meal (MealDate)

function convertDate(inputFormat) {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat)
  return [pad(d.getDate()), pad(d.getMonth()+1), d.getFullYear()].join('/')
}



const insertDates = (class_for_new_edit) => {
  let allDates = ""
  if (class_for_new_edit === "range_start") {
    allDates = document.getElementById('all_dates')
  } else {
    allDates = document.getElementById('all_dates_edit')
  }
  let i = 0

  document.getElementById(class_for_new_edit).addEventListener('change', (event) => {
    console.log("change")

    // Quand on sélectionne egalement la date de fin, mettre l'intervalle de date sur le côté
    if (document.querySelector(".endRange")) {
      console.log("there is end_date")
      const date = event.currentTarget.value

      const start_date = event.currentTarget._flatpickr.selectedDates[0]
      const end_date = event.currentTarget._flatpickr.selectedDates[1]


      allDates.insertAdjacentHTML('beforeend', `<li>
                                                  <input type="hidden" name="interval_dates${i}" value="${convertDate(start_date)} | ${convertDate(end_date)}">
                                                  <div style="display: flex">
                                                    <div class="text-date"> ${date.replaceAll('-', '/').replace('to', '-')} </div>
                                                    <div class="remove-date-link remove-date-for-js"> <i class="fas fa-times icon-update-remove-meal-dates"></i> </div>
                                                  </div>
                                                </li>`)

      i += 1


      // Quand on crée de nouvelles dates (mais qu'elles sont pas vraiment crée en back), on doit pouvoir les retirer en front via ce Listener
      const new_dates = document.querySelectorAll(".remove-date-for-js")
      new_dates.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          event.currentTarget.parentElement.parentElement.remove();
        })
      })


    }
  })
}


const AddDateRangesForMeals = () => {
  if (document.getElementById("all_dates") ) {
    if (document.getElementById("range_start")) {
      console.log("range_start")
      insertDates("range_start")
    }
  }

  if (document.getElementById("all_dates_edit") ) {
    if (document.getElementById("range_start_edit")) {
      console.log("range_start_edit")
      insertDates("range_start_edit")

    }
  }
}


export { AddDateRangesForMeals }
