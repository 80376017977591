const horizontalBarTransition = () => {
  const bars = document.querySelectorAll(".horizontal-bar-statistic")
  bars.forEach(bar => {
    bar.style.width = `${bar.dataset.tagVote}%`
    if(bar.dataset.tagVote < 10 ) {
      const text = bar.querySelector(".horizontal-bar-statistic-pourcentage")
      text.style.setProperty("transform", "translateX(80%)", "important");
      text.style.color = "black";
    }
  })
}

const showTagDetails = () =>{
  const tagsContent = document.querySelectorAll(".tag-content-cut")
  tagsContent.forEach(tagContent => {
    tagContent.addEventListener("mouseenter", ()=> {
      if(tagContent.innerText.length > 20 ) {
        tagContent.nextElementSibling.classList.remove("hidden")
      }
    })
    tagContent.addEventListener("mouseleave", ()=> {
      tagContent.nextElementSibling.classList.add("hidden")
    })
  })
}

export { horizontalBarTransition, showTagDetails }
