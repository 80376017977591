export function disableBtns() {
	const elements = document.querySelectorAll('[data-disable]');

	// Loop through each element and bind the click event
	elements.forEach(function(element) {
    const form = element.closest('form')

    if(form) {
      form.addEventListener('submit', function(event) {
          // Disable the button or element
          element.removeAttribute("type")

          // Check which attribute is present and set the text accordingly
					element.textContent = element.getAttribute('data-disable');
          element.classList.add("not-clickable")
      });
    }
	});
}
