const sortingActiveInactiveMealItems = () => {
  const inactiveMeals = document.querySelector(".inactive_meals_for_js")
  if (inactiveMeals) {
    console.log('sortingActiveInactiveMealItems')
    const showInactiveMealsBtn = document.querySelectorAll(".show-inactive-btn")
    const toggleBtn = document.querySelectorAll(".fas.fa-toggle-on")
    const btnShowInactifGrey = document.querySelectorAll(".btn-show-inactif")
    showInactiveMealsBtn.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        console.log(btn)
        btn.parentElement.nextElementSibling.classList.toggle("hidden")
        btn.parentElement.firstElementChild.classList.toggle("toggle-email-style-on")
        btn.parentElement.firstElementChild.classList.toggle("toggle-email-style-off")
      })
    })
    btnShowInactifGrey.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        console.log(btn)
        btn.parentElement.nextElementSibling.classList.toggle("hidden")
        btn.parentElement.firstElementChild.classList.toggle("toggle-email-style-on")
        btn.parentElement.firstElementChild.classList.toggle("toggle-email-style-off")
      })
    })
  }
}

export { sortingActiveInactiveMealItems };
