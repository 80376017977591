  import { Controller } from "stimulus";
import InfiniteScroll from 'infinite-scroll';

import {modalEventListener} from "../components/modal_event_listener"
import { initializeHandleCmiPrice } from "../components/_handle_cmi_new_edit"

export default class extends Controller {

  page = 1;
  loading = false;
  infiniteScroll = null;
  scrollableTbody = null;
  container = null;
  tbody = null;
  selectedMasterCategories = [];
  selectedCategories = [];
  fetchTimeout = null;

  static targets = ["masterFilter", "categoryFilter", "table"];

  connect() {
    this.initialize();
  }

  initialize() {
    document.addEventListener('turbo:load', () => {
      this.initializeMasterFilterChange();
      this.initializeCategFilterChange();
      this.initializeSearchBar();
      this.initializeInfiniteScroll();
      this.initializeProductStatus();
      this.initializeValidationOnPrice();
      this.initializePriceInputHandler();
      this.initializeDropdownInputWithContainers();
      this.initializePriceFadeOnBlurOrKeyUp();
      this.initializeModalEventListener();
      const selectedMasterCategories = document.querySelectorAll('.modify-prices-filter-item[data-master_category_id]:checked');
      if (selectedMasterCategories) {
        this.filterCategoriesByMaster(this.getFilteredAttributeValues(selectedMasterCategories, 'data-master_category_id'));
      }
    });
  }

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------- Infinite Scroll ------------------------------------------------------
  // --------------------------------------------------------------------------------------------------------

  initializeInfiniteScroll() {
    this.scrollableTbody = document.querySelector('.scrollable-tbody');
    this.container = this.scrollableTbody.querySelector('.modify-product-form-container');
    this.tbody = this.scrollableTbody.querySelector('tbody');

    this.infiniteScroll = new InfiniteScroll(this.scrollableTbody, {
      path: () => `/groups/${this.container.dataset.groupe_id}/sous_groupes/${this.container.dataset.sousGroupe_id}/restaurants/${this.container.dataset.restaurant_id}/meal_items/modify_all_prices.js?page=${this.page + 1}&locale=${this.container.dataset.locale}`,
      append: 'tbody',
      history: false,
    });

    this.infiniteScroll.option({ loadOnScroll: false });
    this.scrollableTbody.onscrollend = (event) => {
      this.loadMoreContent();
    };
  }

  // --------------------------------- Infinite Scroll ( Fonctions Annexe ) ---------------------------------

  loadMoreContent() {
    if (this.loading) return;
    this.loading = true;
    this.addSkeletonRows(20);

    setTimeout(() => {
      const queryParams = new URLSearchParams({
        page: this.page+1,
        master_categories: this.selectedMasterCategories.join(","),
        categories: this.selectedCategories.join(","),
        locale: this.container.dataset.locale
      }).toString();

      const requestData = {
        url: `/groups/${this.container.dataset.groupe_id}/sous_groupes/${this.container.dataset.sousGroupe_id}/restaurants/${this.container.dataset.restaurant_id}/meal_items/modify_all_prices.js?${queryParams}`,
        type: 'GET',
        dataType: 'json',
        success: (response) => {
          console.log("produits chargés avec succès");
          this.loading = false;
          this.tbody.querySelectorAll('tr').forEach(row => {
            if (row.querySelector('td.skeleton')) {
                row.remove();
            }
          });

          this.tbody.insertAdjacentHTML('beforeend', response.html);

          // this.tbody.querySelectorAll('tr:not(.js-event-on-new-row)').forEach(newRow => {
          //   newRow.classList.add('js-event-on-new-row');
          // });

          // this.tbody.querySelectorAll('.js-event-on-new-row').forEach(row => {
          //   row.classList.remove('js-event-on-new-row');
          // });

          this.setupHandlers();
          this.infiniteScroll.option({ loadOnScroll: false });
          this.page++;
        },
        error: () => {
          this.loading = false;
          console.error("Une erreur est survenue lors du chargement des produits:", error);
        },
      };
    $.ajax(requestData);
  } , 200);
  }

  addSkeletonRows(numRows) {
    const tbody = document.querySelector('.scrollable-tbody tbody');
    let skeletonHTML = '';
    const animationDuration = 0.5;
    const delayIncrement = animationDuration / numRows;

    for (let i = 0; i < numRows; i++) {
        const animationDelay = delayIncrement * i;
        skeletonHTML += `
            <tr>
                <td class="skeleton"><div style="animation-delay: ${animationDelay}s;"></div></td>
                <td class="skeleton"><div style="animation-delay: ${animationDelay}s;"></div></td>
                <td class="skeleton"><div style="animation-delay: ${animationDelay}s;"></div></td>
                <td class="skeleton"><div style="animation-delay: ${animationDelay}s;"></div></td>
                <td class="skeleton"><div style="animation-delay: ${animationDelay}s;"></div></td>
            </tr>`;
    }

    tbody.insertAdjacentHTML('beforeend', skeletonHTML);
  }

  // --------------------------------------------------------------------------------------------------------
  // --------------------------------- Filtre ( MasterCategorie / Categorie / Barre de recherche ) ----------
  // --------------------------------------------------------------------------------------------------------

  initializeMasterFilterChange() {
    const masterFilters = document.querySelectorAll('.modify-prices-filter-item[data-master_category_id]');
    const categoryFilters = document.querySelectorAll('.modify-prices-category-filter-item[data-category_id]');

    masterFilters.forEach(masterFilter => {
      masterFilter.addEventListener('change', () => {
        if(this.fetchTimeout) {
          clearTimeout(this.fetchTimeout);
        }
        this.selectedCategories = [];
        categoryFilters.forEach(categoryFilter => categoryFilter.checked = false);
        this.filterCategoriesByMaster(this.getFilteredAttributeValues(masterFilters, 'data-master_category_id'));
        this.updateCategoryFilterDisplay();
        this.updateMasterFilterDisplay();
        this.fetchTimeout = setTimeout(() => {
          this.fetchFilteredMealItems();
        }, 100);
      });
    });

    this.selectAllMasterCategories();

  }

  initializeCategFilterChange() {
    const categoryFilters = document.querySelectorAll('.modify-prices-category-filter-item[data-category_id]');
    categoryFilters.forEach(filter => {
      filter.addEventListener('change', () => {
        if(this.fetchTimeout) {
          clearTimeout(this.fetchTimeout);
        }
        this.updateCategoryFilterDisplay();
        this.fetchTimeout = setTimeout(() => {
          this.fetchFilteredMealItems();
        }, 100);
      });
    });

    this.selectAllCategories();
  }

  initializeSearchBar() {
    $('.modify-prices-search-bar-input').on('keyup', this.debounce((event) => {
      const query = $(event.target).val().toLowerCase();
      const container = document.querySelector('.modify-product-form-container');

      // Si l'utilisateur ecrit quelque chose dans la barre de recherche, on selectionne toutes les master categories et toute les categories
      if (query.length > 0) {
        $('.modify-prices-filter-item').prop('checked', true);
        $('.modify-prices-category-filter-item').prop('checked', true);
        this.updateMasterFilterDisplay();
        this.updateCategoryFilterDisplay();
      } else {
        // Sinon on deselectionne toutes les master categories et toute les categories
        $('.modify-prices-filter-item').prop('checked', false);
        $('.modify-prices-category-filter-item').prop('checked', false);
        this.updateMasterFilterDisplay();
        this.updateCategoryFilterDisplay();
      }


      this.page = 1;
      const requestData = {
        url: `/groups/${container.dataset.groupe_id}/sous_groupes/${container.dataset.sousGroupe_id}/restaurants/${container.dataset.restaurant_id}/meal_items/modify_all_prices.js`,
        type: 'GET',
        data: { query: query, page: this.page },
        dataType: 'json',
        success: this.handleAjaxResponse.bind(this),
        error: () => {}
      };

      $.ajax(requestData);
    }, 300));
  }

  // --------------------------------- Filtre ( Fonctions annexe ) -------------------------------------------

  fetchFilteredMealItems() {
    this.page = 1;
    this.loading = true;
    const container = document.querySelector('.modify-product-form-container');
    const masterFilters = document.querySelectorAll('.modify-prices-filter-item[data-master_category_id]');
    const categoryFilters = document.querySelectorAll('.modify-prices-category-filter-item[data-category_id]');
    const selectedMasterCategories = this.getFilteredAttributeValues(masterFilters, 'data-master_category_id');
    const allMasterCategories = this.getAttributeValues(masterFilters, 'data-master_category_id');
    const uncheckedMasterCategories = allMasterCategories.filter(id => !selectedMasterCategories.includes(id));

    uncheckedMasterCategories.forEach(masterCategoryId => {
      this.removeItemsByMasterCategory(masterCategoryId);
    });

    const selectedCategories = this.getFilteredAttributeValues(categoryFilters, 'data-category_id');

    const url_filters = `/groups/${container.dataset.groupe_id}/sous_groupes/${container.dataset.sousGroupe_id}/restaurants/${container.dataset.restaurant_id}/meal_items/set_filter_price_references`;


    fetch (url_filters, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({
        'selectedCategories': selectedCategories,
        'selectedMasterCategories': selectedMasterCategories
      })
    })

    this.selectedMasterCategories = selectedMasterCategories;
    this.selectedCategories = selectedCategories;

    const queryParams = new URLSearchParams({
      page: this.page,
      locale: container.dataset.locale
    });
    const url = `/groups/${container.dataset.groupe_id}/sous_groupes/${container.dataset.sousGroupe_id}/restaurants/${container.dataset.restaurant_id}/meal_items/modify_all_prices.js?${queryParams.toString()}`;

    fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      this.updateTableWithResponse(data);
      this.loading = false;
      clearTimeout(this.fetchTimeout);
    })
  }

  selectAllMasterCategories() {
    const masterFilters = document.querySelectorAll('.modify-prices-filter-item[data-master_category_id]');
    const selectAllMgButton = document.getElementById('select-all-master-category');
    if (selectAllMgButton) {
      selectAllMgButton.addEventListener('change', () => {
        const checked = selectAllMgButton.checked;
        masterFilters.forEach(filter => filter.checked = checked);
        this.filterCategoriesByMaster(this.getFilteredAttributeValues(masterFilters, 'data-master_category_id'));
        this.updateMasterFilterDisplay();
        this.fetchFilteredMealItems();
      });
    }
  }

  selectAllCategories() {
    const categoryFilters = document.querySelectorAll('.modify-prices-category-filter-item[data-category_id]');
    const selectAllCategButton = document.getElementById('select-all-category');
		if (selectAllCategButton) {
			selectAllCategButton.addEventListener('change', () => {
				const checked = selectAllCategButton.checked;
				categoryFilters.forEach(filter => filter.checked = checked);
				this.updateCategoryFilterDisplay();
				this.fetchFilteredMealItems();
			});
		}

		
  }

  removeItemsByMasterCategory(masterCategoryId) {
    const rows = document.querySelectorAll(`[data-master-category-id="${masterCategoryId}"]`);
      rows.forEach(row => row.remove());
  }

  updateTableWithResponse(response) {
      this.clearTableRows();
      this.addSkeletonRows(20);
      setTimeout(() => {
        this.tbody.querySelectorAll('tr').forEach(row => {
          if (row.querySelector('td.skeleton')) {
              row.remove();
          }
        });
      }, 500);
      this.tbody.insertAdjacentHTML('beforeend', response.html);
      this.setupHandlers();

      this.infiniteScroll.option({ loadOnScroll: false });
      this.page++;
  }

  setupHandlers() {
      this.initializePriceInputHandler();
      this.initializeProductStatus();
      this.initializeValidationOnPrice();
      this.initializeDropdownInputWithContainers();
      this.initializeModalEventListener();
  }

  initializeModalEventListener() {
		const modale = document.getElementById("modalCreateCmiReferentielPrix");
		const rows = this.tbody.querySelectorAll('.js-event-on-new-row');

		if (modale && rows.length > 0) {
			rows.forEach(row => {
				modalEventListener(".new-cmi-referentiel-prix", "#modalCreateCmiReferentielPrix", [
					() => initializeHandleCmiPrice(modale),
				], row)

				row.classList.remove('js-event-on-new-row');
			});
		}
  }

  getFilteredAttributeValues(targets, attributeName) {
      return [...targets].filter(target => target.checked).map(target => target.getAttribute(attributeName));
  }

  getAttributeValues(targets, attributeName) {
      return [...targets].map(target => target.getAttribute(attributeName));
  }

  truncateText(text, maxLength = 24) {
    return text.length > maxLength ? text.substring(0, maxLength - 3) + '...' : text;
  }

  updateMasterFilterDisplay() {
    const checkedFilters = document.querySelectorAll('.modify-prices-filter-item[data-master_category_id]');
    const masterCategoryButton = document.querySelector('.modify-prices-filter-dpd');
    const categoryButton = document.querySelector('.modify-prices-category-filter-dpd');

    let defaultButtonText;

    if (masterCategoryButton) {
      defaultButtonText = masterCategoryButton.dataset.defaultText;
    } else {
      defaultButtonText = categoryButton.dataset.defaultText;
    }

    let buttonText;
    if (checkedFilters.length === 0 || checkedFilters.length > 2) {
      buttonText = `${defaultButtonText}...`;
    } else {
      buttonText = Array.from(checkedFilters).map(input =>
        input.getAttribute('data-master_category_name')
      ).join(', ') || defaultButtonText;
    }

    if (masterCategoryButton) {
      masterCategoryButton.innerText = buttonText;
    } else {
      categoryButton.innerText = buttonText;
    }
  }


  updateCategoryFilterDisplay() {
    const checkedFilters = document.querySelectorAll('.modify-prices-category-filter-item[data-category_id]:checked');
    const categoryButton = document.querySelector('.modify-prices-category-filter-dpd');
    const defaultButtonText = categoryButton.dataset.defaultText;

    let buttonText;
    if (checkedFilters.length > 2) {
      buttonText = `${defaultButtonText}...`;
    } else {
      buttonText = Array.from(checkedFilters).map(input =>
        input.getAttribute('data-category_name')
      ).join(', ') || defaultButtonText;
    }

    categoryButton.innerText = buttonText;
  }

  handleMasterFilterChange() {
    const masterFilters = document.querySelectorAll('.modify-prices-filter-item[data-master_category_id]');
    const selectedMasterCategories = this.getFilteredAttributeValues(masterFilters, 'data-master_category_id');
    this.filterCategoriesByMaster(selectedMasterCategories);
  }

  filterCategoriesByMaster(selectedMasterCategories) {
    $('.modify-prices-category-filter-item').closest('li').hide();

    selectedMasterCategories.forEach(masterCategoryId => {
      $(`.modify-prices-category-filter-item[data-master_category_id="${masterCategoryId}"]`).each(function() {
        $(this).closest('li').show();
      });
    });
  }

  filterRows(selector, filterCondition) {
    let attributeName = selector.match(/\[data-.*?\]/)[0];
    attributeName = attributeName.slice(1, -1);

    $('tbody tr').each(function() {
        const element = $(this).find('[' + attributeName + ']');
        const valueToCheck = element.attr(attributeName);

        if (element.length && filterCondition(valueToCheck)) {
            $(this).show();
        } else {
            $(this).hide();
        }
    });
  }

  handleAjaxResponse(response) {
    this.clearTableRows();

    this.tbody.insertAdjacentHTML('beforeend', response.html);
    this.setupHandlers();
    this.infiniteScroll.option({ loadOnScroll: false });
    this.page++;

  }

  clearTableRows() {
    while (this.tbody.firstChild) {
      this.tbody.removeChild(this.tbody.firstChild);
    }
  }

  debounce(func, wait) {
    let timeout;
    return function (...args) {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  // -------------------------------------------------------------------------------------------------------------
  // ---------------------------- Price / Status / Validation / DropdownContainer --------------------------------
  // -------------------------------------------------------------------------------------------------------------

  initializePriceFadeOnBlurOrKeyUp() {
    let lastUpdateTime = 0;
    $('.price-input, .price-input-modal').off('blur').on('blur', (event) => {
      if (Date.now() - lastUpdateTime > 300) {
        this.updatePrice(event);
        lastUpdateTime = Date.now();
      }
    });

    $('.price-input, .price-input-modal').off('keyup').on('keyup', (event) => {
      if (event.key === 'Enter' && Date.now() - lastUpdateTime > 300) {
        this.updatePrice(event);
        lastUpdateTime = Date.now();
      }
    });
  }

  initializePriceInputHandler() {
    let lastUpdateTime = 0;

    $('.price-input.js-to-remove-price-event-listener, .js-to-remove-price-event-listener').off('blur').on('blur', (event) => {
      if (Date.now() - lastUpdateTime > 300) {
        this.updatePrice(event);
        lastUpdateTime = Date.now();
        event.currentTarget.removeEventListener('blur');
        event.currentTarget.classList.remove('js-to-remove-price-event-listener');
      }
      $(event.currentTarget).removeClass('js-to-remove-price-event-listener');
    });

    $('.price-input.js-to-remove-price-event-listener, .price-input-modal.js-to-remove-price-event-listener').off('keyup').on('keyup', (event) => {
      if (event.key === 'Enter' && Date.now() - lastUpdateTime > 300) {
        this.updatePrice(event);
        lastUpdateTime = Date.now();
        event.currentTarget.removeEventListener('keyup');
        event.currentTarget.classList.remove('js-to-remove-price-event-listener');
      }
      $(event.currentTarget).removeClass('js-to-remove-price-event-listener');
    });
  }

  initializeProductStatus() {
    document.querySelectorAll('.toggle-actif-checkbox.js-to-remove-price-event-listener').forEach((checkbox) => {
      checkbox.addEventListener('change', (event) => {
        const isActive = event.target.checked;
        const productId = event.target.dataset.id;
        const restaurantId = event.target.dataset.restaurantId;
        const groupId = event.target.dataset.groupId;
        const sousGroupeId = event.target.dataset.sousGroupeId;
        const url = this.buildURL(groupId, sousGroupeId, restaurantId);
        this.performAjaxRequest(url, productId, isActive, restaurantId);
      });
      checkbox.classList.remove('js-to-remove-price-event-listener');
    });
  }

  initializeValidationOnPrice() {
    document.querySelectorAll('.price-input.js-to-remove-price-event-listener, .price-input-modal.js-to-remove-price-event-listener').forEach((input) => {
      const inputValidationHandler = function(event) {
        const regex = /^[0-9]+([,.][0-9]{1,2})?$/;
        const inputElement = event.target;
        const inputValue = inputElement.value;

        if (!regex.test(inputValue)) {
          inputElement.value = inputValue.slice(0, -1);
        }
      };

      input.removeEventListener('input', inputValidationHandler);
      input.addEventListener('input', inputValidationHandler);
      input.classList.remove('js-to-remove-price-event-listener');
    });
  }

  initializeDropdownInputWithContainers() {
    document.querySelectorAll('.expand-icon.js-to-remove-price-event-listener').forEach((icon) => {
      icon.addEventListener('click', this.toggleModalDisplay.bind(this, $(icon)));
      icon.classList.remove('js-to-remove-price-event-listener');
    });
  }

  // --------------------------------- Javascript on Rows ( Fonctions Annexe ) ---------------------------------


  toggleModalDisplay($icon) {
    const productId = $icon.data('product-id');
    const $modalElement = $(`#modal-${productId}`);
    $modalElement.toggle();
  }

  buildURL(groupId, sousGroupeId, restaurantId) {
    return `/groups/${groupId}/sous_groupes/${sousGroupeId}/restaurants/${restaurantId}/meal_items/toggle_actif_status_update`;
  }

  performAjaxRequest(url, productId, isActive, restaurantId) {
    $.ajax({
      type: 'PUT',
      url: url,
      data: {
        products: {
          [productId]: {
            id: productId,
            actif: isActive
          }
        },
        restaurant_id: restaurantId
      },
      success: function(response) {
        Swal.fire({
          icon: 'success',
          title: successTitle,
          text: successText,
          timer: 1000,
          showConfirmButton: false,
          timerProgressBar: true
        });
      },
      error: function(err) {
        Swal.fire({
          icon: 'error',
          title: errorTitle,
          text: errorText,
          timer: 1000,
          showConfirmButton: false,
          timerProgressBar: true
        });
      }
    });
  }

  updatePrice(event) {
    const inputElement = event.target;
    const inputId = inputElement.id;
    const newPrice = inputElement.value;
    const productId = inputElement.getAttribute("data-product-id");
    const restaurantId = inputElement.getAttribute("data-restaurant-id");
    const groupId = inputElement.getAttribute("data-group-id");
    const sousGroupeId = inputElement.getAttribute("data-sous-groupe-id");
    const url = this.buildURLForPriceUpdate(groupId, sousGroupeId, restaurantId);
    this.performAjaxRequestForPriceUpdate(url, productId, newPrice, restaurantId, inputId);
  }

  performAjaxRequestForPriceUpdate(url, productId, newPrice, restaurantId, inputId, group_id, sous_groupe_id, index) {
    const productData = {
      id: productId,
      price: newPrice
    };

    if ($(`.price-input-modal.price-input-${productId}`).length > 0) {
      productData.containers = {};

      $(`.price-input-modal.price-input-${productId}`).each(function(containerIndex) {
        const containerPrice = $(this).val();
        const containerId = $(this).siblings('input[type="hidden"]').val();

        productData.containers[containerIndex] = {
          price: containerPrice,
          container_id: containerId
        };
      });
    }

    $.ajax({
      type: 'PUT',
      url: url,
      data: {
        meal_item_id: productId,
        products: {
          [productId]: productData
        },
        restaurant_id: restaurantId,
        group_id: group_id,
        sous_groupe_id: sous_groupe_id,
        index: index
      },
      success: (response) => {
        console.log("prix mis à jour avec succès");
        const jqInputElement = $(`#${inputId}`);
        const checkMarkElement = $('<i class="fa-solid fa-check"></i>');

        if (jqInputElement.hasClass('price-input-modal')) {
          jqInputElement.after(checkMarkElement);
          checkMarkElement.css({
            'position': 'absolute',
            'right': '9px',
            'top': '20%',
            'color': '#1dc9b7',
            'font-size': '15px'
          });
        } else {
          if (jqInputElement.hasClass('price-input')) {
            jqInputElement.after(checkMarkElement);
            checkMarkElement.css({
              'position': 'absolute',
              'right': '115px',
              'top': '20%',
              'color': '#1dc9b7',
              'font-size': '15px'
            });
          }
        }

        checkMarkElement.hide().fadeIn(400);

        setTimeout(() => {
          checkMarkElement.fadeOut(400, () => {
            checkMarkElement.remove();
          });
        }, 1000);
      },

      error: (err) => {
      }
    });
  }

  buildURLForPriceUpdate(groupId, sousGroupeId, restaurantId) {
    return `/groups/${groupId}/sous_groupes/${sousGroupeId}/restaurants/${restaurantId}/meal_items/update_single_price`;
  }

}
