import 'select2';

const manageFonts = (MenuOrQuestionnaire) => {

  ////////// Select init for custom fonts //////////

  // On récupère toutes les basic fonts
  const data_all_fonts = document.querySelector('#datas-select2-fonts').dataset.allFontsForStyle
  const allFonts = JSON.parse(data_all_fonts)
  const allFontsWithId = allFonts.map(font => ({ id: font, text: font }));
  let googleFontsResults = []

  // On créé la liste select2
  $('.list-all-fonts').select2({
    data: allFontsWithId,

    // On applique la police propre à chaque élément de la liste
    templateSelection: function (data) {
      if (data.id) {
        return $('<span>').text(data.text).css('font-family', data.text);
      }
      return data.text;
    },
    templateResult: function (data) {
      if (!data.id) { return data.text; }
      var $result = $('<span>').text(data.text).css('font-family', data.text);
      return $result;
    },

    // Call Google fonts API lorsqu'on a une query
    ajax: {
      url: 'https://www.googleapis.com/webfonts/v1/webfonts',
      dataType: 'json',
      data: {
        key: process.env.GOOGLE_FONTS_API_KEY
      },

      processResults: function (data, params) {
        const query = params.term

        // On filtre les polices de l'API avec la query
        const googleFonts = data.items.filter(item => item.family.toLowerCase().startsWith(query));
        googleFontsResults = googleFonts.map((item) => {
          return { id: item.family, text: item.family, variants: item.variants };
        });

        // S'il n'y a pas de query, on affiche les listes des polices enregistrées en BDD sinon on affiche les résultats API
        const results = query ? googleFontsResults : allFontsWithId;

        // Si la query fait 2 lettres, on affiche le style de la police dans la liste de recherche
        if (query && query.length >= 2) {
          results.forEach((font) => {
            const fontUrl = `https://fonts.googleapis.com/css2?family=${font.text.split(' ').join('+')}`;
            $('head').append(`<link href="${fontUrl}&display=swap" rel="stylesheet">`);
          })
        }

        return { results: results };
      }
    },
  })

  // Placeholder des listes select2
  const placeholder = document.querySelector('#datas-select2-fonts').dataset.select2Placeholder
  $('.list-all-fonts').one('select2:open', function(e) {
    $('input.select2-search__field').prop('placeholder', placeholder);
  });

  ////////// Simulation du changement de font dans l'Iframe //////////
  let fontInputIds = [];
  if (MenuOrQuestionnaire == "questionnaire") {
    fontInputIds = [
      "police_flags_display_in_list",
      "police_flags_display_in_list_begin",
      "police_flags_display_in_list_ns",
      "police_text1_begin",
      "police_text2_begin",
      "police_text3_begin",
      "col-title-multiple-question-js",
      "police_title_multiple_question_survey",
      "police_alert_newsurvey",
      "police_satisfaction_tag_survey",
      "police_contact_input_edit",
      "col-contact-input-js",
      "police_signature_begin",
      "police_question_survey",
      "police_question_note-meal",
      "police_question_new-meal",
      "police_not_concerned_ns",
      "police_not_concerned_nm",
      "police_title_newmeal",
      "police_question_newmeal",
      "police_btn_category",
      "police_btn_ss_category",
      "police_dropdown_select_meal",
      "police_title_notemeal",
      "police_title_survey",
      "police_msg_reward_edit",
      "police_reward_edit",
      "police_details_contact_edit",
      "police_cgu_edit",
      "police_text4_newborne",
      "police_text5_newborne",
      'select-all1',
      'select-all2',
      'select-all3',
      'select-all4',
      'select-all5',
      'select-all6',
      'select-all7'
    ]
  } else if (MenuOrQuestionnaire == "menu") {
    fontInputIds = [
      'police_flags_display_in_list_menu',
      'police_flags_display_in_list_menu_mm',
      'police_flags_display_in_list_menu_dmn',
      "police_mascat_card_mm",
      "police_tabs_all",
      "police_categ",
      "police_pdct",
      "police_descr_categ",
      "police_pdct_descr",
      "police_container",
      "police_price",
      "police_sub_descr",
      "police_back_dmeal",
      "police_pdct_dmeal",
      "police_pdct_descr_dmeal",
      "police_title_allergens_dmeal",
      "police_allergens_all_dmeal",
      "police_sub_descr_dmeal",
      "police_price_dmeal",
      "police_container_dmeal",
      "select-all",
      "select-all2",
      "select-all3"
    ]
  };

  fontInputIds.forEach((inputId) => {
    const $fontInput = $(`#${inputId}`)

    if ($fontInput) {
      function simulateFontChange(textToSelect, menuPage, fontSelector) {

        // On itère sur tous les éléments font du form pour y ajouter un event en change
        $fontInput.on('change', event => {
          const previewDiv = document.querySelector('iframe#preview');
          if (previewDiv.contentDocument.getElementById(menuPage) && document.getElementById(fontSelector) === event.currentTarget) {
            const selectedFont = event.currentTarget.value

            // Si la police sélectionnée .'est pas déjà dans BASIC::Fonts ou CustomFonts
            if (!allFonts.find(font => font === selectedFont)) {

              // On récupère les variants de la police sélectionnée pour généré le lien google fonts
              const selectedFontVariants = googleFontsResults.find(font => font.selected === true).variants;
              const googleFontLink = `https://fonts.googleapis.com/css2?family=${selectedFont.split(' ').join('+')}:wght@${selectedFontVariants.includes('100') ? '100;' : ''}400${selectedFontVariants.includes('700') ? ';700' : ''}&display=swap`;
              const fontLinkTag = `<link rel="stylesheet" href="${googleFontLink}" type="text/css" />`;

              // Charger la feuille de style de la police Google sélectionnée dans le head de l'app
              $('head').append(`<link href="${googleFontLink}&display=swap" rel="stylesheet">`);

              // Charger la feuille de style de la police Google sélectionnée dans le head de l'iframe
              const iframeHead = previewDiv.contentDocument.getElementsByTagName('head')[0];
              iframeHead.insertAdjacentHTML('beforeend', fontLinkTag);
            }

            // On applique la valeur de la font de l'input sur tous les éléments qui sont sur la page en question de l'iframe
            previewDiv.contentDocument.querySelectorAll(textToSelect).forEach((text) => {
              text.style.fontFamily = event.currentTarget.value
            })

            // Pour les catégories "Les textes", on modifie tous les inputs "police" du même formulaire
            const $selectAllInput = $fontInput.closest('form').find('[id^="select-all"]')

            if ($selectAllInput.is($(event.currentTarget))) {
              const $allFontsInputsFromOneForm = $fontInput.closest('form').find('.list-all-fonts').not('.flag-font');

              $allFontsInputsFromOneForm.each(function() {
                const newOption = new Option(event.currentTarget.value, event.currentTarget.value, true, true);
                $(this).append(newOption).val(event.currentTarget.value).trigger('change.select2')
              })
            }
          }
        })
      }

      if (MenuOrQuestionnaire == "questionnaire") {
        // Start
        simulateFontChange(".text-flags", "page-start-questionnaire-for-js", "police_flags_display_in_list")
        simulateFontChange(".btn-validate-for-js", "page-start-questionnaire-for-js", "select-all7")

        // Begin
        simulateFontChange(".text-1-ton-inspect-js", "page-begin-for-custom-for-js", "police_text1_begin")
        simulateFontChange(".text-2-ton-inspect-js", "page-begin-for-custom-for-js", "police_text2_begin")
        simulateFontChange(".text-3-ton-inspect-js", "page-begin-for-custom-for-js", "police_text3_begin")
        simulateFontChange(".text-flags", "page-begin-for-custom-for-js", "police_flags_display_in_list_begin")
        simulateFontChange(".text-signature-ton-inspect-js", "page-begin-for-custom-for-js", "police_signature_begin")
        simulateFontChange(".text-to-modify-js", "page-begin-for-custom-for-js", "select-all1")

        // New survey v2
        simulateFontChange(".text-flags", "page-new-survey-v2-for-custom-for-js", "police_flags_display_in_list_ns")
        simulateFontChange(".question-to-inspect-survey-js", "page-new-survey-v2-for-custom-for-js", "police_question_survey")
        simulateFontChange(".question-to-inspect-note-meal-js", "page-note-meal-for-custom-for-js", "police_question_note-meal")
        simulateFontChange(".question-to-inspect-new-meal-js", "page-new-meal-for-custom-for-js", "police_question_new-meal")
        simulateFontChange(".new-survey-text-not-concerned-js", "page-new-survey-v2-for-custom-for-js", "police_not_concerned_ns")
        simulateFontChange(".col-title-multiple-question-js", "page-new-survey-v2-for-custom-for-js", "police_title_multiple_question_survey")
        simulateFontChange(".col-satisfaction-tag-js", "page-new-survey-v2-for-custom-for-js", "police_satisfaction_tag_survey")
        simulateFontChange(".title-gq-new-survey-to-inspect-js", "page-new-survey-v2-for-custom-for-js", "police_title_survey")
        simulateFontChange(".text-to-modify-js", "page-new-survey-v2-for-custom-for-js", "select-all4")
        simulateFontChange(".swal2-html-container", "page-new-survey-v2-for-custom-for-js", "police_alert_newsurvey")
        simulateFontChange(".swal2-title", "page-new-survey-v2-for-custom-for-js", "police_alert_newsurvey")

        // Note meal
        simulateFontChange(".text-not-concerned-js", "page-note-meal-for-custom-for-js", "police_not_concerned_nm")
        simulateFontChange(".title-gq-note-meal-to-inspect-js", "page-note-meal-for-custom-for-js", "police_title_notemeal")
        simulateFontChange(".text-to-modify-js", "page-note-meal-for-custom-for-js", "select-all3")
        simulateFontChange(".col-satisfaction-tag-js", "page-note-meal-for-custom-for-js", "police_satisfaction_tag_survey")
        simulateFontChange(".col-title-multiple-question-js", "page-note-meal-for-custom-for-js", "police_title_multiple_question_survey")

        // New meal
        simulateFontChange(".title-gq-to-inspect-js", "page-new-meal-for-custom-for-js", "police_title_newmeal")
        simulateFontChange(".question-to-inspect-js", "page-new-meal-for-custom-for-js", "police_question_newmeal")
        simulateFontChange(".tab-master-categories", "page-new-meal-for-custom-for-js", "police_btn_category")
        simulateFontChange(".tab-other-category", "page-new-meal-for-custom-for-js", "police_btn_category")
        simulateFontChange(".tab-ss-category", "page-new-meal-for-custom-for-js", "police_btn_ss_category")
        simulateFontChange(".text-for-select-meal", "page-new-meal-for-custom-for-js", "police_dropdown_select_meal")
        simulateFontChange(".text-to-modify-js", "page-new-meal-for-custom-for-js", "select-all2")

        // Enquete edit
        simulateFontChange(".msg-reward-to-inspect-js", "page-edit-for-custom-for-js", "police_msg_reward_edit")
        simulateFontChange(".reward-to-inspect-js", "page-edit-for-custom-for-js", "police_reward_edit")
        simulateFontChange(".msg-leave-details-to-inspect-js", "page-edit-for-custom-for-js", "police_details_contact_edit")
        simulateFontChange(".cgu-to-inspect-js", "page-edit-for-custom-for-js", "police_cgu_edit")
        simulateFontChange(".text-to-modify-js", "page-edit-for-custom-for-js", "select-all5")
        simulateFontChange(".swal2-html-container", "page-edit-for-custom-for-js", "police_alert_newsurvey")
        simulateFontChange(".col-contact-input-js", "page-edit-for-custom-for-js", "police_contact_input_edit")

        // New borne
        simulateFontChange(".text-4-ton-inspect-js", "page-new-borne-for-custom-for-js", "police_text4_newborne")
        simulateFontChange(".text-5-ton-inspect-js", "page-new-borne-for-custom-for-js", "police_text5_newborne")
        simulateFontChange(".text-6-ton-inspect-js", "page-new-borne-for-custom-for-js", "police_text6_newborne")
        simulateFontChange(".text-to-modify-js", "page-new-borne-for-custom-for-js", "select-all6")

      } else if (MenuOrQuestionnaire == "menu") {
        // Start Page
        simulateFontChange(".text-flags", "page-start-menu-for-js", "police_flags_display_in_list_menu")
        simulateFontChange(".btn-link-page-menu", "page-start-menu-for-js", "select-all3")

        // My menu
        simulateFontChange(".text-flags", "page-my-menu-for-js", "police_flags_display_in_list_menu_mm")
        simulateFontChange(".btn-mastercateg-menu", "page-my-menu-for-js", "police_mascat_card_mm")

        // Menu new
        simulateFontChange(".text-flags", "id-for-custom-menu-js", "police_flags_display_in_list_menu_dmn")
        simulateFontChange(".cat-tabs-js", "id-for-custom-menu-js", "police_tabs_all")
        simulateFontChange(".categorie-name-js", "id-for-custom-menu-js", "police_categ")
        simulateFontChange(".name-pdct-menu-new3-js", "id-for-custom-menu-js", "police_pdct")
        simulateFontChange(".categ-description-menu-js", "id-for-custom-menu-js", "police_descr_categ")
        simulateFontChange(".new-desc-menu-js", "id-for-custom-menu-js", "police_pdct_descr")
        simulateFontChange(".content-menu-js", "id-for-custom-menu-js", "police_container")
        simulateFontChange(".individual-price-js", "id-for-custom-menu-js", "police_price")
        simulateFontChange(".price-large-pdct", "id-for-custom-menu-js", "police_sub_descr")
        simulateFontChange(".text-to-modify-js", "id-for-custom-menu-js", "select-all")

        // Meal menu
        simulateFontChange(".back-menu2-js", "page-detail-meal-for-js", "police_back_dmeal")
        simulateFontChange(".name-pdct-menu-new2-js", "page-detail-meal-for-js", "police_pdct_dmeal")
        simulateFontChange(".new-desc-menu-detail-js", "page-detail-meal-for-js", "police_pdct_descr_dmeal")
        simulateFontChange(".allergen-title-js", "page-detail-meal-for-js", "police_title_allergens_dmeal")
        simulateFontChange(".allergen-style-new-js", "page-detail-meal-for-js", "police_allergens_all_dmeal")
        simulateFontChange(".price-large-pdct-detail", "page-detail-meal-for-js", "police_sub_descr_dmeal")
        simulateFontChange(".price-style-dmeal-js", "page-detail-meal-for-js", "police_price_dmeal")
        simulateFontChange(".container-style-dmeal-js", "page-detail-meal-for-js", "police_container_dmeal")
        simulateFontChange(".text-to-modify-js", "page-detail-meal-for-js", "select-all2")

      }
    }
  })
}

export { manageFonts };
