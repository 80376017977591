
export function btnColorBootstrapOverride() {
	// pour résoudre le problème de bouttons blancs quand actif:
	$(document).ready(function() {
		$(".btn").each(function() {
				var originalColor = $(this).css("color");
				var originalBackgroundColor = $(this).css("background-color");
				var originalBorderColor = $(this).css("border-color");

				$(this).on('mousedown', function() {
						$(this).css({
								"color": originalColor,
								"background-color": originalBackgroundColor,
								"border-color": originalBorderColor
						});
				});

				$(this).on('mouseup', function() {
						$(this).css({
								"color": "",
								"background-color": "",
								"border-color": ""
						});
				});
		});
	});
}