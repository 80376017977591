import { disableBtn } from "../_toggle_btn";
import { enableBtn } from "../_toggle_btn";
import { createListSelectOptions } from "./_create_list_select_options";

const setNestedFilters = () => {
    if (document.getElementById('filters-for-select-download-report') ){

        var url = window.location.pathname.split('/').pop();
        if (url === 'download_report') {
            var controllerAction = 'set_filters_for_download_report';
        } else {
            var controllerAction = 'set_filters_for_download_group_list';
        }

        var filtersForSelect = $('#filters-for-select-download-report');
        var optionsBox = $('#options-for-select-download-report');
        var submitButton = $('#btn-download-report');

        filtersForSelect.on('change', function(){

            var filterSelected = $(this).val();
            optionsBox.addClass('hidden');
            disableBtn(submitButton);

            if (filterSelected === 'Toutes enquêtes confondues' || filterSelected === 'All surveys') {
                optionsBox.empty();
                enableBtn(submitButton);
            } else if (filterSelected) {
                $.ajax({
                    method: 'GET',
                    url: controllerAction,
                    data: { type: 'json' },
                    success: function(response) {
                      if (filterSelected === 'Par enquête' || filterSelected === 'By survey') {
                        optionsBox.attr('name', 'enquete_name');
                        var options = response.enquetes;
                      } else if (filterSelected === 'Par date' || filterSelected === 'By date') {
                        optionsBox.attr('name', 'year');
                        var options = response.year_range;
                      } else {
                        optionsBox.attr('name', 'enquete_retraite_name');
                        var options = response.enquete_retraite;
                      }
                        if(options.length !== 0) {
                          createListSelectOptions(optionsBox, options);
                          optionsBox.removeClass('hidden');
                          enableBtn(submitButton);
                        }
                    },
                })
            } else {
                optionsBox.addClass('hidden');
                disableBtn(submitButton);
            }
        })
    }
}

export { setNestedFilters };
