const destroyEnquete = () => {
	$('.btn-delete-enquete').click(function(event) {
		let $btn = $(this).find("a");
		let $div = $(this).parent().parent()
		$btn.prop('disabled', true)
		event.preventDefault();
		let confirmation = confirm($btn.attr('data-confirm'));
		if (confirmation) { 
			$.ajax({
				url: $btn.attr("href"),
				type: "DELETE",
				data: {},
				success: function(data){
					console.log("SUCCESS", data)
					$div.remove()
				},
				error: function(data){
					console.log("ERROR", data)
				}
			})
		}
	})
} 
export {destroyEnquete};