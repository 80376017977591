import { Controller } from "@hotwired/stimulus";
import { tagPictureCropper } from '../components/_handleCropper';
import {getCollapseIdFromUrl} from '../components/js_for_parametre_groupe_question'

export default class extends Controller {

  connect() {
    this.checkEditTagInputs()
    this.tagCropImage()
    this.scrollToEditTag()
    this.checkTagInputs()
    document.addEventListener('turbo:render', ()=>{
      this.checkTagInputs()
    });
  }


  checkEditTagInputs () {
    function ft_edit_tag(editTagForm) {
      const form_edit_tag_btn = editTagForm.querySelector(".custom-btn.btn-of-modal-edit-serveur");
      const mainLang = document.getElementById("info-language-for-toggle-btn-js").dataset.mainLang;
      const mainContent = editTagForm.querySelector(`#tag_content_${mainLang}`)
      const allInputsLanguage = editTagForm.querySelectorAll("[id^='tag_content_']");
      let tags_content = JSON.parse(document.getElementById("info-language-for-toggle-btn-js").dataset.allTags)
      tags_content = tags_content.filter((el) => el !== mainContent.value.toLowerCase() )

      editTagForm.addEventListener("keyup", (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 0 )
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = tags_content.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon) {
          form_edit_tag_btn.classList.remove("not-clickable");
          form_edit_tag_btn.setAttribute("type", "submit");
        } else {
          form_edit_tag_btn.classList.add("not-clickable");
          form_edit_tag_btn.removeAttribute("type");
        }

      })
    }

    const editTagForms = document.querySelectorAll(".edit-tag-formulaire")
    editTagForms.forEach(editTagForm => {
      ft_edit_tag(editTagForm)
    })
  }

  tagCropImage() {
    function tagImageChange(tagImage) {
      tagImage.addEventListener("change", (event)=>{
        tagPictureCropper(event, false)
      })
    }

    const tagsPictures = document.querySelectorAll(".tag-picture-description")
    tagsPictures.forEach(tag => {
      tagImageChange(tag)
    })

  }

  scrollToEditTag () {
    const collapseValue = getCollapseIdFromUrl()
    if(collapseValue){
      const collapseBtn = document.getElementById(`collapse-btn-${collapseValue}`);
      const editTagFromToScroll = document.getElementById(`collapse-edit-tag${collapseValue}`)
      setTimeout(() => {
        collapseBtn.click();
        if(editTagFromToScroll) {
          editTagFromToScroll.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }, 1000);
    }
  }

  checkTagInputs () {
    const editTagBtns = document.querySelectorAll(".fa-edit")
    editTagBtns.forEach(editBtn =>{
      //if(!editBtn.classList.contains("not-edit")) {
        if(editBtn.closest(".draggable")){
          const clickableRowToCollapse = editBtn.closest(".draggable").querySelector(".newdash-ordre-of-answer")
          const cameraBtn = editBtn.closest(".draggable").querySelector(".fa-camera")
          if(clickableRowToCollapse) {
            clickableRowToCollapse.style.cursor = "pointer";
            clickableRowToCollapse.addEventListener("click", ()=> {
              editBtn.click();
            })
          }
          if(cameraBtn){
            cameraBtn.classList.remove("invisible")
          }
        }
      //}
    })
  }

}
