const clickOnCategory = () => {
  if (document.getElementById("clic-on-category-for-js")) {
		const categories_list = document.querySelectorAll(".categ-and-meal-name-for-js.name-link")
    categories_list.forEach((c) => {
      c.addEventListener('click', (event) => {
        const element = event.currentTarget.firstElementChild
				if (document.querySelector(element.dataset.target)) {
					document.querySelector(element.dataset.target).click()
				}
				const categTab = document.querySelector(element.dataset.target)
        if (categTab) {
				  categTab.scrollIntoView({ behavior: "smooth"});
        }
      })
    })
  }
}

const clickOnThemeQuestion = () => {
  const themeQuestionPage = document.getElementById("clic-on-theme-question-for-js")
  if (themeQuestionPage) {
    console.log("clic_on_theme_question")
     const theme_question_list = document.querySelectorAll(".theme-question.question-asked")
     theme_question_list.forEach((t) => {
	 		t.addEventListener('click', (event) => {
	 			console.log("CLICK GQ")
        const groupe_question = event.currentTarget.firstElementChild.dataset.idGroupeQuestion;
        const groupId = themeQuestionPage.dataset.groupId
        const enqueteId = themeQuestionPage.dataset.enqueteId
        window.open(`/groups/${groupId}/gq_index_questions?enquete=${enqueteId}&gq_id=${groupe_question}`, '_self')
      })
     })
  }
}




export { clickOnCategory, clickOnThemeQuestion };
