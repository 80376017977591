import { Controller } from "stimulus"
import Sortable from "sortablejs"

export default class extends Controller {
  static targets = ["rankItem", "rankInput"]

  connect() {
		this.maxNbAnswersValue = this.element.dataset.maxNbAnswers;
		console.log("Max number of answers:", this.maxNbAnswersValue);
		console.log("ranking controller connect")
    this.sortable = Sortable.create(this.element, {
			handle: ".drag-handle",
      animation: 150,
      ghostClass: 'hide-dragging-div-js',
			onStart: this.handleDragStart.bind(this),
      onEnd: this.handleDragEnd.bind(this),
			onChoose: this.handleDragChoose.bind(this),
      onUnchoose: this.handleDragUnchoose.bind(this),
    })

		console.log("rankItemTargets", this.rankItemTargets)
  }

	handleDragStart(event) {
    event.item.classList.add('dragging')
  }

  handleDragEnd(event) {
    event.item.classList.remove('dragging')
    this.updateRanks()
  }

	handleDragChoose(event) {
    event.item.classList.add('held')
  }

  handleDragUnchoose(event) {
    event.item.classList.remove('held')
  }

  updateRanks() {
		this.rankItemTargets.forEach((element, index) => {
			console.log("updateRanks", element.querySelector(".rank"))
      element.querySelector(".rank").innerText =`${ index + 1} -`
			if (this.maxNbAnswersValue > 0) {
				if (index <  this.maxNbAnswersValue) {
					element.querySelector(".classement-card").classList.remove("greyed-out")
				} else {
					element.querySelector(".classement-card").classList.add("greyed-out")
				}
			}
    })
  }


	moveItemUp(event) {
    event.preventDefault()
    const item = event.target.closest('.rank-item')
    const currentIndex = Array.from(this.rankItemTargets).indexOf(item)
		const targetRank = currentIndex - 1
    if (currentIndex > 0) {
			const order = this.sortable.toArray();
      const tmp = order[targetRank];
			order[targetRank] = order[currentIndex];
			order[currentIndex] = tmp;
			this.sortable.sort(order, true);
      this.updateRanks()
    }
  }

  moveItemDown(event) {
    event.preventDefault()
    const item = event.target.closest('.rank-item')
    const currentIndex = Array.from(this.rankItemTargets).indexOf(item)
		const targetRank = currentIndex + 1
    if (currentIndex < this.rankItemTargets.length - 1) {
      const order = this.sortable.toArray()
      const tmp = order[targetRank];
			order[targetRank] = order[currentIndex];
			order[currentIndex] = tmp;
			this.sortable.sort(order, true);
      this.updateRanks()
    }
  }

}
