import Rails from "@rails/ujs"
import "@rails/actioncable"
Rails.start()

import $ from 'jquery';
window.$ = $;
window.jQuery = $;

import 'mark.js/dist/jquery.mark.es6';
import * as bootstrap from 'bootstrap';
import "../components/polyfill";

import {btnColorBootstrapOverride} from "../components/btn_color_bootstrap_override"

import {execJSextra, execJSall, execJSnav} from "../packs/index"

document.addEventListener('turbo:render', function() {
	execJSall();
	// execJSnav();
})
document.addEventListener('turbo:load', function() {
	execJSall();
	execJSnav();
	execJSextra();
})

// pour résoudre le problème de bouttons blancs quand actif:
btnColorBootstrapOverride();

	// enleve turbo avec la classe '.no-turbo'sortingActiveInactiveMealItems
	document.addEventListener('turbo:click', function (event) {
		const link = event.target.closest('.no-turbo');
		if (link) {
			event.preventDefault();
			Turbo.visit(link.href);
		}
	});

document.addEventListener("turbo:before-cache", function() {
	const loadingDotsContainer = document.querySelector(".dots-container")

	if (loadingDotsContainer && !loadingDotsContainer.classList.contains("hidden")) {
		loadingDotsContainer.classList.add("hidden")
		document.querySelector("body").classList.remove("background-gray");
	}
})
