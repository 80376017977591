const showMaintenanceMess = () => {
    if (document.querySelector(".js-maintenance-message")) {
			const messageContainer = document.querySelectorAll('.maintenance-personalized-message');
			const maintenanceBtn = document.querySelectorAll('.fa-toggle-on');
			const maintenanceBtnOn = maintenanceBtn[0];
			const btnOnOff = document.getElementsByClassName('fa-toggle-on');
			maintenanceBtnOn.addEventListener('click', () => {
					
					if (btnOnOff[0].classList[2] === "toggle-group-maintenance-style-off") {
							messageContainer[0].classList.remove('hidden');
					} else {
							messageContainer[0].classList.add('hidden');
					}
			});
			window.addEventListener('load', () => {
					if (btnOnOff[0].classList[2] === "toggle-group-maintenance-style-on") {
							messageContainer[0].classList.remove('hidden');
					}
			});
    };
};

export { showMaintenanceMess };