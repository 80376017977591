const showHideBar = () => {
	const hideShowButton = document.getElementById("show_hide");
	const hideShowIcons = document.querySelectorAll("#show_hide i");
	const dashboard = document.querySelector(".right-dashboard");
	const sideBar = document.querySelector(".left-navbar-gm");
	if (hideShowButton) {
    console.log('showHideBar')
		hideShowButton.addEventListener("click", (event) => {
		  sideBar.classList.toggle("hiden-sidbar");
		  dashboard.classList.toggle("full-screen-dash");
      Array.prototype.forEach.call(hideShowIcons, function(icon) {
		  	icon.classList.toggle("hidden");
		  })
		});
	}
}

export { showHideBar };






