import { Controller } from "stimulus";
import { displayDoubleCropper, closeModalFt } from '../components/_handleDoubleCropper'


export default class extends Controller {

  static targets = ["source"]
  firstInput = null;
  firstInputLabel = null;
  secondInput = null;
  updateForm = null;

  connect() {
    // select input of interest amoung all inputs
    this.selectInputs()

    // show options when hover on images
    this.optionsImages()

    //put the event listenner to close the modal
    closeModalFt();
  }

  // show double cropper when select image
  change(event) {
    const fileSize = (event.target.files[0].size) / 1000
    // image size can't be more than 300ko
    if (fileSize > 300) {
      // Get url to see if the page is in french or english
      const url = new URL(window.location.href).toString()
      const isEnglishRegex = /(\/en\/)/
      const isEnglish = url.match(isEnglishRegex) ? true : false

      if (isEnglish) {
        alert("Your image's size can't exceed 300ko. You can reduce it on this free website https://shortpixel.com/")
      } else {
        alert("Votre image ne peut dépasser 300ko. Vous pouvez la réduire en vous rendant sur le site gratuit https://shortpixel.com/")
      }
    } else {
      if (event.target) {
        displayDoubleCropper(this.firstInput, this.secondInput, this.firstInputLabel, event)
      }
    }
  }

  deleteImage(event, questionLabel) {
    let firstInput = questionLabel.parentNode.querySelector("#the-first-input")
    let secondInput = questionLabel.parentNode.querySelector("#question_picture_description_landscape")
    // delete image by seting the value to ""
    firstInput.value = "" ;
    secondInput.value = "" ;

    // hide image and show label (add picture)
    const buttonDiv = event.target.parentNode;
    this.hideImage(buttonDiv, questionLabel )
  }

  showConfimation(event, questionLabel) {
    const editMsg = questionLabel.dataset.deleteConfirmation;
    Swal.fire({
      text: editMsg,
      icon: "warning",
      allowOutsideClick: false,
      showCancelButton: true,
      confirmButtonText: questionLabel.dataset.yes,
      cancelButtonText: questionLabel.dataset.no
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteExistingImage(event, questionLabel)
        Swal.close();
      } else {
        Swal.close();
      }
    });
  }

  deleteExistingImage(event, questionLabel) {
    let firstInput = null;
    // select the input from new question description div or created question description
    if(questionLabel.parentNode.querySelector("#the-first-input")){
      firstInput = questionLabel.parentNode.querySelector("#the-first-input")
    }else {
      firstInput = questionLabel.parentNode.querySelector("#question_picture_description_portrait")
    }
    let secondInput = questionLabel.parentNode.querySelector("#question_picture_description_landscape")
    // delete image by seting the value to ""
    firstInput.value = "" ;
    secondInput.value = "" ;

    const photoIndex = questionLabel.parentNode.querySelector("#delete-image-index")
    photoIndex.value = "1"

    // hide image and show label (add picture)
    const buttonDiv = event.target.parentNode;
    this.hideImage(buttonDiv, questionLabel );

    // send the change to the server
    const submitBtn = questionLabel.parentNode.querySelector("#submit-button");
    submitBtn.click();
  }

  editImage(questionLabel) {
    questionLabel.click();
  }

  hideImage(buttonDiv, questionLabel ) {
    if(!buttonDiv.classList.contains("hidden")){
      const imageDiv = buttonDiv.parentNode;
      imageDiv.classList.add("hidden");
      if(questionLabel.classList.contains("hidden")) {
        questionLabel.classList.remove("hidden")
      }
    }
  }

  selectInputs() {
    document.querySelectorAll("label.btn-dropdown-answer-question").forEach(label => {
      label.addEventListener('click', (event) => {
        this.firstInputLabel = event.target;
        this.firstInput = this.firstInputLabel.nextElementSibling;
        this.firstInput.id = "the-first-input"
        this.firstInputLabel.htmlFor = "the-first-input"
        this.secondInput = this.firstInput.parentNode.querySelector('#question_picture_description_landscape');
      })
    });
  }


  optionsImages(){
    const questionImageDivs = document.querySelectorAll(".question-image-div")

    questionImageDivs.forEach(questionImageDiv => {
      let questionLabel = questionImageDiv.parentNode.querySelector("label.custom-btn")
      questionImageDiv.addEventListener('mouseenter', function() {
        questionImageDiv.querySelector('#overlay').style.display = 'flex';
        let questionImage = questionImageDiv.querySelector("img")
        questionImage.style.opacity = "0.6";
        questionImage.style.transition = "all .2s ease-in-out";
      });

      questionImageDiv.addEventListener('mouseleave', function() {
        questionImageDiv.querySelector('#overlay').style.display = 'none';
        let questionImage = questionImageDiv.querySelector("img")
        questionImage.style.opacity = "1";
        questionImage.style.transition = "all .2s ease-in-out";
      });
      if(questionImageDiv.querySelector("#delete")) {
        questionImageDiv.querySelector("#delete").addEventListener("click", (event)=>{
          this.deleteImage(event, questionLabel);
        })
      }
      if(questionImageDiv.querySelector("#delete-created-question")) {
        questionImageDiv.querySelector("#delete-created-question").addEventListener("click", (event)=>{
          this.showConfimation(event, questionLabel)
        })
      }
      questionImageDiv.querySelector("#edit").addEventListener("click", (event)=> {
        this.editImage(questionLabel);
      });
    });
  }
}
