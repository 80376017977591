//variable_multisite_month && month_variable_new_survey_v2 : filtre sur les enquetes
//simulation sur des checkbox qui sont hidden dans les dashboard GQ (month_varibale_new_survey_v2), pour que le btn paraisse actif
// + quand on coche le btn 'toutes les enquetes' décocher tous les autres btn. Et si on appuie sur une enquête et que le btn 'toutes les enquetes' décocher ce btn

const simulateBtnCheckBoxOnFiltersEnquete = () => {
  if (document.getElementById("filtre-on-enquete-for-js")) {

    console.log("simulateBtnCheckBoxOnFiltersEnquete")

    //sur les filtres quand 4 enquetes ou -
    if (document.getElementById("less-than-four-js")) {
      const btnFilters = document.querySelectorAll(".filter-choix-enquete-js")
      const btnAllFilters = document.getElementById("filtre-all-enquetes-js")

      btnFilters.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          if (event.currentTarget.firstElementChild.checked) {
            event.currentTarget.firstElementChild.checked = false
            event.currentTarget.classList.remove("active")
          } else {
            event.currentTarget.firstElementChild.checked = true
            event.currentTarget.classList.add("active")
            // si on appuie sur une des enquete et que le btn AllFiltre etait activé, ca le désactive
            if (btnAllFilters.firstElementChild.checked) {
              btnAllFilters.firstElementChild.checked = false
              btnAllFilters.classList.remove("active")
            }
          }
        })
      })


      // listener sur le btn "AllFiltre" pour que quand on appuie dessus, ca decoche toutes les autres enquêtes
      btnAllFilters.addEventListener('click', (event) => {
        if (event.currentTarget.firstElementChild.checked === false)  {
          event.currentTarget.firstElementChild.checked = true
          event.currentTarget.classList.add("active")
          btnFilters.forEach((btn) => {
            btn.firstElementChild.checked = false
            btn.classList.remove("active")
          })
        } else {
          event.currentTarget.firstElementChild.checked = false
          event.currentTarget.classList.remove("active")
        }
      })


    // sur les filtres + de 4 enquetes
    } else {
      const btnFilters = document.querySelectorAll(".checkbox-of-filters-js")
      const btnAllFilters = document.getElementById("checkbox-all-filters-js")

      btnFilters.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          // si on appuie sur une des enquete et que le btn AllFiltre etait activé, ca le désactive
          if (btnAllFilters.checked) {
            btnAllFilters.checked = false
          }
        })
      })

      // listener sur le btn "AllFiltre" pour que quand on appuie dessus, ca decoche toutes les autres enquêtes
      btnAllFilters.addEventListener('click', (event) => {
        if (event.currentTarget.checked)  {
          btnFilters.forEach((btn) => {
            btn.checked = false
          })
        }
      })


    }

  }
}



// faire apparaitre le btn Valider (sur le filtre des enquetes) dès qu'on appuie sur un des filtres, pour que le btn 'Valider' tappe un peu plus à l'oeil
const displayBtnValidateWhenChangeFilter = () => {
  if (document.getElementById("filtre-on-enquete-for-js") && document.getElementById("less-than-four-js")) {
    const validateBtn = document.querySelector(".container-btn-validate-filter")
    document.querySelectorAll(".all-btn-filters-js").forEach((btnFilter) => {
      btnFilter.addEventListener('click', (event) => {
        validateBtn.classList.remove("hidden")
      })
    })
  }
}


export {simulateBtnCheckBoxOnFiltersEnquete, displayBtnValidateWhenChangeFilter}
