const sidebarTablet = () => {
  const topBar = $('.navbar-wagon.navbar-wagon-right');
  const sideBar = $('.left-navbar-gm.hiden-sidbar-mobile');
  const arrows = document.querySelector('#show_hide');

  if (arrows) {
    console.log('sidebarTablet')
  console.log(topBar.css('margin-left'));
      console.log(topBar.css('border'));
    if (topBar.css('border') == '0px solid rgb(128, 128, 128)') {
      document.querySelector(".right-dashboard").classList.add("full-screen-dash");
      document.querySelector(".left-navbar-gm").classList.add("hiden-sidbar");

      arrows.querySelector('.fa-chevron-circle-right').classList.remove("hidden");
      arrows.querySelector('.fa-chevron-circle-left').classList.add("hidden");

      arrows.querySelector('.fa-chevron-circle-right').addEventListener('click', () => {
      console.log('AZA');

        sideBar.css({'margin-left': '0px', 'display': 'block'});
        topBar.css('margin-left', '18.5vw')
      })

      arrows.querySelector('.fa-chevron-circle-left').addEventListener('click', () => {
      console.log('AZA');

        sideBar.css({'margin-left': '-90vw', 'display': 'none'});
        topBar.css('margin-left', '0vw')
      })
    }
  }
}

export {sidebarTablet};
