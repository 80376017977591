const toggleNestedSidebar = () => {
  const $droprightSidebar = $(".dropright");

  $droprightSidebar.on('mouseover', function() {
    var $menuItem = $(this),
        $submenuWrapper = $('> .dropright-content', $menuItem);
    var menuItemPos = $menuItem.position();
    var windowHeight = $(window).height();
    var menuTop = $menuItem.offset().top;
    var menuHeight = $menuItem.outerHeight();
    var distanceToWindowsBottom = windowHeight - menuTop
    var heightDropright = $submenuWrapper.height()

    if (heightDropright < distanceToWindowsBottom) {
      $submenuWrapper.css({
        top: menuItemPos.top,
        left: menuItemPos.left + Math.round($menuItem.outerWidth() * 0.9999)
      });
    } else {
      $submenuWrapper.css({
        top: menuItemPos.top - heightDropright + menuHeight,
        left: menuItemPos.left + Math.round($menuItem.outerWidth() * 0.9999)
      });
    }
  })
}

export { toggleNestedSidebar }
