import { Controller } from "stimulus";

export default class extends Controller {

  connect() {}

  toggle(e) {
    const nestedItems = e.currentTarget.nextElementSibling;
    const icon        = e.currentTarget.querySelector('.fa-angle-right')

    if (nestedItems && nestedItems.classList.contains("indicator-filter-items")) {
      nestedItems.classList.toggle("hidden");
      icon.classList.toggle('rotate-90')
    }
  }
}
