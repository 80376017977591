function initializeCheckboxFilters() {
  const checkboxes = document.querySelectorAll('.indicator-filter-checkbox');
  const uncheckAllButton = document.querySelector('#uncheck-all-button');
  checkboxes.forEach(checkbox => {
    checkbox.addEventListener('change', () => {
      filterTable();
      updateButtonStyles();
    });
  });

  if (uncheckAllButton) {
    uncheckAllButton.addEventListener('click', () => {
      checkboxes.forEach(checkbox => {
        checkbox.checked = false;
      });
      filterTable();
      updateButtonStyles();
    });
  }
}

function filterTable() {
  const checkboxes = document.querySelectorAll('.indicator-filter-checkbox:checked');
  const themesToShow = Array.from(checkboxes).map(checkbox => checkbox.value);

  const allHeaders = document.querySelectorAll('#main-table2 th[id="newborder"]');
  const allCells = document.querySelectorAll('#main-table2 td[id="newborder"]');

  // Show all columns if no checkbox is checked
  if (themesToShow.length === 0) {
     allHeaders.forEach(header => header.style.display = '');
     allCells.forEach(cell => cell.style.display = '');

     // Reset colspan of enquete name cells
    const enqueteNameCells = document.querySelectorAll('.ecase');
    enqueteNameCells.forEach(cell => {
      const indicatorCells = document.querySelectorAll('#main-table2 th[class="blue-border results-fixed-top tcase"]');
      const indicators_count = indicatorCells.length;
      const enqueteCount = enqueteNameCells.length;
      cell.colSpan = indicators_count / enqueteCount;
    });
     return;
  }

  // Hide all columns
  allHeaders.forEach(header => header.style.display = 'none');
  allCells.forEach(cell => cell.style.display = 'none');

  // Show only the columns of the checked themes
  themesToShow.forEach(theme => {
     const headersToShow = document.querySelectorAll(`#main-table2 th[data-theme="${theme}"]`);
     const cellsToShow = document.querySelectorAll(`#main-table2 td[data-theme="${theme}"]`);

     headersToShow.forEach(header => header.style.display = '');
     cellsToShow.forEach(cell => cell.style.display = '');
  });

  // Update indicators_count variable
  const visibleIndicatorCells = document.querySelectorAll('#main-table2 th[class="blue-border results-fixed-top tcase"]:not([style*="display: none"])');
  const indicators_count = visibleIndicatorCells.length;
  const enqueteNameCells = document.querySelectorAll('.ecase');
  const enqueteCount = enqueteNameCells.length;
  enqueteNameCells.forEach(cell => {
    cell.colSpan = indicators_count / enqueteCount;
  });
}

function updateButtonStyles() {
  const checkboxes = document.querySelectorAll('.indicator-filter-checkbox:checked');
  const chooseThemeButton = document.querySelector('button[onclick="showDropdown()"]');
  const uncheckAllButton = document.querySelector('#uncheck-all-button');

  if (checkboxes.length > 0) {
    chooseThemeButton.style.backgroundColor = '#5d78ff';
    chooseThemeButton.style.color = 'white';
    uncheckAllButton.style.backgroundColor = 'white';
    uncheckAllButton.style.color = '#5d78ff';
  } else {
    chooseThemeButton.style.backgroundColor = '';
    chooseThemeButton.style.color = '';
    uncheckAllButton.style.backgroundColor = '#5d78ff';
    uncheckAllButton.style.color = 'white';
  }
}

export {initializeCheckboxFilters};
