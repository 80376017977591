
// Parametre GQ /resto: Pour La création des tags dans Groupe Question pour afficher dans la modale directement le bon theme / gq du tag à créer
const selectRightTheme = () => {
  if (document.querySelector("#Modal-tag-groupe")) {
    console.log("selectRightTheme")
    const all_btn_create_tag_autre = document.querySelectorAll(".add-tag.custom-btn.autre-theme-gq");
    if (all_btn_create_tag_autre ) {
      const modale_new_tag_autre = document.querySelector("#Modal-tag-groupe").querySelector(".modal-content");
      all_btn_create_tag_autre.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          modale_new_tag_autre.querySelectorAll('select')[0].value = event.currentTarget.dataset.themebtncreatemodal
        })
      })
    }
  }
}

const selectRightCategMealResto = () => {
  if (document.getElementById("parametre-carte-resto-for-js")) {
    console.log("selectRightCategMealResto")
    const all_btn_create_meal_resto = document.querySelectorAll(".add-meal-js");
    if (all_btn_create_meal_resto) {
      const modale_new_meal_resto = document.querySelector("#myModal-a").querySelector(".modal-content");
      all_btn_create_meal_resto.forEach((btn) => {
        btn.addEventListener('click', (event) => {
					if (modale_new_meal_resto.querySelectorAll('select')[0]) {
						modale_new_meal_resto.querySelectorAll('select')[0].value = event.currentTarget.dataset.tabNameMealContentJs
					}
        })
      })
    }
  }
}

const selectRightCategProductetablissement = () => {
  if (document.getElementById("parametre-produit-etablissement-for-js")) {
    console.log("selectRightCategProductetablissement")
    const all_btn_create_meal_resto = document.querySelectorAll(".add-meal-js");
    if (all_btn_create_meal_resto) {
      const modale_new_meal_resto = document.querySelector("#myModal-create-product").querySelector(".modal-content");
      all_btn_create_meal_resto.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          modale_new_meal_resto.querySelectorAll('select')[0].value = event.currentTarget.dataset.tabNameMealContentJs
        })
      })
    }
  }
}

const selectRightMasterCategMealResto = () => {
  if (document.getElementById("parametre-carte-resto-for-js")) {
    console.log("selectRightMasterCategMealResto")
    const all_btn_create_meal_resto = document.querySelectorAll(".add-categ-js");
    if (all_btn_create_meal_resto) {
      const modale_new_meal_resto = document.querySelector("#myModal-b").querySelector(".modal-content");
      all_btn_create_meal_resto.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          modale_new_meal_resto.querySelectorAll('select')[0].value = event.currentTarget.dataset.tabNameCategContentJs
        })
      })
    }
  }
}

const selectRightMasterCategProductEtablissement = () => {
  if (document.getElementById("parametre-produit-etablissement-for-js")) {
    console.log("selectRightMasterCategProductEtablissement")
    const all_btn_create_meal_resto = document.querySelectorAll(".add-categ-js");
    if (all_btn_create_meal_resto) {
      const modale_new_meal_resto = document.querySelector("#myModal-b").querySelector(".modal-content");
      all_btn_create_meal_resto.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          modale_new_meal_resto.querySelectorAll('select')[0].value = event.currentTarget.dataset.tabNameCategContentJs
        })
      })
    }
  }
}

export {selectRightTheme, selectRightCategMealResto, selectRightCategProductetablissement, selectRightMasterCategMealResto, selectRightMasterCategProductEtablissement };
