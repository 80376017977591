const questionnaireJuste = () => {
  const juste_s = document.getElementById('juste_s');
  const faux_s = document.getElementById('faux_s');
  if (juste_s) {
    console.log('questionnaireJuste')
    document.getElementById('juste').addEventListener('change', () => {
      console.log(juste_s)
      juste_s.submit();
    })

    document.getElementById('faux').addEventListener('change', () => {
      faux_s.submit();
    })
  }
}

export {questionnaireJuste}
