import { Controller } from "stimulus";
import { displayCropper } from '../components/_handleCropper'


export default class extends Controller {

  static targets = ["source","previsualisation"]

  connect() {
    this.sourceTarget.addEventListener('click', () => {
      if (this.sourceTarget.value) {
        this.sourceTarget.value = ''
      }
    })
  }


  change(event) {
    const fileSize = (event.target.files[0].size) / 1000
    // image size can't be more than 400ko
    if (fileSize > 400) {
      // Get url to see if the page is in french or english
      const url = new URL(window.location.href).toString()
      const isEnglishRegex = /(\/en\/)/
      const isEnglish = url.match(isEnglishRegex) ? true : false

      if (isEnglish) {
        alert("Your image's size can't exceed 400ko. You can reduce it on this free website https://shortpixel.com/")
      } else {
        alert("Votre image ne peut dépasser 400ko. Vous pouvez la réduire en vous rendant sur le site gratuit https://shortpixel.com/")
      }
    } else {
      displayCropper(this.sourceTarget, null)
    }
  }
}
