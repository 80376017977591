const previewImageOnFileSelect = () => {
  // we select the photo input
  const input = document.getElementById('photo-input');
  if (input) {
    console.log('previewImageOnFileSelect')
    // we add a listener to know when a new picture is uploaded
    input.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreview(input);
    })
  }
}

const displayPreview = (input) => {
  if (input.files && input.files[0]) {
    console.log('displayPreview')
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById('photo-preview').src = event.currentTarget.result;
    }
    reader.readAsDataURL(input.files[0])
    document.getElementById('photo-preview').classList.remove('hidden');
  }
}

const previewImageOnFileSelectEnglish = () => {
  // we select the photo input
  const inputEnglish = document.getElementById('photo-input-english');
  if (inputEnglish) {
    console.log('previewImageOnFileSelectEnglish')
    // we add a listener to know when a new picture is uploaded
    console.log("english version")
    inputEnglish.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreviewEnglish(inputEnglish);
    })
  }
}

const displayPreviewEnglish = (inputEnglish) => {
  if (inputEnglish.files && inputEnglish.files[0]) {
    console.log('displayPreviewEnglish')
    const readerEnglish = new FileReader();
    readerEnglish.onload = (event) => {
      document.getElementById('photo-preview-english').src = event.currentTarget.result;
    }
    readerEnglish.readAsDataURL(inputEnglish.files[0])
    document.getElementById('photo-preview-english').classList.remove('hidden');
  }
}

const previewImageOnFileSelectEdit = () => {
  // we select the photo input
  const inputEdit = document.getElementById('photo-input-edit');
  if (inputEdit) {
    console.log('previewImageOnFileSelectEdit')
    // we add a listener to know when a new picture is uploaded
    console.log("english version")
    inputEdit.addEventListener('change', () => {
      // we call the displayPreview function (who retrieve the image url and display it)
      displayPreviewEdit(inputEdit);
    })
  }
}

const displayPreviewEdit = (inputEdit) => {
  if (inputEdit.files && inputEdit.files[0]) {
    console.log('displayPreviewEdit')
    const readerEdit = new FileReader();
    readerEdit.onload = (event) => {
      document.getElementById('photo-preview-edit').src = event.currentTarget.result;
    }
    readerEdit.readAsDataURL(inputEdit.files[0])
    document.getElementById('photo-preview-edit').classList.remove('hidden');
  }
}

export { previewImageOnFileSelect, previewImageOnFileSelectEnglish, previewImageOnFileSelectEdit };
