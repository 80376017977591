// Fonction pour afficher la tab active dans detail_menu_new
const tabActiveNewMenu = () => {
  if (document.querySelector(".tab-panel-new-menu")) {

    console.log("tabActiveNewMenu");
    const myTabs = document.querySelector("#categ-tabs");
    const tabs = myTabs.querySelectorAll(".cat-tabs");
    const tab_active_clic_dataset = document.querySelector(".tab-active-js").dataset["tabActive"];

    // When there is no sub-description we reduce space between Categ title and meals
    [...document.getElementsByClassName('categ-description-menu-js')].forEach(description => {
      if (description.innerHTML == '') {
        description.nextElementSibling.nextElementSibling.nextElementSibling.classList.add('less-margin');
      }
    });

    // Same as above but when there is no img
    [...document.getElementsByClassName('new-menu-cover_photo')].forEach(img => {
      const src = img.getAttribute('src');
      if (!src) {
        img.parentElement.nextElementSibling.nextElementSibling.classList.add('less-margin');
      }
    });

    tabs.forEach((tab) => {

      // Quand on a un lien dans my_menu vers une category spécifique alors on arrive bien sur cette category
      // Quand on clique sur retour dans detail_meal alors on revient bien sur la bonne category
      if (tab.innerHTML.match(/href="#([^"]*)/)[1] === tab_active_clic_dataset) {
        tab.classList.add("active");
        const activ_tab_id = tab.innerHTML.match(/href="#([^"]*)/)[1];
        console.log(activ_tab_id);
        const my_tabpanel_active = document.getElementById(activ_tab_id);
        console.log(my_tabpanel_active);
        // my_tabpanel_active.scrollIntoView({ block: "start", inline: "nearest" });
        window.scrollBy(0, -110);
        // tab.scrollIntoView({ block: "start", inline: "nearest" });
        // setTimeout(function() {document.querySelector(".cat-tabs.active").scrollIntoView();},1)
        // setTimeout(function() {document.querySelector("#categ-tabs").scrollBy((-tab.getBoundingClientRect().x), 0)});
        // console.log(my_tabpanel_active.getBoundingClientRect())

      }
      // Quand on scroll alors ça se fait bien sur la bonne category
      window.addEventListener("scroll", function () {
        let activ_tab_id_scroll = tab.innerHTML.match(/href="#([^"]*)/)[1];
        if (document.getElementById(activ_tab_id_scroll).getBoundingClientRect().top > -100 && document.getElementById(activ_tab_id_scroll).getBoundingClientRect().top < 100) {
          const current = document.querySelectorAll(".cat-tabs.active");
          console.log(current);
          if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
          }
          tab.classList.add("active");
          // tab.scrollIntoView();
          if (tab.classList.contains('active')) {
            let tabHorizontalPosition = tab.getBoundingClientRect().left;

            myTabs.scrollTo({
              left: tabHorizontalPosition + myTabs.scrollLeft,
              behavior: 'smooth'
            });
          }
          console.log(activ_tab_id_scroll);
        }
      });
      // Quand on clique sur une tab (category) alors le scroll se fait bien sur la bonne category de produits
      tab.addEventListener("click", function () {
        const current = document.querySelectorAll(".cat-tabs.active");
        console.log(current);
        if (current.length > 0) {
          current[0].className = current[0].className.replace(" active", "");
        }
        tab.classList.add("active");

        const activ_tab_id_clic = tab.innerHTML.match(/href="#([^"]*)/)[1];
        console.log(activ_tab_id_clic);

        const my_tabpanel_active_clic = document.getElementById(activ_tab_id_clic);
        console.log(my_tabpanel_active_clic);

        // my_tabpanel_active_clic.classList.add("add-padding-top-menu-new-scroll");
        my_tabpanel_active_clic.scrollIntoView({ block: "start", inline: "nearest" });
        // tab.scrollIntoView({ block: "start", inline: "nearest" });
        setTimeout(function() {window.scrollBy(0, -110);},1)
        // setTimeout(function() {document.querySelector("#categ-tabs").scrollBy((-tab.getBoundingClientRect().x), 0)});
      })

    })
  }
}

export { tabActiveNewMenu };
