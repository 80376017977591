const centerPercent = () => {
  const percent = document.querySelector(".data-of-dnts2");

  if (percent != null) {
    console.log('centerPercent')
    if (percent.innerText == "100%") {
      percent.setAttribute("style", "left: 41%;");
    }
    else if (percent.innerText == "0%") {
      percent.setAttribute("style", "left: 45%;");
    }
  }
}

export {centerPercent}
