const showHideBarMobile = () => {
	const hideButtonMobile = document.getElementById("hide");
	const showButtonMobile = document.getElementById("show");
	const hideShowIcons = document.querySelectorAll("#show_hide i");
	const dashboardMobile = document.querySelector(".right-dashboard");
  const sideBarMobile = document.querySelector(".left-navbar-gm");
	const filterMobile = document.querySelector(".filter-mobile");
  const nav = document.querySelector(".left-navbar-gm");
  if (hideButtonMobile && showButtonMobile && hideShowIcons && dashboardMobile && sideBarMobile && filterMobile && nav) {
    console.log('showHideBarMobile')
    showButtonMobile.addEventListener("click", () => {
      nav.classList.toggle("hiden-sidbar-mobile");
      filterMobile.classList.toggle("hidden");
    })

    hideButtonMobile.addEventListener("click", () => {
      nav.classList.toggle("hiden-sidbar-mobile");
      filterMobile.classList.toggle("hidden");
    })

    filterMobile.addEventListener("click", () => {
      nav.classList.toggle("hiden-sidbar-mobile");
      filterMobile.classList.toggle("hidden");
    })
  }

}

export { showHideBarMobile };





