import { showBanner, setBanner } from './_flash_banner';

const checkboxCheckAllOthersCheckbox = () => {
  const $mainCheckbox = $('#base-client-main-checkbox');
  if ($mainCheckbox) {
    $mainCheckbox.on('change', function() {
      // Check toutes les checkboxes quand on check la "mainCheckbox"
      const isChecked = $(this).is(':checked');
      const $allCheckbox = $('.customer-checkbox');
      $allCheckbox.prop('checked', isChecked);

      // Ajout front sur bouton "supprimer" et lignes sélectionnées
      const $checkedNumber = $allCheckbox.filter(':checked').length
      frontForDeleteBtn($checkedNumber)
      $allCheckbox.closest('tr').toggleClass('line-checked-js', isChecked)
    })
  }
}

const destroyClientsFromDatabase = () => {
  const $deleteBtn = $('#btn-base-client-delete');
	const $banner = setBanner();

  if ($deleteBtn) {
    $deleteBtn.on('click', function(event) {
      // Utiliser SweetAlert2 pour afficher une boîte de dialogue de confirmation
			event.preventDefault();
      Swal.fire({
        title: $deleteBtn.attr('data-lang') === 'fr' ? 'Êtes-vous sûr de vouloir supprimer ce(s) client(s) ?' : "Are you sure you want to delete these customer(s)?",
        text: $deleteBtn.attr('data-lang') === 'fr' ? 'Cette action est irréversible' : 'This action is irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: $deleteBtn.attr('data-lang') === 'fr' ? 'Oui, supprimer' : "Yes, remove" ,
        cancelButtonText: $deleteBtn.attr('data-lang') === 'fr' ? 'Annuler' : "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          // Si l'utilisateur clique sur "Oui, supprimer", effectuer la suppression via AJAX
          const checkedCheckboxes = document.querySelectorAll('input[type="checkbox"].customer-checkbox:checked');
          const customerIds = Array.from(checkedCheckboxes).map(checkbox => parseInt(checkbox.getAttribute('data-customer-id')));
          const destroyCustomerUrl = $deleteBtn.attr('href');
          const groupId = $("#delete-btn-container").attr('data-group-id');

          // Méthode AJAX pour supprimer les clients de la base de données
          $.ajax({
            method: 'DELETE',
            url: destroyCustomerUrl,
            data: {
              type: 'json',
              customer_ids: customerIds,
              group_id: groupId
            },
            success: () => {
              checkedCheckboxes.forEach((checkbox) => {
                checkbox.closest('tr').innerHTML = '';
              });

              $deleteBtn.removeClass('btn-delete-js');
              $deleteBtn.prop('disabled', true);

              // Afficher une bannière de succès si nécessaire
              if ($banner) {
                showBanner($banner.attr("data-edit-success"), "success");
              };
            },
            error: function () {
              console.log('error');
              // Afficher une bannière d'erreur si nécessaire
              if ($banner) {
                showBanner($banner.attr("data-edit-error"), "error");
              }
            }
          });
        }
      });
    });
  };
};

const allCheckboxesFront = () => {
  const $checkboxes = $('input[type="checkbox"].customer-checkbox');

  if ($checkboxes) {
    $checkboxes.on('change', function() {
      // Ajout front sur bouton "supprimer" et lignes sélectionnées
      const $checkedNumber = $checkboxes.filter(':checked').length;
      frontForDeleteBtn($checkedNumber);
      $(this).closest('tr').toggleClass('line-checked-js', $(this).is(':checked'));
    });
  };
}

const frontForDeleteBtn = (checkedNumber) => {
  const $deleteBtn = $('#btn-base-client-delete')

  $deleteBtn.toggleClass('btn-delete-js', checkedNumber > 0);
  $deleteBtn.prop('disabled', checkedNumber === 0);
}

export { checkboxCheckAllOthersCheckbox, destroyClientsFromDatabase, allCheckboxesFront }
