import { showBanner, setBanner } from './_flash_banner';
import { initSelect2 } from 'plugins/init_select2';


// Afficher la liste des indicateurs inactifs
const toggleBtnForIndicatorsList = () => {
  const toggleBtn = document.getElementById('inactive-indicators-toggle-btn')
  const list = document.querySelector('.inactive-indicators-list')
  toggleBtnFront(toggleBtn, list)
}

// Toggle btn pour indicateur specific enquete / création dans les nouvelles enquêtes
const toggleBtnEditSpecificEnqueteBoolean = () => {
  const toggleBtn = document.getElementById('specific-general-toggle-btn')
  toggleBtnFront(toggleBtn)

  const $toggleBtn = $(toggleBtn)
  if ($toggleBtn) {
    $toggleBtn.tooltip({ content: $toggleBtn.attr('title') })

    $toggleBtn.on('click', function(e) {
      const $indicatorId = $(this).data('indicator-id')

      $.ajax({
        url: "/indicators/" + $indicatorId + "/change_enquete_specific" ,
        type: 'PATCH',
        data: {
          type: 'json',
          indicator_id: $indicatorId
        },
        success: function(data) {
          console.log(data)
        },
        error: function(error) {
          console.log(error);
        }
      });
    });
  };

  const $toggleBtnBasicsIndicator = $('#specific-general-toggle-btn-basics-indicators')
  if ($toggleBtnBasicsIndicator) {
    $toggleBtnBasicsIndicator.tooltip({ content: $toggleBtnBasicsIndicator.attr('title') })
  }
};



// Autoscroll jusqu'à la div "Ajouter un indicateur"
const scrollToAddIndicator = () => {
  const btn = document.querySelector('.add-new-indicator')
  const anchor = document.getElementById('indicator-create-container')

  if (btn) {
    btn.addEventListener('click', () => {
      anchor.scrollIntoView({ block: 'start', behavior: 'smooth' })
    })
  }
}

// Selected indicator front
function selectedIndicatorFocus(selector = '.clickable-indicator') {
	const $indicatorNames = $(selector);

  if ($indicatorNames) {
		$indicatorNames.on('click', event => {
			const $allIndicators = $('.clickable-indicator')
			$allIndicators.removeClass('selected-indicator no-transition');

      const $currentTarget = $(event.currentTarget);
      $allIndicators.not($currentTarget).addClass('no-transition');

      $allIndicators.removeClass('selected-indicator');
      $currentTarget.addClass('selected-indicator');

      setTimeout(() => {
        $indicatorNames.removeClass('no-transition');
      }, 0);
    });
  };
}

// Refresh AJAX des détails de l'indicateur sélectionné dans la div de droite
function ajaxToDisplayIndicatorInfos(selector = ".clickable-indicator") {
	const $indicatorNames = $(selector);

  // Mettre à jour la div de l'indicateur en question (btn submit en remote: true)
  if ($indicatorNames) {
    $indicatorNames.on('click', function() {
      const indicatorId = $(this).data('indicator-id');
      const pathEdit = $(this).data('path');


      if (indicatorId) {
        $.ajax({
          url: pathEdit,
          type: 'GET',
          dataType: 'json',
          success: function(data) {
            const activeStatus = data.status;

            if (activeStatus) {
              $('#indicator-edit-container').css('border', "2px solid #5d78ff");
            } else {
              $('#indicator-edit-container').css('border', "2px solid #B9BDCA");
            };

            $('#indicator-edit-form').html(data.html);

            updateIndicatorStatusAjax();
            validationsForIndicators();
            updateFrontAfterDeleteOrSubmit();
            showConcernedSurveys();
            initSelect2();
            formLanguagesDisplay();
            tooltipForEditQuestion();
            toggleBtnEditSpecificEnqueteBoolean();
          },
          error: function(error) {
            console.log(error);
          }
        })
      }
    });
  };
}

// Update l'indicateur entre inactif et actif
const updateIndicatorStatusAjax = () => {
  const $btn = $('.indicator-status-btn');

  if ($btn) {
    $btn.on('click', function() {
      const $indicatorId = $(this).data('indicator-id')
      const $activeLabel = $(this).data('active-label')
      const $inactiveLabel = $(this).data('inactive-label')


      $.ajax({
        url: "/indicators/" + $indicatorId + "/change_status" ,
        type: 'PATCH',
        data: {
          type: 'json',
          indicator_id: $indicatorId
        },
        success: function(data) {

          const $indicator = $('.selected-indicator')

          $btn.toggleClass('indicator-active-btn')
          $btn.toggleClass('indicator-inactive-btn')

          $indicator.toggleClass('inactive-item')
          $indicator.find('#indicator-drag-drop-icon').toggleClass('invisible')

          if (data.new_status) {
            // Met à jour le texte du btn et la bordure du form container
            $btn.html($activeLabel)
            $('#indicator-edit-container').css('border', "2px solid #5d78ff");

            // Change l'emplacement du thème entre actif et inactif
            const $targetElement = $('.active-indicators-list').find('.clickable-indicator').eq(0)
            if ($targetElement.length > 0) {
              $indicator.insertBefore($targetElement);
            } else {
              $('.active-indicators-list').append($indicator);
            }

            updateInactiveIndicatorCounter()

          } else {

            $btn.html($inactiveLabel)
            $('#indicator-edit-container').css('border', "2px solid #B9BDCA");
            $indicator.appendTo('.inactive-indicators-list')

            updateInactiveIndicatorCounter()


          }
        },
        error: function(error) {
          console.log(error);
        }
      });
    });
  };
};

// Permet de ne pas envoyer des inputs vides ou des possible_answers identiques
const validationsForIndicators = () => {

  // Pour la partie Edit indicateur
  const indicatorFormAllInputs = document.querySelectorAll('.indicator-edit-form-input')

  Array.prototype.forEach.call(indicatorFormAllInputs, (input) => {
    if (input) {
      input.addEventListener('keyup', function() {
        const indicatorFormBtn = input.closest('.form-container').querySelector('.indicator-validation-btn');
        const indicatorFormInputs = input.closest('.form-container').querySelectorAll('.indicator-edit-form-input');
        const possibleAnswerInputs = input.closest('.form-container').querySelectorAll('.indicator-pa-form-edit');

        const allContentFilled = noBlankInput(indicatorFormInputs);
        const isSame = notSameContent(possibleAnswerInputs);

        if (allContentFilled && !isSame) {
          indicatorFormBtn.classList.remove("not-clickable");
          indicatorFormBtn.setAttribute("type", "submit");
        } else {
          indicatorFormBtn.classList.add("not-clickable");
          indicatorFormBtn.removeAttribute("type");
        }
      });
    };
  });

  // Pour la partie Create indicateur
  const createIndicatorForm = document.querySelector('.form-create-container')
  if (createIndicatorForm) {
    createIndicatorForm.addEventListener('keyup', function(event) {
      validationForCreateIndicator('keyUpFunction');
    });
  };
};

// Affiche la banner de validation / error lors de l'envoie du formulaire
const newIndicatorFormSubmission = () => {
  const $banner = setBanner();

  $(document).off('ajax:success').on('ajax:success', '[id^="edit_indicator_"], #create-indicator-form', function(event) {
    let [data, status, xhr] = event.detail; // JSON returned from the create indicator ft

    const indicatorList = document.querySelector(".active-indicators-list");

    let editPathScheme = indicatorList.querySelector(".clickable-indicator").dataset.path

    let path = editPathScheme.replace(/(\/indicators\/)(\d+)(\/edit)/, `$1${data.id}$3`);
    indicatorList.insertAdjacentHTML('beforeend',
    ` <div class="flex-space-between indicators-list-item clickable-indicator" data-indicator-id="${data.id}" data-indicator-status="true" data-path="${path}">
        <div class="indicator-list-item-name">${data.theme}</div>
        <i class="fas fa-exchange-alt fa-rotate-90 move-icon indicator-drag-drop-icon"></i>
      </div>`)
      let selector = `[data-indicator-id="${data.id}"]`
      ajaxToDisplayIndicatorInfos(selector)
      selectedIndicatorFocus(selector)

    if ($banner) {
      showBanner($banner.attr("data-edit-success"), "success");
      $('.indicator-edit-validation-btn').css('cursor', 'pointer');
    }

    // On vide tous les inputs une fois le form create envoyé
    const indicatorThemeInput = document.getElementById('theme-indicator-input');
    const indicatorQuestionInputs = document.querySelectorAll('[id^="question_"]:not(#question_type)');
    const indicatorPossibleAnswerInputs = document.querySelector('.form-new-possibles-answers-container:not(.hidden)').querySelectorAll('.new-indicator-pa-input');
    const indicatorPaWithoutOuiNon = Array.from(indicatorPossibleAnswerInputs).filter(input => !input.readOnly);

    const allInputs = [
      indicatorThemeInput,
      ...indicatorQuestionInputs,
      ...indicatorPaWithoutOuiNon
    ];

    allInputs.forEach((input) => {
      input.value = ''; // Attribuer une valeur vide à l'input
    });

    const indicatorFormBtn = document.getElementById('create-indicator-btn');
    indicatorFormBtn.classList.add("not-clickable");
    indicatorFormBtn.removeAttribute("type");

  });

  $(document).on('ajax:error', '[id^="edit_indicator_"], #create-indicator-form', function() {
    if ($banner) {
      showBanner($banner.attr("data-edit-error"), "error");
    }
  });

}

// Sweet alert + Supprimer la div de l'indicateur en question (btn delete en remote: true) + update theme quand submit
const updateFrontAfterDeleteOrSubmit = () => {
  const deleteBtn = document.querySelector(".btn-delete-indicator")
  if (deleteBtn) {

    $('.btn-delete-indicator').on('click', function(e) {
      e.preventDefault();
      Swal.fire({
        title: $(this).attr('data-lang') === 'fr' ? 'Êtes-vous sûr de vouloir supprimer cet indicateur ?' : "Are you sure you want to delete this indicator ?",
        text: $(this).attr('data-lang') === 'fr' ? 'Cette action est irréversible' : 'This action is irreversible',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: $(this).attr('data-lang') === 'fr' ? 'Oui, supprimer' : "Yes, remove" ,
        cancelButtonText: $(this).attr('data-lang') === 'fr' ? 'Annuler' : "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          $.ajax({
            method: 'DELETE',
            url: $('.btn-delete-indicator').data('url'),
            dataType: 'json',
            success: function() {
              // Remove div concernée
              const $divToDelete = $('.selected-indicator');
              $divToDelete.remove();

              // Update compteur de filtres inactifs
              updateInactiveIndicatorCounter()

              // Refresh edit form sur le premier indicateur de la liste
              const firstClickableIndicator = document.querySelector('.clickable-indicator')
              if (firstClickableIndicator) {
                firstClickableIndicator.click()
              };
            },
            error: function() {
              // Traitez l'erreur si nécessaire
            }
          });
        }
      });
    });
  };

  const submitBtn = document.querySelector(".indicator-edit-validation-btn")
  if (submitBtn) {
    submitBtn.addEventListener('click', () => {
      const newIndicatorValue = $('#indicator_theme').val();
      const $divToUpdate = $('.selected-indicator').find('.indicator-list-item-name');
      $divToUpdate.html(newIndicatorValue);
    })
  };
};

// "Enquêtes concernées" en bas à gauche du form edit
const showConcernedSurveys = () => {
  const concernedSurveys = document.getElementById('surveys-concerned-container');
  if (concernedSurveys) {
    concernedSurveys.addEventListener('mouseenter', () => {
      $('#surveys-concerned-links').addClass('surveys-concerned-open')
    });

    concernedSurveys.addEventListener('mouseleave', () => {
      $('#surveys-concerned-links').removeClass('surveys-concerned-open')
    });
  };
};

// Display les questionnaires propres à chaque langage
const formLanguagesDisplay = () => {

  // Pour le edit indicator form
  displayInDifferentLanguages(
    '.language-tab-element-edit',
    '.form-container',
    '.flags-menu-edit-indicators'
  );

  // Pour le new indicator form (questions)
  displayInDifferentLanguages(
    '.language-tab-element-new',
    '.indicator-new-quest',
    '.flags-menu-new-indicator',
    'CreateFormQuestions'
  );

  // Pour le new indicator form (possible answers)
  displayInDifferentLanguages(
    '.language-tab-element-new',
    '.indicator-new-pa',
    '.flags-menu-new-indicator',
    'CreateFormPa'
  );

  displayInDifferentLanguages(
    '.language-tab-element-new',
    '.indicator-display-placeholder',
    '.flags-menu-new-indicator',
    'CreateFormPaNationality'
  );
};

// Ajouter / enlenver un PA
const addAndRemovePossibleAnswer = () => {
  const indicatorPaContainersUniq = document.querySelectorAll('.indicator-pa-uniq');
  AddAndRemovePaFunction(indicatorPaContainersUniq, '.new-indicator-remove-pa-uniq', '.new-indicator-add-pa-uniq');

  const indicatorPaContainersOuiNon = document.querySelectorAll('.indicator-pa-oui-non');
  AddAndRemovePaFunction(indicatorPaContainersOuiNon, '.new-indicator-remove-pa-oui-non', '.new-indicator-add-pa-oui-non');
};

// Change les possibles answers en fonction du type_question choisi
const displayPossibleAnswers = () => {
  const typeQuestionList = document.getElementById('question_type')
  if (typeQuestionList) {
    typeQuestionList.addEventListener('change', (event) => {
      const $allContainers = $('.form-new-possibles-answers-container')
      $allContainers.addClass('hidden')

      const selectedTypeQuestion = event.currentTarget.value

      if (selectedTypeQuestion === 'Tag Choix Simple') {
        const UniqPaContainer = document.getElementById('form-new-pa-for-uniq')
        UniqPaContainer.classList.remove('hidden')

      } else if (selectedTypeQuestion === 'Oui Non') {
        const OuiNonPaContainer = document.getElementById('form-new-pa-for-oui-non')
        OuiNonPaContainer.classList.remove('hidden')

      } else if (selectedTypeQuestion === 'Nationalité') {
        const NationalityPaContainer = document.getElementById('form-new-pa-for-nationality')
        NationalityPaContainer.classList.remove('hidden')
      };

      // Pour la partie Create indicateur
      validationForCreateIndicator();

      const anchor = document.getElementById('create-indicator-btn')
      anchor.scrollIntoView({ block: 'start', behavior: 'smooth' })
    });
  };
};

// Update prévisualisation de la réponse "Nationalité"
const updateNationalityAnswerPreview = () => {

  // Modification du placeholder
  const $mainLanguage = $('.indicator-display-placeholder').data('main-language')
  const $placeholderInput = $(`#placeholder_nationality_${$mainLanguage}`)
  if ($placeholderInput) {
    $placeholderInput.on('keyup', function() {
      const $answerPreviewInput = $('.select2-selection__placeholder')
      $answerPreviewInput.text($placeholderInput.val()).trigger('change');
    });
  };

  // Option mise en avant
  const $highlightOptionInput = $('.placeholder-container .selector-nationality');
  const $originalAnswersPreview = $('#preview-answer-create-indicator-form').html();

  if ($highlightOptionInput) {
    $highlightOptionInput.on('change', function() {
      const $answerPreviewInput = $('#preview-answer-create-indicator-form');
      const value = $highlightOptionInput.val();
      const $selectedOption = $answerPreviewInput.find('option[value="' + value + '"]');

      $answerPreviewInput.html($originalAnswersPreview);
      $selectedOption.prependTo($answerPreviewInput);
      $answerPreviewInput.find('option[value="' + value + '"]').eq(1).remove();
      $answerPreviewInput.select2('open');
    });
  };
};

// Tooltip si les questions sont différentes d'une enquête à l'autre

const tooltipForEditQuestion = () => {
  const $warningLogo = $('.warning-not-same')
  if ($warningLogo) {
    $warningLogo.tooltip({
      content: $warningLogo.attr('title'),
    });
  }
}

export {
  toggleBtnForIndicatorsList,
  scrollToAddIndicator,
  selectedIndicatorFocus,
  ajaxToDisplayIndicatorInfos,
  updateIndicatorStatusAjax,
  validationsForIndicators,
  newIndicatorFormSubmission,
  updateFrontAfterDeleteOrSubmit,
  showConcernedSurveys,
  formLanguagesDisplay,
  addAndRemovePossibleAnswer,
  displayPossibleAnswers,
  updateNationalityAnswerPreview,
  tooltipForEditQuestion,
  toggleBtnEditSpecificEnqueteBoolean
};

const toggleBtnFront = (btn, list = null) => {
  if (btn) {
    btn.addEventListener('click', () => {
      console.log('hello')
      btn.classList.toggle('toggle-email-style-on');
      btn.classList.toggle('toggle-email-style-off');
      if (list) { list.classList.toggle('hidden') }
    })
  }
};

const noBlankInput = (inputs) => {
  const filledOrNot = []
  let val = ""
  Array.prototype.forEach.call( inputs, (input) => {
    val = input.value.replaceAll(' ', '')
    filledOrNot.push(val !== '')
  });
  return filledOrNot.includes(false) ? false : true
};

const notSameContent = (inputs) => {
  let isSame = false;
  let occurrences = [];

  inputs.forEach((input) => {
    if (input.value) {
      const currentInputValue = input.value.toLowerCase().trim();
      occurrences.push(currentInputValue);
    } else {
      const currentInputValue = input;
      occurrences.push(currentInputValue);
    }
  });

  if (occurrences.length !== new Set(occurrences).size) {
    isSame = true;
  }
  return isSame
};

const updateInactiveIndicatorCounter = () => {
  const $inactiveIndicatorCount = $('.inactive-indicators-list .clickable-indicator').length
  $('#inactive-indicator-counter').text($inactiveIndicatorCount);
};

const displayInDifferentLanguages = (flagsList, elements, flag, createForm = null ) => {
  const $tab = $(flagsList)

  if ($tab) {
    // Laisse uniquement le premier élément et entoure le premier drapeau
    if (createForm) {
      $(elements).each(function() {
        const $element = $(this)
        if ($element.data('language') !== $element.data('main-language')) {
          $element.hide();
        }
      })
    } else {
      $(elements).not(':first').hide();
    }

    $(flag).first().addClass('active-flags-tab');

    $tab.on('click', function(e) {
      var language = $(this).data('language');
      $(elements).hide();
      $(`${elements}[data-language="` + language + '"]').show();

      // Ne cache pas l'élément dont la langue est la langue principale du groupe
      if (createForm) {
        $(elements).filter(function() {
          return $(this).data('language') === $(this).data('main-language');
        }).show();
      }

      // Entoure le nouveau drapeau sélectionné
      $(flag).removeClass('active-flags-tab')
      $(this).find(flag).addClass('active-flags-tab')
    });
  };

};

const AddAndRemovePaFunction = (containers, minus, plus) => {
  const $removePaBtn = $(minus);
  const $addPaBtn = $(plus);

  if ($addPaBtn) {
    $addPaBtn.on('click', function() {
      // Ajoute la nouvelle possible_answer à la fin de la ligne
      Array.prototype.forEach.call(containers, (paContainer) => {
        const paNumber = paContainer.childElementCount
        const locale = paContainer.closest('.indicator-new-pa').getAttribute('data-language')
        const newInput = document.createElement('input');

        newInput.setAttribute('type', 'text');
        newInput.setAttribute('placeholder', '...');
        newInput.classList.add('new-indicator-pa-input', 'indicator-form-input');

        if ($addPaBtn.hasClass("new-indicator-add-pa-oui-non") && $(paContainer).children('.new-indicator-pa-input').length < 3) {
          newInput.setAttribute('name', `tag[oui_non][pa_${paNumber + 1}][${locale}]`);
          newInput.setAttribute('id', `tag_oui_non_pa_${paNumber + 1}_${locale}`);
          paContainer.appendChild(newInput);
        } else if ($addPaBtn.hasClass("new-indicator-add-pa-uniq")) {
          newInput.setAttribute('name', `tag[uniq][pa_${paNumber + 1}][${locale}]`);
          newInput.setAttribute('id', `tag_uniq_pa_${paNumber + 1}_${locale}`);
          paContainer.appendChild(newInput);
        }
      });
      disableEnableMinusForPa(containers, minus);

      if ($addPaBtn.hasClass("new-indicator-add-pa-oui-non")) {
        disableEnableplusForPa(containers, plus)
      }

      validationForCreateIndicator('addPaFuntion');

    });
  };

  if ($removePaBtn) {
    $removePaBtn.on('click', function() {
      // Retire la div souhaitée
      const paContainers = $(containers);
      paContainers.each(function() {
        const paContainer = $(this);
        const paElements = paContainer.children('.new-indicator-pa-input');

        if (paElements.length > 2) {
          paElements.last().remove();
        }
      });

      disableEnableMinusForPa(containers, minus);

      if ($addPaBtn.hasClass("new-indicator-add-pa-oui-non")) {
        disableEnableplusForPa(containers, plus)
      };

      validationForCreateIndicator('removePaFuntion');
    });
  };
};

const disableEnableMinusForPa = (containers, minus) => {
  // Disable le " - " si le nombre d'inputs est égal à 2
  const elementsNumber = containers[0].querySelectorAll('.new-indicator-pa-input').length
  if (elementsNumber === 2) {
    $(minus).addClass('inactive-add-remove');
  } else {
    $(minus).removeClass('inactive-add-remove');
  }
};

const disableEnableplusForPa = (containers, plus) => {
  // Disable le " + " si le nombre d'inputs est égal à 3
  const elementsNumber = containers[0].querySelectorAll('.new-indicator-pa-input').length

  if (elementsNumber === 3) {
    $(plus).addClass('inactive-add-remove');
  } else {
    $(plus).removeClass('inactive-add-remove');
  }
};

const validationForCreateIndicator = (keyupAndAddPaFunction) => {
  const indicatorThemeInput = document.getElementById('theme-indicator-input');
  const indicatorQuestionInputs = document.querySelectorAll('[id^="question_"]');
  const indicatorPossibleAnswerInputs = document.querySelector('.form-new-possibles-answers-container:not(.hidden)').querySelectorAll('.new-indicator-pa-input');
  const indicatorPossibleAnswerNationality = document.querySelector('.form-new-possibles-answers-container:not(.hidden)').querySelectorAll('[id^="placeholder_nationality_"]');

  // Vérifie si tous les inputs sont remplis
  const allInputs = [
    indicatorThemeInput,
    ...indicatorQuestionInputs,
    ...indicatorPossibleAnswerInputs,
    ...indicatorPossibleAnswerNationality
  ];
  const allContentFilled = noBlankInput(allInputs);

  // Vérifie si le thème n'est pas déjà associé à un indicateur
  const themeInput = document.getElementById('theme-indicator-input');
  const otherThemeDatas = themeInput.getAttribute('data-other-theme-names');
  const otherThemes =  JSON.parse(otherThemeDatas);
  const allThemes = ([themeInput.value].concat(otherThemes)).map(theme => theme.toLowerCase().trim());
  const isSameTheme = notSameContent(allThemes);

  // Vérifie si deux possible answers ne sont pas identiques
  const isSame = []
  document.querySelector('.form-new-possibles-answers-container:not(.hidden)').querySelectorAll(".indicator-new-pa").forEach( (div) => {
    const inputs = div.querySelectorAll(".new-indicator-pa-input")
    isSame.push(notSameContent(inputs));
  })

  // Display & Hide la div "alert" pour les validations du bouton Submit
  const alertContainer = document.getElementById('alert-validations-create-indicator')
  if (alertContainer) {
    if (!allContentFilled) {
        if (keyupAndAddPaFunction) {
          alertContainer.classList.remove('hidden')
          alertContainer.innerHTML = document.getElementById('alert-validations-create-indicator').dataset.alerts.split(",")[1]
        }
    } else if (isSame.includes(true)) {
        alertContainer.classList.remove('hidden')
        alertContainer.innerHTML = document.getElementById('alert-validations-create-indicator').dataset.alerts.split(",")[0]

    } else if (isSameTheme) {
        alertContainer.classList.remove('hidden')
        alertContainer.innerHTML = document.getElementById('alert-validations-create-indicator').dataset.alerts.split(",")[2]
    } else {
        alertContainer.classList.add('hidden')
    }
  }

  // Toggle class "not-clickable" si les validations sont respectées ou non
  const indicatorFormBtn = document.getElementById('create-indicator-btn');
  const indicatorFormBorder = document.getElementById('indicator-create-container')

  if (allContentFilled && !isSame.includes(true) && !isSameTheme) {
    indicatorFormBtn.classList.remove("not-clickable");
    indicatorFormBtn.setAttribute("type", "submit");
    indicatorFormBorder.classList.add('indicator-create-container-border');
  } else {
    indicatorFormBtn.classList.add("not-clickable");
    indicatorFormBtn.removeAttribute("type");
    indicatorFormBorder.classList.remove('indicator-create-container-border');

  }
}

// function addNewIndicatorToList() {
// 	const form = document.getElementById("indicator-create-container")

// 	form.addEventListener("submit")
// }
