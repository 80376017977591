const modal_relance = () => {

  const btns = document.querySelectorAll(".far.fa-envelope.enveloppe-icon-design");

  Array.prototype.forEach.call(btns, function(btn) {
    btn.addEventListener("click", () => {
      const span = document.querySelector("#numCust");
      span.innerText = `${btn.getAttribute("data-id")}`;
    })
  })
};

export{ modal_relance };
