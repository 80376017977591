const create_campaign = () => {
  const campagneEmailPage = document.querySelector("#js-mail-preview.campagne_mail")

  if (campagneEmailPage && !(campagneEmailPage.classList.contains("on-edit-for-js"))){
    console.log("create_campaign")
    const form = document.getElementById("Creationcampagne")
    const btnSave = document.getElementById("btn-save-campaign-js").firstElementChild
    const btnSend = document.getElementById("btn-send-campaign-js").firstElementChild
    const btnSchedule = document.getElementById("btn-schedule-campaign-js").firstElementChild
    const fakeEtablissement = document.querySelector("#js-mail-preview").dataset.fake

    const checkConditions = function() {
      console.log("checkConditions")

      // type campagne
      let typeCampagne = form.querySelectorAll("input[name='type_campagne']:checked")[0].value

      // filter on customers
      let inputFilters = form.querySelectorAll("input[name='filter_on_customers']:checked")
      let radiosFilterCustomerClicked = inputFilters.length
      let valueOfEnqueteForFilter = form.querySelectorAll("select#filter_by_enquete")[0].value
      let valueOfCustomerPerDateStartForFilter = form.querySelectorAll("#start_date_custo")[0].value
      let valueOfCustomerPerDateEndForFilter = form.querySelectorAll("#end_date_custo")[0].value
			let customersOfSelect2;
      if (form.querySelector("ul.select2-selection__rendered")) {
				customersOfSelect2 = form.querySelector("ul.select2-selection__rendered").innerText
      }

      // select date of schedule
      let scheduleCapaignOuiNon = form.querySelectorAll("input[name='scheduled_oui_non']:checked").length
      let dateValue = form.querySelector("input[name='date_of_schedule']").value

      //theme campagne
      let themeOldOrNew = form.querySelectorAll("input[name='new_campagne']:checked")
      let valueOfNewThemeMail = form.querySelector("input[name='identifier']").value
      let valueOfNewThemeSMS = form.querySelector("input[name='identifier_sms']").value
      let ChoseOldCampaignMail = form.querySelectorAll("select#restaurant_mails_mail")[0].value
      let ChoseOldCampaignSms = form.querySelectorAll("select#restaurant_mails_sms")[0].value

      //object value
      let objectValue = form.querySelector("input#object").value

      //email
      let corps = tinyMCE.activeEditor

      //sms
      let corps_sms = form.querySelector("textarea").value

      // TOGGLE BTN SAVE: pour que ce btn soit accessible il faut que:
        // si mail : corps, object soit renseigné + nom de la nouvelle campagne si il a coché 'nouvelle campagne' OU nom de l'ancienne campagne si il en choisi une déjà utilisée
        // si sms: corps + nom de la nouvelle campagne si il a coché 'nouvelle campagne' OU nom de l'ancienne campagne si il en choisi une déjà utilisée
      if (themeOldOrNew.length === 1 &&
        (( themeOldOrNew[0].id === "new_campagne_false" && ((ChoseOldCampaignMail !== "" && typeCampagne === "mail") || (ChoseOldCampaignSms !== "" && typeCampagne === "sms") ))  || (typeCampagne === "mail" && themeOldOrNew[0].id  === "new_campagne_true" && valueOfNewThemeMail !== "" && tinyMCE.activeEditor.getContent().length > 5 && objectValue.length > 3) || (typeCampagne === "sms" && themeOldOrNew[0].id  === "new_campagne_true" && valueOfNewThemeSMS !== "" && corps_sms.length > 5 )   )
      ) {
				console.log("2")
        btnSave.disabled = false;
        btnSave.classList.remove("not-clickable")
      } else {
				console.log("3")
        btnSave.disabled = true;
        btnSave.classList.add("not-clickable")
      }

      // TOGGLE BTN SEND: pour que ce btn soit accessible il faut que:
        // Si mail : corps + object + filtre customer + nouvelle (avec identifier) ou ancienne campagne (avec id du rm) renseignée
        // si SMS : corps + filtre customer + nouvelle (avec identifier) ou ancienne campagne (avec id du rm) renseignée
      if (fakeEtablissement === 'false' && themeOldOrNew.length === 1 &&
        (( themeOldOrNew[0].id === "new_campagne_false" && ((ChoseOldCampaignMail !== "" && typeCampagne === "mail") || (ChoseOldCampaignSms !== "" && typeCampagne === "sms") ))  || (typeCampagne === "mail" && themeOldOrNew[0].id  === "new_campagne_true" && valueOfNewThemeMail !== "" && tinyMCE.activeEditor.getContent().length > 5 && objectValue.length > 3) || (typeCampagne === "sms" && themeOldOrNew[0].id  === "new_campagne_true" && valueOfNewThemeSMS !== "" && corps_sms.length > 5 )   ) &&
        radiosFilterCustomerClicked > 0 &&
        (inputFilters[0].id === "filter_on_customers_total" || inputFilters[0].id === "filter_on_customers_total_not_sent" || (inputFilters[0].id === "filter_on_customers_by_enquete" && valueOfEnqueteForFilter !== "") || (inputFilters[0].id === "filter_on_customers_by_customer_per_date" && valueOfCustomerPerDateStartForFilter !== "" && valueOfCustomerPerDateEndForFilter !== "") || (inputFilters[0].id === "filter_on_customers_individual_selection" && customersOfSelect2 !== ""))
      ) {
        console.log("4")
        btnSend.disabled = false;
        btnSend.classList.remove("not-clickable")

      } else {
        console.log("5")
        btnSend.disabled = true;
        btnSend.classList.add("not-clickable")
      }

      // TOGGLE BTN SCHEDULE: pour que ce btn soit accessible il faut que:
        // Si mail : corps + object + filtre customer + nouvelle (avec identifier) ou ancienne campagne (avec id du rm) renseignée + si btn schedule activé qu'il y ai une date
        // si SMS : corps + filtre customer + nouvelle (avec identifier) ou ancienne campagne (avec id du rm) renseignée + si btn schedule activé qu'il y ai une date
      if (fakeEtablissement === 'false' && themeOldOrNew.length === 1 &&
        (( themeOldOrNew[0].id === "new_campagne_false" && ((ChoseOldCampaignMail !== "" && typeCampagne === "mail") || (ChoseOldCampaignSms !== "" && typeCampagne === "sms") ))  || (typeCampagne === "mail" && themeOldOrNew[0].id  === "new_campagne_true" && valueOfNewThemeMail !== "" && tinyMCE.activeEditor.getContent().length > 5 && objectValue.length > 3) || (typeCampagne === "sms" && themeOldOrNew[0].id  === "new_campagne_true" && valueOfNewThemeSMS !== "" && corps_sms.length > 5 )   ) &&
        radiosFilterCustomerClicked > 0 &&
        (inputFilters[0].id === "filter_on_customers_total" || inputFilters[0].id === "filter_on_customers_total_not_sent" || (inputFilters[0].id === "filter_on_customers_by_enquete" && valueOfEnqueteForFilter !== "") || (inputFilters[0].id === "filter_on_customers_by_customer_per_date" && valueOfCustomerPerDateStartForFilter !== "" && valueOfCustomerPerDateEndForFilter !== "") || (inputFilters[0].id === "filter_on_customers_individual_selection" && customersOfSelect2 !== "")) &&
        (scheduleCapaignOuiNon === 1 &&  dateValue !== "")
      ) {
        console.log("6")
        btnSchedule.disabled = false;
        btnSchedule.classList.remove("not-clickable")

      } else {
        console.log("7")
        btnSchedule.disabled = true;
        btnSchedule.classList.add("not-clickable")
      }
    }

    setTimeout( function(event) {

      // Reset the idle timeout on any of the events listed below
      document.addEventListener('click', checkConditions, false)
      document.addEventListener('keyup', checkConditions, false)
      document.addEventListener('change', checkConditions, false)

      $('.select2_customer_for_campaign').on('select2:select', function (e) {
        checkConditions(e)
      });

      // listener sur tiny
      if (tinymce.activeEditor) {
        tinymce.activeEditor.on('keyup', function(e) {
          checkConditions(e)
        });
      }
    }, 0)

  }
}


const edit_campaign = () => {

  const campagneEmailPage = document.querySelector("#js-mail-preview.campagne_mail")

  if (campagneEmailPage && campagneEmailPage.classList.contains("on-edit-for-js")){
    console.log("edit_campaign")
    document.querySelector(".previsualisation-ancienne-campagne-sms-js").classList.add("hidden")
    document.querySelector(".previsualisation-ancienne-campagne-sms-js").querySelector('.alert-notice').classList.add("hidden")
    const typeMail = campagneEmailPage.dataset.typeMail
    const form = document.getElementById("Creationcampagne")
    const btnSave = document.getElementById("btn-save-campaign-on-edit-js").firstElementChild
    const btnSend = document.getElementById("btn-send-campaign-on-edit-js").firstElementChild
    const fakeEtablissement = document.querySelector("#js-mail-preview").dataset.fake

    const checkConditionsEdit = function() {
      console.log("checkConditionsEdit")

      // type campagne
      let typeCampagne = form.querySelectorAll("input[name='type_campagne']:checked")[0].value

      // filter on customers
      let inputFilters = form.querySelectorAll("input[name='filter_on_customers']:checked")
      let radiosFilterCustomerClicked = inputFilters.length
      let valueOfEnqueteForFilter = form.querySelectorAll("select#filter_by_enquete")[0].value
      let valueOfCustomerPerDateStartForFilter = form.querySelectorAll("#start_date_custo")[0].value
      let valueOfCustomerPerDateEndForFilter = form.querySelectorAll("#custo")[0].value
      let customersOfSelect2 = form.querySelector("ul.select2-selection__rendered").innerText

      // select date of schedule
      let scheduleCapaignOuiNon = form.querySelectorAll("input[name='scheduled_oui_non']:checked").length
      let dateValue = form.querySelector("input[name='date_of_schedule']").value

      //theme campagne
      let themeOldOrNew = form.querySelectorAll("input[name='new_campagne']:checked")
      let valueOfNewThemeMail = form.querySelector("input[name='identifier']").value
      let valueOfNewThemeSMS = form.querySelector("input[name='identifier_sms']").value
      let ChoseOldCampaignMail = form.querySelectorAll("select#restaurant_mails_mail")[0].value
      let ChoseOldCampaignSms = form.querySelectorAll("select#restaurant_mails_sms")[0].value

      if (document.getElementById("btn-schedule-campaign-on-edit-js")) {
        let btnSchedule = document.getElementById("btn-schedule-campaign-on-edit-js").firstElementChild
      }

      //object value
      let objectValue = form.querySelector("input#object").value

      //email
      let corps = tinyMCE.activeEditor

      //sms
      let corps_sms = form.querySelector("textarea").value

      if (campagneEmailPage.dataset.typeEdit === "edit-already-scheduled" && typeMail === "campagne sms") {
        console.log("edit sms of a scheduled campaign")

        if (fakeEtablissement === 'false' && corps_sms.length > 5) {
          btnSend.disabled = false;
          btnSave.disabled = false;
          btnSend.classList.remove("not-clickable")
          btnSave.classList.remove("not-clickable")
        } else if (fakeEtablissement === 'true' && corps_sms.length > 5) {
          btnSend.disabled = true;
          btnSave.disabled = false;
          btnSend.classList.add("not-clickable")
          btnSave.classList.remove("not-clickable")
        } else {
          btnSend.disabled = true;
          btnSave.disabled = true;
          btnSend.classList.add("not-clickable")
          btnSave.classList.add("not-clickable")
        }


      } else if (campagneEmailPage.dataset.typeEdit === "edit-already-scheduled" && typeMail === "campagne mail") {
        console.log("edit mail of a scheduled campaign")

        if (fakeEtablissement === 'false' && tinyMCE.activeEditor.getContent().length > 5 && objectValue.length > 3) {
          console.log("if")
          btnSend.disabled = false;
          btnSave.disabled = false;
          btnSend.classList.remove("not-clickable")
          btnSave.classList.remove("not-clickable")
        } else if (fakeEtablissement === 'true' && tinyMCE.activeEditor.getContent().length > 5 && objectValue.length > 3) {
          console.log("else if")
          btnSend.disabled = true;
          btnSave.disabled = false;
          btnSend.classList.add("not-clickable")
          btnSave.classList.remove("not-clickable")
        } else {
          console.log("else")
          btnSend.disabled = true;
          btnSave.disabled = true;
          btnSend.classList.add("not-clickable")
          btnSave.classList.add("not-clickable")
        }

      } else if (campagneEmailPage.dataset.typeEdit === "edit-of-saved" && typeMail === "campagne sms") {

        if (fakeEtablissement === 'false' && corps_sms.length > 5 &&
          (scheduleCapaignOuiNon === 0 || (scheduleCapaignOuiNon === 1 && dateValue !== "") ) &&
          radiosFilterCustomerClicked > 0 &&
          (inputFilters[0].id === "filter_on_customers_total" || inputFilters[0].id === "filter_on_customers_total_not_sent" || (inputFilters[0].id === "filter_on_customers_by_enquete" && valueOfEnqueteForFilter !== "") || (inputFilters[0].id === "filter_on_customers_by_customer_per_date" && valueOfCustomerPerDateStartForFilter !== "" && valueOfCustomerPerDateEndForFilter !== "") || (inputFilters[0].id === "filter_on_customers_individual_selection" && customersOfSelect2 !== ""))
          ) {
          if (scheduleCapaignOuiNon === 1) {
            btnSchedule.disabled = false;
            btnSchedule.classList.remove("not-clickable")
          } else {
            btnSend.disabled = false;
            btnSend.classList.remove("not-clickable")
          }

        } else {

          if (scheduleCapaignOuiNon === 1) {
            btnSchedule.disabled = true;
            btnSchedule.classList.add("not-clickable")
          } else {
            btnSend.disabled = true;
            btnSend.classList.add("not-clickable")
          }
        }

        if (corps_sms.length > 5 ) {
          btnSave.disabled = false;
          btnSave.classList.remove("not-clickable")
        } else {
          btnSave.disabled = true;
          btnSave.classList.add("not-clickable")
        }

      } else if (campagneEmailPage.dataset.typeEdit === "edit-of-saved" && typeMail === "campagne mail") {

        if (fakeEtablissement === 'false' && tinyMCE.activeEditor.getContent().length > 5 &&
          objectValue.length > 3 &&
          (scheduleCapaignOuiNon === 0 || (scheduleCapaignOuiNon === 1 && dateValue !== "") ) &&
          radiosFilterCustomerClicked > 0 &&
          (inputFilters[0].id === "filter_on_customers_total" || inputFilters[0].id === "filter_on_customers_total_not_sent" || (inputFilters[0].id === "filter_on_customers_by_enquete" && valueOfEnqueteForFilter !== "") || (inputFilters[0].id === "filter_on_customers_by_customer_per_date" && valueOfCustomerPerDateStartForFilter !== "" && valueOfCustomerPerDateEndForFilter !== "") || (inputFilters[0].id === "filter_on_customers_individual_selection" && customersOfSelect2 !== ""))

          ) {

            if (scheduleCapaignOuiNon === 1) {
              btnSchedule.disabled = false;
              btnSchedule.classList.remove("not-clickable")
            } else {
              // btnSave.disabled = false;
              btnSend.disabled = false;
              // btnSave.classList.remove("not-clickable")
              btnSend.classList.remove("not-clickable")
            }
        } else {

            if (scheduleCapaignOuiNon === 1) {
              btnSchedule.disabled = true;
              btnSchedule.classList.add("not-clickable")
            } else {
              btnSend.disabled = true;
              btnSend.classList.add("not-clickable")
            }
        }

        if (tinyMCE.activeEditor.getContent().length > 5 && objectValue.length > 3) {
          btnSave.disabled = false;
          btnSave.classList.remove("not-clickable")
        } else {
          btnSave.disabled = true;
          btnSave.classList.add("not-clickable")
        }
      }
    }

    $('.select2_customer_for_campaign').on('select2:select', function (e) {
      checkConditionsEdit(e)
    });

    // listener sur tiny
    tinymce.activeEditor.on('keyup', function(e) {
        checkConditionsEdit(e)
    });

  }

}

const display_clients_numbers_for_compaign = () => {

  // JS pour le nombre de clients n'ayant pas reçu une enquete:
  const  enqueteDropdown = document.getElementById('filter_by_enquete');
  const  selectedEnqueteCount = document.getElementById('selected-enquete-count');

  if (enqueteDropdown) {
    // Ajouter un event listener à enqueteDropdown
    enqueteDropdown.addEventListener('change', function() {

      const selectedEnqueteId = this.value;
      console.log("this value",this.value);
      const clientNumber = selectedEnqueteId ? JSON.parse(selectedEnqueteCount.dataset.unsentEnqueteNbClients)[selectedEnqueteId] : 0;
      selectedEnqueteCount.innerHTML = "<strong>" + clientNumber + "</strong> clients";

      });
    };

    // JS pour le nombre de clients n'ayant pas reçu une campagne:
    const campaignDropdown = document.getElementById('restaurant_mails_mail');
    const  selectedCampaignCount = document.getElementById('selected-campaign-count');
    if (campaignDropdown) {

      campaignDropdown.addEventListener('change', function() {
        const selectedCampaignId = this.value;
        const selectedOption = this.querySelector(`[value='${selectedCampaignId}']`);
        const campaignName = selectedCampaignId ? selectedCampaignCount.dataset.traduction + "<strong>" + selectedOption.innerHTML + "</strong>" : "";
        const clientsNumber = selectedCampaignId ? JSON.parse(selectedCampaignCount.dataset.unsentCampaignNbClients)[selectedCampaignId] : 0;
        selectedCampaignCount.innerHTML = "<strong>" + clientsNumber + "</strong> clients<br/>" + campaignName ;

      });
    }
}

export {create_campaign, edit_campaign, display_clients_numbers_for_compaign}
