const disableBtn = function (btn) {
    btn.attr('disabled', true);
    btn.addClass('disabled');
}

const enableBtn = function (btn) {
    btn.attr('disabled', false);
    btn.removeClass('disabled');
}

export { disableBtn };
export { enableBtn };