const toggleTabsInCumulFidelite = () => {
  // pour afficher seulement la tab de creation ou d'affichage du client, mais les 2 ne peuvent pas être ouverte en meme temps.
  if (document.querySelector(".custom-btn.btn-add-clients")) {
    console.log("toggleTabsInCumulFidelite")
    const btnAddClient = document.querySelector(".custom-btn.btn-add-clients")
    const searchClient = document.querySelector(".custmer-search-info")
    const infoCumulFid = document.querySelector(".info-cumul-fid.no-client")

    btnAddClient.addEventListener('click', (event) => {
      if (searchClient ){ searchClient.classList.add("hidden") }
      if (infoCumulFid ){ infoCumulFid.classList.add("hidden") }
    })

  }

}

export { toggleTabsInCumulFidelite }
