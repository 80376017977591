import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["flashBanner"];

  connect() {
    const flash = document.querySelector(".flash-banner");
    // Pages where do not want the banner to disappear:
    const disableHideBanner = document.querySelector(".reward-activation-dashboard")
    if (!disableHideBanner) {
      setTimeout(function(){flash.classList.add("hidden")}, 4000);
    }
  }

  hideFlash() {
    this.flashBannerTarget.classList.add('hidden');
  }
}
