const logoBkgColorMenu = () => {
  //START_PAGE_MENU, MY_MENU: changer couleur du fond du logo de l'etablissement
	const previewDiv = document.querySelector("iframe#preview");
  const colorLogos = document.querySelectorAll('#bkg_col_logo_mm, #bkg_col_logo_spm');
  colorLogos.forEach((colorLogo) => {
    colorLogo.addEventListener("change", (event) => {
      if (previewDiv.contentDocument.getElementById("page-start-menu-for-js")) {
        if (previewDiv.contentDocument.querySelector(".logo-links-menu")) {
          previewDiv.contentDocument.querySelector(".logo-links-menu").style.backgroundColor = event.currentTarget.value
        }
      } else if (previewDiv.contentDocument.getElementById("page-my-menu-for-js")) {
        previewDiv.contentDocument.querySelector(".logo-menu-new").style.backgroundColor = event.currentTarget.value
      }
    })
  })


  //START_PAGE_MENU, MY_MENU: simuler la suppression de la couleur de fond du logo de l'etablissement dans l'iframe (sans avoir à rafraichir la page)
  const trashIcon = document.querySelectorAll('.remove-bkg-logo')
  trashIcon.forEach((trash) => {
    trash.addEventListener("click", (event) => {
      previewDiv.contentDocument.getElementById('dLabel').style.setProperty("background", "transparent", "important")
    })
  })


  // START PAGE
  const removeColorPickerInput = document.getElementById('remove-colorpicker-input-spm-for-js')
  let bkgColLogoSpm = document.getElementById('remove-colorpicker-spm')
  const displayColorPickerInput = document.getElementById('display_color_picker_bkg_logo_spm')
  // START PAGE : Supprimer l'input du color picker quand on clique sur la poubelle pour éviter qu'un fond noir s'applique en fond du logo après le submit
  const trashIconCustomMenu = document.getElementById('remove-bkg-logo-spm')
  if (trashIconCustomMenu) {
    trashIconCustomMenu.addEventListener("click", () => {
      bkgColLogoSpm.innerHTML = ""
      removeColorPickerInput.classList.add("hidden")
      document.querySelectorAll('.hide-check-text-spm').forEach((elem) => {
        elem.classList.remove("hidden")
      })
      displayColorPickerInput.checked = false
    })
  }
  // Afficher l'input du color picker pour pouvoir sélectionner une couleur à appliquer en fond du logo
  if (displayColorPickerInput && displayColorPickerInput.checked === false) {
    displayColorPickerInput.addEventListener("click", () => {
      bkgColLogoSpm.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_logo_spm' id='bkg_col_logo_spm'>")
      bkgColLogoSpm.querySelector("input").addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".logo-links-menu").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
      removeColorPickerInput.classList.remove("hidden")
      document.querySelectorAll('.hide-check-text-spm').forEach((elem) => {
        elem.classList.add("hidden")
      })
    })
  }


  // MY MENU PAGE
  const removeColorPickerInputMm = document.getElementById('remove-colorpicker-input-mm-for-js')
  let bkgColLogoMm = document.getElementById('remove-colorpicker-mm')
  const displayColorPickerInputMm = document.getElementById('display_color_picker_bkg_logo_mm')
  // MY MENU PAGE : Supprimer l'input du color picker quand on clique sur la poubelle pour éviter qu'un fond noir s'applique en fond du logo après le submit
  const trashIconCustomMenuMm = document.getElementById('remove-bkg-logo-mm')
  trashIconCustomMenuMm.addEventListener("click", () => {
    bkgColLogoMm.innerHTML = ""
    removeColorPickerInputMm.classList.add("hidden")
    document.querySelectorAll('.hide-check-text-mm').forEach((elem) => {
      elem.classList.remove("hidden")
    })
    displayColorPickerInputMm.checked = false
  })
  // Afficher l'input du color picker pour pouvoir sélectionner une couleur à appliquer en fond du logo
  if (displayColorPickerInputMm.checked === false) {
    displayColorPickerInputMm.addEventListener("click", () => {
      bkgColLogoMm.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_logo_mm' id='bkg_col_logo_mm'>")
      bkgColLogoMm.querySelector("input").addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".logo-menu-new").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
      removeColorPickerInputMm.classList.remove("hidden")
      document.querySelectorAll('.hide-check-text-mm').forEach((elem) => {
        elem.classList.add("hidden")
      })
    })
  }


  // Cacher le logo : simulation dans l'iframe
  //START PAGE
  const checkLogo = document.getElementById("hide_logo_spm")
  if (checkLogo) {
    checkLogo.addEventListener("click", () => {
      const selectLogoClass = previewDiv.contentDocument.querySelector('.check-if-logo-spm')
      selectLogoClass.classList.toggle('hidden')
    })
  }

  // MY MENU PAGE
  const checkLogoMm = document.getElementById("hide_logo_mm")
  if (checkLogoMm) {
    checkLogoMm.addEventListener("click", () => {
      const selectLogoClass = previewDiv.contentDocument.querySelector('.check-if-logo-mm')
      if (selectLogoClass) {
      selectLogoClass.classList.toggle('hidden')
      }
    })
  }
}

export { logoBkgColorMenu };
