const create_admin_mail = () => {
  const adminMailPage = document.getElementById("create-amin-mail-page-js")

  if (adminMailPage){
    console.log("create_admin_mail")

    //btn clickable/not-clickable de la création de l'admin mail
    const form = document.getElementById("CreationAdminMail")
    let btnValidate = document.getElementById("btn-create-admin-mail-js").firstElementChild

    const checkConditionsForAdminMail = function() {
      console.log("checkConditionsForAdminMail")

      // type campagne
      let typeAdminMailSms = form.querySelectorAll("input[name='typo']:checked")[0].value

      // select date of schedule
      let scheduleAdminMail = form.querySelectorAll("input[name='schedule']:checked")[0].value
      let timeTemporality = form.querySelector("select#temporality").value
      let timeValue = form.querySelector("input#hour").value

      //theme campagne
      let identifierAdminMail = form.querySelector("input#identifier").value

      // filter on customers
      let inputFilters = form.querySelectorAll("input[name='filter_on_customers']:checked")[0].value
      let radiosFilterCustomerClicked = inputFilters.length
      let valueOfGroupeAll = form.querySelectorAll("select#groups_all")[0].value
      let valueOfGroupeMultisite = form.querySelectorAll("select#groups_multisite")[0].value
      let valueOfGroupeUnique = form.querySelectorAll("select#groups_unique")[0].value
      let customersOfSelect2 = form.querySelectorAll("select#gerant_ids")[0].value

      //object value
      let objectValue = form.querySelector("input#object").value

      //email
      let corps = tinyMCE.activeEditor

      //sms
      let corps_sms = form.querySelector("textarea").value


      if ( identifierAdminMail.length > 3
        && ((scheduleAdminMail === 'true' && timeValue !== '' && timeTemporality !== '' ) || scheduleAdminMail === 'false' )
        && ( (inputFilters === 'total')  ||  (inputFilters === 'total_of_specific_group' && valueOfGroupeAll !== '')  ||   (inputFilters === 'total_multisite_of_specific_group' && valueOfGroupeMultisite !== '')    ||   (inputFilters === 'total_unique_of_specific_group' && valueOfGroupeUnique !== '')  ||   (inputFilters === 'individual_selection' && customersOfSelect2 !== '')  )
        && (  (typeAdminMailSms === "email" && objectValue.length > 3)   ||  (typeAdminMailSms === "sms" && corps_sms.length > 5) )
      ) {
        console.log("if")
        btnValidate.disabled = false;
        btnValidate.classList.remove("not-clickable")

      } else {
        console.log("else")
        btnValidate.disabled = true;
        btnValidate.classList.add("not-clickable")
      }

    }

			// ['click', 'keyup', 'change'].forEach(evts =>
			// 	document.addEventListener(evts, checkConditionsForAdminMail, false)
			// );

			document.addEventListener("click", checkConditionsForAdminMail, false)
			document.addEventListener("keyup", checkConditionsForAdminMail, false)
			document.addEventListener("change", checkConditionsForAdminMail, false)

			$('.select2_customer_for_campaign').on('select2:select', function (e) {
			checkConditionsForAdminMail(e)
			});

			// listener sur tiny
			tinymce.activeEditor.on('keyup', function(e) {
			checkConditionsForAdminMail(e)
			});


    // window.addEventListener("DOMContentLoaded", (event) => {

    //   // Reset the idle timeout on any of the events listed below
    //   ['click', 'keyup', 'change'].forEach(evts =>
    //       document.addEventListener(evts, checkConditionsForAdminMail, false)
    //   );

    //   $('.select2_customer_for_campaign').on('select2:select', function (e) {
    //     checkConditionsForAdminMail(e)
    //   });

    //   // listener sur tiny
    //   tinymce.activeEditor.on('keyup', function(e) {
    //      checkConditionsForAdminMail(e)
    //   });
    // })

  }
}

export {create_admin_mail}
