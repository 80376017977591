const toggleDisableEnqueteFilter = () => {
  const enqueteRetraiteFilter = $('#enqueteRetraiteFilter')

  if((window.location.href.indexOf("comparatif_enquetes_") === -1) && enqueteRetraiteFilter) {
    const enqueteRetraiteOptions = $("#enqueteRetraiteFilter :input")
    const enqueteRetraiteDropdown = $("#enqueteRetraiteFilter")
    const enqueteRetraiteBtn = enqueteRetraiteDropdown.prev()
    const enqueteBruteOptions = $("#enqueteFilter :input")
    const enqueteBruteDropdown = $("#enqueteFilter")
    const enqueteBruteBtn = enqueteBruteDropdown.prev()

    enqueteRetraiteDropdown.on('click', function(){
      toggleEnableEnquete(enqueteBruteBtn, enqueteBruteDropdown, enqueteBruteOptions, true)
    })

    enqueteBruteDropdown.on('click', function(){
      toggleEnableEnquete(enqueteRetraiteBtn, enqueteRetraiteDropdown, enqueteRetraiteOptions, false)
    })
  }
}

const toggleEnableEnquete = (enqueteBtn, enqueteDropdown, enqueteOptions, enqueteRetraite) => {
  const enquetesChecked = enqueteRetraite ? $("#enqueteRetraiteFilter :input[type=checkbox]:checked").length : $("#enqueteFilter :input[type=checkbox]:checked").length;

  if (enquetesChecked == 0) {
    enqueteBtn.removeClass("filter-disabled")
    enqueteBtn.prop("disabled", false)
    enqueteOptions.removeAttr('disabled');
  } else {
    enqueteBtn.addClass("filter-disabled")
    enqueteDropdown.removeClass("show")
    enqueteBtn.prop("disabled", true)
    enqueteOptions.attr('disabled', 'disabled');
  }
}

export { toggleDisableEnqueteFilter }
