
const createRedirection = () => {
    if (document.getElementById("redirections-index-for-js")) {
        console.log("createRedirection")

        //toggle div of condition if checkbox checked
        const checkBoxCondition = document.getElementById("conditions_oui_non")
        if (checkBoxCondition) {
            const divConditions = document.getElementById("condition-div")
            checkBoxCondition.addEventListener('change', (event) => {
                divConditions.classList.toggle("hidden")
            })
        }

        //show right questions according to groupe_question selected
        const selectGroupeQuestion = document.getElementById("groupe_question")
        if (selectGroupeQuestion) {
            selectGroupeQuestion.addEventListener('change', (event) => {
                console.log(event.currentTarget.value)
                const allQuestions = document.querySelectorAll(".questions-for-js")
                const allAnswers = document.querySelectorAll(".answers-for-js")
                allQuestions.forEach((quest) => { quest.classList.add("hidden")})
                allAnswers.forEach((answ) => { answ.classList.add("hidden")})
                document.querySelectorAll("[type='checkbox']:not(#conditions_oui_non)").forEach((chckbx) => {chckbx.checked = false})

                document.getElementById(`${event.currentTarget.value}`).classList.remove("hidden")
            })
        }

        // show right conditions (answers) according to question selected
        const selectQuestions = document.querySelectorAll("[id^='question_id_of_']")
        if (selectQuestions != []) {
            selectQuestions.forEach((select_question) => {
                select_question.addEventListener('change', (event) => {
                    console.log(event.currentTarget.value)
                    const allAnswers = document.querySelectorAll(".answers-for-js")
                    allAnswers.forEach((answ) => { answ.classList.add("hidden")})
                    document.querySelectorAll("[type='checkbox']:not(#conditions_oui_non)").forEach((chckbx) => {chckbx.checked = false})
                    document.getElementById(`answer-${event.currentTarget.value}`).classList.remove("hidden")
                })
            })
        }


    }

}

export { createRedirection};
