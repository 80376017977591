const searchRestaurants = () => {
  const searchResto = document.querySelector('#research-search-bar-navbar-restaurants_index_nav');

  if (searchResto) {
    console.log('searchRestaurants');
    const listRestos = document.querySelector(".restolist.js").children
    const listRestoNames = []

    Array.prototype.forEach.call(listRestos, function(restoName) {
      listRestoNames.push(restoName.dataset.name)
    });
    const filteredListRestoNames = listRestoNames.filter(function(x) {
         return x !== undefined;
    });

    searchResto.addEventListener("keyup", (event) => {
      const ListToHidden = filteredListRestoNames.filter(function(x) {
          return  !x.includes(searchResto.value.toLowerCase())
      });

      Array.prototype.forEach.call(listRestos, function(resto) {
        if (ListToHidden.includes(resto.dataset.name)){
          resto.classList.add('hidden');
        } else {
          resto.classList.remove('hidden');
          console.log(resto.innerText.toLowerCase());
        }
      })
    })
  }

}

const searchCustomers = () => {
  const searchUser = document.querySelector('#research-search-bar-navbar-customers');
  if (searchUser) {
    console.log('searchCustomers')
    searchUser.addEventListener('keyup', () => {

      if (searchUser.value === "") {
        const url_string = window.location.href
        const url = new URL(url_string);
        const redirectionPath = url.pathname
        location.href = redirectionPath
      }
    })
  }
};

const searchCustomersrgpd = () => {
  const searchCustom = document.querySelector('#research-search-bar-customers_rgpd');
  if (searchCustom) {
    console.log('searchCustomersrgpd')
    searchCustom.addEventListener('keyup', () => {

      if (searchCustom.value === "") {
        const url_string = window.location.href
        const url = new URL(url_string);
        const redirectionPath = url.pathname
        location.href = redirectionPath
      }
    })
  }
};



const searchCustomersbase = () => {
  const searchCustombase = document.querySelector('#research-search-bar-customers_list');
  if (searchCustombase) {
    console.log('searchCustomersbase')
    searchCustombase.addEventListener('keyup', () => {
      if (searchCustombase.value === "") {
        const url_string = window.location.href
        const url = new URL(url_string);
        const redirectionPath = url.pathname
        location.href = redirectionPath
      }
    })
  }
};

const searchScanMenuDigital = () => {
  const searchRestaurantScan = document.querySelector('#research-search-bar-scan-menu-digital');
  if (searchRestaurantScan) {
    console.log('searchRestaurantScan');

    // Needed to get the number of items displayed before search
    const searchScanResultsCount = document.querySelector('.search-scan-results-count');
    if (searchScanResultsCount.innerText === "") {
      let temporaryResults = document.getElementById("table_id").getElementsByTagName("tr").length - 1;

      // Condition for the new table where there is 1 more <tr> tag
      if (document.getElementById('table-scroll')) {
        temporaryResults = document.getElementById("table_id").getElementsByTagName("tr").length - 2;
      }

      if (temporaryResults == 1) {
        searchScanResultsCount.innerText = `${temporaryResults} établissement`;
      } else {
        searchScanResultsCount.innerText = `${temporaryResults} établissements`;
      }
    }

    // Init search by keyup
    searchRestaurantScan.addEventListener("keyup", (event) => {
      let displayedItems = -1;

      // Condition for the new table where there is 1 more <tr> tag
      if (document.getElementById("table-scroll")) {
        displayedItems = -2;
      }
      var filter, table, tr, td, i, j;

      filter = searchRestaurantScan.value.toUpperCase();
      table = document.getElementById("table_id");
      tr = table.getElementsByTagName("tr");

      // Loop through all table rows, and hide those who don't match the search query
      for (i = 0; i < tr.length; i++) {
        td = tr[i].querySelectorAll("#search-value-scan-my-menu") ;
        for(j=0 ; j<td.length ; j++)
        {
          let tdata = td[j] ;
          if (tdata) {
            if (tdata.innerHTML.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
              break ;
            } else {
              tr[i].style.display = "none";
            }
          }
        }
      }

      // Needed to get the number of items displayed
      for (i = 0; i < tr.length; i++) {
        if (tr[i].style.display == "") {
          displayedItems += 1;
        }
      }
      if (displayedItems == 1) {
        searchScanResultsCount.innerText = `${displayedItems} établissement`;
      } else {
        searchScanResultsCount.innerText = `${displayedItems} établissements`;
      }
    })
  }
};

const searchSurveys = () => {
  const searchSurvey = document.querySelector('#research-search-bar-navbar-surveys');


  if (searchSurvey) {
    console.log('searchSurveys')
    searchSurvey.addEventListener('keyup', () => {
      const url_string = window.location.href
      const url = new URL(url_string);
      if (searchSurvey.value === "") {
        if (url.search !== "" && url.search.includes("date")) {
          const searchDate = url.search.slice(url.search.indexOf("date"), url.search.indexOf("date") + 15)
          const redirectionPath = `${url.pathname}?utf8=✓&${searchDate}`
          location.href = redirectionPath
        } else {
          const redirectionPath = url.pathname
          location.href = redirectionPath
        }
      }
      else {
        if (url.search !== "" && url.search.includes("date")){
          const searchDate = url.search.slice(url.search.indexOf("date"), url.search.indexOf("date") + 15)
          const redirectionPath = `${url.pathname}?utf8=✓&${searchDate}&query=${searchSurvey.value}`
          location.href = redirectionPath
        }
      }
    })
  }
}

const searchLoginActivity = () => {
  const searchLogin = document.querySelector('#research-search-bar-navbar-login-activities-nav');
  if (searchLogin) {
    console.log('searchLoginActivity')
    searchLogin.addEventListener('keyup', () => {

      if (searchLogin.value === "") {
        const redirectUrl =  `../login_activities`
        location.href = redirectUrl
      }
    })
  }
};

const searchUser = () => {
  const searchUser = document.querySelector('#research-search-bar-navbar-users');
  if (searchUser) {
    console.log('searchUser')
    searchUser.addEventListener('keyup', () => {

      if (searchUser.value === "") {
        const redirectUrl =  `../users`
        location.href = redirectUrl
      }
    })
  }

};

const searchRestaurantIndex = () => {

  const searchFormRestoIndex = document.getElementById("research-search-bar-restaurants-index")
  if (searchFormRestoIndex) {
    console.log("searchRestaurantIndex")
    const listRestos = document.querySelector(".restolist.js").children
    const listRestoNames = []
    Array.prototype.forEach.call(listRestos, function(restoName) {
      listRestoNames.push(restoName.dataset.name)
    });

    searchFormRestoIndex.addEventListener("keyup", (event) => {
      const ListToHidden = listRestoNames.filter(function(x) {
        console.log (!x.includes(searchFormRestoIndex.value.toLowerCase()))
        return  !x.includes(searchFormRestoIndex.value.toLowerCase())
      })
      console.log(ListToHidden);

      Array.prototype.forEach.call(listRestos, function(resto) {
        if (ListToHidden.includes(resto.dataset.name)){
          resto.classList.add('hidden');
        } else {
          resto.classList.remove('hidden');
          console.log(resto.innerText.toLowerCase());
        }
      })
    })
  }
}

export {searchRestaurantIndex, searchRestaurants, searchCustomers, searchSurveys, searchLoginActivity, searchUser, searchCustomersrgpd, searchCustomersbase, searchScanMenuDigital };
