import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { createLabelsArray } from './utilities'

export function courbe_evolution_csat() {

  let locale;
  let format = "cumul"
  if (document.querySelector(".nav-filter")) {
    locale = document.querySelector(".nav-filter").dataset.locale
  }
  if (document.getElementById("date-data")) {
    format = document.getElementById("date-data").dataset.timeframe
  }
  let labelUn = "test"
  let labelDeux = "test"
  console.log(format)

  if (locale == "fr") {
    if (format === "day") {
      labelUn = "Aujourd'hui ";
      labelDeux = "Hier ";
    }
    else if (format === "week") {
      labelUn = "Cette semaine ";
      labelDeux = "La semaine dernière ";
    }
    else if (format === "month") {
      labelUn = "Ce mois-ci ";
      labelDeux = "Le mois dernier ";
    }
    else if (format === "year") {
      labelUn = "Cette année ";
      labelDeux = "L'année dernière ";
    }
    else if (format === "cumul") {
      labelUn = "Cumul ";
      labelDeux = " ";
    }
    else {
      labelUn = "Cette periode";
      labelDeux = "Periode précédente";
    }
  } else if (locale == "en") {
    if (format === "day") {
      labelUn = "Today ";
      labelDeux = "Yesterday ";
    }
    else if (format === "week") {
      labelUn = "This week ";
      labelDeux = "Last week ";
    }
    else if (format === "month") {
      labelUn = "This month ";
      labelDeux = "Last month ";
    }
    else if (format === "year") {
      labelUn = "This year ";
      labelDeux = "Last year ";
    }
    else if (format === "cumul") {
      labelUn = "Cumul ";
      labelDeux = " ";
    }
    else {
      labelUn = "Cette periode";
      labelDeux = "Periode précédente";
    }
  }


	const evolutionSatisfactions = document.querySelectorAll(".evolution-satisfaction-chart");

  if (evolutionSatisfactions.length > 0) {
    console.log("evolution-satisfaction-chart");
    evolutionSatisfactions.forEach((evolutionSatisfaction) => {
      let max = 100;
      let max_value = 8;
			const date = new Date(document.getElementById("date")?.value);
			console.log("const date = new Date(document.getElementById(\"date\")?.value);", date)
      let labels = createLabelsArray(date, format);
			console.log("labels", labels)
      if (document.URL.includes("/scan_my_menu")) {
        // Displays numbers above the bar if the bar is not big enough.
        let scansScores = evolutionSatisfaction.dataset.points.match(/\d+/g).map(Number).filter(number => !isNaN(number));
        let highestScanscore = Math.max.apply(null, scansScores);
        max_value = highestScanscore * 0.20;
        // Change the date displayed on the labels according to the temporality.
        let dayBeforeYesterday = new Date();
        if (format === "Day" || format === "Jour" || format === "Month" || format === "Mois" || format === "Year" || format === "Année") {
          dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 2);
        } else if (format === "Week" || format === "Semaine") {
          dayBeforeYesterday.setDate(dayBeforeYesterday.getDate() - 1);
        }
        labels = createLabelsArray(dayBeforeYesterday, format);
      }

      if (evolutionSatisfaction.dataset.maxYAxe) {
        max = evolutionSatisfaction.dataset.maxYAxe
      }

      let tempFormat = format
      console.log("evolutionSatisfaction", evolutionSatisfaction)
      console.log("labels", labels)
      console.log("date", date)
      if (document.getElementById("calendar")) {
        console.log(document.getElementById("calendar").dataset.ranges)
      }
      if (evolutionSatisfaction.getAttribute('id') == 'five') {
        max = 5;
        max_value = 4;
        if (evolutionSatisfaction.classList.contains("five-day")) { format = "Jour" }
        if (evolutionSatisfaction.classList.contains("five-week")) { format = "Semaine" }
        if (evolutionSatisfaction.classList.contains("five-month")) { format = "Mois" }
        if (evolutionSatisfaction.classList.contains("five-year")) { format = "Année" }
        labels = createLabelsArray(date, format);
        format = tempFormat;
      }
      let maxTicks = 11;
      let labelHover = 'CSAT'
      if (document.URL.includes("/scan_my_menu")) {
        maxTicks = 5;
        labelHover = "Scans"
      }

      if (evolutionSatisfaction.dataset.typeNoteUnDix == 'true') {
        max = 10;
        if (locale == "fr") {
          labelHover = "Nombre d'avis par note ";
        } else {
          labelHover = "Number of reviews per note";
        }
      }

      const evolution_satisfaction_generale = new Chart(evolutionSatisfaction, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [{
            label: labelHover, //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'

            data: JSON.parse(evolutionSatisfaction.dataset.points),
            backgroundColor: [
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#5d78ff',
            ],

            hoverBackgroundColor: [
              'rgb(81,138,268)',
              'rgb(81,138,268)',
              'rgb(81,138,268)',
              'rgb(81,138,268)',
              'rgb(81,138,268)',
              'rgb(81,138,268)',
              'rgb(81,138,268)',
              'rgb(81,138,268)',
            ],

            borderColor: [
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#e5e7f1',
              '#5d78ff',
            ],

            hoverBorderColor: [
              '#5ca4e3',
              '#5ca4e3',
              '#5ca4e3',
              '#5ca4e3',
              '#5ca4e3',
              '#5ca4e3',
              '#6b76ff',
            ],

            borderWidth: 1
          }]
          // pour fixer le gadriage et les axes du graphe
        },
        options: {
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
            }],
            yAxes: [{
              ticks: {
                display: true,
                stepSize: 1,
                suggestedMin: 0,
                suggestedMax: max,
                callback: function (label) {
                  if (document.URL.includes("/scan_my_menu")) {
                    // Adding a space between numbers: "10000" will become "10 000".
                    return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
                  } else {
                    return label;
                  }
                },
                maxTicksLimit: maxTicks,
              },
              gridLines: {
                drawBorder: false,
                display: false
              },
            }]
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: function (context) {
                return context.dataset.data[context.dataIndex] < max_value ? 'top' : 'start';
              },
              color: function (context) {
                return context.dataset.data[context.dataIndex] < max_value ? ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff'] : ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', 'white'];
              },
              formatter: function (value, context) {
                if (value) {
                  if (evolutionSatisfaction.dataset.evolutionCsat === "true") {
                    let percentage = value + "%";
                    return percentage;
                  } else {
                    return value;
                  }
                }
                return '';
              },
            },
          },
          tooltips: {
            callbacks: {
              label: function (tooltipItem, data) {
                const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
                const value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] || 0;
                if (value !== 0) {
                  if (evolutionSatisfaction.dataset.evolutionCsat === "true") {
                    return datasetLabel + ': ' + value + '%';
                  } else {
                    return datasetLabel + ': ' + value
                  }
                }
              },
            },
          },
        },
      });

      let chart = evolution_satisfaction_generale
      chart.options.animation = false;


      console.log(evolution_satisfaction_generale.data.labels);
      console.log(evolution_satisfaction_generale.data.labels[0]);
      console.log(typeof evolution_satisfaction_generale.data.labels[0]);

      if (evolution_satisfaction_generale.data.labels[0] == 'NaN undefined' || evolution_satisfaction_generale.data.labels[0] == 'S NaN/NaN' || evolution_satisfaction_generale.data.labels[0] == 'undefined NaN' || `${evolution_satisfaction_generale.data.labels[0]}` == 'NaN') {
        location.reload();
      }
    })

  }
}
