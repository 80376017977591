const btnCreateGroupAppearInRestaurantNew = () => {
  if (document.querySelector('.btn-for-modal-creation-group-js')) {
    console.log('btnCreateGroupAppearInRestaurantNew')
    const collection = document.querySelector('.collection-of-groups-for-js')
    if (collection) {
      const arrayOfGroups = JSON.parse(document.querySelector('.collection-of-groups-for-js').dataset.allNameGroups)

      collection.addEventListener('change' , (event) => {

        if (parseInt(document.querySelector('.collection-of-groups-for-js').selectedIndex) - 1 == arrayOfGroups.indexOf("Ajouter un nouveau groupe") ) {
          document.querySelector('.btn-for-modal-creation-group-js').classList.remove('hidden')
        } else {
          document.querySelector('.btn-for-modal-creation-group-js').classList.add('hidden')
        }
      })
    }
  }
}


const btnCreateGroupAppearInRestaurantNewWithParams = () => {
  if (document.querySelector('.btn-for-modal-creation-group-js')) {
    console.log('btnCreateGroupAppearInRestaurantNewWithParams')
    const collection = document.querySelector('.collection-of-groups-for-sg-js')
    if (collection) {
      const arrayOfGroups = JSON.parse(document.querySelector('#all-name-group-sg-for-js').dataset.allNameGroups)
      console.log(arrayOfGroups)
      collection.addEventListener('change' , (event) => {

        if (parseInt(document.querySelector('#all-name-group-sg-for-js').firstElementChild.children[2].selectedIndex) - 1 == arrayOfGroups.indexOf("Ajouter un nouveau groupe") ) {
          document.querySelector('.btn-for-modal-creation-group-js').classList.remove('hidden')
        } else {
          document.querySelector('.btn-for-modal-creation-group-js').classList.add('hidden')
        }
      })
    }
  }

};


export { btnCreateGroupAppearInRestaurantNew, btnCreateGroupAppearInRestaurantNewWithParams}
