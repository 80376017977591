import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const paramsBtnDropdown = document.getElementById("backoffice-btn");
    const logoutBtnDropdown = document.getElementById("logout-btn");
    const dropdownButton = document.getElementById("dropdownButton");

    const hideElement = (element) => {
      if (element) {
        element.style.display = "none";
      }
    };

    const toggleElementDisplay = (element) => {
      if (element) {
        element.style.display = (element.style.display === "none") ? "block" : "none";
      }
    };

    const setDropdownStyles = () => {
      const isDropdownOpen = logoutBtnDropdown.style.display === "block";
      dropdownButton.style.borderBottomLeftRadius = isDropdownOpen ? '0' : '';
      dropdownButton.style.borderBottomRightRadius = isDropdownOpen ? '0' : '';
    };

    const closeDropdownIfClickedOutside = (element, event) => {
      if (element && !element.contains(event.target) && event.target !== dropdownButton) {
        element.style.display = "none";
      }
    };

    // Hide the "Backoffice" & "Logout" button initially
    hideElement(paramsBtnDropdown);
    hideElement(logoutBtnDropdown);

    const toggleDropdown = (event) => {
      toggleElementDisplay(paramsBtnDropdown);
      toggleElementDisplay(logoutBtnDropdown);

      if (!paramsBtnDropdown) {
        logoutBtnDropdown.style.marginTop = '0';
        logoutBtnDropdown.style.borderTop = 'none';
      }

      setDropdownStyles();
    };

    dropdownButton.addEventListener("click", toggleDropdown);
    if (paramsBtnDropdown) {
      paramsBtnDropdown.addEventListener("click", toggleDropdown);
    }

    document.addEventListener("click", function (event) {
      closeDropdownIfClickedOutside(paramsBtnDropdown, event);
      closeDropdownIfClickedOutside(logoutBtnDropdown, event);
      setDropdownStyles();
    });
  }

	clearNavbarCache() {
		localStorage.removeItem('navbar_ancestry_loop');
		localStorage.removeItem('navbar_ancestry_loop_states');
	}
}
