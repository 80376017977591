const user_new_remove_choice = () => {
  const new_user = document.getElementById('user-new-js')
  if (new_user) {
    console.log("user_new_remove_choice")
    // remove Restaurants Selection if Admin and/or LAdmin had been checked
    const restaurantsCollection = document.getElementById('restaurants-collection')
    const rolesCollection = document.getElementById('roles-collection')
    const groupsCollection = document.getElementById('groupes-collection')
    const checkboxAdmin = document.querySelectorAll('input[type="checkbox"]')[0]
    const checkboxFake = document.querySelectorAll('input[type="checkbox"]')[1]
    const userAccred = document.getElementById('user-accred-js')

    checkboxFake.addEventListener('click', (event) => {
      if (checkboxFake.checked === true) {
        checkboxAdmin.parentElement.classList.add("hidden")
      } else {
        checkboxAdmin.parentElement.classList.remove("hidden")
      }
    })

    const checkBoxesAdminLAdmin = [checkboxAdmin];

    checkBoxesAdminLAdmin.forEach(checkbox => {
      checkbox.addEventListener('click', (event) => {
        if (checkboxAdmin.checked === true) {
          checkboxFake.parentElement.classList.add("hidden")
          userAccred.classList.add("hidden")
        } else {
          checkboxFake.parentElement.classList.remove("hidden")
          userAccred.classList.remove("hidden")
        }
      })
    });
  }
}
export {user_new_remove_choice};
