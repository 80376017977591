const mobileScreenRemoveActiveIn = () => {
  if (document.querySelector(".nav-item-theme-for-js") !== null) {
    console.log("mobileScreenRemoveActiveIn")
    jQuery(document).ready(function($) {
      const alterClass = function() {
        const ww = document.body.clientWidth;
        console.log("screen width ⬇")
        console.log(ww)
        if (ww < 600 && document.querySelector(".nav-item-theme-for-js").dataset.infoJsShowMascatForMobile === "true") {
          $(".tab-pane-js").removeClass("active");
          const navTabs = document.querySelector(".tab-content-meals")
          const tabPanes = document.querySelectorAll(".tab-pane-js")
          const arrowBackMaster = document.querySelector(".arrow-back-to-mastercategs")

            navTabs.addEventListener("click", () => {
              navTabs.classList.add("hidden")
              arrowBackMaster.classList.remove("hidden")
            })

            arrowBackMaster.addEventListener("click", () => {
              console.log(navTabs)
              navTabs.classList.remove("hidden")
              tabPanes.forEach((tab) => {
                console.log(tab.classList)
                tab.classList.remove("hidden")
                tab.classList.remove("active")
                tab.classList.remove("in")
              })
              arrowBackMaster.classList.add("hidden")
            })

        }
      };
      $(window).resize(function(){
        alterClass();
      });
      //Fire it when the page first loads:
      alterClass();
    });
  }
}

export { mobileScreenRemoveActiveIn }
