import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'btn']

  connect() {
  }

  toggleContent(event) {
    let locationCode  = event.currentTarget.dataset.code
    let contentTarget = this.contentTargets.find(
      (target) => target.dataset.code === locationCode
    );

    this.contentTargets.forEach(element => {
        element.classList.add('hidden')
    })

    this.btnTargets.forEach(element => {
      element.classList.remove('selected')
    })

    contentTarget.classList.remove('hidden')
    event.target.classList.add('selected')
  }

  toggleContext(event) {
    const contentBox = event.currentTarget.closest('.content-box')
    contentBox.classList.toggle('expanded')
  }

  toggleAllCheckboxes(event) {
    const isChecked        = event.currentTarget.checked
    const location         = event.currentTarget.dataset.location
    const childItems       = document.querySelectorAll(`.form-check-input.child[data-location="${location}"]`);
    const nestedChildItems = document.querySelectorAll(`.form-check-input.nested-child[data-location="${location}"]`);

    childItems.forEach(checkbox => {
      checkbox.checked = isChecked
    })
    nestedChildItems.forEach(checkbox => {
      checkbox.checked = isChecked
    })
  }

  toggleIfAllSelected() {
    const location          = event.currentTarget.dataset.location
    const relatedCheckboxes = document.querySelectorAll(`.form-check-input.child[data-location="${location}"]`);
    const mainCheckbox      = document.querySelector(`.form-check-input.parent[data-location="${location}"]`)

    this.toggleParentCheckbox(relatedCheckboxes, mainCheckbox)
    this.toggleCheckedOnNestedItems(event.currentTarget)
  }

  toggleCheckedOnNestedItems(target) {
    const locationContext  = target.dataset.locationContext
    const nestedCheckboxes = document.querySelectorAll(`.form-check-input.nested-child[data-location-context="${locationContext}"]`)

    nestedCheckboxes.forEach(checkbox => {
      checkbox.checked = target.checked
    })
  }

  toggleParentIfAllSelected() {
    const location          = event.currentTarget.dataset.location
    const locationContext   = event.currentTarget.dataset.locationContext
    const relatedCheckboxes = document.querySelectorAll(`.form-check-input.nested-child[data-location-context="${locationContext}"]`);
    const parentCheckbox    = document.querySelector(`.form-check-input.child[data-location-context="${locationContext}"]`)
    const mainCheckbox      = document.querySelector(`.form-check-input.parent[data-location="${location}"]`)
    const relatedParentCheckboxes = document.querySelectorAll(`.form-check-input.child[data-location="${location}"]`);

    this.toggleParentCheckbox(relatedCheckboxes, parentCheckbox)
    this.toggleParentCheckbox(relatedParentCheckboxes, mainCheckbox)
  }

  toggleParentCheckbox(checkboxes, targetCheckbox) {
    let allSelected = true;

    checkboxes.forEach(checkbox => {

      if (!checkbox.checked) {
        allSelected = false;
        return;
      }
    });

    if (allSelected) {
      targetCheckbox.checked = true
    } else {
      targetCheckbox.checked = false
    }
  }
}
