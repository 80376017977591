import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ["color", "validate"]

  connect() {
    this.updateQrCodeColor()
  }

  colorChange() {
    this.validateTarget.classList.remove("not-clickable")
  }


  updateQrCodeColor() {

    if (this.element) {
      const qrCodeColorSelector = this.colorTarget
      qrCodeColorSelector.addEventListener('change', () => {

        const qrCodeDiv = document.getElementById('svg-color-js')
        const pixels = qrCodeDiv.getElementsByTagName('rect')

        for (let pixel of pixels) {
          pixel.style.fill = qrCodeColorSelector.value;
        }
      });
    }
  }
}
