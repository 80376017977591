// debug js: dans survey, page new_client: pour la question 'motif de visite' si on appuie sur Autre puis sur un autre btn le text_field_tag ne part pas
// Rajout champs pour autre motif de visite

const surveyNewClientPageForBtnAutre = () => {
  if (document.getElementById('new_client') ){
    console.log("surveyNewClientPageForBtnAutre")
    const textContainer = document.querySelector(".form-container-proposition2")

    document.querySelectorAll(".client_visit_tag").forEach((clientTag) => {
      clientTag.addEventListener('click', (event) => {
        if (clientTag.classList.contains("autre")) {
          if (textContainer.classList.contains("hidden")) {
            textContainer.classList.remove("hidden")
          }
        } else {
          if (textContainer.classList.contains("hidden") === false) {
            textContainer.classList.add("hidden")
          }
        }
      })
    })

  }
}

const surveyNewClientPageForBtnAutreMobile = () => {
  if (document.getElementById('new_client') ){
    console.log("surveyNewClientPageForBtnAutreMobile")
    const textContainer = document.querySelector(".form-container-proposition2-mobile")

    document.querySelectorAll(".client_visit_tag_mobile").forEach((clientTag) => {
      clientTag.addEventListener('click', (event) => {
        if (clientTag.classList.contains("autre_mobile")) {
          if (textContainer.classList.contains("hidden")) {
            textContainer.classList.remove("hidden")
          }
        } else {
          if (textContainer.classList.contains("hidden") === false) {
            textContainer.classList.add("hidden")
          }
        }
      })
    })

  }
}

export {surveyNewClientPageForBtnAutre, surveyNewClientPageForBtnAutreMobile}
