const alertNameMealItemsJs = () => {
  if ( document.getElementById("alert-js-meal")) {
    console.log('alertNameMealItemsJs');

    let inputArr = [];

    document.querySelectorAll('[id^="meal_item_name_"]').forEach(input => {
      console.log(input);
      console.log(document.querySelector('.modal-body'));

      inputArr.push(input);
      console.log(`length = ${inputArr.length}`);

      document.getElementById('alert-js-meal').addEventListener('change', () => {
        console.log(input.value);
        
        let filterArr = inputArr.filter(i => i.value =="");
        console.log(filterArr.length);

        if(filterArr.length != 0) {
          console.log(`${input} empty`);
          document.getElementById('alert-name').classList.remove('hidden-full-state');
        } else {
          document.getElementById('alert-name').classList.add('hidden-full-state');
        }
      });
    });
  }
};


export { alertNameMealItemsJs };
