const unhideMealMenuBoissons = () => {
  const pageDetailMenu = document.querySelector(".meal-titl-menu")
  if (pageDetailMenu) {
    const mealCategMenuDetail = document.querySelectorAll(".meal-categ-menu-detail")
    const mealCategMenuDetailEnglish = document.querySelectorAll(".meal-english-categ-menu-detail")

    if (mealCategMenuDetail) {
      console.log('unhideMealMenuBoissons')
      mealCategMenuDetail.forEach((categ) => {
        console.log('detail_js_menu')
        categ.addEventListener('click', (event) => {
          categ.nextElementSibling.classList.toggle("hidden")
        })
      })
      mealCategMenuDetailEnglish.forEach((categ) => {
        categ.addEventListener('click', (event) => {
          console.log('detail_js_menu')
          categ.nextElementSibling.classList.toggle("hidden")
        })
      })
    }
  }
}

export { unhideMealMenuBoissons };
