const simulateFormNewEquipe = () => {
  if (document.getElementById("input-service-nom-equipe-js")) {
    console.log('simulateFormNewEquipe')
    const inputNameEquipe = document.getElementById("input-service-nom-equipe-js")
    const buttonValidateEquipe = document.getElementById("button-validate-service-nom-equipe-js")

    // QUAND ON CLIQUE SUR LE BTN 'VALIDER' de 'EQUIPE': SIMULATION POUR QUE LE FAUX FORM SOIT PRIS EN COMPTE ET ENREGISTRE
    buttonValidateEquipe.addEventListener('click', (event) => {
      document.querySelector(".simple_form.new_employee").children[2].children[0].children[2].querySelector("input").click()
      document.querySelector(".simple_form.new_employee").children[2].querySelector(".employee_first_name").children[1].value = inputNameEquipe.value
      document.querySelector(".simple_form.new_employee").children[2].querySelector(".employee_last_name").children[1].value = "Equipe"
      document.querySelector(".simple_form.new_employee").lastElementChild.firstElementChild.classList.remove("not-clickable")
      document.querySelector("input.custom-btn.btn-of-modal.btn-of-modal-edit-serveur").type = "submit"
      document.querySelector("input.custom-btn.btn-of-modal.btn-of-modal-edit-serveur").click()
      document.querySelector("button#close").click()
    });

    // BTN CLIQUABLE OU NON CLIQUABLE CF METHODE create_new_equipe dans modales_new_edit.js
  }
};






export { simulateFormNewEquipe }
