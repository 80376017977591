
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { createLabelsArray } from './utilities'

export function courbe_evolution_nps() {
  const evolutionNPS = document.getElementById("courbe-nps");
  if (evolutionNPS) {
    console.log("courbe_nps");
    const evolution_nps = new Chart(evolutionNPS, {
      type: 'bar',
      data: {
        labels: createLabelsArray(date),
        datasets: [{
          label: 'NPS ', //'Moyenne mercredi', 'Moyenne jeudi', 'Moyenne vendredi', 'Moyenne samedi', 'Moyenne dimanche', 'Moyenne lundi'
          data: JSON.parse(evolutionNPS.dataset.points).map(point => (point !== null && point !== undefined) ? Math.round(point) : point),
          backgroundColor: [
            '#e5e7f1',
            '#e5e7f1',
            '#e5e7f1',
            '#e5e7f1',
            '#e5e7f1',
            '#e5e7f1',
            setBackgroundColorForNPS(evolutionNPS.dataset.points),
          ],
          hoverBackgroundColor: setHoverColorForNPS(evolutionNPS.dataset.points),
          borderWidth: 1
        }]
        // pour fixer le gadriage et les axes du graphe
      },
      options: {
        legend: {
          display: false
        },
        scales: {
          xAxes: [{
            gridLines: {
              display: false
            },
          }],
          yAxes: [{
            ticks: {
              display: true,
              min: -100,
              max: 100
            },
            gridLines: {
              drawBorder: false,
              display: false
            },
          }]
        },
        plugins: {
          datalabels: {
            anchor: 'end',
            align: function (context) {
              return context.dataset.data[context.dataIndex] < 8 ? 'top' : 'start';
            },
            color: function (context) {
              return context.dataset.data[context.dataIndex] < 8 ? ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff'] : ['#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', '#5d78ff', 'white'];
            },
          },
        }
      },

    });

    let chart = evolution_nps
    chart.options.animation = false;

    console.log(evolution_nps.data.labels);
    if ((evolution_nps.data.labels[0] == 'NaN undefined') || (evolution_nps.data.labels[0] == 'S NaN/NaN') || (evolution_nps.data.labels[0] == 'undefined NaN') || `${evolution_nps.data.labels[0]}` == 'NaN') {
      location.reload();
    }
  }
}

const setBackgroundColorForNPS = (point) => {
  const allPoints = point.split('[').join('').split(']').join('').split(',')
  const lastPoint = allPoints[allPoints.length - 1]
  if (lastPoint < 0) {
    return '#fd397a';
  } else {
    return '#1dc9b7';
  }
}

const setHoverColorForNPS = (dataset) => {
  const colors = []
  const array_data = dataset.split('[').join('').split(']').join('').split(',')

  Array.prototype.forEach.call(array_data, function (d) {
    if (d < 0) {
      colors.push('#fd397a');
    } else {
      colors.push('#1dc9b7');
    }
  })
  return colors
}
