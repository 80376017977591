const initializeQuestion = (questionBox) => {
  const targetNegativeDesc = questionBox.find('[data-bs-target="negative-description"]')
  const targetPositiveDesc = questionBox.find('[data-bs-target="positive-description"]')
  const allNegativeDescriptionInputs = questionBox.find('[data-source="negative-description"]')
  const allPositiveDescriptionInputs = questionBox.find('[data-source="positive-description"]')
  const inputNegativeDes = allNegativeDescriptionInputs.first()
  const inputPositiveDes = allPositiveDescriptionInputs.first()

  changeText(inputNegativeDes, targetNegativeDesc)
  changeText(inputPositiveDes, targetPositiveDesc)
  toggleStatusDescriptions(questionBox, targetNegativeDesc, targetPositiveDesc, allNegativeDescriptionInputs, allPositiveDescriptionInputs)
}

const notationQuestionNewManager = () => {
  const dropdown = $('.dropdown-choice-answerstype')
  if (dropdown) {
    dropdown.each(function() {
      $(this).click(function() {
        const typeOfQuestion = $(this).find('button').text().trim()
        if ((typeOfQuestion == "Note Un à Dix") || (typeOfQuestion == "1 to 10 Notation")) {
          const parentBox = $(this).parent().parent().parent()
          initializeQuestion(parentBox)
        }
      })
    })
  }
}

const notationQuestionCreatedManager = () => {
  const createdQuestionBox = $('.created-question-note-for-js')
  if (createdQuestionBox) {
    createdQuestionBox.each(function() {
      initializeQuestion($(this))
    })
  }
}

// Simulate change text on created and new question 'Note 1 to 10'
const changeText = (sourceDesc, targetDesc) => {
  sourceDesc.on('input', function(e){
    sourceDesc.attr('maxlength', 25);
    targetDesc.text($(this).val())
  })
}

// Enable/disable descriptions on question note 1 to 10.
const toggleStatusDescriptions = (parentDiv, negativeDescription, positiveDescription, negativeInput, positiveInput) => {
  const checkbox = parentDiv.find('[name*="active_descriptions"]');
  const disabledColor = 'white'
  const enabledColor = '#989eb1'
  const disabledClass = 'description-disabled'

  function updateStatusDescriptions() {
    if (checkbox.is(':checked')) {
      positiveInput.parent().removeClass(disabledClass);
      negativeInput.parent().removeClass(disabledClass);
      negativeDescription.css('color', enabledColor);
      positiveDescription.css('color', enabledColor);
    } else {
      positiveInput.parent().addClass(disabledClass);
      negativeInput.parent().addClass(disabledClass);
      negativeDescription.css('color', disabledColor);
      positiveDescription.css('color', disabledColor);
    }
  }

  // Disable items that are not checked.
  updateStatusDescriptions();

  checkbox.change(function() {
    updateStatusDescriptions();
  });
}

export { notationQuestionNewManager, notationQuestionCreatedManager }
