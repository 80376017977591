const loadingDots = (context = document) => {
    if (context.querySelector(".show-loading-dots")) {
        const dots = context.querySelectorAll(".show-loading-dots");
        const loadingDotsContainer = document.querySelector(".dots-container");

        dots.forEach((dot) => {
            dot.addEventListener("click", (event) => {
              if (loadingDotsContainer) {
                loadingDotsContainer.classList.remove("hidden")
                document.querySelector("body").classList.add("background-gray");
              }
            })
        })
    }
}


function triggerLoadingDots(container) {
	// LOADING DOTS
	if (container) {
		container.classList.remove("hidden")
		document.querySelector("body").classList.add("background-gray");
	} // END LOADING DOTS
}

export { loadingDots, triggerLoadingDots};
