const shinkAndBrink = () => {
  const navbar = document.querySelector(".navbar-wagon-right")
  const btn_left = document.querySelector(".fas.fa-chevron-circle-left.color-arrow-icon")
  const btn_right = document.querySelector(".fas.fa-chevron-circle-right.color-arrow-icon")
  if (btn_left) {
    console.log('shinkAndBrink')
    btn_left.addEventListener("click", () => {
      navbar.setAttribute("style", "margin-left: 0;")
    })

    btn_right.addEventListener("click", () => {
      navbar.setAttribute("style", "")
    })

  }
}

export {shinkAndBrink};
