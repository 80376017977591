const logoBkgColor = () => {
  function bkgColLogoCard(bkgColorLogoCard, logoClass) {
  const colorLogoCard = document.getElementById(bkgColorLogoCard)
  if (colorLogoCard) {
    colorLogoCard.addEventListener("change", (event) => {
      previewDiv.contentDocument.querySelectorAll(`.${logoClass}`).forEach((btn) => {
        btn.style.setProperty('background-color', event.currentTarget.value, 'important');
      })
    })
  }
  }

  // START PAGE : background-color of logo card
  bkgColLogoCard("bkg_col_logo_sp", "logo-links-menu")

  // BEGIN PAGE : background-color of logo card
  bkgColLogoCard("bkg_col_logo_begin", "logo-position-start")

  // NEW BORNE PAGE : background-color of logo card
  bkgColLogoCard("bkg_col_logo_nb", "logo-survey-end")



  //START_PAGE : simuler la suppression de la couleur de fond du logo de l'etablissement dans l'iframe (sans avoir à rafraichir la page)
  // const trashIconCustomQuestionnaire = document.querySelectorAll('.remove-bkg-logo')
  const trashIconCustomQuestionnaire = document.getElementById('remove-bkg-logo-sp')
  if (trashIconCustomQuestionnaire) {
    trashIconCustomQuestionnaire.addEventListener("click", () => {
      if (previewDiv.contentDocument.getElementById('dLabel')) {
        previewDiv.contentDocument.getElementById('dLabel').style.setProperty("background", "transparent", "important")
      }
    })
  }

  //BEGIN_PAGE : simuler la suppression de la couleur de fond du logo de l'etablissement dans l'iframe (sans avoir à rafraichir la page)
  const trashIconCustomQuestionnaireBegin = document.getElementById('remove-bkg-logo-begin')
  if (trashIconCustomQuestionnaireBegin) {
    trashIconCustomQuestionnaireBegin.addEventListener("click", () => {
      previewDiv.contentDocument.querySelectorAll('.logo-position-start').forEach((logo) => logo.style.setProperty("background", "transparent", "important"))
    })
  }

  //NEW_BORNE_PAGE : simuler la suppression de la couleur de fond du logo de l'etablissement dans l'iframe (sans avoir à rafraichir la page)
  const trashIconCustomQuestionnaireNewBorne = document.getElementById('remove-bkg-logo-nb')
  if (trashIconCustomQuestionnaireNewBorne) {
    trashIconCustomQuestionnaireNewBorne.addEventListener("click", () => {
      previewDiv.contentDocument.querySelectorAll('.logo-survey-end').forEach((logo) => logo.style.setProperty("background", "transparent", "important"))
    })
  }


  // START PAGE
    const removeColorPickerInput = document.getElementById('remove-colorpicker-input-for-js')
    let bkgColLogoSp = document.getElementById('remove-colorpicker-sp')
    const displayColorPickerInput = document.getElementById('display_color_picker_bkg_logo_sp')
    // START PAGE : Supprimer l'input du color picker quand on clique sur la poubelle pour éviter qu'un fond noir s'applique en fond du logo après le submit
    if (trashIconCustomQuestionnaire) {
      trashIconCustomQuestionnaire.addEventListener("click", () => {
        bkgColLogoSp.innerHTML = ""
        removeColorPickerInput.classList.add("hidden")
        document.querySelectorAll('.hide-check-text-sp').forEach((elem) => {
          elem.classList.remove("hidden")
        })
        displayColorPickerInput.checked = false
      })
    }
    // Afficher l'input du color picker pour pouvoir sélectionner une couleur à appliquer en fond du logo
    if (displayColorPickerInput && displayColorPickerInput.checked === false) {
      displayColorPickerInput.addEventListener("click", () => {
        bkgColLogoSp.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_logo_sp' id='bkg_col_logo_sp'>")
        bkgColLogoSp.querySelector("input").addEventListener("change", (event) => {
          previewDiv.contentDocument.querySelectorAll(".logo-links-menu").forEach((btn) => {
            btn.style.setProperty('background-color', event.currentTarget.value, 'important');
          })
        })
        removeColorPickerInput.classList.remove("hidden")
        document.querySelectorAll('.hide-check-text-sp').forEach((elem) => {
          elem.classList.add("hidden")
        })
      })
    }

  // BEGIN PAGE
  const removeColorPickerInputBegin = document.getElementById('remove-colorpicker-input-begin-for-js')
  let removeColorPickerBegin = document.getElementById('remove-colorpicker-begin')
  const displayColorPickerInputBegin = document.getElementById('display_color_picker_bkg_logo_begin')
  // BEGIN PAGE : Supprimer l'input du color picker quand on clique sur la poubelle pour éviter qu'un fond noir s'applique en fond du logo après le submit
  if (trashIconCustomQuestionnaireBegin) {
    trashIconCustomQuestionnaireBegin.addEventListener("click", () => {
      removeColorPickerBegin.innerHTML = ""
      removeColorPickerInputBegin.classList.add("hidden")
      document.querySelectorAll('.hide-check-text-begin').forEach((elem) => {
        elem.classList.remove("hidden")
      })
      displayColorPickerInputBegin.checked = false
    })
  }
  // Afficher l'input du color picker pour pouvoir sélectionner une couleur à appliquer en fond du logo
  if (displayColorPickerInputBegin && displayColorPickerInputBegin.checked === false) {
    displayColorPickerInputBegin.addEventListener("click", () => {
      removeColorPickerBegin.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_logo_begin' id='bkg_col_logo_begin'>")
      removeColorPickerBegin.querySelector("input").addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".logo-position-start").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
      removeColorPickerInputBegin.classList.remove("hidden")
      document.querySelectorAll('.hide-check-text-begin').forEach((elem) => {
        elem.classList.add("hidden")
      })
    })
  }

  // NEW BORNE PAGE
  const removeColorPickerInputNewBorne = document.getElementById('remove-colorpicker-input-nb-page')
  let removeColorPickerNewBorne = document.getElementById('remove-colorpicker-nb')
  const displayColorPickerInputNewBorne = document.getElementById('display_color_picker_bkg_logo_nb')
  // NEW BORNE : Supprimer l'input du color picker quand on clique sur la poubelle pour éviter qu'un fond noir s'applique en fond du logo après le submit
  if (trashIconCustomQuestionnaireNewBorne) {
    trashIconCustomQuestionnaireNewBorne.addEventListener("click", () => {
      removeColorPickerNewBorne.innerHTML = ""
      removeColorPickerInputNewBorne.classList.add("hidden")
      document.querySelectorAll('.hide-check-text-nb').forEach((elem) => {
        elem.classList.remove("hidden")
      })
      displayColorPickerInputNewBorne.checked = false
    })
  }
  // Afficher l'input du color picker pour pouvoir sélectionner une couleur à appliquer en fond du logo
  if (displayColorPickerInputNewBorne && displayColorPickerInputNewBorne.checked === false) {
    displayColorPickerInputNewBorne.addEventListener("click", () => {
      removeColorPickerNewBorne.insertAdjacentHTML("beforeEnd", "<input type='color' name='bkg_col_logo_nb' id='bkg_col_logo_nb'>")
      removeColorPickerNewBorne.querySelector("input").addEventListener("change", (event) => {
        previewDiv.contentDocument.querySelectorAll(".logo-survey-end").forEach((btn) => {
          btn.style.setProperty('background-color', event.currentTarget.value, 'important');
        })
      })
      removeColorPickerInputNewBorne.classList.remove("hidden")
      document.querySelectorAll('.hide-check-text-nb').forEach((elem) => {
        elem.classList.add("hidden")
      })
    })
  }


  // START PAGE : Cacher le logo : simulation dans l'iframe
  const checkLogo = document.getElementById("hide_logo_sp")
  if (checkLogo && checkLogo.checked == false) {
    // document.getElementById("remove-colorpicker-input-for-js").classList.remove("hidden")
    checkLogo.addEventListener("click", () => {
      // document.getElementById("remove-colorpicker-input-for-js").classList.add("hidden")
      const selectLogoClass = previewDiv.contentDocument.querySelector(".check-if-logo-sp")
      if (selectLogoClass.classList.contains("hidden")) {
        selectLogoClass.classList.remove("hidden")
      } else {
        selectLogoClass.classList.add("hidden")
      }
    })
  } else if (checkLogo) {
    // document.getElementById("remove-colorpicker-input-for-js").classList.add("hidden")
    checkLogo.addEventListener("click", () => {
      const selectLogoClass = previewDiv.contentDocument.querySelector(".check-if-logo-sp")
      // document.getElementById("remove-colorpicker-input-for-js").classList.remove("hidden")
      if (selectLogoClass.classList.contains("hidden")) {
        selectLogoClass.classList.remove("hidden")
      } else {
        selectLogoClass.classList.add("hidden")
      }
    })
  }

  // BEGIN PAGE : Cacher le logo de la begin page : simulation dans l'iframe
  const checkLogoBegin = document.getElementById("hide_logo_begin")
  if (checkLogoBegin && checkLogoBegin.checked == false) {
    checkLogoBegin.addEventListener("click", () => {
      const selectLogoClass = previewDiv.contentDocument.querySelector(".check-if-logo-begin")
      if (selectLogoClass.classList.contains("hidden")) {
        selectLogoClass.classList.remove("hidden")
      } else {
        selectLogoClass.classList.add("hidden")
      }
    })
  } else if (checkLogoBegin) {
    checkLogoBegin.addEventListener("click", () => {
      const selectLogoClass = previewDiv.contentDocument.querySelector(".check-if-logo-begin")
      if (selectLogoClass.classList.contains("hidden")) {
        selectLogoClass.classList.remove("hidden")
      } else {
        selectLogoClass.classList.add("hidden")
      }
    })
  }

  // NEW BORNE PAGE : Cacher le logo de la new borne page : simulation dans l'iframe
  const checkLogoNewBorne = document.getElementById("hide_logo_nb")
  if (checkLogoNewBorne) {
    checkLogoNewBorne.addEventListener("click", () => {
      const selectLogoClass = previewDiv.contentDocument.querySelector(".check-if-logo-nb")
      if (selectLogoClass.classList.contains("hidden")) {
        selectLogoClass.classList.remove("hidden")
      } else {
        selectLogoClass.classList.add("hidden")
      }
    })
  }
}

export { logoBkgColor };
