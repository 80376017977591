import { cleanClassesOn } from '../plugins/year_select_flatpickr';
import { setTimeframe, addClassesToCell } from '../plugins/shared_flatpickr';

export function engineMonthSelect(instance, selectedDates) {
  if (selectedDates.length === 1) {
    let cells = document.querySelectorAll('.flatpickr-monthSelect-month')
    cleanClassesOn(cells)

    setTimeframe('custom')
    instance.firstDateSelected = selectedDates[0]
  }

  if (selectedDates.length === 2) {
    const [startDate, endDate] = selectedDates;
    const lastDayOfMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0);
    if (selectedDates[0].toLocaleDateString() === selectedDates[1].toLocaleDateString()) {
      let monthNumber = selectedDates[0].getMonth()
      addClassesToCell(monthNumber, ["startRange", "endRange"])
    }
    instance.setDate([startDate, lastDayOfMonth]);

    let lastDaySelected = [startDate, lastDayOfMonth].filter(function(item) {
        return item.toLocaleDateString() != instance.firstDateSelected.toLocaleDateString()
    })[0]

    instance.currentYear = lastDaySelected.getFullYear()

    // TODO : replace the year on the header by lastDaySelected.getFullYear()

    setTimeout(function() {
      handleClassesOnSelectMonth(instance, lastDaySelected)
    instance.firstDateSelected = null
    }, 0);

    document.querySelector('.calendar-begin-end-container').classList.remove('not-clickable-item')
  }
}

export function handleClassesOnSelectMonth(instance, lastDaySelected = null) {
  let cells = document.querySelectorAll('.flatpickr-monthSelect-month')
  let startDate = instance.selectedDates[0]
  let endDate = instance.selectedDates[1]
  let currentYear = instance.currentYear

  cleanClassesOn(cells)

  // Add endRange class
  let endDateYearSelected = endDate.getFullYear()
  let endDateMonthSelected = endDate.getMonth()
  if (currentYear === endDateYearSelected) {
    cells[endDateMonthSelected].classList.add('endRange');
  }

  // Add startRange class
  let startDateYearSelected = startDate.getFullYear()
  let startDateMonthSelected = startDate.getMonth()
  if (currentYear === startDateYearSelected) {
    cells[startDateMonthSelected].classList.add('startRange');
  }

  // Add inRange class
  let monthsBetween = []
  let min;
  let max;

  if (startDateYearSelected == endDateYearSelected) {
    min = startDateMonthSelected + 1
    max = endDateMonthSelected
  } else if (lastDaySelected && lastDaySelected.toLocaleDateString() == startDate.toLocaleDateString() ) {
    min = startDateMonthSelected + 1
    max = 12
  } else if (lastDaySelected && lastDaySelected.toLocaleDateString() == endDate.toLocaleDateString() ) {
    min = 0
    max = endDateMonthSelected
  }

  for (let i = min; i < max; i++) {
    monthsBetween.push(i)
  }

  monthsBetween.forEach(month => {
    cells[month].classList.add('inRange')
  })

  removeSelectedClass()

}

function removeSelectedClass() {
  setTimeout(function() {
    const selectedCell = document.querySelectorAll('.selected')
    selectedCell.forEach(cell => {
      cell.classList.remove('selected');
    })
  }, 0);
}

// Patch: AddClassEndRangeOnYearChange
export function addClassEndRangeOnYearChange(instance) {
  if (instance.selectedDates[1]?.getFullYear() == instance.currentYear) {
    addClassesToCell(instance.selectedDates[1].getMonth(), ['endRange'])
  }
}
