// Graphes donughts per theme

import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
//import { triggerLoadingDots } from './components/_loading_dots';

export function donught_csat_theme() {
  let improvement = "Amélioration possible"
  const locale = window.location.href.match(/\/en\//) ? 'en' : 'fr'
  if (locale == "en") {
    improvement = "Possible improvement"
  }
  console.log(locale);
  let satisfaction_generale_chart = null;
  const all_doughnuts = document.querySelectorAll(".satisfaction_generale_chart");
  if (all_doughnuts) {
    console.log("satisfaction_generale_chart")
    Array.prototype.forEach.call(all_doughnuts, function (doughnut) {
      var back_color = '#5d78ff';
      var hover_color = 'rgb(81,138,268)';
      var hover_border_color = 'rgb(81,138,268)';
      if (doughnut.getAttribute('id') == 'csat-pos') {
        back_color = '#1dc9b7';
        hover_color = '#46D2C4';
        hover_border_color = '#46D2C4';
      } else if (doughnut.getAttribute('id') == 'csat-neg') {
        back_color = '#fd397a';
        hover_color = '#fd397a';
        hover_border_color = '#fd397a';
      } else if (doughnut.getAttribute('id') == 'csat-neutral') {
        back_color = '#ffb822';
        hover_color = '#FFCB5E';
        hover_border_color = '#FFCB5E';
      }

      var labels_by_type_of_graph = ["Note", improvement]
      if (doughnut.id.includes("csat")) {
        labels_by_type_of_graph = ["CSAT", improvement]
      }

      satisfaction_generale_chart = new Chart(doughnut, {
        type: 'doughnut',
        data: {
          labels: labels_by_type_of_graph,
          datasets: [{
            datalabels: {
              display: false,
            },
            data: JSON.parse(doughnut.dataset.points),

            backgroundColor: [
              back_color,
              '#e5e7f1',
            ],

            hoverBackgroundColor: [
              hover_color,
              'rgb(248,233,260)',
            ],

            hoverBorderColor: [
              hover_border_color,
              'rgb(248,233,260)',
            ],
            borderWidth: 1
          }],

        },
        options: {
          cutoutPercentage: 80,
          legend: {
            display: false
          },
        },
      });

      let chart = satisfaction_generale_chart
      chart.options.animation = false;

      doughnut.onmouseover = function (e) {
        let compteur = doughnut.dataset.compteur;
        if (compteur === "1" || compteur === "2") {
          document.body.style.cursor = "pointer";
        }
      }

      doughnut.onmouseout = function (e) {
        let compteur = doughnut.dataset.compteur;
        if (compteur === "1" || compteur === "2") {
          document.body.style.cursor = "";
        }
      }

      doughnut.onclick = function () {
        if (doughnut.dataset.url) {
          const loadingDotsContainer = document.querySelector(".dots-container");
          triggerLoadingDots(loadingDotsContainer);
          window.open(doughnut.dataset.url, '_self');
        }
      }
    });
  }
  const loadingDots = (context = document) => {
    if (context.querySelector(".show-loading-dots")) {
      const dots = context.querySelectorAll(".show-loading-dots");
      const loadingDotsContainer = document.querySelector(".dots-container");

      dots.forEach((dot) => {
        dot.addEventListener("click", (event) => {
          if (loadingDotsContainer) {
            loadingDotsContainer.classList.remove("hidden")
            document.querySelector("body").classList.add("background-gray");
          }
        })
      })
    }
  }


  function triggerLoadingDots(container) {
    // LOADING DOTS
    if (container) {
      container.classList.remove("hidden")
      document.querySelector("body").classList.add("background-gray");
    } // END LOADING DOTS
  }
}
