export function hourlyDistrib() {
	const npsHoraireGq = document.querySelectorAll("#nps-horaire-gq");
  Array.prototype.forEach.call(npsHoraireGq, function (npsHourly) {
    if (npsHourly) {
      console.log("npsHourly")
      const nps_decompo_per_enquete = new Chart(npsHourly, {
        type: 'bar',
        data: {
          labels: JSON.parse(npsHourly.dataset.points_em),
          datasets: [ {
            label: JSON.parse(npsHourly.dataset.labels_repartition)[0],
            data: JSON.parse(npsHourly.dataset.points_tres_insatisfait),
            backgroundColor: '#FA0000'
          }, {
            label: JSON.parse(npsHourly.dataset.labels_repartition)[1],
            data: JSON.parse(npsHourly.dataset.points_insatisfait),
            backgroundColor: '#fd397a'
          }, {

            label: JSON.parse(npsHourly.dataset.labels_repartition)[2],
            data: JSON.parse(npsHourly.dataset.points_neutre),
            backgroundColor: '#ffb822'
          }, {
            label: JSON.parse(npsHourly.dataset.labels_repartition)[3],
            data: JSON.parse(npsHourly.dataset.points_satisfait),
            backgroundColor: '#1dd18f'
          }, {
            label: JSON.parse(npsHourly.dataset.labels_repartition)[4],
            data: JSON.parse(npsHourly.dataset.points_tres_satisfait),
            backgroundColor: '#1dc9b7'
          }
          ]
        },

        options: {
          maintainAspectRatio: false,
          tooltips: {
            displayColors: true,
            callbacks: {
              mode: 'x',
            },
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                let percentage = (value).toFixed(0) + "%";
                return percentage;
              },
              color: '#fff',
              display: function(context) {
                return context.dataset.data[context.dataIndex] !== 0;
              }
            }
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              ticks: {
                display: true
              },
              stacked: true,
              offset: true
            },
            {
              position: 'top',
              ticks: {
                display: true
              },
              stacked: true,
              offset: true,
              gridLines: {
                display: false,
              },
              labels: JSON.parse(npsHourly.dataset.nb_avis)
            }],
            yAxes: [{
              ticks: {
                suggestedMin: 0,
                max: 100
              },

              stacked: true,
              gridLines: {
                display: false,
              }
            }]
          }
        }
      });
      nps_decompo_per_enquete.update();

      let chart = nps_decompo_per_enquete
      chart.options.animation = false;

    }
  });
}
