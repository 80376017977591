import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["search"];

  connect() {
    console.log("HighlightSearchNavBar controller connecté");
  }

  highlightSearch() {
    clearTimeout(this.timeout); // Clear any existing timeout to reset the timer

    this.timeout = setTimeout(() => {
      const query = this.searchTarget.value.toLowerCase();
      const options = {
          element: "span",
          className: "highlight-search",
          separateWordSearch: false,
      };

        $('.card-admin-rest-select-sous-groupe, .card-admin-rest-select-resto, .icon-qr-code-container').removeClass('hidden');


        $('.card-admin-rest-select-sous-groupe, .card-admin-rest-select-resto').unmark({
            done: () => {
                $('.card-admin-rest-select-sous-groupe, .card-admin-rest-select-resto').mark(query, options);

                if (query.length > 0) {
                    const firstHighlight = $('.highlight-search').first();
                    if (firstHighlight.length) {
                        const elementTop = firstHighlight.offset().top;
                        const viewportTop = $(window).scrollTop();
                        const viewportBottom = viewportTop + $(window).height();

                        if (elementTop < viewportTop || elementTop > viewportBottom) {
                            $('html, body').scrollTop(elementTop - 100); // Scroll to the first highlighted element
                        }
                    } else {
                      $('#no-results-message').removeClass('hidden');
                      this.pageInitialState();
                      $('.card-admin-rest-select-sous-groupe').each(function() {
                        const count = parseInt($(this).data('restaurants-count'));
                        if (count > 8) {
                          $('.icon-qr-code-container').addClass('hidden');
                          $('.card-admin-rest-select-resto').addClass('hidden');
                        }
                      });
                    }
                } else {
                  $('#no-results-message').addClass('hidden');
                  this.pageInitialState();
                  $('.card-admin-rest-select-sous-groupe').each(function() {
                    const count = parseInt($(this).data('restaurants-count'));
                    if (count > 8) {
                      $('.icon-qr-code-container').addClass('hidden');
                      $('.card-admin-rest-select-resto').addClass('hidden');
                    }
                  });

                }
            }
        });
    }, 220);
  }

  pageInitialState() {
      const sousGroupes = document.querySelectorAll(".card-admin-rest-select-sous-groupe");
      sousGroupes.forEach(sg => {
          const rootId = sg.dataset.rootId;
          const sgId = sg.dataset.sgId;
          const count = parseInt(sg.dataset.restaurantsCount);

          if (count > 8 && rootId !== sgId) {
              sg.classList.add("hidden"); // hide descendants
          }
      });
  }


}
