const hoverAndCheckEmoji = () => {
  if (document.querySelector('.emoji-modif')) {
    console.log('hoverAndCheckEmoji');

    const allInputs = document.querySelectorAll('.btn-statistics.emoji-modif');

    //click
    allInputs.forEach((check) =>
    check.addEventListener('click', () => {
        let selectedInput = document.querySelector('.statistics-neutre-js');
        let inputsWithoutSelected = [...allInputs].filter( input => input != selectedInput);

        console.log(selectedInput);
        console.log(inputsWithoutSelected);

        check.children[1].style.filter = "invert(100%) sepia(92%) saturate(2%) hue-rotate(167deg) brightness(105%) contrast(100%)";

        if (check.children[1].classList.contains('very-happy')) {
          check.style.background = "#00B050";
        } else if (check.children[1].classList.contains('happy')) {
          check.style.background = "#92D050";
        } else if (check.children[1].classList.contains('bad')) {
          check.style.background = "#FF5050";
        } else if (check.children[1].classList.contains('very-bad')) {
          check.style.background = "#C00000";
        }

        inputsWithoutSelected.forEach( input => {
          putBackColor(input);
        });
      })
    );
  }
};

function putBackColor(check) {
  check.style.background = "#fff";
  if (check.children[1].classList.contains('very-happy')) {
    check.children[1].style.filter = "invert(44%) sepia(73%) saturate(2297%) hue-rotate(120deg) brightness(93%) contrast(102%)";
  } else if (check.children[1].classList.contains('happy')) {
    check.children[1].style.filter = "invert(73%) sepia(76%) saturate(378%) hue-rotate(38deg) brightness(96%) contrast(79%)";
  } else if (check.children[1].classList.contains('bad')) {
    check.children[1].style.filter = "invert(49%) sepia(54%) saturate(3968%) hue-rotate(331deg) brightness(100%) contrast(107%)";
  } else if (check.children[1].classList.contains('very-bad')) {
    check.children[1].style.filter = "invert(11%) sepia(94%) saturate(4816%) hue-rotate(357deg) brightness(89%) contrast(114%)";
  }
}

const checkEmojiQuestionnaire = () => {
  if (document.querySelector('.smiley-button') || document.querySelector('.smiley-button-cis')) {
    console.log('checkEmojiQuestionnaire');

    const allSmileys = [...document.getElementsByClassName('smiley-button'), ...document.getElementsByClassName('smiley-button-cis')];

    if (document.getElementsByClassName('question_initiale')) {

      let smileyQuestionAddi = allSmileys.filter(smiley => smiley.parentNode.parentNode.parentNode.classList.contains('question_addi'));
      uncheckAllOthersAfterCheck(smileyQuestionAddi);

      let smileyQuestionInitiale = allSmileys.filter(smiley => smiley.parentNode.parentNode.parentNode.classList.contains('question_initiale'));
      uncheckAllOthersAfterCheck(smileyQuestionInitiale);

    } else {

      uncheckAllOthersAfterCheck(allSmileys);

    }

  }
};

function uncheckAllOthersAfterCheck(array_of_smiley) {
  array_of_smiley.forEach(smiley => {
    smiley.addEventListener('click', () => {
      // Tout les smileys qui on le même question_id sauf celui sur lequel on vient de cliquer
      let allOtherSmiley = array_of_smiley.filter( input => ((input.dataset.question_id == smiley.dataset.question_id) && (input.dataset.smiley != smiley.dataset.smiley)) );
      allOtherSmiley.forEach(input => input.checked = false);
    });
  });
}

export { hoverAndCheckEmoji, checkEmojiQuestionnaire };
