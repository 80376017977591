import { createPopper } from '@popperjs/core/lib/createPopper';
import { showBanner, setBanner } from './_flash_banner';

const changeEmojiType = () => {
	const $banner = setBanner();
  /////////////////////// NEW QUESTION EMOJI ///////////////////////

  // ON DESACTIVE TOUTES LES CHECKBOX DES NOUVEAUX SMILEY LORS DU CONNECT
  const checkboxNew = document.querySelectorAll('.new-smiley-checkbox')
  const labelNew = document.querySelectorAll('.new-smiley-label')

  Array.prototype.forEach.call(checkboxNew, (chexbox) => {
    chexbox.disabled=true
  });
  Array.prototype.forEach.call(labelNew, (label) => {
    label.style.cursor = 'auto'
  });

  // ON RÉCUPÈRE LES RADIO BUTTONS ET APPLIQUE FONCTION POUR FRONT DES EMOJIS
  const radioBtnOriginals = document.querySelectorAll(".original_emojis_class")
  const radioBtnNews = document.querySelectorAll(".new_emojis_class")

  Array.prototype.forEach.call(radioBtnOriginals, (btn) => {
    if (btn) {
      btn.addEventListener("click", () => {
        handleSmileyFrontOnClick(btn, true)
      })
    }
  })

  Array.prototype.forEach.call(radioBtnNews, (btn) => {
    if (btn) {
      btn.addEventListener("click", () => {
        handleSmileyFrontOnClick(btn, false)
      })
    }
  })

  /////////////////////// EDIT QUESTION EMOJI ///////////////////////

  // ON RÉCUPÈRE TOUS LES BOUTONS "MODIFIER LES EMOJIS" DES QUESTIONS CRÉÉES
  const buttons_emoji = document.querySelectorAll('.toogle-smiley-type')

  Array.prototype.forEach.call(buttons_emoji, (btn) => {
    if (btn) {
      btn.addEventListener("click", () => {

				// ON ASSIGNE A CHAQUE BOUTON L'URL POUR AJAX AINSI QUE L'ID AUQUEL CELUI-CI EST LIÉ
				const editEmojiPath = btn.dataset.path
				const questionId = btn.dataset.questionId

				// ON RÉCUPÈRE LES EMOJIS PROPRES À LA QUESTION
				const verySad = document.querySelector(`#very-sad-${questionId}`)
				const sad = document.querySelector(`#sad-${questionId}`)
				const neutral = document.querySelector(`#neutral-${questionId}`)
				const happy = document.querySelector(`#happy-${questionId}`)
				const veryHappy = document.querySelector(`#very-happy-${questionId}`)

				const allEmojis = [verySad, sad, neutral, happy, veryHappy]

				let originalSmiley = btn.dataset.originalSmiley


				console.log(verySad, sad, neutral, happy, veryHappy)

        // ON TOOGLE LE STATUT DE ORIGINAL_SMILEY LORS DE CLICK
        if (originalSmiley == 'false') { originalSmiley = false }
        originalSmiley = !originalSmiley;

        // AJAX POUR METTRE À JOUR LE STATUT DANS LE CONTROLLER + FRONT
        $.ajax({
          method: 'PATCH',
          url: editEmojiPath,
          data: { type: 'json', original_smiley: originalSmiley },

          success: (data) => {
						console.log("originalSmiley", originalSmiley)
						if ($banner) {
							showBanner($banner.attr("data-edit-success"), "success");
						}
						btn.setAttribute("data-original-smiley",originalSmiley)
            Array.prototype.forEach.call(allEmojis, (emoji) => {
              emoji.classList.toggle('new-emoji')
            });

            if (data.original_smiley) {
              if (verySad) { verySad.innerHTML = "<img src='/assets/very-sad2.svg'>" }
              if (sad) { sad.innerHTML = "<img src='/assets/sad2.svg'>" }
              if (neutral) { neutral.innerHTML = "<img src='/assets/neutral-face.svg'>" }
              if (happy) { happy.innerHTML = "<img src='/assets/happy2.svg'>" }
              if (veryHappy) { veryHappy.innerHTML = "<img src='/assets/very-happy2.svg'>" }
            } else {
              if (verySad) { verySad.innerHTML = "<img src='/assets/emoji-very-unsatisfied.svg'>" }
              if (sad) { sad.innerHTML = "<img src='/assets/emoji-unsatisfied.svg'>" }
              if (neutral) { neutral.innerHTML = "<img src='/assets/emoji-neutral.svg'>" }
              if (happy) { happy.innerHTML = "<img src='/assets/emoji-satisfied.svg'>" }
              if (veryHappy) { veryHappy.innerHTML = "<img src='/assets/emoji-very-satisfied.svg'>" }
            }
          },
          error: function () {
            console.log('error')
						if ($banner) {
							showBanner($banner.attr("data-edit-error"), "error");
						}
          }
        })
      });
    };
  });
};

function handleSmileyFrontOnClick(btn, isOriginal) {
  const containerTargeted = btn.parentNode.parentNode

  const emojisOriginal = containerTargeted.querySelector(".form-emoji-type-true-js")
  const checkboxOriginal = containerTargeted.querySelectorAll('.original-smiley-checkbox')
  const labelOriginal = containerTargeted.querySelectorAll('.original-smiley-label')

  const emojisNew = containerTargeted.querySelector(".form-emoji-type-false-js")
  const checkboxNew = containerTargeted.querySelectorAll('.new-smiley-checkbox')
  const labelNew = containerTargeted.querySelectorAll('.new-smiley-label')

  if (isOriginal) {

    // ADD FRONT TO SELECTED EMOJIS (ORIGINAL EMOJIS)
    emojisOriginal.classList.add('form-emoji-type-selected')
    emojisOriginal.classList.remove('form-emoji-type-unselected')
    Array.prototype.forEach.call(checkboxOriginal, (chexbox) => {
      chexbox.disabled=false
    });
    Array.prototype.forEach.call(labelOriginal, (label) => {
      label.style.cursor = 'pointer'
    });

    // ADD FRONT TO UNSELECTED EMOJIS (NEW EMOJIS)
    emojisNew.classList.add('form-emoji-type-unselected')
    emojisNew.classList.remove('form-emoji-type-selected')
    Array.prototype.forEach.call(checkboxNew, (chexbox) => {
      chexbox.disabled=true
    });
    Array.prototype.forEach.call(labelNew, (label) => {
      label.style.cursor = 'auto'
    });

  } else {

    // ADD FRONT TO SELECTED EMOJIS (NEW EMOJIS)
    emojisNew.classList.add('form-emoji-type-selected')
    emojisNew.classList.remove('form-emoji-type-unselected')
    Array.prototype.forEach.call(checkboxNew, (chexbox) => {
      chexbox.disabled=false
    });
    Array.prototype.forEach.call(labelNew, (label) => {
      label.style.cursor = 'pointer'
    });

    // ADD FRONT TO UNSELECTED EMOJIS (ORIGINAL EMOJIS)
    emojisOriginal.classList.add('form-emoji-type-unselected')
    emojisOriginal.classList.remove('form-emoji-type-selected')
    Array.prototype.forEach.call(checkboxOriginal, (chexbox) => {
      chexbox.disabled=true
    });
    Array.prototype.forEach.call(labelOriginal, (label) => {
      label.style.cursor = 'auto'
    });
  };
}

function toggleActiveEmojis() {
	const $emojiLinks = $(".toggle-active-emoji")

	$emojiLinks.each(function(index, elem) {
		$(elem).click(checkEmoji);
	});
}

function checkEmoji(event) {
	const $link = $(this);
	event.preventDefault();
	$.ajax({
		url: $link.attr("href"),
		method: 'PATCH',
		data: {},
		success: function(response) {
			const $banner = setBanner();
			if ($banner) {showBanner($banner.attr("data-edit-success"), "success")}
			console.log($link.find("input"))
			const $checkbox = $link.find('input[type="checkbox"]');
			$checkbox.prop('checked', !$checkbox.prop('checked'));

		}
	})
}

function ftUpdateTagStatus() {
	const $tags = $(".update-tag-status")
  const $imageTags = $(".image-tags")

	$tags.each(function(index, elem) {
		$(elem).click(updateTagStatus);
	});

  $imageTags.each(function(index, elem) {
		$(elem).on("click", updateTagStatus);
	});
}

function updateTagStatus(event) {
	const $link = $(this);
	event.preventDefault();
  var iconeStatus = $link.closest('.created-tag-card').find(".fa-solid").hasClass("hidden")
	$.ajax({
		url: $link.attr("data-url"),
		method: 'PATCH',
		data: {},
		success: function(response) {
			const $banner = setBanner();
			if ($banner) {showBanner($banner.attr("data-edit-success"), "success")}
      if(!$link.is("img")) {
        if ($link.hasClass("btn-actif-inatif-tag")) {
          $link.toggleClass("btn-nonactif-tag")
        } else {
          $link.parent().toggleClass("btn-nonactif-tag")
        }
        // mettre l'image en gris si on appuie sur le texte
        $link.closest('.created-tag-card').toggleClass("image-gray")

      }else {
        // mettre l'image en gris et le texte si on appuie sur l'image
        $link.closest('.created-tag-card').toggleClass("image-gray")
        $link.closest('.created-tag-card').find(".custom-btn").toggleClass("btn-nonactif-tag")
      }

      if(!iconeStatus) {
        $link.closest('.created-tag-card').find(".fa-solid").addClass("hidden")
      }else {
        $link.closest('.created-tag-card').find(".fa-solid").removeClass("hidden")
      }
		}
	})
}


function ftChangeOrderPossibleAnswers() {
	const $btns = $(".button-order")

	$btns.each(function(index, elem) {
		$(elem).click(ChangeOrderPossibleAnswers);
	});
}

function ChangeOrderPossibleAnswers(event) {
	const $link = $(this);
	const href = $link.attr('href')
	$link.removeAttr('href');
	event.preventDefault();
	$.ajax({
		url: href,
		method: 'POST',
		data: {},
		success: function(response) {
			const $banner = setBanner();
			if ($banner) {showBanner($banner.attr("data-edit-success"), "success")}
			console.log("context:", $link.parent().parent().find(".order-pa-div"))
			console.log("partial:", response.partial_html)

			$link.parent().parent().find(".order-pa-div").html(response.partial_html)
			$link.attr('href', href);

			// on réapplique les event listener
			if (response.type_question == "Émojis") {
				const $emojiLinks = $link.parent().parent().find(".toggle-active-emoji")

				$emojiLinks.each(function(index, elem) {
					$(elem).click(checkEmoji);
				});
			}
		}
	})
}

export { changeEmojiType, toggleActiveEmojis, ftUpdateTagStatus, ftChangeOrderPossibleAnswers }
