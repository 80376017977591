// JS pour les boutons de format de prix:

  function setupPriceFormatButtons() {

      if (document.querySelector("#price-format-for-js")) {

      const buttons = document.querySelectorAll(".price-format-btn");
      const hiddenField = document.getElementById("selected_price_format_field");
      const initialValue = hiddenField.value;

      if (hiddenField) {
        buttons.forEach(button => {
          if (button.getAttribute("data-format") === initialValue) {
            button.classList.remove("btn-admin-no-check");
            button.classList.add("btn-admin-edit");
          }
        });

        buttons.forEach(button => {
          button.addEventListener("click", function() {
            const selectedFormat = this.getAttribute("data-format");
            hiddenField.value = selectedFormat;

            buttons.forEach(btn => {
              btn.classList.remove("btn-admin-edit");
              btn.classList.add("btn-admin-no-check");
            });

            this.classList.remove("btn-admin-no-check");
            this.classList.add("btn-admin-edit");
          });
        });
      }
    }
  }

  export{setupPriceFormatButtons};
