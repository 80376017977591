import { Controller } from "stimulus";
export default class extends Controller {

  connect() {

    this.element.querySelectorAll(".card-admin-rest-select-sous-groupe").forEach((parentSousGroup) => {
        parentSousGroup.addEventListener("click", () => this.toggleChildSousGroups(parentSousGroup));
    });

    this.hideDescendantsIfCountGreaterThan8();
  }


  hideDescendantsIfCountGreaterThan8() {
    const sousGroupes = this.element.querySelectorAll(".card-admin-rest-select-sous-groupe");
    sousGroupes.forEach(sg => {
      const rootId = sg.dataset.rootId;
      const sgId = sg.dataset.sgId;
      const count = parseInt(sg.dataset.restaurantsCount);

      if (count > 8 && rootId !== sgId) {
        sg.classList.add("hidden"); // hide descendants
      }
    });
  }


  toggleChildSousGroups(parentSousGroup, to_hide = null) {
    console.log('click !');
    const sousGroupId = parentSousGroup.getAttribute("data-sg-id");
    const elementsToToggle = document.querySelectorAll(`[data-parent-id="${sousGroupId}"]`);

    // If to_hide is not explicitly provided, determine based on the current visibility of direct children
    if (to_hide === null && elementsToToggle.length > 0) {
      to_hide = !elementsToToggle[0].classList.contains("hidden");
    }

    console.log(to_hide);

    // Function to recursively hide all descendants
    const hideDescendants = (parentId) => {
      const childElements = document.querySelectorAll(`[data-parent-id="${parentId}"]`);
      childElements.forEach(child => {
        child.classList.add("hidden");

        // Hide associated restaurants and QR codes
        const childSgId = child.getAttribute("data-sg-id");
        document.querySelectorAll(`.card-admin-rest-select-resto.sg-${childSgId}`).forEach(restaurant => restaurant.classList.add("hidden"));
        document.querySelectorAll(`.sousgroupe-${childSgId}.custom-btn.icon-qr-code-container`).forEach(qrCode => qrCode.classList.add("hidden"));

        // Recursively hide the descendants
        hideDescendants(childSgId);
      });
    };

    elementsToToggle.forEach((element) => {
      if (to_hide) {
        element.classList.add("hidden");
        hideDescendants(element.getAttribute("data-sg-id")); // Recursively hide all descendants
      } else {
        element.classList.remove("hidden");
        element.classList.add("visible");
      }
    });

  }
	
	clearNavbarCache() {
		localStorage.removeItem('navbar_ancestry_loop');
		localStorage.removeItem('navbar_ancestry_loop_states');
	}
}
