import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const date = document.getElementById('date');

    if (date) {
      const form = document.querySelector('form.date-resize');
      date.addEventListener('change', () => {
        form.submit();
      });
    }
  }
}
