import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';  // optional if you have css loader

const initSelect2 = (context = document) => {
  console.log("initSelect2");
  $('#select-sg').select2();
  $('#select-sous-groupes').select2();
  $('#select2ssg').select2();
  $('.select2-admin').select2();
  $('.select2resto-user').select2();
  $('.select2edit-restaurant-user').select2();
  $('.select2create-restaurant-user').select2();
  $('#select2sg').select2();
  $('.select2groupe-user').select2();
  $('#allergens_new').select2(); // Not used.
  $('#allergens_new_group').select2(); // Not used.
  $('.select2-allergens-edit').select2(); // Not used.
  $('.select2resto-menu').select2(); // Not used.
  $('.select2_customer_for_campaign').select2();
  $('.select2_restaurants_for_import_meal_on_multisite').select2();
  $('.select2_groups_for_admin_mail_groups_all').select2();
  $('.select2_enquete_to_duplicate').select2();
  $('.select2_mails_to_duplicate').select2();
  $('.select2_groups_in_wich_duplicate_enquete').select2();
  $('.select2_sg_in_wich_duplicate_enquete').select2();
  $('.select2_meals_to_duplicate').select2(); // Not used.
  $('.select2_groups_in_wich_duplicate_meals').select2(); // Not used.
  $('.select2_sg_in_wich_duplicate_meals').select2();
  $('.select2_group_create_resto').select2();
  $('.select2_group_create_sous_groupe').select2();
  $('.select2_review_admin_mail').select2();

  /**** Start nationality select2 ****/
  // Add flag's images to nationality question.
  $(".selector-nationality").each(function() {
    // Prevent to launch select2 if it has already been launched in a selector-nationality.
    if (!$(this).hasClass('select2-hidden-accessible')) {
      $(this).select2({
        templateResult: formatState,
        templateSelection: formatState,
        placeholder: setPlaceholder(),
        "language": {
          "noResults": function(){
              return "";
          }
        },
      });
    }
  });


  // If select2 is located in a modal, we need to set the modal as parent container.
  $("body").on("shown.bs.modal", ".modal", function() {
    $(this)
      .find(".selector-nationality-additional-question")
      .each(function() {
        $(this).select2({
          dropdownParent: getDropdownParent($(this)),
          templateResult: formatState,
          templateSelection: formatState,
          "language": {
            "noResults": function() {
              return "";
            }
          }
        });
      });
  });

  function setPlaceholder() {
    var placeholder = $(".selector-nationality").attr('data-placeholder')
    return placeholder
  }

  function formatState (opt) {
    if (!opt.id) {
      return opt.text;
    }
    var optimage = $(opt.element).attr('data-image');
    if(!optimage){
      return opt.text;
    } else {
      var $opt = $(
        '<span><img src="' + optimage + '" width="20px" style="margin-bottom: 2px; margin-right: 10px;" />' + opt.text + '</span>'
      );
      return $opt;
    }
  };

  function getDropdownParent(element) {
    var dropdownParent = $(document.body);
    if (element.parents(".modal-edit-additional-question-select2").length !== 0)
      dropdownParent = element.parents(".modal-edit-additional-question-select2");
    return dropdownParent;
  }
  /**** End nationality select2 ****/

};

export { initSelect2 };
