// Lorsque la checkbox est cochée, la dropdown des types de questions correspondant aux questions pouvant être des filtres

const checkboxFilteredDropdown = () => {
  const $checkbox = $('[id="question_is_a_filter"]');

  if ($checkbox) {
    $checkbox.on('change',function() {
      const $questionTypesToHide = $(this).closest('form').find('[class^="type_question"]').not("[data-type='oui-non'],[data-type='tag-unique'],[data-type='nationality']")
      const $dropdownBtn = $(this).closest('form').find('#dropdownMenu1')

      if ($(this).is(':checked')) {
        $questionTypesToHide.hide();
        $dropdownBtn.css('background-color', '#46D2C4');
      } else {
        $questionTypesToHide.show();
        $dropdownBtn.css('background-color', '#989eb1');
      }
    });
  };
};

// Lorsque l'utilisateur choisit une question qui ne peux pas être un filtre => On désactive la checkbox
const dropdownDisableCheckbox = () => {
  const $questionTypeList = $('#dropdown-question-types-list li')

  if ($questionTypeList)  {
    $questionTypeList.on('click',function() {

      const $checkboxAndLabel = $(this).closest('form').find('.indicator-checkbox-label');
      const $checkbox = $(this).closest('form').find('#question_is_a_filter');

      if (!['oui-non', 'tag-unique', 'nationality'].includes($(this).data('type'))) {
        $checkbox.prop('disabled', true);
        $checkboxAndLabel.css('opacity', '0.4');

        $checkboxAndLabel.tooltip({
          content: $checkboxAndLabel.attr('title'),
        });

      } else {
        $checkbox.prop('disabled', false);
        $checkboxAndLabel.css('opacity', '1');
        $checkboxAndLabel.tooltip('destroy');
      }
    });
  }
}

export { checkboxFilteredDropdown, dropdownDisableCheckbox }
