const disapear_flashes = () => {
  const flash = document.querySelector(".flash-banner");

  // Pages where do not want the banner to disappear:
  const disableHideBanner = document.querySelector(".reward-activation-dashboard")

  if (flash && !disableHideBanner) {
    setTimeout(function(){flash.classList.add("hidden")}, 4000);
  }
}

export {disapear_flashes};
