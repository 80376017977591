const fixPositionOnScroll = () => {
	if (document.getElementById('review-answer')) {
	  console.log('fixPositionOnScroll');
	  let answerPosition = document.getElementById('review-answer').getBoundingClientRect().top + window.scrollY;

	  window.addEventListener('scroll', () => {
			if (document.getElementById('review-answer')) {
				if (window.scrollY > answerPosition) {
					document.getElementById('review-answer').classList.add('fix-position');
				} else {
					if(document.querySelector('.fix-position')) {
					document.getElementById('review-answer').classList.remove('fix-position');
					}
				}
			}
	  });
	}
  };

  export { fixPositionOnScroll };
