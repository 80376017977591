import { Controller } from 'stimulus';
export default class extends Controller {
  connect() {
    console.log("check_enter_js")
  }

  async update_customer_date_filter(){
    const start_date = document.querySelector("#start_date_custo");
    const end_date = document.querySelector("#end_date_custo");
    console.log("update_customer_date_filter");
    const response = await fetch(start_date.dataset.url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
      },
      body: JSON.stringify({ start_date: start_date.value, end_date: end_date.value })
    })
    const data = await response.json();
    this.updateCountCustomer(data);
  };

  updateCountCustomer(jsonData) {
    const newCustomerCountPerDate = jsonData.customer_per_date
    const valueCustomerPerDate = document.getElementById('selected-customer-per-date-count');
    valueCustomerPerDate.dataset.unsentCustomerPerDateNbClients = newCustomerCountPerDate;
    valueCustomerPerDate.innerHTML = "<strong>" + newCustomerCountPerDate + "</strong> clients";

  };

}
