// pour avoir la liste des restos qui n'ont pas le meal item ou la categorie de plat pour la modal
const listItem = content => `<li class="list-group-item item-resto-inactif-no-exist">${content}</li>`;

const unorderedList = (items) => {
  const content = items.map(item => listItem(item)).join('\n ');
  return `<ul class="list-group list-resto-inactif-no-exist">${content}</ul>`;
};

const restoInactifNoExistOne = () => {
  if (document.querySelector("#liste-resto-inactif-for-js")) {
    console.log("restoInactifNoExistOnes")
    const linkModal = document.querySelectorAll(".list-resto-js");

    if (linkModal) {
      const modalListRestoOne = document.querySelector("#modalcarte-groupe1").querySelector(".modal-content");
      linkModal.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const arrayRestoNoExist = JSON.parse(event.currentTarget.dataset.restolistnoexist)
          modalListRestoOne.querySelector('.value-2-restau').innerHTML = unorderedList(arrayRestoNoExist);
          const arrayActif = event.currentTarget.dataset.restolistactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          if (arrayActif[0] === "") {
            modalListRestoOne.querySelector("#groupe2two").innerHTML = "<div class='modal-list-plat-resto-title'>Cette catégorie n'est active dans aucun restaurant.</div>";
          }
          else {
            modalListRestoOne.querySelector('.value-3-restau').innerHTML = unorderedList(arrayActif);
          }
        })
      })
    }

  }

};

const restoInactifNoExistTwo = () => {
  if (document.querySelector("#liste-resto-inactif-for-js")) {
    console.log("restoInactifNoExistTwo")
    const linkModalTwo = document.querySelectorAll(".list-resto-js");

    if (linkModalTwo) {
      const modalListRestoTwo = document.querySelector("#modalcarte-groupe2").querySelector(".modal-content");

      linkModalTwo.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const arrayRestoInactif = event.currentTarget.dataset.restolistinactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          modalListRestoTwo.querySelector(".value-1-restau").innerHTML = unorderedList(arrayRestoInactif);
          const arrayActif = event.currentTarget.dataset.restolistactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          if (arrayActif[0] === "") {
            modalListRestoTwo.querySelector("#groupe2two").innerHTML = "<div class='modal-list-plat-resto-title'>Cette catégorie n'est active dans aucun restaurant.</div>";
          }
          else {
            modalListRestoTwo.querySelector('.value-3-restau').innerHTML = unorderedList(arrayActif);
          }
        })
      })
    }

  }

};

const restoInactifNoExistThree = () => {
  if (document.querySelector("#liste-resto-inactif-for-js")) {
    console.log('restoInactifNoExistThree')
    const linkModalThree = document.querySelectorAll(".list-resto-js");

    if (linkModalThree) {
      const modalListRestoThree = document.querySelector("#modalcarte-groupe3").querySelector(".modal-content");

      linkModalThree.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const arrayRestoInactif = event.currentTarget.dataset.restolistinactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          const arrayRestoNoExist = JSON.parse(event.currentTarget.dataset.restolistnoexist)
          modalListRestoThree.querySelector(".value-1-restau").innerHTML = unorderedList(arrayRestoInactif);
          modalListRestoThree.querySelector(".value-2-restau").innerHTML = unorderedList(arrayRestoNoExist);
          const arrayActif = event.currentTarget.dataset.restolistactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          if (arrayActif[0] === "") {
            modalListRestoThree.querySelector("#groupe3two").innerHTML = "<div class='modal-list-plat-resto-title'>Cette catégorie n'est active dans aucun restaurant.</div>";
          }
          else {
            modalListRestoThree.querySelector('.value-3-restau').innerHTML = unorderedList(arrayActif);
          }
        })
      })
    }

  }

}


const restoPlatInactifNoExistOne = () => {
  if (document.querySelector("#liste-resto-inactif-for-js")) {
    console.log('restoPlatInactifNoExistOne')
    const linkModalPlat = document.querySelectorAll(".list-plat-resto-js");

    if (linkModalPlat) {
      const modalPlatListRestoOne = document.querySelector("#modalcarte-plat1").querySelector(".modal-content");
      linkModalPlat.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const arrayPlatRestoNoExist = event.currentTarget.dataset.restoplatlistnoexist.split(",");
          modalPlatListRestoOne.querySelector('.value-plat-2-restau').innerHTML = unorderedList(arrayPlatRestoNoExist);
          const arrayActif = event.currentTarget.dataset.restoplatlistactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          if (arrayActif[0] === "") {
            modalPlatListRestoOne.querySelector("#plat1two").innerHTML = "<div class='modal-list-plat-resto-title'>Ce plat n'est actif dans aucun restaurant.</div>";
          }
          else {
            modalPlatListRestoOne.querySelector('.value-plat-3-restau').innerHTML = unorderedList(arrayActif);
          }
        })
      })
    }

  }

};

const restoPlatInactifNoExistTwo = () => {
  if (document.querySelector("#liste-resto-inactif-for-js")) {
    console.log('restoPlatInactifNoExistTwo')
    const linkModalPlatTwo = document.querySelectorAll(".list-plat-resto-js");

    if (linkModalPlatTwo) {
      const modalPlatListRestoTwo = document.querySelector("#modalcarte-plat2").querySelector(".modal-content");

      linkModalPlatTwo.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const arrayPlatRestoInactif = event.currentTarget.dataset.restoplatlistinactif.slice(0, -2).split(",");
          modalPlatListRestoTwo.querySelector(".value-plat-1-restau").innerHTML = unorderedList(arrayPlatRestoInactif);
          const arrayActif = event.currentTarget.dataset.restoplatlistactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          if (arrayActif[0] === "") {
            modalPlatListRestoTwo.querySelector("#plat2two").innerHTML = "<div class='modal-list-plat-resto-title'>Ce plat n'est actif dans aucun restaurant.</div>";
          }
          else {
            modalPlatListRestoTwo.querySelector('.value-plat-3-restau').innerHTML = unorderedList(arrayActif);
          }
        })
      })
    }

  }

};

const restoPlatInactifNoExistThree = () => {
  if (document.querySelector("#liste-resto-inactif-for-js")) {
    console.log('restoPlatInactifNoExistThree')
    const linkModalPlatThree = document.querySelectorAll(".list-plat-resto-js");

    if (linkModalPlatThree) {
      const modalPlatListRestoThree = document.querySelector("#modalcarte-plat3").querySelector(".modal-content");

      linkModalPlatThree.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          const arrayPlatRestoInactif = event.currentTarget.dataset.restoplatlistinactif.slice(0, -2).split(",");
          const arrayPlatRestoNoExist = event.currentTarget.dataset.restoplatlistnoexist.split(",");
          modalPlatListRestoThree.querySelector(".value-plat-1-restau").innerHTML = unorderedList(arrayPlatRestoInactif);
          modalPlatListRestoThree.querySelector(".value-plat-2-restau").innerHTML = unorderedList(arrayPlatRestoNoExist);
          const arrayActif = event.currentTarget.dataset.restoplatlistactif.slice(0, -2).replace('[', '').replace(']', '').split(",");
          if (arrayActif[0] === "") {
            modalPlatListRestoThree.querySelector("#plat3two").innerHTML = "<div class='modal-list-plat-resto-title'>Ce plat n'est actif dans aucun restaurant.</div>";
          }
          else {
            modalPlatListRestoThree.querySelector('.value-plat-3-restau').innerHTML = unorderedList(arrayActif);
          }
        })
      })
    }

  }

}

export {restoInactifNoExistOne, restoPlatInactifNoExistOne, restoInactifNoExistTwo, restoPlatInactifNoExistTwo, restoInactifNoExistThree, restoPlatInactifNoExistThree };
