const toggleInputQuantityForContainer = () => {

  if (document.querySelector(".contenant-type-container")) {

    let i = 1;

    console.log("toggleInputQuantityForContainer");
    const btnPlusAll = document.querySelectorAll(".quantity-plus");
    // console.log(btnPlusAll);

    const personalizedQuantityAll = document.querySelectorAll('.check-personal-quantity');
    // console.log(personalizedQuantityAll);

    const btnContenantAll = document.querySelectorAll('.all-btn-container-type input');
    // console.log(btnContenantAll)

    const btnAllChecked = document.querySelectorAll('#all-checked input');
    // console.log(btnAllChecked)

    // On veut ajouter un input avec insertAdjacentHtml ou l'on rajoutera +1 au fur et à mesure ex: perso_quantity_cl-2
    btnPlusAll.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const type = event.currentTarget.dataset.unityType
        let quantityPlusInputs = document.querySelector(`.input-container-${type}`)
        // console.log(quantityPlusInputs)
        quantityPlusInputs.insertAdjacentHTML("beforeend", `<div class="question-text-input"><input type="text" name="perso_quantity_${type}_${i}" id="perso_quantity_${type}_${i}" placeholder="Quantité"></div>`)

        i+=1;
      })
    });

    // TOGGLE personalizedQuantityAll hidden class
    personalizedQuantityAll.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const type = event.currentTarget.dataset.unityType
        let personalizedQuantityContainer = document.querySelector(`.list-quantity-multiple-${type}`)
        // console.log(personalizedQuantityContainer)

        personalizedQuantityContainer.classList.toggle('hidden')
      })
    });

    // TOGGLE contenant-type container
    btnContenantAll.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const value = event.currentTarget.value
        let contenantTypeContainer = document.querySelector(`.contenant-type-${value}`)
        // console.log(contenantTypeContainer)
        contenantTypeContainer.classList.toggle('hidden')

        let btnBaseQuantity = document.getElementById(`basic_quantity_${value}`);
        // console.log(btnBaseQuantity)
        if(btn.checked) {
          btnBaseQuantity.checked = true;
        } else {
          btnBaseQuantity.checked = false;
        }
      })
    });

    // Check all the btnContenantAll btn
    btnAllChecked.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        let btnBaseQuantityAllInput = document.querySelectorAll('.all-btn-container-type input');

        // Puts querySelectorAll container-type here
        let containerTypeContainer = document.querySelectorAll('.contenant-type-container')
        // console.log(containerTypeContainer)

        // Puts querySelectorAll base-quantity btn here
        let btnBaseQuantityAllCheckedOrNot = document.querySelectorAll('.check-base-quantity input')
        // console.log(btnBaseQuantityAllCheckedOrNot)

        if(btn.checked) {
          btnBaseQuantityAllInput.forEach((btn) => {
            btn.checked = true;
            // TOGGLE hidden class to show all container-type container
            containerTypeContainer.forEach((container) => {
              container.classList.remove('hidden');
            });
            // Make sure that all base-quantity btn are checked
            btnBaseQuantityAllCheckedOrNot.forEach((btn) => {
              btn.checked = true;
            });
          });
        } else {
          btnBaseQuantityAllInput.forEach((btn) => {
            btn.checked = false;
            // TOGGLE hidden class to hide all container-type container
            containerTypeContainer.forEach((container) => {
              container.classList.add('hidden')
            });
            // Make sure that all base-quantity btn are  NOT checked
            btnBaseQuantityAllCheckedOrNot.forEach((btn) => {
              btn.checked = false;
            });
          });
        }
      });
    });
  }

}

export { toggleInputQuantityForContainer }
