const checkboxesDisableEdit = () => {
  const pageEditMealAll = document.getElementById("edit_meal_group")
  if (pageEditMealAll) {
    console.log("checkboxesDisableEdit")
    const checkbox = document.querySelector('.checkbox-edit-meal-all-modificable-fields')
    if (checkbox) {
      const modifilcableFields = document.querySelector(".checkboxes-to-disable-js").querySelectorAll("[type='checkbox']")
      console.log('checkboxesDisableEdit')
      checkbox.addEventListener('click', (event) => {
        if (checkbox.checked === false) {
          modifilcableFields.forEach((box) => {
            box.disabled = true;
          })
        } else {
          modifilcableFields.forEach((box) => {
            box.disabled = false;
          })
        }
      })
    }

    const checkboxNameDescription = document.querySelectorAll('.checkbox-edit-meal-all ');
    if (checkboxNameDescription) {
      checkboxNameDescription.forEach(checkbox =>
        checkbox.addEventListener('click', () =>  {
          console.log(checkbox.nextElementSibling);
					if (checkbox.classList.contains("edit-descriptions")) {

					} else {
						if(checkbox.checked) {
							checkbox.nextElementSibling.disabled = false;
						} else {
							checkbox.nextElementSibling.disabled = true;
						}
					}
        })
      );
    }

    const checkboxPhoto = document.querySelector('.checkbox-edit-meal-all-photo')
    if (checkboxPhoto) {
      console.log('checkboxesDisableEdit')
      checkboxPhoto.addEventListener('click', (event) => {
        if (checkboxPhoto.nextElementSibling.firstElementChild.disabled === false) {
          checkboxPhoto.nextElementSibling.firstElementChild.disabled = true;
        } else {
          checkboxPhoto.nextElementSibling.firstElementChild.disabled = false;
        }
      })
    }


    const checkboxAllergens = document.querySelector(".checkbox-edit-meal-all-allergens")
    if (checkboxAllergens) {
      console.log('checkboxesDisableEdit')
      checkboxAllergens.addEventListener('click', (event) => {
        checkboxAllergens.parentElement.parentElement.previousElementSibling.classList.toggle("masque-allergens-to-remove")
      })
    }

    const checkboxPictos = document.querySelector(".checkbox-edit-meal-all-pictos")
    if (checkboxPictos) {
      const inputPicto = document.querySelectorAll("input[name='picto']")
      inputPicto.forEach((pic) => {
        pic.disabled = true
        pic.classList.add('icon-picto-selected-without-hover')
        pic.classList.remove('icon-picto-selected')
      })
      console.log('checkboxesDisableEdit')
      checkboxPictos.addEventListener('click', (event) => {
        if (checkboxPictos.checked === true) {
          inputPicto.forEach((pic) => {
            pic.disabled = false
            pic.nextElementSibling.classList.add('form-check-label')
            pic.classList.remove('icon-picto-selected-without-hover')
            pic.classList.add('icon-picto-selected')
          })
        } else {
          inputPicto.forEach((pic) => {
            pic.disabled = true
            pic.classList.add('icon-picto-selected-without-hover')
            pic.classList.remove('icon-picto-selected')
          })
        }
      })
    }

    const checkboxTags = document.querySelector(".checkbox-edit-meal-all-tags")
    if (checkboxTags) {
      console.log('checkboxesDisableEdit')
      checkboxTags.addEventListener('click', (event) => {
        checkboxTags.parentElement.parentElement.previousElementSibling.classList.toggle("masque-tags-to-remove")
      })
    }

    const checkboxContainers = document.querySelector(".checkbox-edit-meal-all-price")
    if (checkboxContainers) {
      console.log('checkboxesDisableEdit')
      checkboxContainers.addEventListener('click', (event) => {
        if (checkboxContainers.parentElement.parentElement.children[1].firstElementChild.firstElementChild.disabled) {
          checkboxContainers.parentElement.parentElement.children[1].firstElementChild.firstElementChild.disabled = false
        } else {
          checkboxContainers.parentElement.parentElement.children[1].firstElementChild.firstElementChild.disabled = true
        }
      })
    }


  }
}

export { checkboxesDisableEdit };
