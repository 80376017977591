const navbarTransition = () => {
  const navbar = document.querySelector(".navbar-wagon-home")
  const blac = document.querySelector(".blac")
  const wite = document.querySelector(".wite")
  const logos = document.querySelectorAll(".size-logo-home")

  if (blac) {
    console.log('navbarTransition')
    window.onscroll = function(){
      if(document.documentElement.scrollTop > 500){
        navbar.classList.add("scrolled");
        blac.classList.remove("hidden");
        wite.classList.add("hidden");
      }
      if(document.documentElement.scrollTop < 500){
        navbar.classList.remove("scrolled");
        blac.classList.add("hidden");
        wite.classList.remove("hidden");
      }
    }
  }
}

const nestedDropdowns = () => {
  const dropdownSub = document.querySelector(".dropdown-submenu")
  if (dropdownSub) {
    console.log('nestedDropdowns')
    Array.prototype.forEach.call(document.querySelectorAll(".dropdown-submenu"), function(dd) {
      dd.addEventListener("click", (event) => {
        event.stopPropagation();
      })
      dd.addEventListener("mouseover", (event) => {
        console.log(event.propagation);
        event.stopPropagation();
      })
    })
  }
}

export { nestedDropdowns, navbarTransition};
