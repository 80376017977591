const manageTagsMenu = () => {

	const previewDiv = document.querySelector("iframe#preview")
  // SIMULATION IFRAME POUR AFFICHER / CACHER LE FLAG "BEST-SELLER"
  const checkboxBestSellerTag = document.getElementById("hide_best_seller_tag")
  if (checkboxBestSellerTag) {
    checkboxBestSellerTag.addEventListener("click", () => {
      const BestSellerTags = previewDiv.contentDocument.querySelectorAll('.best-seller-tag-js')
      BestSellerTags.forEach(tag => {
        tag.classList.toggle('hidden')
      });
    })
  }

  // SIMULATION DANS L'IFRAME POUR COLORER LE BACKGROUND DES CARDS "BEST-SELLER"
  const colorBestSellerCard = document.getElementById("color_best_seller_card")
  if (colorBestSellerCard) {
    colorBestSellerCard.addEventListener("change", (event) => {
      const BestSellerTags = previewDiv.contentDocument.querySelectorAll('.card-menu-new-best-seller')
      BestSellerTags.forEach(card => {
        card.style.backgroundColor = event.currentTarget.value
      });
    })
  }
}

export { manageTagsMenu };
