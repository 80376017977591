const allergensSelectionToggleCss = (context = document) => {
  if (context.querySelector(".allergens-columns-new") || (context.querySelector(".allergens-columns-edit")  && context.querySelector("#edit-meal-dates-page-for-js").dataset.userAllowedToModifyAllergens === "true"  ) ) {
    const allergens = context.querySelectorAll(".allergens-columns-new");
    console.log("allergensSelectionToggleCss")
    context.querySelectorAll(".form-check-input").forEach((allergen) => {
      if (allergen.name == "allergens[]") {
        allergen.addEventListener('click', () => {
          allergen.parentElement.classList.toggle("selected-allergen-box")
          allergen.nextElementSibling.lastElementChild.classList.toggle("selected-allergen-text")
          allergen.nextElementSibling.firstElementChild.classList.toggle("hidden")
          allergen.nextElementSibling.children[1].classList.toggle("hidden")

        })
      }
    })
  }
}

const allergensSelectionEdit = (context = document) => {
  if (context.querySelector(".allergens-columns-edit") ) {
    const allergensForEdit = context.querySelectorAll(".allergens-columns-edit");
    const selectedAllergens = context.querySelector("[name='allergens_of_meal[]']").value.split(", ")
    allergensForEdit.forEach((allergen) => {
      const valueAllergen = allergen.firstElementChild.value

      if (selectedAllergens.includes(valueAllergen)) {
        allergen.firstElementChild.checked = true
        allergen.classList.toggle("selected-allergen-box")
        allergen.lastElementChild.lastElementChild.classList.toggle("selected-allergen-text")
        allergen.lastElementChild.firstElementChild.classList.toggle("hidden")
        allergen.lastElementChild.children[1].classList.toggle("hidden")

      }
    })
  }
}

export { allergensSelectionToggleCss, allergensSelectionEdit};
