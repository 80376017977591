const checkbox_switch = () => {

  const reward_cb = document.getElementById("restaurant_reward_oui_non")
  const category_cb = document.getElementById("restaurant_category_oui_non")
  const service_cb = document.getElementById("restaurant_service_oui_non")
  const nps_cb = document.getElementById("restaurant_nps_oui_non")
  const client_cb = document.getElementById("restaurant_client_oui_non")
  const claim_cb = document.getElementById("restaurant_claim_oui_non")
  const pub_cb = document.getElementById("restaurant_pub_oui_non")
  const contact_covid_cb = document.getElementById("restaurant_contact_covid_oui_non")
  const table_number_cb = document.getElementById("restaurant_table_number_oui_non")
  const page_start_cb = document.getElementById("restaurant_page_start_oui_non")
  const page_start_menu_cb = document.getElementById("restaurant_page_start_menu_oui_non")
  const tiny_option_cb = document.getElementById("restaurant_tiny_option")
  const fake_cb = document.getElementById("restaurant_fake")
  const tablet_cb = document.getElementById("restaurant_tablet_support")
  const url_cb = document.getElementById("restaurant_url_support")
  const qrcode_cb = document.getElementById("restaurant_qrcode_support")
  const module_fid_cb = document.getElementById("restaurant_module_fid_oui_non")
  const public_reviews_cb = document.getElementById("restaurant_public_reviews_oui_non")
  const public_reviews_average_cb = document.getElementById("restaurant_public_reviews_average_oui_non")
  const answers_public_reviews_cb = document.getElementById("restaurant_answers_public_reviews_oui_non")
  const badge_cb = document.getElementById("restaurant_badge_oui_non")
  const show_mast_cat_cb = document.getElementById("restaurant_show_mast_cat_oui_non")
  const many_enquete_cb = document.getElementById("restaurant_many_enquete_oui_non")
  const anonym_cb = document.getElementById("restaurant_anonym_oui_non")
  const container_cb = document.getElementById("restaurant_container_oui_non")
  const evolution_cb = document.getElementById("restaurant_evolution")
  const anonym_results_cb = document.getElementById("restaurant_anonym_results")
  const rgpd_managment_cliking_cb = document.getElementById("restaurant_rgpd_managment_cliking")
  const phone_cb = document.getElementById("restaurant_phone_oui_non")
  const category_btn = document.getElementById("category_btn")
  const category_btn_no = document.getElementById("category_btn_no")
  const service_btn = document.getElementById("service_btn")
  const service_btn_no = document.getElementById("service_btn_no")
  const nps_btn = document.getElementById("nps_btn")
  const nps_btn_no = document.getElementById("nps_btn_no")
  const client_btn = document.getElementById("client_btn")
  const client_btn_no = document.getElementById("client_btn_no")
  const claim_btn = document.getElementById("claim_btn")
  const claim_btn_no = document.getElementById("claim_btn_no")
  const pub_btn = document.getElementById("pub_btn")
  const pub_btn_no = document.getElementById("pub_btn_no")
  const contact_covid_btn = document.getElementById("contact_covid_btn")
  const contact_covid_btn_no = document.getElementById("contact_covid_btn_no")
  const table_number_btn = document.getElementById("table_oui_non_btn")
  const table_number_btn_no = document.getElementById("table_oui_non_btn_no")
  const page_start_btn = document.getElementById("start_oui_non_btn")
  const page_start_btn_no = document.getElementById("start_oui_non_btn_no")
  const page_start_menu_btn = document.getElementById("start_menu_oui_non_btn")
  const page_start_menu_btn_no = document.getElementById("start_menu_oui_non_btn_no")
  const tiny_option_btn = document.getElementById("tiny_option_btn")
  const tiny_option_btn_no = document.getElementById("tiny_option_btn_no")
  const reward_btn = document.getElementById("reward_btn")
  const reward_btn_no = document.getElementById("reward_btn_no")
  const fake_btn = document.getElementById("fake_btn")
  const fake_btn_no = document.getElementById("fake_btn_no")
  const tablet_btn = document.getElementById("tablet_btn")
  const tablet_btn_no = document.getElementById("tablet_btn_no")
  const url_btn = document.getElementById("url_btn")
  const url_btn_no = document.getElementById("url_btn_no")
  const qrcode_btn = document.getElementById("qrcode_btn")
  const qrcode_btn_no = document.getElementById("qrcode_btn_no")
  const module_fid_btn = document.getElementById("module_fid_oui_non_btn")
  const module_fid_btn_no = document.getElementById("module_fid_oui_non_btn_no")
  const public_reviews_btn = document.getElementById("public_reviews_oui_non_btn")
  const public_reviews_btn_no = document.getElementById("public_reviews_oui_non_btn_no")
  const public_reviews_average_btn = document.getElementById("public_reviews_average_oui_non_btn")
  const public_reviews_average_btn_no = document.getElementById("public_reviews_average_oui_non_btn_no")
  const answers_public_reviews_btn = document.getElementById("answers_public_reviews_oui_non_btn")
  const answers_public_reviews_btn_no = document.getElementById("answers_public_reviews_oui_non_btn_no")
  const badge_btn = document.getElementById("badge_oui_non_btn")
  const badge_btn_no = document.getElementById("badge_oui_non_btn_no")
  const show_mast_cat_btn = document.getElementById("show_mast_cat_oui_non_btn")
  const show_mast_cat_btn_no = document.getElementById("show_mast_cat_oui_non_btn_no")
  const many_enquete_btn = document.getElementById("many_enquete_oui_non_btn")
  const many_enquete_btn_no = document.getElementById("many_enquete_oui_non_btn_no")
  const anonym_btn = document.getElementById("anonym_oui_non_btn")
  const anonym_btn_no = document.getElementById("anonym_oui_non_btn_no")
  const container_btn = document.getElementById("container_oui_non_btn")
  const container_btn_no = document.getElementById("container_oui_non_btn_no")
	const evolution_btn = document.getElementById("evolution_btn")
  const evolution_btn_no = document.getElementById("evolution_btn_no")
  const anonym_results_btn = document.getElementById("anonym_results_btn")
  const anonym_results_btn_no = document.getElementById("anonym_results_btn_no")
	const rgpd_managment_cliking_btn = document.getElementById("rgpd_managment_cliking_btn")
  const rgpd_managment_cliking_btn_no = document.getElementById("rgpd_managment_cliking_btn_no")
  const phone_btn = document.getElementById("phone_oui_non_btn")
  const phone_btn_no = document.getElementById("phone_oui_non_btn_no")

  if (reward_cb) {
    console.log('checkbox_switch')

    category_btn.addEventListener('click', () => {
      category_cb.checked = false;
      category_btn.classList.add('hidden');
      category_btn_no.classList.remove('hidden');
    });

    service_btn.addEventListener('click', () => {
      service_cb.checked = false;
      service_btn.classList.add('hidden');
      service_btn_no.classList.remove('hidden');
    });

    nps_btn.addEventListener('click', () => {
      nps_cb.checked = false;
      nps_btn.classList.add('hidden');
      nps_btn_no.classList.remove('hidden');
    });

    client_btn.addEventListener('click', () => {
      client_cb.checked = false;
      client_btn.classList.add('hidden');
      client_btn_no.classList.remove('hidden');
    });

    claim_btn.addEventListener('click', () => {
      claim_cb.checked = false;
      claim_btn.classList.add('hidden');
      claim_btn_no.classList.remove('hidden');
    });

    pub_btn.addEventListener('click', () => {
      pub_cb.checked = false;
      pub_btn.classList.add('hidden');
      pub_btn_no.classList.remove('hidden');
    });

    contact_covid_btn.addEventListener('click', () => {
      contact_covid_cb.checked = false;
      contact_covid_btn.classList.add('hidden');
      contact_covid_btn_no.classList.remove('hidden');
    });

    table_number_btn.addEventListener('click', () => {
      table_number_cb.checked = false;
      table_number_btn.classList.add('hidden');
      table_number_btn_no.classList.remove('hidden');
    });

    page_start_btn.addEventListener('click', () => {
      page_start_cb.checked = false;
      page_start_btn.classList.add('hidden');
      page_start_btn_no.classList.remove('hidden');
    });

    page_start_menu_btn.addEventListener('click', () => {
      page_start_menu_cb.checked = false;
      page_start_menu_btn.classList.add('hidden');
      page_start_menu_btn_no.classList.remove('hidden');
    });

    tiny_option_btn.addEventListener('click', () => {
      tiny_option_cb.checked = false;
      tiny_option_btn.classList.add('hidden');
      tiny_option_btn_no.classList.remove('hidden');
    });

    reward_btn.addEventListener('click', () => {
      reward_cb.checked = false;
      reward_btn.classList.add('hidden');
      reward_btn_no.classList.remove('hidden');
    });

    fake_btn.addEventListener('click', () => {
      fake_cb.checked = false;
      fake_btn.classList.add('hidden');
      fake_btn_no.classList.remove('hidden');
    });

    tablet_btn.addEventListener('click', () => {
      tablet_cb.checked = false;
      tablet_btn.classList.add('hidden');
      tablet_btn_no.classList.remove('hidden');
    });

    url_btn.addEventListener('click', () => {
      url_cb.checked = false;
      url_btn.classList.add('hidden');
      url_btn_no.classList.remove('hidden');
    });

    qrcode_btn.addEventListener('click', () => {
      qrcode_cb.checked = false;
      qrcode_btn.classList.add('hidden');
      qrcode_btn_no.classList.remove('hidden');
    });

		if (module_fid_btn) {
			module_fid_btn.addEventListener('click', () => {
				module_fid_cb.checked = false;
				module_fid_btn.classList.add('hidden');
				module_fid_btn_no.classList.remove('hidden');
			});
		}

    public_reviews_btn.addEventListener('click', () => {
      public_reviews_cb.checked = false;
      public_reviews_btn.classList.add('hidden');
      public_reviews_btn_no.classList.remove('hidden');
    });

    public_reviews_average_btn.addEventListener('click', () => {
      public_reviews_average_cb.checked = false;
      public_reviews_average_btn.classList.add('hidden');
      public_reviews_average_btn_no.classList.remove('hidden');
    });

    badge_btn.addEventListener('click', () => {
      badge_cb.checked = false;
      badge_btn.classList.add('hidden');
      badge_btn_no.classList.remove('hidden');
    });

    answers_public_reviews_btn.addEventListener('click', () => {
      answers_public_reviews_cb.checked = false;
      answers_public_reviews_btn.classList.add('hidden');
      answers_public_reviews_btn_no.classList.remove('hidden');
    });

    show_mast_cat_btn.addEventListener('click', () => {
      show_mast_cat_cb.checked = false;
      show_mast_cat_btn.classList.add('hidden');
      show_mast_cat_btn_no.classList.remove('hidden');
    });

    many_enquete_btn.addEventListener('click', () => {
      many_enquete_cb.checked = false;
      many_enquete_btn.classList.add('hidden');
      many_enquete_btn_no.classList.remove('hidden');
    });

    anonym_btn.addEventListener('click', () => {
      anonym_cb.checked = false;
      anonym_btn.classList.add('hidden');
      anonym_btn_no.classList.remove('hidden');
    });

    container_btn.addEventListener('click', () => {
      container_cb.checked = false;
      container_btn.classList.add('hidden');
      container_btn_no.classList.remove('hidden');
    });

    evolution_btn.addEventListener('click', () => {
      evolution_cb.checked = false;
      evolution_btn.classList.add('hidden');
      evolution_btn_no.classList.remove('hidden');
    });

    anonym_results_btn.addEventListener('click', () => {
      anonym_results_cb.checked = false;
      anonym_results_btn.classList.add('hidden');
      anonym_results_btn_no.classList.remove('hidden');
    });

		rgpd_managment_cliking_btn.addEventListener('click', () => {
      rgpd_managment_cliking_cb.checked = false;
      rgpd_managment_cliking_btn.classList.add('hidden');
      rgpd_managment_cliking_btn_no.classList.remove('hidden');
    });

    phone_btn.addEventListener('click', () => {
      phone_cb.checked = false;
      phone_btn.classList.add('hidden');
      phone_btn_no.classList.remove('hidden');
    });

    category_btn_no.addEventListener('click', () => {
      category_cb.checked = true;
      category_btn_no.classList.add('hidden');
      category_btn.classList.remove('hidden');
    });

    service_btn_no.addEventListener('click', () => {
      service_cb.checked = true;
      service_btn_no.classList.add('hidden');
      service_btn.classList.remove('hidden');
    });

    nps_btn_no.addEventListener('click', () => {
      nps_cb.checked = true;
      nps_btn_no.classList.add('hidden');
      nps_btn.classList.remove('hidden');
    });

    client_btn_no.addEventListener('click', () => {
      client_cb.checked = true;
      client_btn_no.classList.add('hidden');
      client_btn.classList.remove('hidden');
    });

    claim_btn_no.addEventListener('click', () => {
      claim_cb.checked = true;
      claim_btn_no.classList.add('hidden');
      claim_btn.classList.remove('hidden');
    });

    pub_btn_no.addEventListener('click', () => {
      pub_cb.checked = true;
      pub_btn_no.classList.add('hidden');
      pub_btn.classList.remove('hidden');
    });


    contact_covid_btn_no.addEventListener('click', () => {
      contact_covid_cb.checked = true;
      contact_covid_btn_no.classList.add('hidden');
      contact_covid_btn.classList.remove('hidden');
    });

    table_number_btn_no.addEventListener('click', () => {
      table_number_cb.checked = true;
      table_number_btn_no.classList.add('hidden');
      table_number_btn.classList.remove('hidden');
    });

    page_start_btn_no.addEventListener('click', () => {
      page_start_cb.checked = true;
      page_start_btn_no.classList.add('hidden');
      page_start_btn.classList.remove('hidden');
    });

    page_start_menu_btn_no.addEventListener('click', () => {
      page_start_menu_cb.checked = true;
      page_start_menu_btn_no.classList.add('hidden');
      page_start_menu_btn.classList.remove('hidden');
    });

    tiny_option_btn_no.addEventListener('click', () => {
      tiny_option_cb.checked = true;
      tiny_option_btn.classList.remove('hidden');
      tiny_option_btn_no.classList.add('hidden');
    });

    reward_btn_no.addEventListener('click', () => {
      reward_cb.checked = true;
      reward_btn_no.classList.add('hidden');
      reward_btn.classList.remove('hidden');
    });

    tablet_btn_no.addEventListener('click', () => {
      tablet_cb.checked = true;
      tablet_btn_no.classList.add('hidden');
      tablet_btn.classList.remove('hidden');
    });

    url_btn_no.addEventListener('click', () => {
      url_cb.checked = true;
      url_btn_no.classList.add('hidden');
      url_btn.classList.remove('hidden');
    });

    qrcode_btn_no.addEventListener('click', () => {
      qrcode_cb.checked = true;
      qrcode_btn_no.classList.add('hidden');
      qrcode_btn.classList.remove('hidden');
    });

    module_fid_btn_no.addEventListener('click', () => {
      module_fid_cb.checked = true;
      module_fid_btn_no.classList.add('hidden');
      module_fid_btn.classList.remove('hidden');
    });

    public_reviews_btn_no.addEventListener('click', () => {
      public_reviews_cb.checked = true;
      public_reviews_btn_no.classList.add('hidden');
      public_reviews_btn.classList.remove('hidden');
    });

    public_reviews_average_btn_no.addEventListener('click', () => {
      public_reviews_average_cb.checked = true;
      public_reviews_average_btn_no.classList.add('hidden');
      public_reviews_average_btn.classList.remove('hidden');
    });

    badge_btn_no.addEventListener('click', () => {
      badge_cb.checked = true;
      badge_btn_no.classList.add('hidden');
      badge_btn.classList.remove('hidden');
    });

    answers_public_reviews_btn_no.addEventListener('click', () => {
      answers_public_reviews_cb.checked = true;
      answers_public_reviews_btn_no.classList.add('hidden');
      answers_public_reviews_btn.classList.remove('hidden');
    });

    show_mast_cat_btn_no.addEventListener('click', () => {
      show_mast_cat_cb.checked = true;
      show_mast_cat_btn_no.classList.add('hidden');
      show_mast_cat_btn.classList.remove('hidden');
    });

    many_enquete_btn_no.addEventListener('click', () => {
      many_enquete_cb.checked = true;
      many_enquete_btn_no.classList.add('hidden');
      many_enquete_btn.classList.remove('hidden');
    });

    anonym_btn_no.addEventListener('click', () => {
      anonym_cb.checked = true;
      anonym_btn_no.classList.add('hidden');
      anonym_btn.classList.remove('hidden');
    });

    container_btn_no.addEventListener('click', () => {
      container_cb.checked = true;
      container_btn_no.classList.add('hidden');
      container_btn.classList.remove('hidden');
    });

     evolution_btn_no.addEventListener('click', () => {
      evolution_cb.checked = true;
      evolution_btn_no.classList.add('hidden');
      evolution_btn.classList.remove('hidden');
    });

    anonym_results_btn_no.addEventListener('click', () => {
      anonym_results_cb.checked = true;
      anonym_results_btn_no.classList.add('hidden');
      anonym_results_btn.classList.remove('hidden');
    });

    rgpd_managment_cliking_btn_no.addEventListener('click', () => {
      rgpd_managment_cliking_cb.checked = true;
      rgpd_managment_cliking_btn_no.classList.add('hidden');
      rgpd_managment_cliking_btn.classList.remove('hidden');
    });

    phone_btn_no.addEventListener('click', () => {
      phone_cb.checked = true;
      phone_btn_no.classList.add('hidden');
      phone_btn.classList.remove('hidden');
    });

    if (fake_btn && fake_btn_no) {
      console.log('checkbox_switch')

      fake_btn_no.addEventListener('click', () => {
        fake_cb.checked = true;
        fake_btn_no.classList.add('hidden');
        fake_btn.classList.remove('hidden');
      });
    }

  }

};

export { checkbox_switch };
