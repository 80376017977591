import { renderInactiveMeals } from './render_inactive_meals';

const renderSearchMeals = (newCategorySecondId) => {

    const body = $('body')
    const mealItemsBox = $('.active-mealitems-box')

    if (mealItemsBox) {
        let searchBarForm = $('.search-bar-form')
        let loaderDiv = $('.dots-container')
        let searchBtn = searchBarForm.find('#btn-search')
        let input = searchBarForm.find('#search-input')
        let categorySecondId = newCategorySecondId || new URLSearchParams(window.location.search).get('cat_sec_id')


        // If input empty.
        input.bind('keyup', function(e) {
            if ($(this).val().length == 0) {
                e.preventDefault()
                loaderDiv.first().removeClass('hidden')
                body.addClass('background-gray')
                searchBtn.trigger('click')
            }
        })

        searchBtn.on('click', function(e){
            e.preventDefault()
            initSearch(input.val(), categorySecondId, mealItemsBox, searchBarForm, loaderDiv, body)
            renderInactiveMeals(categorySecondId) // Refresh variables.
        })
    }
}

const initSearch = (value, categorySecondId, resultsDiv, searchBarForm, loaderDiv, body) => {
    let counterActiveMeals = searchBarForm.find('.data-base-subtitle b')
    let counterInactiveMeals = $('.produits-desactives-btn').find('span')
    let boxInactiveMeals = $('.inactive-mealitems-box')
    let btnToggleInactiveMeals = $('.toggle-email-style-on')
    
    btnToggleInactiveMeals.click()
    boxInactiveMeals.empty()
    resultsDiv.empty()
    counterActiveMeals.empty()
    counterInactiveMeals.empty()
    $.ajax({
        method: 'GET',
        url: 'render_partial_for_meals',
        data: { type: 'json', cat_sec_id: categorySecondId, query: value, case: 'render-searched-meals' },
        success: function(response) {
            body.removeClass('background-gray')
            loaderDiv.addClass('hidden')
            counterActiveMeals.empty().append(response.counter_search)
            counterInactiveMeals.empty().append(response.counter_inactive_meals)
            resultsDiv.html(response.html)
        },
        error: function (XMLHttpRequest, textStatus, errorThrown) {
            console.log('Error on render_search_meals')
            console.log("Status: " + textStatus + ". Error: " + errorThrown); 
        }
    })
}

export { renderSearchMeals }