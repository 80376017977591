
//methode pour positionner le curseur à où on veut dans le input
function setCaretPosition(ctrl, pos) {
  // Modern browsers
  if (ctrl.setSelectionRange) {
    ctrl.focus();
    ctrl.setSelectionRange(pos, pos);

  // IE8 and below
  } else if (ctrl.createTextRange) {
    var range = ctrl.createTextRange();
    range.collapse(true);
    range.moveEnd('character', pos);
    range.moveStart('character', pos);
    range.select();
  }
}

const mailPreview = () => {

  if (document.getElementById("js-mail-preview")) {

    const previewCorps = document.getElementById("preview-corps");
    const previewObject = document.getElementById("preview-object");
    const corpsValue = document.getElementById("corps");
    const object = document.getElementById("object");
    const nomCorps = document.getElementById("nom-client-corps");
    const prenomCorps = document.getElementById("prenom-client-corps");
    const nomObject = document.getElementById("nom-client-object");
    const prenomObject = document.getElementById("prenom-client-object");
    const recompBtn = document.getElementById("intitule-recompense-corps-true");
    const qrBtnT = document.getElementById("qr-code-corps-true");
    const linkcodeBtnT = document.getElementById("link-or-code-corps-true");
    const carteFidBtn = document.getElementById("carte-fid-corps-true");
    const urlICIBtn = document.getElementById("url-ICI-corps");
    const theme = document.getElementById("theme");
    const corpsH = document.getElementById("corps-h");
    const objectH = document.getElementById("object-h");
    const themeH = document.getElementById("theme-h");

    // if (!(document.querySelector('#corps_ifr') || tinyMCE)) {

			tinymce.init({
				selector: '#corps',
				height: 400
			})
		// }

		console.log('mailPreview1')

		// if (nomCorps) {
		//   nomCorps.addEventListener('click', (e) => {
		//     e.preventDefault;
		//     console.log('nom');
		//     const corps = document.querySelector('#corps_ifr').contentDocument.getElementById("tinymce");
		//     const ed = tinyMCE.get('corps');
		//     // console.log(ed)
		//     // const cursor = ed.setCursorLocation;
		//     // console.log(cursor)
		//     // const range = ed.selection.startOffset;
		//     // console.log(range)
		//     // const before = corps.innerText.substring(0, range);
		//     // console.log(before)
		//     // const after = corps.innerText.substring(range, corps.innerText.length);
		//     // console.log(after)
		//     corps.innerText = before + "$nomClient" + after
		//   })
		// }

		if (nomCorps) {
			console.log('nomCorps')

			nomCorps.addEventListener('click', (e) => {
				e.preventDefault;
				var corps;
				const iframeElement = document.querySelector('#corps_ifr');
				if (iframeElement) {
					corps = iframeElement.contentDocument.getElementById("tinymce");
				}

				var ed = tinyMCE.get('corps');
				// e.preventDefault;
				console.log('e');
				// console.log(ed)
				// const cursor = ed.setCursorLocation;
				// console.log(cursor)
				// const range = ed.selection.startOffset;
				// console.log(range)
				// const before = corps.innerText.substring(0, range);
				// console.log(before)
				// const after = corps.innerText.substring(range, corps.innerText.length);
				// console.log(after)
				//corps.innerText = before + "$nomClient" + after
				if (ed) {
					ed.insertContent("$nomClient")
				}
			})
		}

		if (prenomCorps){
			prenomCorps.addEventListener('click', (e) => {
				e.preventDefault;
				console.log('prenomCorps');
				var corps;
				if (document.querySelector('#corps_ifr')) {
					corps = document.querySelector('#corps_ifr').contentDocument.getElementById("tinymce");
				}
				var ed = tinyMCE.get('corps');
				if (ed) {
					ed.insertContent("$prenomClient")
				}
				// console.log(ed)
				// var range = ed.selection.startOffset;
				// console.log(range)
				// if (range == undefined){
				//   console.log("range undefined")
				//   corps.innerText = corps.innerText + "$prenomClient"
				// } else {
				//   const before = corps.innerText.substring(0, range);
				//   console.log(before)
				//   const after = corps.innerText.substring(range, corps.innerText.length);
				//   console.log(after)
				//   corps.innerText = before + "$prenomClient" + after
				// }
			})
		}

		if (recompBtn) {
			recompBtn.addEventListener('click', () => {
				console.log('recomp');
				let corps;
				const corpsIfr = document.querySelector('#corps_ifr');
				if (corpsIfr) {
					corps = corpsIfr.contentDocument.getElementById("tinymce");
				}
				const ed = tinyMCE.get('corps');
				// console.log(ed)
				// const range = ed.selection.startOffset;
				// console.log(range)
				// const before = corps.innerText.substring(0, range);
				// console.log(before)
				// const after = corps.innerText.substring(range, corps.innerText.length);
				// console.log(after)
				// corps.innerText = before + "$intituleRecompense" + after
				if (ed) {
					ed.insertContent("$intituleRecompense")
				}
			})
		}

		if (qrBtnT) {
			qrBtnT.addEventListener('click', () => {
				console.log('qrcode');
				let corps;
				const corpsIfr = document.querySelector('#corps_ifr');
				if (corpsIfr) {
					corps = corpsIfr.contentDocument.getElementById("tinymce");
				}

				const ed = tinyMCE.get('corps');
				// console.log(ed)
				// const range = ed.selection.startOffset;
				// console.log(range)
				// const before = corps.innerText.substring(0, range);
				// console.log(before)
				// const after = corps.innerText.substring(range, corps.innerText.length);
				// console.log(after)
				// corps.innerText = before + "$qrCode" + after
				if (ed) {
					ed.insertContent("$qrCode")
				}
			})
		}

		if (linkcodeBtnT) {
			linkcodeBtnT.addEventListener('click', () => {
				console.log('link_code');
				let corps;
				const corpsIfr = document.querySelector('#corps_ifr');
				if (corpsIfr) {
					corps = corpsIfr.contentDocument.getElementById("tinymce");
				}
				const ed = tinyMCE.get('corps');
				// console.log("ed")
				// console.log(ed)
				// const range = ed.selection.startOffset;
				// console.log("range")
				// console.log(range)
				// const before = corps.innerText.substring(0, range);
				// console.log("before")
				// console.log(before)
				// const after = corps.innerText.substring(range, corps.innerText.length);
				// console.log("after")
				// console.log(after)
				// corps.innerText = before + "$linkCode" + after
				if (ed) {
					ed.insertContent("$linkCode")
				}
			})
		}

		if (carteFidBtn) {
			carteFidBtn.addEventListener('click', () => {
				console.log('carteFid');
				const corps = document.querySelector('#corps_ifr').contentDocument.getElementById("tinymce");
				const ed = tinyMCE.get('corps');
				// console.log(ed)
				// const range = ed.selection.startOffset;
				// console.log(range)
				// const before = corps.innerText.substring(0, range);
				// console.log(before)
				// const after = corps.innerText.substring(range, corps.innerText.length);
				// console.log(after)
				// corps.innerText = before + "$carteFid" + after
				ed.insertContent("$carteFid")
			})
		}

		if (urlICIBtn) {
			urlICIBtn.addEventListener('click', () => {
				console.log('urlICI');
				const corps = document.querySelector('#corps_ifr').contentDocument.getElementById("tinymce");
				const ed = tinyMCE.get('corps');
				// console.log(ed)
				// const range = ed.selection.startOffset;
				// console.log(range)
				// const before = corps.innerText.substring(0, range);
				// console.log(before)
				// const after = corps.innerText.substring(range, corps.innerText.length);
				// console.log(after)
				// corps.innerText = before + "$url_ICI" + after
				// if (document.getElementById("js-mail-preview").classList.contains("campagne_mail")) {
					ed.insertContent("$Lien_Questionnaire")
				// } else {
				//   ed.insertContent("$url_ICI")
				// }

			})
		}

    if (object) {
      console.log('mailPreview')
      if (nomObject) {
        nomObject.addEventListener('click', (e) => {
          e.preventDefault;
          console.log('e');
          var startPosition = object.selectionStart;
          // var endPosition = object.selectionEnd;
          const before = object.value.substring(0, startPosition);
          const after = object.value.substring(startPosition, object.value.length);

          object.value = before +  "$nomClient" + after
          // Set the cursor position right after the '$nomClient'
          setCaretPosition(object, before.length + 10);


        })
      }

      if (prenomObject) {
        prenomObject.addEventListener('click', (e) => {
          e.preventDefault;
          console.log('e');
          console.log(object)
          var startPosition = object.selectionStart;
          // var endPosition = object.selectionEnd;
          const before = object.value.substring(0, startPosition);
          const after = object.value.substring(startPosition, object.value.length);

          object.value = before +  "$prenomClient" + after
          // Set the cursor position right after the '$prenomClient'
          setCaretPosition(object, before.length + 13);
        })
      }
    }

    // POUR CAMPAGNE EMAIL - SMS
    const corpsSms = document.getElementById("corps_sms")
    const nomCorpsSms = document.getElementById("nom-client-corps-sms")
    const prenomCorpsSms = document.getElementById("prenom-client-corps-sms")
    const urlCorpsSms = document.getElementById("url-ICI-corps-sms")

    if (nomCorpsSms) {
      nomCorpsSms.addEventListener('click', (e) => {
        e.preventDefault;
        console.log('e');
        console.log(corpsSms)
        var startPosition = corpsSms.selectionStart;
        // var endPosition = corpsSms.selectionEnd;
        const before = corpsSms.value.substring(0, startPosition);
        const after = corpsSms.value.substring(startPosition, corpsSms.value.length);

        corpsSms.value = before +  "$nomClient" + after
        // Set the cursor position right after the '$prenomClient'
        setCaretPosition(corpsSms, before.length + 10);
      })
    }

    if (prenomCorpsSms) {
      prenomCorpsSms.addEventListener('click', (e) => {
        e.preventDefault;
        console.log('e');
        console.log(corpsSms)
        var startPosition = corpsSms.selectionStart;
        // var endPosition = corpsSms.selectionEnd;
        const before = corpsSms.value.substring(0, startPosition);
        const after = corpsSms.value.substring(startPosition, corpsSms.value.length);

        corpsSms.value = before +  "$prenomClient" + after
        // Set the cursor position right after the '$prenomClient'
        setCaretPosition(corpsSms, before.length + 13);
      })
    }

    if (urlCorpsSms) {
      urlCorpsSms.addEventListener('click', (e) => {
        e.preventDefault;
        console.log('e');
        console.log(corpsSms)
        var startPosition = corpsSms.selectionStart;
        // var endPosition = corpsSms.selectionEnd;
        const before = corpsSms.value.substring(0, startPosition);
        const after = corpsSms.value.substring(startPosition, corpsSms.value.length);

        corpsSms.value = before +  "$Lien_Questionnaire" + after
        // Set the cursor position right after the '$prenomClient'
        setCaretPosition(corpsSms, before.length + 19);
      })
    }

  }
}

export { mailPreview };
