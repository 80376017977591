import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['select', 'form', 'loader']

  connect() {
  }

  change() {
    this.refreshList()
    this.formTarget.classList.add('hidden')
    this.loaderTarget.classList.remove('hidden')
    this.loaderTarget.firstElementChild.classList.remove('hidden')
  }

  refreshList() {
    const restaurant_id = this.selectTarget.value
    const url = `edit_permissions?restaurant_id=${restaurant_id}`
    this.doTurboRequest(url)
  }

  doTurboRequest(url) {
    fetch(url, {
      headers: {
        Accept: 'text/vnd.turbo-stream.html',
      },
    })
    .then((response) => response.text())
    .then((html) => {
      Turbo.renderStreamMessage(html);
    });
  }
}
