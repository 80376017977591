
import {modalEventListener} from "../components/modal_event_listener"
import {previewImageOnFileSelectCategory} from "../components/photo_preview_new_category"
import {previewImageOnFileSelectMeal} from "../components/photo_preview_new_meal"
import { addDateField } from '../components/_show_hide_price_container';
import { uncheckRadioBtns } from '../components/uncheck_radio_buttons';
import { tagsSelectionToggleCss, tagsSelectionEdit, pictoSelectionEdit} from "../components/meal_items_tags_selection"
import { allergensSelectionToggleCss, allergensSelectionEdit} from "../components/allergens_selection"
import {programDateMeal} from "../components/program_date_meal"
import { initializeHandleCmiPrice } from "../components/_handle_cmi_new_edit"
import { initSelect2 } from '../plugins/init_select2';
import { editCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";
import { newCountryCodeAndFlagForPhoneNumbers } from "../components/phone_number_input";
import { loadingDots } from '../components/_loading_dots';

// Création des MealItems au niveau d'un restaurant
function initTinyMCE(selector) {
	// initialisation tiny
	tinymce.init({
		selector: selector,
		height: 200,
		plugins: 'lists',
		toolbar: 'undo redo |bold italic underline strikethrough | numlist bullist | forecolor backcolor',
		color_cols: 5,
		menubar: false,
	});
}
function ft_create_new_meal(context = document) {
	console.log("create_new_meal")
	const modale_new_meal = document.querySelector("#myModal-a").querySelector(".modal-content.content-modal");
	const modale_new_meal_btn = document.querySelector("#myModal-a").querySelector(".custom-btn.btn-of-modal");
	const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
	const mainContent = modale_new_meal.querySelector(`#meal_item_name_${mainLang}`);
	const allInputsLanguage = modale_new_meal.querySelectorAll("[id^='meal_item_name_']");
	let names_meals = [];
	const all_btn_create_meal_resto = document.querySelectorAll(".add-meal-js");
	if (all_btn_create_meal_resto) {
		all_btn_create_meal_resto.forEach((btn) => {
			btn.addEventListener('click', (event) => {
				names_meals = JSON.parse(event.currentTarget.dataset.mealsOfCateg)
				console.log(names_meals)
			})
		})
	}

	["click", "keyup"].forEach((action) => {
		modale_new_meal.addEventListener(action, (event) => {

			let allContentFilled = false
			const filledByLanguage = []
			let val = ""

			let allContentValid = false
			const validContent = []
			// Array pour savoir si tous les contents de chaque langue sont remplis
			Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
				val = content_lang_input.value.replaceAll(' ', '')
				filledByLanguage.push(val !== '' && val.length > 2 )
				const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
				validContent.push(!isTrimmed);
			})
			allContentFilled = filledByLanguage.includes(false) ? false : true
			allContentValid = validContent.includes(false) ? false : true

			toggleValidationMessages(allInputsLanguage, 'new_meal_', 'meal_item')

			//savoir si le content du main_language n'existe pas déjà
			console.log("mainLang", mainLang)
			console.log("modale_new_meal", modale_new_meal)
			const alreadyIncludedOuiNon = names_meals.includes(mainContent.value.toLowerCase())

			// toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
			if (allContentFilled && !alreadyIncludedOuiNon && allContentValid && modale_new_meal_btn.disabled == false) {
				modale_new_meal_btn.classList.remove("not-clickable");
				modale_new_meal_btn.setAttribute("type", "submit");
			} else {

				modale_new_meal_btn.classList.add("not-clickable");
				modale_new_meal_btn.removeAttribute("type");
			}

		})
	})

	$('#myModal-a').on('hidden.bs.modal', function (e) {
		$(this)
			.find("input#meal_item_name, input#meal_item_content_english")
					.val('')
					.end()
			.find("input[type=submit]")
				.prop('class', 'custom-btn btn-of-modal not-clickable')
				.prop('type', "")
				.end();
			$('#allergens_new').val(null).trigger('change');
			if (document.getElementById("product_img_create_meal")) {
				document.getElementById("product_img_create_meal").value = null
			}
			document.getElementById("photo-preview-meal-new").classList.add("hidden")
	})
}

// Création des produits au niveau d'un établissement
const create_new_product = () => {
  if (document.getElementById("parametre-produit-etablissement-for-js")) {
    if (document.querySelector("#myModal-create-product")) {
      console.log("create_new_product")

      const modale_new_product = document.querySelector("#myModal-create-product").querySelector(".modal-content.content-modal");
      const modale_new_product_btn = document.querySelector("#myModal-create-product").querySelector(".custom-btn.btn-of-modal");
      const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
      const mainContent = modale_new_product.querySelector(`#meal_item_name_${mainLang}`);
      const allInputsLanguage = modale_new_product.querySelectorAll("[id^='meal_item_name_']");
      let names_products = [];

      const all_btn_create_product_resto = document.querySelectorAll(".add-meal-js");
      if (all_btn_create_product_resto) {
        all_btn_create_product_resto.forEach((btn) => {
          btn.addEventListener('click', (event) => {
            names_products = JSON.parse(event.currentTarget.dataset.mealsOfCateg)
            console.log(names_products)
          })
        })
      }

      ["click", "keyup"].forEach((action) => {
        modale_new_product.addEventListener(action, (event) => {

          let allContentFilled = false
          const filledByLanguage = []
          let val = ""

          let allContentValid = false
          const validContent = []

          // Array pour savoir si tous les contents de chaque langue sont remplis
          Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
            val = content_lang_input.value.replaceAll(' ', '')
            filledByLanguage.push(val !== '' && val.length > 2 )
            const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
            validContent.push(!isTrimmed);
          })
          allContentFilled = filledByLanguage.includes(false) ? false : true
          allContentValid = validContent.includes(false) ? false : true

          toggleValidationMessages(allInputsLanguage, 'new_product_', 'meal_item')
          //savoir si le content du main_language n'existe pas déjà
          const alreadyIncludedOuiNon = names_products.includes(mainContent.value.toLowerCase())

          // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
          if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) {
            modale_new_product_btn.classList.remove("not-clickable");
            modale_new_product_btn.setAttribute("type", "submit");
          } else {
            modale_new_product_btn.classList.add("not-clickable");
            modale_new_product_btn.removeAttribute("type");
          }

        })
      })

      $('#myModal-create-product').on('hidden.bs.modal', function (e) {
        $(this)
          .find("input#meal_item_name, input#meal_item_content_english")
             .val('')
             .end()
          .find("input[type=submit]")
           .prop('class', 'custom-btn btn-of-modal not-clickable')
           .prop('type', "")
           .end();
      })

    }

  }
}

const create_new_meal_all = () => {
  if (document.getElementById("new-Meal-all-resto-js")) {
    console.log("create_new_meal_all");

    const new_meal_all_resto = document.querySelector("#new-Meal-all-resto-js");
    const new_meal_btn_all_resto = document.querySelector(".custom-btn.btn-of-modal");
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
    const mainContent = document.querySelector(`#name_${mainLang}`);
    const allInputsLanguage = document.querySelectorAll("[id^='name_']");

    //pour récupérer les noms des meals deja existants sur la categorie
    let names_meals = [];
    const all_btn_create_meal_resto = document.querySelectorAll(".add-meal-js");
    if (all_btn_create_meal_resto) {
      all_btn_create_meal_resto.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          if (names_meals != []) {names_meals = []}
          const categ = event.currentTarget.dataset.tabCategContentAllJs.replace(/\s/g, '').toLowerCase()
          const mastcat = event.currentTarget.dataset.tabNameMealMascatContentAllJs.replace(/\s/g, '').toLowerCase()
          const allMealsOfCateg = document.querySelectorAll(".meal_item_row.meal-tab-rows.div_meals_for_js_toggle_validate_btn." + CSS.escape(categ) + "." + CSS.escape(mastcat))
          Array.prototype.forEach.call(allMealsOfCateg, function(tab) {
          // document.querySelectorAll(".meal-tab.meals").forEach((tab) => {
            names_meals.push(tab.dataset.miContent.replace(/\s/g, '').toLowerCase());
            console.log(names_meals)
          })
        })
      })
    }

    ["click", "keyup"].forEach((action) => {
      console.log("click || keyup");
      new_meal_all_resto.addEventListener(action, (event) => {
        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allContentValid = false
        const validContent = []
        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )

          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        toggleValidationMessages(allInputsLanguage, 'content_', 'name_')

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = names_meals.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) {
          new_meal_btn_all_resto.classList.remove("not-clickable");
          new_meal_btn_all_resto.setAttribute("type", "submit");
        } else {
          new_meal_btn_all_resto.classList.add("not-clickable");
          new_meal_btn_all_resto.removeAttribute("type");
        }

      })
    })

  }
}

// modale edit_meal au niveau resto
const edit_meal = () => {

  if (document.querySelector("#edit-meal-dates-page-for-js")) {

    if (document.querySelector(".container-edit-meal")) {
      console.log("edit_meal - resto")

      const modale_edit_meal = document.querySelector(".container-edit-meal")
      const modale_edit_meal_btn = document.querySelector(".container-edit-meal").querySelector(".custom-btn.btn-edit-meal-new");
      const mainLang = document.getElementById("edit-meal-dates-page-for-js").dataset.mainLang;
      const allCurrentContent = JSON.parse(document.getElementById("edit-meal-dates-page-for-js").dataset.otherMealsCateg);
      const mainContent = modale_edit_meal.querySelector(`#meal_item_name_${mainLang}`);
      const allInputsLanguage = modale_edit_meal.querySelectorAll("[id^='meal_item_name_']");

      ["click", "keyup"].forEach((action) => {
        modale_edit_meal.addEventListener(action, (event) => {

          let allContentFilled = false
          const filledByLanguage = []
          let val = ""

          let allContentValid = false
          const validContent = []
          // Array pour savoir si tous les contents de chaque langue sont remplis
          Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
            val = content_lang_input.value.replaceAll(' ', '')
            filledByLanguage.push(val !== '' && val.length > 2 )
            const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
            validContent.push(!isTrimmed);
          })
          allContentFilled = filledByLanguage.includes(false) ? false : true
          allContentValid = validContent.includes(false) ? false : true

          toggleValidationMessages(allInputsLanguage, 'edit_meal_', 'meal_item')
          //savoir si le content du main_language n'existe pas déjà
          const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

          // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
          if (allContentFilled && !alreadyIncludedOuiNon && allContentValid && modale_edit_meal_btn.disabled == false) {
            modale_edit_meal_btn.classList.remove("not-clickable");
            modale_edit_meal_btn.setAttribute("type", "submit");
          } else {
            modale_edit_meal_btn.classList.add("not-clickable");
            modale_edit_meal_btn.removeAttribute("type");
          }

        })
      })

      $('#myModal-edit-product').on('hidden.bs.modal', function (e) {
        $(this)
          .find("input#meal_item_name, input#meal_item_content_english")
              .val('')
              .end()
          .find("input[type=submit]")
            .prop('class', 'custom-btn btn-of-modal not-clickable')
            .prop('type', "")
            .end();
      })
    }
  }
}

const edit_product = () => {

  if (document.querySelector("#myModal-edit-product")) {
    console.log("edit_product - etab")

    const modale_edit_product = document.querySelector("#myModal-edit-product")
    const modale_edit_product_btn = document.querySelector("#myModal-edit-product").querySelector("input.custom-btn.btn-of-modal-edit-serveur");
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
    const mainContent = modale_edit_product.querySelector(`#meal_item_name_${mainLang}`);
    const allInputsLanguage = modale_edit_product.querySelectorAll("[id^='meal_item_name_']");

    ["click", "keyup"].forEach((action) => {
      modale_edit_product.addEventListener(action, (event) => {
       // for etab
        let names_products_edit = [];
        names_products_edit = modale_edit_product.querySelector(".list-meal").dataset.otherMealsCateg.split('"');

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allContentValid = false
        const validContent = []

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )
          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        toggleValidationMessages(allInputsLanguage, 'edit_meal_', 'meal_item')
        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = names_products_edit.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) {
          modale_edit_product_btn.classList.remove("not-clickable");
          modale_edit_product_btn.setAttribute("type", "submit");
        } else {
          modale_edit_product_btn.classList.add("not-clickable");
          modale_edit_product_btn.removeAttribute("type");
        }

      })
    })

    $('#myModal-edit-product').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#meal_item_name, input#meal_item_content_english")
           .val('')
           .end()
        .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .prop('type', "")
         .end();
    })

  }
}

// modale edit meal niveau groupe
const edit_meal_all = () => {
  if (document.getElementById("edit_meal_all_resto")) {
    console.log("edit_meal_all")

    const formEdit = document.querySelector(".container-edit-meal")
    const formEditBtn = formEdit.querySelector(".custom-btn.btn-of-modal")
    const mainLang = document.getElementById("edit-meal-dates-multisite-page-for-js").dataset.mainLang;
    const localLang = document.getElementById("edit-meal-dates-multisite-page-for-js").dataset.localLang
    const mainContent = formEdit.querySelector(`#name_${mainLang}`);
    let names_meals_parse = JSON.parse(document.getElementById("edit-meal-dates-multisite-page-for-js").dataset.allMealsCateg);
    let names_meals = names_meals_parse.filter((el) => el !== mainContent.value.toLowerCase());
    const allInputsLanguage = formEdit.querySelectorAll("[id^='name_']");
    const userAdminOuiNon = document.getElementById("edit-meal-dates-multisite-page-for-js").dataset.typeUserAdmin === "true";


    ["keyup"].forEach((action) => {
      formEdit.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allContentValid = false
        const validContent = []
        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )

          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        toggleValidationMessages(allInputsLanguage, 'content_', 'name_')

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = names_meals.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && (!alreadyIncludedOuiNon || userAdminOuiNon) && allContentValid) {
          formEditBtn.classList.remove("not-clickable");
          formEditBtn.setAttribute("type", "submit");
        } else {
          formEditBtn.classList.add("not-clickable");
          formEditBtn.removeAttribute("type");
        }

        // faire apparaitre message d'alerte aux admin quand on update un meal à un nom qui existe déjà
        if (names_meals.includes(mainContent.value.toLowerCase().replace(/\s/g, '')) && userAdminOuiNon) {
          console.log("msgAdmin if")
          const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
          if (!msgAdmin) {
            console.log("msgAdmin if 2")
            if (localLang === 'fr') {
              console.log("message admin fr")
              mainContent.parentElement.parentElement.insertAdjacentHTML("beforeend", "<div id='msg-for-admin-categ-exist' class='msg-categ-for-admin'> <b> Attention : </b> Ce nom de produit existe déjà dans certains établissements: toutes les modifications que vous effectuez seront donc réalisées sur le produit déjà existant.</div>")
            } else {
              console.log("message admin en")
              mainContent.parentElement.parentElement.insertAdjacentHTML("beforeend", "<div id='msg-for-admin-categ-exist' class='msg-categ-for-admin'> <b> Attention : </b> This name is already used in other etablishments: all the modifications you make will therefore be made on the already existing product.</div>")
            }
          }
        } else if (names_meals.includes(mainContent.value.toLowerCase().replace(/\s/g, ''))) {
          console.log("msgAdmin else if")
          const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
          if (!msgAdmin) {
            if (localLang === 'fr') {
              console.log("message au bureau fr")
              mainContent.parentElement.parentElement.insertAdjacentHTML("beforeend", "<div id='msg-for-admin-categ-exist' class='msg-categ-for-admin'> <b> Attention : </b> Ce nom de produit existe déjà dans certains établissements, vous ne pouvez donc pas utiliser ce nom.</div>")
            } else {
              console.log("message au bureau en")
              mainContent.parentElement.parentElement.insertAdjacentHTML("beforeend", "<div id='msg-for-admin-categ-exist' class='msg-categ-for-admin'> <b> Attention : </b> This product name already exists in some locations, so you cannot use this name.</div>")
            }
          }
        } else {
          console.log("msgAdmin else2")
          const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
          if (msgAdmin) {
            msgAdmin.remove();
          }
        }

      })
    })

    const checkBoxAllTop = document.querySelector(".all_sites_edit_meal");
    if (checkBoxAllTop) {
      const checkBoxAllNames = document.querySelectorAll("[id^='box_name_']");
      checkBoxAllTop.addEventListener('click', (event) => {
        if(checkBoxAllTop.checked === true){
          checkBoxAllNames.forEach((box) => {
            box.checked = true;
            // Trouver le champ texte associé à la checkbox et le passer à disabled=false
            const textFieldId = box.id.replace('box_name', 'name');
            const textField = document.getElementById(textFieldId);
            if (textField) {
              textField.disabled = false;
            }
          });
        } else {
          checkBoxAllNames.forEach((box) => {
            box.checked = false;
            // Trouver le champ texte associé à la checkbox et le passer à disabled=true
            const textFieldId = box.id.replace('box_name', 'name');
            const textField = document.getElementById(textFieldId);
            if (textField) {
              textField.disabled = true;
            }
          });
        }
      });
    }
  }

}


const create_new_service = () => {
  if (document.querySelector("#myModal-y")) {

    const modale_new_service = document.querySelector("#myModal-y").querySelector(".modal-content.content-modal");
    const modale_new_service_btn = document.querySelector("#myModal-y").querySelector(".custom-btn.btn-of-modal");
    const names_service = []
    let five = ""
    let six = ""

    Array.prototype.forEach.call(document.querySelectorAll(".employee-tab"), function(tab) {
        names_service.push(tab.querySelector(".position-tab-content.name-link").innerText.replace(/\s/g, '').toLowerCase());
      })

    modale_new_service.querySelectorAll("input")[3].addEventListener("click", () => {
      //first_name
      five = modale_new_service.querySelector('#first-name-new-employee').value
      // last_name
      six = modale_new_service.querySelector('#last-name-new-employee').value

      if (five.length >= 2 && six.length >= 1 && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_service_btn.classList.add("not-clickable");
          modale_new_service_btn.removeAttribute("type");
        }
    })

    modale_new_service.querySelectorAll("input")[4].addEventListener("click", () => {
      five = modale_new_service.querySelectorAll("input")[5].value
      six = modale_new_service.querySelectorAll("input")[6].value

      if (five.length >= 2 && six.length >= 1 && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_service_btn.classList.add("not-clickable");
          modale_new_service_btn.removeAttribute("type");
        }
    })

    modale_new_service.querySelectorAll("input")[5].addEventListener("keyup", () => {
      five = modale_new_service.querySelectorAll("input")[5].value
      six = modale_new_service.querySelectorAll("input")[6].value

      if (five.length >= 2 && six.length >= 1 && (modale_new_service.querySelectorAll("input")[3].checked || modale_new_service.querySelectorAll("input")[4].checked) && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_service_btn.classList.add("not-clickable");
          modale_new_service_btn.removeAttribute("type");
        }
    })

    modale_new_service.querySelectorAll("input")[6].addEventListener("keyup", () => {
      five = modale_new_service.querySelectorAll("input")[5].value
      six = modale_new_service.querySelectorAll("input")[6].value

      if (five.length >= 2 && six.length >= 1 && (modale_new_service.querySelectorAll("input")[3].checked || modale_new_service.querySelectorAll("input")[4].checked) && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_service_btn.classList.add("not-clickable");
          modale_new_service_btn.removeAttribute("type");
        }
    })

    $('#myModal-y').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input,textarea,select")
           .val('')
           .end()
        .find("input.custom-btn.btn-of-modal")
           .val('AJOUTER')
           .end()
        .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .prop('type', "")
         .end()
        .find("input[type=checkbox], input[type=radio]")
           .prop("checked", "")
           .end();
    })

  }
}


const create_new_equipe = () => {
  if (document.querySelector("#myModal-y")) {
    const modale_new_equipe = document.querySelector("#myModal-y").querySelector(".modal-content.content-modal");
    const modale_new_equipe_btn = modale_new_equipe.querySelector("button#button-validate-service-nom-equipe-js");

    const inputNameEquipe = modale_new_equipe.querySelector("input#input-service-nom-equipe-js")
    const names_service = []


    Array.prototype.forEach.call(document.querySelectorAll(".employee-tab"), function(tab) {
      names_service.push(tab.querySelector(".position-tab-content.name-link").innerText.replace(/\s/g, '').toLowerCase());
    })

    inputNameEquipe.addEventListener("keyup", () => {
      if (inputNameEquipe.value.length >= 2 && (names_service.includes((`${inputNameEquipe.value}`).replace(/\s/g, '').toLowerCase()) === false) ) {
        modale_new_equipe_btn.classList.remove('not-clickable');
        modale_new_equipe_btn.disabled = "";
      } else {
        modale_new_equipe_btn.classList.add('not-clickable');
        modale_new_equipe_btn.disabled = "disabled"
      }
    })

  }
}

const edit_service = () => {

  if (document.querySelector("#myModal-t")) {

    const modale_new_service = document.querySelector("#myModal-t").querySelector(".modal-content");
    const modale_new_service_btn = document.querySelector("#myModal-t").querySelector("input.custom-btn.btn-of-modal-edit-serveur");
    const names_service = []
    let five = ""
    let six = ""

    Array.prototype.forEach.call(document.querySelectorAll(".employee-tab"), function(tab) {
        names_service.push(tab.querySelector(".position-tab-content.name-link").innerText.replace(/\s/g, '').toLowerCase());
      })

    modale_new_service.querySelectorAll("input")[4].addEventListener("click", () => {
      //first_name
      five = modale_new_service.querySelectorAll("input")[6].value;
      // last_name
      six = modale_new_service.querySelectorAll("input")[7].value;

      if (five.length >= 2 && six.length >= 1 && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_service_btn.classList.add("not-clickable");
          modale_new_service_btn.removeAttribute("type");
        }
    })

    modale_new_service.querySelectorAll("input")[5].addEventListener("click", () => {
      five = modale_new_service.querySelectorAll("input")[6].value
      six = modale_new_service.querySelectorAll("input")[7].value

      if (five.length >= 2 && six.length >= 2 && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_service_btn.classList.add("not-clickable");
          modale_new_service_btn.removeAttribute("type");
        }
    })

    modale_new_service.querySelectorAll("input")[6].addEventListener("keyup", () => {
      five = modale_new_service.querySelectorAll("input")[6].value
      six = modale_new_service.querySelectorAll("input")[7].value

      if (five.length >= 2 && six.length >= 1 && (modale_new_service.querySelectorAll("input")[4].checked || modale_new_service.querySelectorAll("input")[5].checked) && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
      else {
        modale_new_service_btn.classList.add("not-clickable");
        modale_new_service_btn.removeAttribute("type");
      }
    })

    modale_new_service.querySelectorAll("input")[7].addEventListener("keyup", () => {
      five = modale_new_service.querySelectorAll("input")[6].value
      six = modale_new_service.querySelectorAll("input")[7].value

      if (five.length >= 2 && six.length >= 1 && (modale_new_service.querySelectorAll("input")[4].checked || modale_new_service.querySelectorAll("input")[5].checked) && (names_service.includes((`${five} ${six}`).replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_service_btn.classList.remove("not-clickable");
          modale_new_service_btn.setAttribute("type", "submit");
        }
      else {
        modale_new_service_btn.classList.add("not-clickable");
        modale_new_service_btn.removeAttribute("type");
      }
    })
  }

}

const create_new_category = () => {

  $('#myModal-b').on('hidden.bs.modal', function (e) {
    $(this)
      .find("input#category_content_fr, input#category_content_en, input#category_content_it, input#category_content_ar, input#category_content_pt, input#category_content_pt_br, input#category_content_ru, input#category_content_kz, input#category_content_es, input#category_content_de, input#category_content_mn, textarea, select")
         .val('')
         .end()
      .find("input[type=radio]")
         .prop("checked", "")
         .end()
      .find("input#category_icon_shopping-basketsvg")
         .prop("checked", "checked")
         .end()
      .find("input#category_icon_forksvg")
         .prop("checked", "checked")
         .end()
      .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .prop('type', "")
         .end();

  })


  if (document.querySelector("#myModal-b")) {
    console.log("create_new_categ")
    const modale_new_category = document.querySelector("#myModal-b").querySelector(".modal-content.content-modal");
    const modale_new_category_btn = document.querySelector("#myModal-b").querySelector(".custom-btn.btn-of-modal");
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
    const mainContent = modale_new_category.querySelector(`#category_content_${mainLang}`);
    const allInputsLanguage = modale_new_category.querySelectorAll("[id^='category_content_']");
    const allInputsShortTitleLanguage = modale_new_category.querySelectorAll("[id^='category_short_title_']");
    const newMenuOuiNon = document.getElementById("all-current-master-categ-content-js").dataset.newMenuOuiNon === "true";

    // Règle pour permettre la validation:
      // - il faut que le content de chaque langue soit remplit
      // - qu'il y ai un minimum de 2 caractère par content
      // - que sur le content de la langue principale, le content n'existe pas déjà initialement au sein de cette master_category
      // - si on est sur un établissement qui a l'option new_menu_oui_non : rendre obligatoire (dans toutes les langues) le champs short_title
      // - si il y a le short_title: toggle le message d'erreur qui spécifie qu'il doit être renseigné

    // Récuperer les noms de toutes les catégories au sein de cette master_category au click du btn de création d'une categ pour voir si au moment de la création il n'y a pas de doulon
    let names_categories = []
    const all_btn_mast_cat = document.querySelectorAll(".btn-add-new.add-categ-js")
    if (all_btn_mast_cat) {
      all_btn_mast_cat.forEach((btn) => {
        btn.addEventListener('click', (event) => {
          if (event.currentTarget.dataset.allCategsOfMascat) {
            names_categories = JSON.parse(event.currentTarget.dataset.allCategsOfMascat)
          }
        })
      })
    }

    ["click", "keyup"].forEach((action) => {
      modale_new_category.addEventListener(action, (event) => {
        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allShortTitleFilled = false
        const filledShortTilteByLanguage = []
        let valShortTitle = ""

        let allContentValid = false
        const validContent = []
        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )

          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true


        // si l'établissement a l'option new_menu_oui_non : Array pour savoir si tous les short_title de chaque langue sont remplis
        if (newMenuOuiNon) {
          console.log("newMenuOuiNon")
          Array.prototype.slice.call( allInputsShortTitleLanguage ).forEach((content_lang_input) => {
            valShortTitle = content_lang_input.value.replaceAll(' ', '')
            let true_or_false = valShortTitle !== '' && valShortTitle.length > 2
            filledShortTilteByLanguage.push(true_or_false)
          })

          // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés
          allShortTitleFilled = filledShortTilteByLanguage.includes(false) ? false : true

          // toggle les messages d'alert sur content et short_title
          const allInputs = Array.from(allInputsLanguage).concat(Array.from(allInputsShortTitleLanguage))
          Array.prototype.slice.call( allInputs ).forEach((content_lang_input) => {
            const element = content_lang_input.value.replaceAll(' ', '')
            const lang = content_lang_input.id.match(/_(en|pt_br|fr|it|de|mn|es|ru|pt|ar|kz)$/)[1]
            const element_with_whitespaces = content_lang_input.value

            let msg1;
            let msg2;

            if (content_lang_input.id.includes("category_content")) {
              msg1 = document.getElementById(`message_for_format_title_${lang}`)
              msg2 = document.getElementById(`message_for_whitespaces_title_${lang}`)
            } else {
              msg1 = document.getElementById(`message_for_format_short_title_${lang}`)
            }

            msg1.style.display = (element !== '' && element.length > 2) ? 'none' : 'flex';

            if (content_lang_input.id.includes("category_content_") && msg2) {
              msg2.style.display = (element_with_whitespaces.trim() == element_with_whitespaces) ? 'none' : 'flex';
            }
          })


        } else {
          // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés : Quand pas de menu_new, alors automatiquement à true
          allShortTitleFilled = true
          toggleValidationMessages(allInputsLanguage, '', 'content_')
        }

        // savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = names_categories.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allShortTitleFilled && allContentValid) {
          modale_new_category_btn.classList.remove("not-clickable");
          modale_new_category_btn.setAttribute("type", "submit");
        } else {
          modale_new_category_btn.classList.add("not-clickable");
          modale_new_category_btn.removeAttribute("type");
        }

      })
    })

  }
}


const create_new_categ_all_multisite = () => {
  if (document.getElementById("new_categ_all_resto")) {
    console.log("create_new_categ_all_multisite")

    const form = document.getElementById("new_category");
    const btn = form.querySelector(".custom-btn.btn-of-modal.not-clickable");
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
    const mainContent = form.querySelector(`#content_${mainLang}`);
    const allInputsLanguage = form.querySelectorAll("[id^='content_']");
    const categOfMCAlreadyExist = JSON.parse(document.getElementById("new_categ_all_resto").dataset.allCategsOfMascat);
    const allInputsShortTitleLanguage = form.querySelectorAll("[id^='short_title_']");
    const newMenuOuiNon = document.getElementById("all-current-master-categ-content-js").dataset.newMenuOuiNon === "true";

    ['click', 'keyup'].forEach((action) => {
      form.addEventListener(action, (event) => {
        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allShortTitleFilled = false
        const filledShortTilteByLanguage = []
        let valShortTitle = ""

        let allContentValid = false
        const validContent = []

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )

          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })

        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        // si l'établissement a l'option new_menu_oui_non : Array pour savoir si tous les short_title de chaque langue sont remplis
        if (newMenuOuiNon) {
          console.log("newMenuOuiNon")
          Array.prototype.slice.call( allInputsShortTitleLanguage ).forEach((content_lang_input) => {
            valShortTitle = content_lang_input.value.replaceAll(' ', '')
            let true_or_false = valShortTitle !== '' && valShortTitle.length > 2
            filledShortTilteByLanguage.push(true_or_false)

          })
          // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés
          allShortTitleFilled = filledShortTilteByLanguage.includes(false) ? false : true

          // toggle les messages d'alert sur content et short_title
          const allInputs = Array.from(allInputsLanguage).concat(Array.from(allInputsShortTitleLanguage))
          Array.prototype.slice.call( allInputs ).forEach((content_lang_input) => {
            const element = content_lang_input.value.replaceAll(' ', '')
            //const lang = content_lang_input.id.match(/([^_]*)$/)[0]
            const lang = content_lang_input.id.match(/_(en|pt_br|fr|it|de|mn|es|ru|pt|ar|kz)$/)[1]
            const element_with_whitespaces = content_lang_input.value

            let msg1;
            let msg2;

            if (content_lang_input.id.includes("content_")) {
              msg1 = document.getElementById(`message_for_format_title_${lang}`)
              msg2 = document.getElementById(`message_for_whitespaces_title_${lang}`)
            } else {
              msg1 = document.getElementById(`message_for_format_short_title_${lang}`)
            }

            msg1.style.display = (element !== '' && element.length > 2) ? 'none' : 'flex';

            if (content_lang_input.id.includes("content_") && msg2) {
              msg2.style.display = (element_with_whitespaces.trim() == element_with_whitespaces) ? 'none' : 'flex';
            }
          })

        } else {
          // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés : Quand pas de menu_new, alors automatiquement à true
          allShortTitleFilled = true
        }

        // savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = categOfMCAlreadyExist.includes(mainContent.value.toLowerCase())
        console.log(alreadyIncludedOuiNon)

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allShortTitleFilled && allContentValid) {
          btn.classList.remove("not-clickable");
          btn.setAttribute("type", "submit");
        } else {
          btn.classList.add("not-clickable");
          btn.removeAttribute("type");
        }

      })
    })

  }
}

const create_new_master_category = () => {

  if (document.querySelector("#new-Master-categ-all-resto-js")){
    console.log("create_new_master_category")

    const new_master_category_all_resto = document.querySelector("#new-Master-categ-all-resto-js");
    const new_master_category_btn_all_resto = new_master_category_all_resto.querySelector(".custom-btn.btn-of-modal");
    const allCurrentContent = JSON.parse(document.getElementById("all-current-master-categ-content-js").dataset.currentContent)
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang
    const mainContent = document.querySelector(`#content_${mainLang}`);
    const allInputsLanguage = document.querySelectorAll("[id^='content_']");

    // Règle pour permettre la validation:
      // - il faut que le content de chaque langue soit remplit
      // - qu'il y ai un minimum de 2 caractère par content
      // - que sur le content de la langue principale, le content n'existe pas déjà initialement
      // - le contenu ne doit pas commencer ou se terminer par des espaces.
    ["click", "keyup"].forEach((action) => {
      new_master_category_all_resto.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allContentValid = false
        const validContent = []
        // Array pour savoir si tous les contents de chaque langue sont remplis et ne commencent pas ou se terminent par des espaces.
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )

          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })

        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        toggleValidationMessages(allInputsLanguage, 'master_categ_', 'content_')

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) { //if (allContentFilled) {

          new_master_category_btn_all_resto.classList.remove("not-clickable");
          new_master_category_btn_all_resto.setAttribute("type", "submit");
        } else {
          new_master_category_btn_all_resto.classList.add("not-clickable");
          new_master_category_btn_all_resto.removeAttribute("type");
        }
      })
    })
  }
}

const create_new_master_category_all = () => {
  // Methode qui enleve les elements renseigné dans la modale create_mastercateg si on sort de la modale
  $('#myModal-g').on('hidden.bs.modal', function (e) {
    $(this)
      .find("input#content_fr, input#content_en, input#content_it, input#content_ar, input#content_pt, input#content_pt_br, input#content_ru, input#content_kz, input#content_es, input#content_de, input#content_mn, textarea,select")
         .val('')
         .end()
      .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .prop('type', "")
         .end();
  })

  if (document.querySelector("#myModal-e")) {
    console.log("create_new_master_category_all")

    const modale_new_category = document.querySelector("#myModal-e").querySelector(".modal-content.content-modal");
    const modale_new_category_btn = document.querySelector("#myModal-e").querySelector(".custom-btn.btn-of-modal");
    const allInputsLanguage = modale_new_category.querySelectorAll("[id^='master_category_content_']");
    const allCurrentContent = JSON.parse(document.getElementById("all-current-master-categ-content-js").dataset.currentContent);
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
    const mainContent = modale_new_category.querySelector(`#master_category_content_${mainLang}`);

    // Règle pour permettre la validation:
      // - il faut que le content de chaque langue soit remplit
      // - qu'il y ai un minimum de 2 caractère par content
      // - que sur le content de la langue principale, le content n'existe pas déjà initialement
    ["click", "keyup"].forEach((action) => {
      modale_new_category.addEventListener(action, (event) => {

          let allContentFilled = false
          const filledByLanguage = []
          let val = ""

          let allContentValid = false
          const validContent = []
          // Array pour savoir si tous les contents de chaque langue sont remplis
          Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
            val = content_lang_input.value.replaceAll(' ', '')
            filledByLanguage.push(val !== '' && val.length > 2 )

            const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
            validContent.push(!isTrimmed);
          })
          allContentFilled = filledByLanguage.includes(false) ? false : true
          allContentValid = validContent.includes(false) ? false : true

          toggleValidationMessages(allInputsLanguage, 'new_master_categ_', 'category_content')

          //savoir si le content du main_language n'existe pas déjà
          const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

          // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
          if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) {
            modale_new_category_btn.classList.remove("not-clickable");
            modale_new_category_btn.setAttribute("type", "submit");
          } else {
            modale_new_category_btn.classList.add("not-clickable");
            modale_new_category_btn.removeAttribute("type");
          }

        })
      })

  }

}

function ft_edit_category() {
  	const modale_edit_categ = document.querySelector("#myModal-d").querySelector('.modal-dialog')
    const modale_edit_categ_btn = document.querySelector("#myModal-d").querySelector("input.custom-btn.btn-of-modal");

    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang;
    const mainContent = modale_edit_categ.querySelector(`#category_content_${mainLang}`);
    const initialCateg = mainContent.value.toLowerCase()
    const allInputsLanguage = modale_edit_categ.querySelectorAll("[id^='category_content_']");
    const allInputsShortTitleLanguage = modale_edit_categ.querySelectorAll("[id^='category_short_title_']");
    const newMenuOuiNon = document.getElementById("all-current-master-categ-content-js").dataset.newMenuOuiNon === "true";
    const userAdminOuiNon = document.getElementById("all-current-master-categ-content-js").dataset.typeUserAdmin === "true";

    ["click", "keyup"].forEach((action) => {

      modale_edit_categ.addEventListener(action, (event) => {
        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allShortTitleFilled = false
        const filledShortTilteByLanguage = []
        let valShortTitle = ""

        let allContentValid = false
        const validContent = []
        //pour récupérer les noms des categ deja existants sur la master_categorie
        let names_categories = [];
        const selected_mascat = document.getElementById("category_master_category_id")
        const mastcat = selected_mascat.options[selected_mascat.selectedIndex].innerText
        const allCategsOfMascat = document.querySelectorAll("." + CSS.escape(mastcat) + "meal-tab-category")

        Array.prototype.forEach.call(allCategsOfMascat, function(tab) {
          names_categories.push(tab.dataset.categ.replace(/\s/g, '').toLowerCase());
        })
        names_categories = names_categories.filter((el) => el !== initialCateg);

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )
          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        // si l'établissement a l'option new_menu_oui_non : Array pour savoir si tous les short_title de chaque langue sont remplis
        if (newMenuOuiNon) {
          console.log("newMenuOuiNon")
          Array.prototype.slice.call( allInputsShortTitleLanguage ).forEach((content_lang_input) => {
            valShortTitle = content_lang_input.value.replaceAll(' ', '')
            let true_or_false = valShortTitle !== '' && valShortTitle.length > 2
            filledShortTilteByLanguage.push(true_or_false)
          })

          // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés
          allShortTitleFilled = filledShortTilteByLanguage.includes(false) ? false : true

          // toggle les messages d'alert sur content et short_title
          const allInputs = Array.from(allInputsLanguage).concat(Array.from(allInputsShortTitleLanguage))
          Array.prototype.slice.call( allInputs ).forEach((content_lang_input) => {
            const element = content_lang_input.value.replaceAll(' ', '')
            // const lang = content_lang_input.id.match(/([^_]*)$/)[0]
            //const lang = content_lang_input.id.match(/content_(\w{2}(?:_\w{2})?)/)[1]
            const lang = content_lang_input.id.match(/_(en|pt_br|fr|it|de|mn|es|ru|pt|ar|kz)$/)[1]
            const element_with_whitespaces = content_lang_input.value

            let msg1;
            let msg2;
            if (content_lang_input.id.includes("category_content")) {
              msg1 = document.getElementById(`message_for_format_title_edit_${lang}`)
              msg2 = document.getElementById(`message_for_whitespaces_title_edit_${lang}`)
            } else {
              msg1 = document.getElementById(`edit_message_for_format_short_title_${lang}`)
            }

            msg1.style.display = (element !== '' && element.length > 2) ? 'none' : 'flex';


            if (content_lang_input.id.includes("category_content") && msg2) {
              msg2.style.display = (element_with_whitespaces.trim() == element_with_whitespaces) ? 'none' : 'flex';
            }
          })

        } else {
          // initialiser la variable qui permet de savoir si tous les shortTitle ont été renseignés : Quand pas de menu_new, alors automatiquement à true
          allShortTitleFilled = true

          // toggle les messages d'alert sur content
          toggleValidationMessages(allInputsLanguage, 'edit_', 'content_')
        }

        // savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = names_categories.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && (!alreadyIncludedOuiNon || userAdminOuiNon) && allShortTitleFilled && allContentValid) {
          modale_edit_categ_btn.classList.remove("not-clickable");
          modale_edit_categ_btn.setAttribute("type", "submit");
        } else {
          modale_edit_categ_btn.classList.add("not-clickable");
          modale_edit_categ_btn.removeAttribute("type");
        }

        // faire apparaitre message d'alerte aux admin quand on update une categ à un nom qui existe déjà (pour transférer tous les meal_item dans l'autre categ)
        if (names_categories.includes(mainContent.value.toLowerCase().replace(/\s/g, '')) && userAdminOuiNon) {
          console.log("msgAdmin if")
          const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
          if (!msgAdmin) {
            console.log("msgAdmin if 2")
            mainContent.parentElement.insertAdjacentHTML("beforeend", "<div id='msg-for-admin-categ-exist' class='msg-categ-for-admin'> <strong> Attention ADMIN : </strong> ce nom de catégorie existe déjà, vous êtes sur le point de migrer tous les meals dans l'autre catégorie</div>")
          }
        } else {
          console.log("msgAdmin else2")
          const msgAdmin = document.getElementById("msg-for-admin-categ-exist");
          if (msgAdmin) {
            msgAdmin.remove();
          }
        }

      })
    })
}

const edit_category = () => {

  if (document.querySelector("#myModal-d")) {

    modalEventListener(".edit-category", "#myModal-d", [
			() => ft_edit_category(),
			() => previewImageOnFileSelectCategory(document.querySelector("#myModal-d")),
			() => uncheckRadioBtns(document.querySelector("#myModal-d")),
		]) // modalEventListener(btnsSelector, modalElementSelector)

  }
}

function ft_edit_master_category() {
  const modale_edit_master_categ = document.querySelector("#myModal-f").querySelector('.modal-dialog')
    const modale_edit_master_categ_btn = document.querySelector("#myModal-f").querySelector("input.custom-btn.btn-of-modal");
    let allCurrentContent = JSON.parse(document.getElementById("all-current-master-categ-content-js").dataset.currentContent)
    const mainLang = document.getElementById("all-current-master-categ-content-js").dataset.mainLang
    const mainContent = modale_edit_master_categ.querySelector(`#master_category_content_${mainLang}`)
    allCurrentContent = allCurrentContent.filter((el) => el !== mainContent.value.toLowerCase() )

    const allInputsLanguage = modale_edit_master_categ.querySelectorAll("[id^='master_category_content_']");

    ["click", "keyup"].forEach((action) => {
      modale_edit_master_categ.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""
        let allContentValid = false
        const validContent = []
        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )
          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        toggleValidationMessages(allInputsLanguage, 'edit_master_categ_', 'category_content')

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) {
          modale_edit_master_categ_btn.classList.remove("not-clickable");
          modale_edit_master_categ_btn.setAttribute("type", "submit");
        } else {
          modale_edit_master_categ_btn.classList.add("not-clickable");
          modale_edit_master_categ_btn.removeAttribute("type");
        }

      })
    })
}

const edit_master_category = () => {

  if (document.querySelector("#myModal-f")) {

		modalEventListener(".edit-master-category", "#myModal-f", [
			() => ft_edit_master_category(),
			() => uncheckRadioBtns(document.querySelector("#myModal-f"))
		]) // modalEventListener(btnsSelector, modalElementSelector)

  }
}

//modale edit master category au niveau groupe

const edit_master_category_all = () => {

  if (document.querySelector("#edit_master_categ_all_resto")) {
    console.log("edit_master_category_all")

    const form = document.getElementById("edit_category");
    const edit_master_categ_btn = document.querySelector("input.custom-btn.btn-of-modal");

    let allCurrentContent = JSON.parse(document.getElementById("mast-cat-content-for-js").dataset.allMascatContent);
    const mainLang = document.getElementById("mast-cat-content-for-js").dataset.mainLang;
    const mainContent = form.querySelector(`#content_${mainLang}`)
    allCurrentContent = allCurrentContent.filter((el) => el !== mainContent.value.toLowerCase() )

    const allInputsLanguage = form.querySelectorAll("[id^='content_'] input:not([id^='content_before_master'])");

    ["click", "keyup"].forEach((action) => {
      form.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        let allContentValid = false
        const validContent = []
        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )

          const isTrimmed = content_lang_input.value.trim() !== content_lang_input.value;
          validContent.push(!isTrimmed);
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true
        allContentValid = validContent.includes(false) ? false : true

        toggleValidationMessages(allInputsLanguage, 'master_categ_', 'content_')

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if (allContentFilled && !alreadyIncludedOuiNon && allContentValid) {
          edit_master_categ_btn.classList.remove("not-clickable");
          edit_master_categ_btn.setAttribute("type", "submit");
        } else {
          edit_master_categ_btn.classList.add("not-clickable");
          edit_master_categ_btn.removeAttribute("type");
        }

      })
    })

  }
}

// Modal pour récompense simple
const create_new_reward = () => {

  if (document.querySelector("#myModal-new-recompense-directe")) {
    const modale_new_reward = document.querySelector("#myModal-new-recompense-directe").querySelector(".modal-content.content-modal");
    const modale_new_reward_btn = document.querySelector("#myModal-new-recompense-directe").querySelector(".custom-btn.btn-of-modal");
    const mainLang = document.getElementById("div-content-for-js").dataset.mainLang;
    const mainContent = modale_new_reward.querySelector(`#reward_content_${mainLang}`);
    const allInputsLanguage = modale_new_reward.querySelectorAll("[id^='reward_content_']");
    const allCurrentContent = JSON.parse(document.getElementById("div-content-edit-for-js").dataset.otherRewards);

    ["click", "keyup"].forEach((action) => {
      modale_new_reward.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if ((allContentFilled) && (!alreadyIncludedOuiNon) )  {
          modale_new_reward_btn.classList.remove("not-clickable");
          modale_new_reward_btn.setAttribute("type", "submit");
        } else {

          modale_new_reward_btn.classList.add("not-clickable");
          modale_new_reward_btn.removeAttribute("type");
        }

      })
    })

    $('#myModal-new-recompense-directe').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#reward_content, input#reward_content_english, input#reward-time-of-day")
           .val('')
           .end()
        .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .end();
    })

  }

}

const create_new_reward_fid = () => {

  if (document.querySelector("#myModal-fidelite")) {
    const modale_new_reward = document.querySelector("#myModal-fidelite").querySelector(".modal-content.content-modal");
    const modale_new_reward_btn = document.querySelector("#myModal-fidelite").querySelector(".custom-btn.btn-of-modal");
    const type_etabl = document.querySelector(".ajax-reward-recompense-direct-actif-inactif-type-resto").textContent
    const mainLang = document.getElementById("div-content-fid-for-js").dataset.mainLang;
    const mainContent = modale_new_reward.querySelector(`#reward_content_${mainLang}`);
    const allInputsLanguage = modale_new_reward.querySelectorAll("[id^='reward_content_']");
    const allCurrentContent = JSON.parse(document.getElementById("div-content-fid-edit-for-js").dataset.otherRewards);

    ["click", "keyup"].forEach((action) => {
      modale_new_reward.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true

        const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if ((allContentFilled) && (!alreadyIncludedOuiNon) )  {
          modale_new_reward_btn.classList.remove("not-clickable");
          modale_new_reward_btn.setAttribute("type", "submit");
        } else {
          modale_new_reward_btn.classList.add("not-clickable");
          modale_new_reward_btn.removeAttribute("type");
        }

      })
    })

    $('#myModal-fidelite').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#reward_content, input#reward_content_english, input#reward-time-of-day")
           .val('')
           .end()
        .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .end();
    })

  }

}

const create_new_reward_jeu_concours = () => {

  if (document.querySelector("#myModal-new-jeu-concours")) {
    const modale_new_reward = document.querySelector("#myModal-new-jeu-concours").querySelector(".modal-content.content-modal");
    const modale_new_reward_btn = document.querySelector("#myModal-new-jeu-concours").querySelector(".custom-btn.btn-of-modal");
    const type_etabl = document.querySelector(".ajax-reward-recompense-direct-actif-inactif-type-resto").textContent
    const mainLang = document.getElementById("div-content-contest-for-js").dataset.mainLang;
    const mainContent = modale_new_reward.querySelector(`#reward_content_${mainLang}`);
    const allInputsLanguage = modale_new_reward.querySelectorAll("[id^='reward_content_']");
    const allCurrentContent = JSON.parse(document.getElementById("div-content-jconcours-edit-for-js").dataset.otherRewards);

    ["click", "keyup"].forEach((action) => {
      modale_new_reward.addEventListener(action, (event) => {

        let allContentFilled = false
        const filledByLanguage = []
        let val = ""

        // Array pour savoir si tous les contents de chaque langue sont remplis
        Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
          val = content_lang_input.value.replaceAll(' ', '')
          filledByLanguage.push(val !== '' && val.length > 2 )
        })
        allContentFilled = filledByLanguage.includes(false) ? false : true

        //savoir si le content du main_language n'existe pas déjà
        const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

        // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
        if  ( allContentFilled  && !alreadyIncludedOuiNon ) {
          modale_new_reward_btn.classList.remove("not-clickable");
          modale_new_reward_btn.setAttribute("type", "submit");
        } else {
          modale_new_reward_btn.classList.add("not-clickable");
          modale_new_reward_btn.removeAttribute("type");
        }

      })
    })

    $('#myModal-fidelite').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#reward_content, input#reward_content_english, input#reward-time-of-day")
           .val('')
           .end()
        .find("input[type=submit]")
         .prop('class', 'custom-btn btn-of-modal not-clickable')
         .end();
    })

  }

}

// Modal edit pour les récompenses directes
function ft_edit_reward() {

  const modale_edit_reward = document.querySelector("#myModal-edit-recompense-directe").querySelector(".modal-content.content-modal");
  const modale_edit_reward_btn = document.querySelector("#myModal-edit-recompense-directe").querySelector("input.custom-btn.btn-of-modal-edit-serveur");
  const mainLang = document.getElementById("div-content-edit-for-js").dataset.mainLang;
  const allCurrentContent = JSON.parse(document.getElementById("div-content-edit-for-js").dataset.otherRewards);
  const mainContent = modale_edit_reward.querySelector(`#reward_content_${mainLang}`);
  const allInputsLanguage = modale_edit_reward.querySelectorAll("[id^='reward_content_']");

  ["click", "keyup"].forEach((action) => {

    modale_edit_reward.addEventListener(action, (event) => {

      let allContentFilled = false
      const filledByLanguage = []
      let val = ""
      let  timeOfDayDiv = document.querySelector(".time-of-day-values-js");
      let timeOfDayReward;
      let contentReward;
      let changeTimeOfDay;

      if (timeOfDayDiv) {
        timeOfDayReward = JSON.parse(timeOfDayDiv.dataset.timeOfDayReward);
        contentReward = timeOfDayDiv.dataset.nameMainLanguage;
        changeTimeOfDay = [(modale_edit_reward.querySelector("#breakfast").value === "1"), (modale_edit_reward.querySelector("#lunch").value === "1"), (modale_edit_reward.querySelector("#dinner").value == "1")]
      }

      // Array pour savoir si tous les contents de chaque langue sont remplis
      Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
        val = content_lang_input.value.replaceAll(' ', '')
        filledByLanguage.push(val !== '' && val.length > 2 )
      })

      allContentFilled = filledByLanguage.includes(false) ? false : true

      console.log("allCurrentContent HERE:")
      console.log(allCurrentContent)
      //savoir si le content du main_language n'existe pas déjà
      const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

      // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon

        if (!alreadyIncludedOuiNon && allContentFilled ) {
          modale_edit_reward_btn.classList.remove("not-clickable");
          modale_edit_reward_btn.setAttribute("type", "submit");
        } else if (timeOfDayDiv && (alreadyIncludedOuiNon && (mainContent.value.toLowerCase() == contentReward.toLowerCase()) && allContentFilled) && ((JSON.stringify(timeOfDayReward) != JSON.stringify(changeTimeOfDay)))) {
          modale_edit_reward_btn.classList.remove("not-clickable");
          modale_edit_reward_btn.setAttribute("type", "submit");
        } else {
          modale_edit_reward_btn.classList.add("not-clickable");
          modale_edit_reward_btn.removeAttribute("type");
        }

    })

  })
}

const edit_reward = () => {

  if (document.querySelector("#myModal-edit-recompense-directe")) {

    modalEventListener(".edit_reward", "#myModal-edit-recompense-directe", [
			() => ft_edit_reward()
		])
  }

}

// Modal edit pour les cartes de fidélité
function ft_edit_reward_fid() {
  console.log("edit_reward_fid")


  const modale_edit_reward = document.querySelector("#myModal-edit-carte-fidelite").querySelector(".modal-content.content-modal");
  const modale_edit_reward_btn = document.querySelector("#myModal-edit-carte-fidelite").querySelector("input.custom-btn.btn-of-modal-edit-serveur");
  const mainLang = document.getElementById("div-content-fid-edit-for-js").dataset.mainLang;
  const allCurrentContent = JSON.parse(document.getElementById("div-content-fid-edit-for-js").dataset.otherRewards);
  const mainContent = modale_edit_reward.querySelector(`#reward_content_${mainLang}`);
  const allInputsLanguage = modale_edit_reward.querySelectorAll("[id^='reward_content_']");

  ["click", "keyup"].forEach((action) => {
    modale_edit_reward.addEventListener(action, (event) => {

      let allContentFilled = false
      const filledByLanguage = []
      let val = ""

      // Array pour savoir si tous les contents de chaque langue sont remplis
      Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
        val = content_lang_input.value.replaceAll(' ', '')
        filledByLanguage.push(val !== '' && val.length > 2 )
      })
      allContentFilled = filledByLanguage.includes(false) ? false : true

      //savoir si le content du main_language n'existe pas déjà
      const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

      // toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon
      if (allContentFilled && !alreadyIncludedOuiNon) {
        modale_edit_reward_btn.classList.remove("not-clickable");
        modale_edit_reward_btn.setAttribute("type", "submit");
      } else {
        modale_edit_reward_btn.classList.add("not-clickable");
        modale_edit_reward_btn.removeAttribute("type");
      }

    })
  })
}

const edit_reward_fid = () => {

  if (document.querySelector("#myModal-edit-carte-fidelite")) {
    modalEventListener(".edit-reward-fid", "#myModal-edit-carte-fidelite", [
			() => ft_edit_reward_fid()
		])
  }

}

// Modal edit pour jeu concours
function ft_edit_reward_concours() {
  console.log("edit_reward_concours")

	const modale_edit_reward = document.querySelector("#myModal-edit-jeu-concours").querySelector(".modal-content.content-modal");
	const modale_edit_reward_btn = document.querySelector("#myModal-edit-jeu-concours").querySelector("input.custom-btn.btn-of-modal-edit-serveur");
	const mainLang = document.getElementById("div-content-edit-for-js").dataset.mainLang;
	const allCurrentContent = JSON.parse(document.getElementById("div-content-edit-for-js").dataset.otherRewards);
	const mainContent = modale_edit_reward.querySelector(`#reward_content_${mainLang}`);
	const allInputsLanguage = modale_edit_reward.querySelectorAll("[id^='reward_content_']");

	["click", "keyup"].forEach((action) => {

		modale_edit_reward.addEventListener(action, (event) => {

			let allContentFilled = false
			const filledByLanguage = []
			let val = ""

			// Array pour savoir si tous les contents de chaque langue sont remplis
			Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
				val = content_lang_input.value.replaceAll(' ', '')
				filledByLanguage.push(val !== '' && val.length > 2 )
			})

			allContentFilled = filledByLanguage.includes(false) ? false : true

			console.log("allCurrentContent HERE:")
			console.log(allCurrentContent)
			//savoir si le content du main_language n'existe pas déjà
			const alreadyIncludedOuiNon = allCurrentContent.includes(mainContent.value.toLowerCase())

			// toggle le btn en fonction de la validation allContentFilled et alreadyIncludedOuiNon

				if (!alreadyIncludedOuiNon && allContentFilled ) {
					modale_edit_reward_btn.classList.remove("not-clickable");
					modale_edit_reward_btn.setAttribute("type", "submit");
				} else if (timeOfDayDiv && (alreadyIncludedOuiNon && (mainContent.value.toLowerCase() == contentReward.toLowerCase()) && allContentFilled) && ((JSON.stringify(timeOfDayReward) != JSON.stringify(changeTimeOfDay)))) {
					modale_edit_reward_btn.classList.remove("not-clickable");
					modale_edit_reward_btn.setAttribute("type", "submit");
				} else {
					modale_edit_reward_btn.classList.add("not-clickable");
					modale_edit_reward_btn.removeAttribute("type");
				}

		})

	})
}

const edit_reward_concours = () => {
  if (document.querySelector("#myModal-edit-jeu-concours")) {

    console.log("edit_reward_concours")
    modalEventListener(".edit-reward-concours", "#myModal-edit-jeu-concours", [
			() => ft_edit_reward_concours()
		])
  }
}

const create_new_tag = () => {
}


const create_new_groupe_question = () => {
  if (document.querySelector("#myModal-gqn")) {
    $('#myModal-gqn').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#groupe_question_title, input#groupe_question_title_english, input#groupe_question_content, input#groupe_question_content_english")
          .val('')
          .end()
        .find("input[type=submit]")
          .prop('class', 'custom-btn btn-primary btn-of-modal not-clickable')
          .prop('type', "")
          .end()
        .find("input[type=checkbox],input[type=radio]")
           .prop("checked", "")
           .end();
    })
  }
}

const create_new_groupe_question_page = () => {
  if (document.getElementById("page-new-gq-for-js")) {
    console.log("create_new_groupe_question_page")

    const form = document.getElementById("new_groupe_question");
    const btn = document.querySelector(".custom-btn.btn-of-modal.not-clickable");
    const typeQuestion = document.getElementById("page-new-gq-for-js").dataset.typeQuestion;
    const radios = document.querySelectorAll("[name='ini_or_new']");
    const allCurrentContent = JSON.parse(document.getElementById("page-new-gq-for-js").dataset.allExistingGq);
    const allInputsLanguage = form.querySelectorAll("[id^='groupe_question_visible_title_']");
    const theme = document.querySelector("#main_lang").querySelector("input");

    ['keyup', 'click'].forEach((action) => {
      form.addEventListener(action, (event) => {
        // const theme = form.querySelector("input#groupe_question_visible_title")

        let allConditions = [];
        if (typeQuestion === "Note Un a Dix" || typeQuestion === "Étoiles" || typeQuestion === "Nationalité") {
          const allConditionsNote = form.querySelectorAll("[name='condition']:checked");
          const allConditionsStars = form.querySelectorAll("[name='stars_link']:checked");
          const allConditionsInfSup = form.querySelectorAll("[name='condition_inf_sup']:checked");
          const allConditionsNationality = $('select').val();

          if ((allConditionsNote.length === 1 && allConditionsInfSup.length === 1) || (allConditionsStars.length === 1 && allConditionsInfSup.length === 1) || (allConditionsNationality.length >= 1)) {
            allConditions.push(1);
          }
        } else {
          allConditions = form.querySelectorAll("[name='condition[]']:checked");
        }

        if (document.querySelectorAll("[name='ini_or_new']:checked").length === 1 && document.querySelectorAll("[name='ini_or_new']:checked")[0].id === "ini_or_new_initial_gq") {

          if (document.querySelector("select#chose_initial_gq").value !== "" && allConditions.length >= 1){
            btn.setAttribute("type", "submit");
            btn.classList.remove("not-clickable");
          } else {
            btn.removeAttribute("type");
            btn.classList.add("not-clickable");
          }

        } else {

          let allContentFilled = false
          const filledByLanguage = []
          let val = ""

          // Array pour savoir si tous les contents de chaque langue sont remplis
          Array.prototype.slice.call( allInputsLanguage ).forEach((content_lang_input) => {
            val = content_lang_input.value.replaceAll(' ', '')
            filledByLanguage.push(val !== '' && val.length > 2 )
          })
          allContentFilled = filledByLanguage.includes(false) ? false : true
          //savoir si le content du main_language n'existe pas déjà
          const alreadyIncludedOuiNon = allCurrentContent.includes(theme.value.toLowerCase())
          if (document.getElementById('smiley-liaison') || document.querySelector('.star-checkbox')) {
            if (allContentFilled && !alreadyIncludedOuiNon && allConditions.length >= 1) {
              btn.setAttribute("type", "submit");
              btn.classList.remove("not-clickable");
            } else {
              btn.removeAttribute("type");
              btn.classList.add("not-clickable");
            }
          } else {
            if (allContentFilled && !alreadyIncludedOuiNon && allConditions.length >= 1 && document.querySelectorAll("[name='ini_or_new']:checked").length === 1){
              btn.setAttribute("type", "submit");
              btn.classList.remove("not-clickable")
            } else {
              btn.removeAttribute("type");
              btn.classList.add("not-clickable")
            }
          }
        }
      });
    });
  }
};

const create_new_enquete = () => {
  if (document.querySelector("#myModal-new-enquete")) {
    $('#myModal-new-enquete').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#enquete_name")
          .val('')
          .end()
        .find("input[type=submit]")
          .prop('class', 'custom-btn btn-primary btn-of-modal not-clickable')
          .prop('type', "")
          .end()
        .find("input[type=checkbox],input[type=radio]")
           .prop("checked", "")
           .end();
    })
  }
}

const create_new_claim = () => {

  if (document.querySelector("#myModal-claim")) {
    const modale_new_claim = document.querySelector("#myModal-claim").querySelector(".modal-content");
    const modale_new_claim_btn = document.querySelector("#myModal-claim").querySelector(".custom-btn.btn-of-modal.btn-primary");
    const names_claims = [];
    let french = "" ;
    let english = "" ;

    Array.prototype.forEach.call(document.querySelectorAll(".claim"), function(r) {
      names_claims.push(r.innerText.replace(/\s/g, '').toLowerCase())
    })

    modale_new_claim.querySelectorAll("input")[2].addEventListener("keyup", () => {
      french = modale_new_claim.querySelectorAll("input")[2].value
      english = modale_new_claim.querySelectorAll("input")[3].value
      if (french.length >= 2 && english.length >= 2 && french.length < 21 && ((modale_new_claim.querySelectorAll("input")[2].value.replace(/\s/g, '').toLowerCase()).match(/[!@#$%^&*'(),;.?"-_:{}|<>1234567890\s]/g) == null) && (names_claims.includes(modale_new_claim.querySelectorAll("input")[2].value.replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_claim_btn.classList.remove("not-clickable");
          modale_new_claim_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_claim_btn.classList.add("not-clickable");
          modale_new_claim_btn.removeAttribute("type");
      }
    })

    modale_new_claim.querySelectorAll("input")[3].addEventListener("keyup", () => {
      french = modale_new_claim.querySelectorAll("input")[2].value
      english = modale_new_claim.querySelectorAll("input")[3].value
      if (french.length >= 2 && english.length >= 2 && french.length < 21 && ((modale_new_claim.querySelectorAll("input")[3].value.replace(/\s/g, '').toLowerCase()).match(/[!@#$%^&*'(),;.?"-_:{}|<>1234567890\s]/g) == null) && (names_claims.includes(modale_new_claim.querySelectorAll("input")[3].value.replace(/\s/g, '').toLowerCase()) === false)) {
          modale_new_claim_btn.classList.remove("not-clickable");
          modale_new_claim_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_claim_btn.classList.add("not-clickable");
          modale_new_claim_btn.removeAttribute("type");
      }
    })

    $('#myModal-claim').on('hidden.bs.modal', function (e) {
      $(this)
        .find("input#claim_content, input#claim_content_english")
          .val('')
          .end()
        .find("input[type=submit]")
          .prop('class', 'custom-btn btn-primary btn-of-modal not-clickable')
          .prop('type', "")
          .end();
    })

  }

}

const create_new_pub = () => {

  if (document.querySelector("#myModal-pub")) {
    const modale_new_pub = document.querySelector("#myModal-pub").querySelector(".modal-content");
    const modale_new_pub_btn = document.querySelector("#myModal-pub").querySelector(".custom-btn.btn-of-modal.btn-primary");
    const names_pubs = [];
    let french = "";
    let english = "";

    Array.prototype.forEach.call(document.querySelectorAll(".pub"), function(r) {
      names_pubs.push(r.innerText.replace(/\s/g, '').toLowerCase())
    })

    modale_new_pub.querySelectorAll("input")[2].addEventListener("change", () => {
      french = modale_new_pub.querySelectorAll("input")[2].value
      english = modale_new_pub.querySelectorAll("input")[4].value
      if (french.length >= 2 && english.length >= 2 ) {
          modale_new_pub_btn.classList.remove("not-clickable");
          modale_new_pub_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_pub_btn.classList.add("not-clickable");
          modale_new_pub_btn.removeAttribute("type");

      }
    })

    modale_new_pub.querySelectorAll("input")[4].addEventListener("change", () => {
      french = modale_new_pub.querySelectorAll("input")[2].value
      english = modale_new_pub.querySelectorAll("input")[4].value
      if (french.length >= 2 && english.length >= 2 ) {
          modale_new_pub_btn.classList.remove("not-clickable");
          modale_new_pub_btn.setAttribute("type", "submit");
        }
        else {
          modale_new_pub_btn.classList.add("not-clickable");
          modale_new_pub_btn.removeAttribute("type");
      }
    })

  $('#myModal-pub').on('hidden.bs.modal', function (e) {
    $(this)
      .find("img")
         .prop('src', '')
         .end()
      .find("input[type=file]")
        .val('')
        .end()
      .find("input[type=submit]")
        .prop('class', 'custom-btn btn-primary btn-of-modal not-clickable')
        .prop('type', "")
        .end();
  })

  }

}

//edit groupe question
function ft_edit_groupe_question() {
  $('#myModal-gqm').on('hidden.bs.modal', function (e) {
    $(this)
      .find("input[type=submit]")
        .prop('class', 'custom-btn btn-primary btn-of-modal not-clickable')
        .prop('type', "")
        .end();
  })
}

const edit_gp = () => {
  if (document.querySelector("#myModal-edit-groupe-question")) {

    modalEventListener(".edit-groupe-question", "#myModal-edit-groupe-question", [
			() => ft_edit_groupe_question()
		])
  }
}

const create_new_restaurant = () => {
  if (document.querySelector(".form-sign-edit-restaurant.create-js")) {
    console.log("create_new_restaurant")
    const form_new_resto = document.querySelector(".form-sign-edit-restaurant");
    const new_resto_btn = document.querySelector(".form-sign-edit-restaurant").querySelector("input.btn-edit-approve");

    if (form_new_resto.children[1].children[4] && form_new_resto.children[1].children[4].querySelector('select')) {

			form_new_resto.children[1].children[4].querySelector('select').addEventListener("change", () => {
						if (form_new_resto.querySelectorAll('input')[1].value.length >= 2
							&& form_new_resto.querySelectorAll('input')[2].value.length >= 2
							&& form_new_resto.children[1].children[4].querySelector('select').value !== "") {
							console.log("true_1")
							new_resto_btn.classList.remove("not-clickable");
							new_resto_btn.setAttribute("type", "submit");

						}
						else {
							console.log("false_1")
							new_resto_btn.classList.add("not-clickable");
							new_resto_btn.removeAttribute("type");
						}
					})
		}

    form_new_resto.querySelectorAll('input')[1].addEventListener("keyup", () => {
      if (form_new_resto.querySelectorAll('input')[1].value.length >= 2
        && form_new_resto.querySelectorAll('input')[2].value.length >= 2
        && form_new_resto.children[1].children[3].querySelector('select').value !== "" ) {
        console.log("true_2")
        new_resto_btn.classList.remove("not-clickable");
        new_resto_btn.setAttribute("type", "submit");
      }
      else {
        console.log("false_2")
        new_resto_btn.classList.add("not-clickable");
        new_resto_btn.removeAttribute("type");
      }
    })

    if (form_new_resto.querySelectorAll('input')[2]) {
      form_new_resto.querySelectorAll('input')[2].addEventListener("keyup", () => {
        if (form_new_resto.querySelectorAll('input')[1].value.length >= 2
          && form_new_resto.querySelectorAll('input')[2].value.length >= 2
          && form_new_resto.children[1].children[3].querySelector('select').value !== "") {
          console.log("true_3")
          new_resto_btn.classList.remove("not-clickable");
          new_resto_btn.setAttribute("type", "submit");
        }
        else {
          console.log("false_3")
          new_resto_btn.classList.add("not-clickable");
          new_resto_btn.removeAttribute("type");
        }
      })
    }
  }
}


const edit_restaurant = () => {
  if (document.querySelector(".form-sign-edit-restaurant.edit-js")) {
    console.log("edit restaurant")

    const form_new_resto = document.querySelector(".form-sign-edit-restaurant");
    const new_resto_btn = document.querySelector(".form-sign-edit-restaurant").querySelector("input.btn-edit-approve");

    form_new_resto.querySelectorAll('input')[3].addEventListener("keyup", () => {
      if (form_new_resto.querySelectorAll('input')[3].value.length >= 2
        && form_new_resto.querySelectorAll('input')[4].value.length >= 2
        && form_new_resto.children[1].children[3].querySelector('select').value !== "" ) {
        new_resto_btn.classList.remove("not-clickable");
        new_resto_btn.setAttribute("type", "submit");
      }
      else {
        new_resto_btn.classList.add("not-clickable");
        new_resto_btn.removeAttribute("type");
      }
    })

    form_new_resto.querySelectorAll('input')[4].addEventListener("keyup", () => {
      if (form_new_resto.querySelectorAll('input')[3].value.length >= 2
        && form_new_resto.querySelectorAll('input')[4].value.length >= 2
        && form_new_resto.children[1].children[3].querySelector('select').value !== "") {
        new_resto_btn.classList.remove("not-clickable");
        new_resto_btn.setAttribute("type", "submit");
      }
      else {
        new_resto_btn.classList.add("not-clickable");
        new_resto_btn.removeAttribute("type");
      }
    })

    form_new_resto.children[1].children[3].querySelector('select').addEventListener("change", () => {
      if (form_new_resto.querySelectorAll('input')[3].value.length >= 2
        && form_new_resto.querySelectorAll('input')[4].value.length >= 2
        && form_new_resto.children[1].children[3].querySelector('select').value !== "") {
        new_resto_btn.classList.remove("not-clickable");
        new_resto_btn.setAttribute("type", "submit");
      }
      else {
        new_resto_btn.classList.add("not-clickable");
        new_resto_btn.removeAttribute("type");
      }
    })

    const change_sous_groupe = document.querySelector(".form-sign-edit-restaurant").querySelector("#restaurant_sous_groupe_id");

    change_sous_groupe.addEventListener("change", () => {
      const group_selected = document.querySelector('.group-selected').value;

      const option_value_sous_groupe = document.getElementById('restaurant_sous_groupe_id');
      const value_text = option_value_sous_groupe.options[option_value_sous_groupe.selectedIndex].text.split(': ')[1].slice(0, -1);

      if (value_text == group_selected) {
        document.getElementById("wrong-sous-groupe-selected").classList.add("hidden")
        document.getElementById("wrong-sous-groupe-selected").nextSibling.classList.add("hidden")
      }
      else {
        document.getElementById("wrong-sous-groupe-selected").classList.remove("hidden")
        document.getElementById("wrong-sous-groupe-selected").nextSibling.classList.remove("hidden")
      }
    })
  }
}

const create_new_group = () => {
  if (document.querySelector('.create-new-group-js')) {
    console.log("js create group")
    const allGroupsName = document.querySelector('.create-new-group-js').dataset.allGroups
    const newGroup = document.querySelector('input#group_name')
    const btnCreateGroup = document.querySelector('.btn-edit-approve')

    newGroup.addEventListener('keyup', () => {
      if (JSON.parse(allGroupsName).includes(newGroup.value) && newGroup.value.length > 2) {
        btnCreateGroup.classList.add("not-clickable");
        btnCreateGroup.removeAttribute("type");

       } else {
        btnCreateGroup.classList.remove("not-clickable");
        btnCreateGroup.setAttribute("type", "submit");
      }
    })
  }
}

const create_new_sous_group = () => {
  if (document.querySelector('.create-new-group-js')) {
    console.log("js create group")
    const allGroupsName = document.querySelector('.create-new-group-js').dataset.allGroups
    const newGroup = document.querySelector('input#group_name')
    const btnCreateGroup = document.querySelector('.btn-edit-approve')

    newGroup.addEventListener('keyup', () => {
      if (JSON.parse(allGroupsName).includes(newGroup.value) && newGroup.value.length > 2) {
        btnCreateGroup.classList.add("not-clickable");
        btnCreateGroup.removeAttribute("type");

       } else {
        btnCreateGroup.classList.remove("not-clickable");
        btnCreateGroup.setAttribute("type", "submit");
      }
    })
  }
}

// Créer un nouveau lien dans la page d'accueil du questionnaire
const create_new_start_button = () => {
}

// Modifier le premier lien dans la page d'accueil du questionnaire
function ft_edit_questionnaire_url() {
}

const edit_questionnaire_url = () => {
  if (document.querySelector("#myModal-edit-questionnaire-survey")) {

    modalEventListener(".edit-questionnaire-url", "#myModal-edit-questionnaire-survey", [
			() => ft_edit_questionnaire_url()
		])
  }
}
// Modifier un lien dans la page d'accueil du questionnaire
function ft_edit_start_button() {
}

const edit_start_button = () => {
  if (document.querySelector("#myModal-edit-start-url-survey")) {

    modalEventListener(".edit-start-url", "#myModal-edit-start-url-survey", [
			() => ft_edit_start_button(),
			() => uncheckRadioBtns(document.querySelector("#myModal-edit-start-url-survey"))
		])

  }
}

const notAllowedOuiNon = (questionTitle) => {
  const notAllowedWordsForQuestionTitle = ['emoji'];
  return !(notAllowedWordsForQuestionTitle.includes(questionTitle))
}

function ft_edit_question() {
  console.log("edit_question")
	const modaleEdit = document.querySelector("#myModal-edit-question").firstElementChild
	const form = modaleEdit.querySelector('form')
	const inputTheme = modaleEdit.querySelector('input#question_theme')
	const allThemesOfGq = []
	modaleEdit.querySelector('input#question_groupe_question_theme').value.split(' ').forEach((theme) => {
		allThemesOfGq.push(theme.toLowerCase().trim())
	})
	const btnEdit = modaleEdit.querySelector('input.custom-btn.btn-of-modal-edit-serveur')

	form.addEventListener('keyup', (event) => {
		console.log(allThemesOfGq)
		if (inputTheme.value.trim().length > 2 && !allThemesOfGq.includes(inputTheme.value.toLowerCase().trim()) && notAllowedOuiNon(inputTheme.value.toLowerCase())){
			btnEdit.type = "submit"
			btnEdit.classList.remove("not-clickable")
		} else {
			btnEdit.type = ""
			btnEdit.classList.add("not-clickable")
		}
	})

	form.addEventListener('click', (event) => {
		console.log(allThemesOfGq)
		if (inputTheme.value.trim().length > 2 && !allThemesOfGq.includes(inputTheme.value.toLowerCase().trim()) && notAllowedOuiNon(inputTheme.value.toLowerCase())){
			btnEdit.type = "submit"
			btnEdit.classList.remove("not-clickable")
		} else {
			btnEdit.type = ""
			btnEdit.classList.add("not-clickable")
		}
	})
}

const edit_question = () => {
  if (document.getElementById("groupe_question_index_parametre_for_js")){
    modalEventListener(".edit-question", "#myModal-edit-question", [
			() => ft_edit_question()
		])
  }
}

function ft_edit_question_add() {
}

const edit_question_without_theme = () => {
  if (document.getElementById("groupe_question_index_parametre_for_js")){
    modalEventListener(".edit-question-add", "#myModal-edit-question-add", [
			() => ft_edit_question_add()
		])
  }
}


const new_customer_form = () => {
	const formCreate = document.querySelector("form#creating_client_form");
	const inputEmail = document.querySelector("input#email");
	const inputPhone = document.querySelector("input#phone");
	const alertEmail = document.getElementById("wrong-input-value-mail-js");
	const alertPhone = document.getElementById("wrong-input-value-phone-js");
  const createCustomerBtn = document.getElementById("btn-to-create-customer");
  const containerSelectNew = document.querySelector('.create-restaurant-user')
  const selectTagToModify = containerSelectNew.querySelector('.select2-selection.select2-selection--multiple')

	newAndEditClientModal(formCreate, inputEmail, inputPhone, alertEmail, alertPhone, createCustomerBtn, selectTagToModify)

	if (formCreate) {
		createCustomerBtn.removeAttribute("type");
	}
}

const ft_edit_client_modal = () => {
	const formUpdate = document.querySelector("form#update_client_form");
	const inputEmail = document.querySelector("input#email");
	const inputPhone = document.querySelector("input#client_phone");
	const alertEmail = document.getElementById("wrong-input-value-mail-edit-js");
	const alertPhone = document.getElementById("wrong-input-value-phone-edit-js");
	const updateCustomerBtn = document.getElementById("btn-to-update-customer");
  const containerSelectEdit = document.querySelector('.edit-restaurant-user')
  const selectTagToModify = containerSelectEdit.querySelector('.select2-selection.select2-selection--multiple')

	newAndEditClientModal(formUpdate, inputEmail, inputPhone, alertEmail, alertPhone, updateCustomerBtn, selectTagToModify)
}

function edit_client_modal() {
  const modale = document.getElementById('myModal-custom-edit');

	if (modale) {
    modalEventListener(".edit-client-for-js", "#myModal-custom-edit", [
      () => {$('.select2edit-restaurant-user').select2();},
      () => ft_edit_client_modal(),
      () => editCountryCodeAndFlagForPhoneNumbers(modale)
    ]);
	}
}

const newAndEditClientModal = (form, email, phone, alertEmail, alertPhone, submitBtn, selectTag) => {
	if (form) {
		const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		const regex_phone_number = /^(?!\+33)\+\d{4,15}$/;
		const regex_phone_number_france = /^(?:\+33)[1-9](?:\d{8})$/;

		if (email) {
			email.addEventListener('keyup', (event) => {
				if (email.value.match(regex_email) == null) {
					alertEmail.classList.remove("hidden")
					email.style.borderColor = '#fd397a'
				} else {
					alertEmail.classList.add("hidden")
					email.style.borderColor = '#989eb1'
				}
			})
		}

		if (phone) {
			phone.addEventListener('keyup', (event) => {
				if (phone.value.match(regex_phone_number_france) === null || phone.value.match(regex_phone_number) === null) {
					alertPhone.classList.remove("hidden")
					phone.style.borderColor = '#fd397a'
				} else {
					alertPhone.classList.add("hidden")
					phone.style.borderColor = '#989eb1'
				}
			})
		};

    if(selectTag) {
      selectTag.style.background = 'transparent';
      selectTag.style.height = '30px'
      selectTag.style.borderRadius = '8px'
      selectTag.style.border = '1px solid #989eb1'
      selectTag.style.width = '660px'
    }


    form.addEventListener('keyup', (event) => {

      if (
        ((email.value !== '' && email.value.match(regex_email) !== null) &&
          (phone.value !== '+33' && phone.value !== '' && (phone.value.match(regex_phone_number_france) !== null || phone.value.match(regex_phone_number) !== null))) ||
        (email.value === '' &&
          (phone.value !== '+33' && phone.value !== '' && (phone.value.match(regex_phone_number_france) !== null || phone.value.match(regex_phone_number) !== null))) ||
        ((phone.value === '' || phone.value === '+33') &&
          (email.value !== '' && email.value.match(regex_email) !== null))
      ) {
        alertEmail.classList.add("hidden")
        email.style.borderColor = '#989eb1'
        alertPhone.classList.add("hidden")
        phone.style.borderColor = '#989eb1'
        submitBtn.setAttribute("type", "submit");
        submitBtn.classList.remove("not-clickable");
      } else {
        submitBtn.removeAttribute("type")
        submitBtn.classList.add("not-clickable");
      }
    });


		const cguCheckboxOne = $('#cgu_oui_non_1')
		const cguCheckboxTwo = $('#cgu_oui_non_2')
		const cguCheckboxThree = $('#cgu_oui_non_3')

		cguCheckboxOne.on('click', function() {
			const isChecked = cguCheckboxOne.prop('checked')
			if (isChecked === false) {
				cguCheckboxTwo.prop('checked', isChecked);
				cguCheckboxThree.prop('checked', isChecked);
			}
		});

		cguCheckboxTwo.add(cguCheckboxThree).on('click', function() {
			const isChecked = $(this).prop('checked');
			if (isChecked === true) { cguCheckboxOne.prop('checked', isChecked) };
		})
	};
};

const new_user_form = () => {
  if (document.querySelector("#creating_user_form")) {
    const form = document.querySelector("form#creating_user_form")
    const createUserBtn = document.getElementById("btn-to-create-user")
    createUserBtn.removeAttribute("type");

    const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex_phone_number = /^(?!\+33)\+\d{4,15}$/;
    const regex_phone_number_france = /^(?:\+33)[1-9](?:\d{8})$/

    const inputEmail = document.querySelector("input#user_email")
    const inputPhone = document.querySelector("input#user_phone")

    inputEmail.addEventListener('keyup', (event) => {
      if (inputEmail.value.match(regex_email) == null) {
        document.getElementById("wrong-input-value-mail").classList.remove("hidden")
        document.getElementById("wrong-input-value-mail").nextSibling.classList.remove("hidden")
        inputEmail.style.borderColor = '#fd397a'
      } else {
        document.getElementById("wrong-input-value-mail").classList.add("hidden")
        document.getElementById("wrong-input-value-mail").nextSibling.classList.add("hidden")
        inputEmail.style.borderColor = '#1dc9b7'
      }
    })

    inputPhone.addEventListener('keyup', (event) => {
      if (inputPhone.value.match(regex_phone_number_france) === null || inputPhone.value.match(regex_phone_number) === null) {
        document.getElementById("wrong-input-value-phone").classList.remove("hidden")
        document.getElementById("wrong-input-value-phone").nextSibling.classList.remove("hidden")
        inputPhone.style.borderColor = '#fd397a'
      } else {
        document.getElementById("wrong-input-value-phone").classList.add("hidden")
        document.getElementById("wrong-input-value-phone").nextSibling.classList.add("hidden")
        inputPhone.style.borderColor = '#1dc9b7'
      }
    });

    // enlever le not-clickable si au moins 1 des 4 input info rempli
    form.addEventListener('keyup', (event) => {
        if (
          ((inputEmail.value !== '' && inputEmail.value.match(regex_email) !== null) && (inputPhone.value !== '+33' && inputPhone.value !== '' && (inputPhone.value.match(regex_phone_number_france) !== null || inputPhone.value.match(regex_phone_number) !== null))) ||
          (inputEmail.value === '' && (inputPhone.value !== '+33' && inputPhone.value !== '' && (inputPhone.value.match(regex_phone_number_france) !== null || inputPhone.value.match(regex_phone_number) !== null))) ||
          ((inputPhone.value === '' || inputPhone.value === '+33') && (inputEmail.value !== '' && inputEmail.value.match(regex_email) !== null))
          ) {
          document.getElementById("wrong-input-value-mail").classList.add("hidden")
          document.getElementById("wrong-input-value-mail").nextSibling.classList.add("hidden")
          inputEmail.style.borderColor = '#1dc9b7'
          document.getElementById("wrong-input-value-phone").classList.add("hidden")
          document.getElementById("wrong-input-value-phone").nextSibling.classList.add("hidden")
          inputPhone.style.borderColor = '#1dc9b7'
          createUserBtn.setAttribute("type", "submit")
          createUserBtn.classList.remove("not-clickable");
        } else {
          createUserBtn.removeAttribute("type")
          createUserBtn.classList.add("not-clickable");
        }
    });
  };
}

const edit_user_form = () => {
  if (document.getElementById("modifying_user_form")){
    console.log("#modifying_user_form")

    const form = document.querySelector("form#modifying_user_form")
    const modifyUserBtn = document.getElementById("btn-to-modify-user")
    modifyUserBtn.removeAttribute("type");

    const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regex_phone_number = /^(?!\+33)\+\d{4,15}$/;
    const regex_phone_number_france = /^(?:\+33)[1-9](?:\d{8})$/;

    const inputEmail = document.querySelector("input#user_email")
    const inputPhone = document.querySelector("input#user_phone")
    const inputLangueFr = document.querySelector("input#langue_fr")
    const inputLangueEn = document.querySelector("input#langue_en")
    const inputCompteAdmin = document.querySelector("input#admin")
    const inputCompteLAdmin = document.querySelector("input#sales")
    const inputCompteFake = document.querySelector("input#fake_oui_non")
    const inputEtab = document.querySelector(".select2-selection__rendered")

    inputEmail.addEventListener('keyup', (event) => {
      if (inputEmail.value.match(regex_email) == null) {
        document.getElementById("wrong-input-value-mail").classList.remove("hidden")
        document.getElementById("wrong-input-value-mail").nextSibling.classList.remove("hidden")
        inputEmail.style.borderColor = '#fd397a'
      } else {
        document.getElementById("wrong-input-value-mail").classList.add("hidden")
        document.getElementById("wrong-input-value-mail").nextSibling.classList.add("hidden")
        inputEmail.style.borderColor = '#1dc9b7'
      }
    });

    inputPhone.addEventListener('keyup', (event) => {
      if ((inputPhone.value.match(regex_phone_number_france) === null) || (inputPhone.value.match(regex_phone_number) === null)) {
        document.getElementById("wrong-input-value-phone").classList.remove("hidden")
        document.getElementById("wrong-input-value-phone").nextSibling.classList.remove("hidden")
        inputPhone.style.borderColor = '#fd397a'
      } else {
        document.getElementById("wrong-input-value-phone").classList.add("hidden")
        document.getElementById("wrong-input-value-phone").nextSibling.classList.add("hidden")
        inputPhone.style.borderColor = '#1dc9b7'
      }
    });

    inputLangueFr.addEventListener('click', (event) => {
      modifyUserBtn.setAttribute("type", "submit")
      modifyUserBtn.classList.remove("not-clickable");
    });

    inputLangueEn.addEventListener('click', (event) => {
      modifyUserBtn.setAttribute("type", "submit")
      modifyUserBtn.classList.remove("not-clickable");
    });

    if (inputCompteAdmin) {
      inputCompteAdmin.addEventListener('click', (event) => {
        modifyUserBtn.setAttribute("type", "submit")
        modifyUserBtn.classList.remove("not-clickable");
      });

      inputCompteLAdmin.addEventListener('click', (event) => {
        modifyUserBtn.setAttribute("type", "submit")
        modifyUserBtn.classList.remove("not-clickable");
      });
    };

    inputCompteFake.addEventListener('click', (event) => {
      modifyUserBtn.setAttribute("type", "submit")
      modifyUserBtn.classList.remove("not-clickable");
    });

    if (inputEtab) {
      inputEtab.addEventListener('click', (event) => {
        if (inputEtab.children[0] !== '') {
          modifyUserBtn.setAttribute("type", "submit")
          modifyUserBtn.classList.remove("not-clickable");
        };
      });
    };

    // enlever le not-clickable si au moins 1 des 4 input info rempli
    form.addEventListener('keyup', (event) => {
        if (
          ((inputEmail.value !== '' && inputEmail.value.match(regex_email) !== null) && (inputPhone.value !== '+33' && inputPhone.value !== '' && (inputPhone.value.match(regex_phone_number_france) !== null || inputPhone.value.match(regex_phone_number) !== null))) ||
          (inputEmail.value === '' && (inputPhone.value !== '+33' && inputPhone.value !== '' && (inputPhone.value.match(regex_phone_number_france) !== null || inputPhone.value.match(regex_phone_number) !== null))) ||
          ((inputPhone.value === '' || inputPhone.value === '+33') && (inputEmail.value !== '' && inputEmail.value.match(regex_email) !== null))
          ) {
          document.getElementById("wrong-input-value-mail").classList.add("hidden")
          document.getElementById("wrong-input-value-mail").nextSibling.classList.add("hidden")
          inputEmail.style.borderColor = '#1dc9b7'
          document.getElementById("wrong-input-value-phone").classList.add("hidden")
          document.getElementById("wrong-input-value-phone").nextSibling.classList.add("hidden")
          inputPhone.style.borderColor = '#1dc9b7'
          modifyUserBtn.setAttribute("type", "submit")
          modifyUserBtn.classList.remove("not-clickable");
        } else {
          modifyUserBtn.removeAttribute("type")
          modifyUserBtn.classList.add("not-clickable");
        }
    });
  };
}

function toggleValidationMessages(allInputs, type, id) {
  Array.prototype.slice.call(allInputs).forEach((content_lang_input) => {
    const element = content_lang_input.value.replaceAll(' ', '')
    const lang = content_lang_input.id.match(/_(en|pt_br|fr|it|de|mn|es|ru|pt|ar|kz)$/)[1]
    const element_with_whitespaces = content_lang_input.value
    let msg1;
    let msg2;

    if (content_lang_input.id.includes(id)) {
      msg1 = document.getElementById(`message_for_format_title_${type}${lang}`)
      msg2 = document.getElementById(`message_for_whitespaces_title_${type}${lang}`)
    } else {
      msg1 = document.getElementById(`message_for_format_short_title_${lang}`)
    }

    if (msg1) {
      msg1.style.display = (element !== '' && element.length > 2) ? 'none' : 'flex';
    }

    if (content_lang_input.id.includes(id) && msg2) {
      msg2.style.display = (element_with_whitespaces.trim() == element_with_whitespaces) ? 'none' : 'flex';
    }
  })
}

export function editMealDate() {
	if (document.getElementById("modalEditDateProgram")){
    modalEventListener(".edit-meal-date", "#modalEditDateProgram", [])
  }
}

export function editMealDateAll() {
	if (document.getElementById("modalEditDateProgramAll")){
    modalEventListener(".edit-meal-date-all", "#modalEditDateProgramAll", [])
  }
}

export function newMealDate() {
	if (document.getElementById("modalCreateDateProgram")){
		modalEventListener(".new-meal-date", "#modalCreateDateProgram", [])
	}
}

export function newMealItem() {
	const modale = document.getElementById("myModal-a");
	if (modale && document.getElementById("parametre-carte-resto-for-js")){
		modalEventListener(".new-meal-item", "#myModal-a", [
			() => ft_create_new_meal(modale),
			() => initTinyMCE(".corps_tiny"),
			() => tagsSelectionToggleCss(modale),
			() => tagsSelectionEdit(modale),
			() => pictoSelectionEdit(modale),
			() => allergensSelectionToggleCss(modale),
			() => allergensSelectionEdit(modale),
			() => programDateMeal(modale),
      () => initializeHandleCmiPrice(modale),
      () => addDateField(modale),
			() => uncheckRadioBtns(modale),
			() => previewImageOnFileSelectMeal(modale),
		])
	}
}

export function editContainerMealItem() {
	if (document.getElementById("modalEditContainerMealItem")){
		modalEventListener(".edit-container-meal-item", "#modalEditContainerMealItem", [])
  }
}

export function editContainerMealItemAll() {
	const modale = document.getElementById("modalEditContainerMealItemAll");
	if (modale){
		modalEventListener(".edit-container-meal-item-all", "#modalEditContainerMealItemAll", [
			() => initializeHandleCmiPrice(modale),
		])
  }
}

export function newContainerMealItem() {
	const modale = document.getElementById("modalCreateContainerMealItem");
	if (modale){
		modalEventListener(".new-container-meal-item", "#modalCreateContainerMealItem", [
			() => initializeHandleCmiPrice(modale),
      console.log("newContainerMealItem")
		])
	}
}

export function newCmiReferentielPrix() {
  const modale = document.getElementById("modalCreateCmiReferentielPrix");
  if (modale) {
    modalEventListener(".new-cmi-referentiel-prix", "#modalCreateCmiReferentielPrix", [
      () => {
        $(modale).on('shown.bs.modal', function () {
          initializeHandleCmiPrice(modale);
        });
      },
    ]);
  }
}

export function importDataBaseClients() {
  const modale = document.getElementById("myModal-import-customers");

  if (modale) {
    modalEventListener(".custom_modal_import_data", "#myModal-import-customers", []);
  }
}

export function CustomCreateClient() {
  const modale = document.getElementById("myModal-custom-create");

  if (modale) {
    modalEventListener(".custom_modal_new_user", "#myModal-custom-create", [
      () => {$('.select2create-restaurant-user').select2();},
      () => new_customer_form(),
      () => newCountryCodeAndFlagForPhoneNumbers(modale),
    ]);
  }
}

export function editEnquete() {
  const modale = document.getElementById("myModal-edit-enquete");

	if (modale){
		modalEventListener(".btn-edit-enquete", "#myModal-edit-enquete", [
      () => loadingDots(modale)
    ])
	}
}



export {create_new_categ_all_multisite, create_new_enquete, create_new_product, create_new_meal_all, create_new_service, create_new_equipe, edit_service, create_new_master_category, create_new_category, edit_category, create_new_reward, create_new_reward_fid, create_new_reward_jeu_concours, edit_reward_fid, edit_reward_concours, create_new_tag, create_new_groupe_question, create_new_groupe_question_page, create_new_claim, create_new_pub, edit_meal, edit_meal_all, edit_product, edit_reward, edit_gp, create_new_restaurant, edit_restaurant, create_new_group, create_new_master_category_all, edit_master_category, edit_master_category_all, edit_question, edit_question_without_theme, create_new_start_button, edit_start_button, edit_client_modal, new_user_form, edit_user_form, edit_questionnaire_url};
