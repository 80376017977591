import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log("connect-distri-horaire-stimulus")
    this.setupQuestionDropdown();
    this.initializeOptions();
    this.graphManipulation();
  }


  toggleDropdowns(event) {
    // Prevent click on the button from propagating
    event.stopPropagation();
    var dropdown = document.getElementById("questionsDropdown");
    if (dropdown) {
      dropdown.classList.toggle("show-dropdown");
    }
  }

  toggleDropdown(event, questionId) {

    event.stopPropagation(); // Prevent click on the button from propagating
    var questionId = event.currentTarget.dataset.questionId;
    // Construct the ID using the questionId parameter
    var dropdownId = "dropdownContent_" + questionId;
    var dropdown = document.getElementById(dropdownId);

    if (dropdown) {
      dropdown.classList.toggle("show-dropdown");
    }
  }


  toggleDropdowns(event) {
    event.stopPropagation();
    var dropdown = document.getElementById("questionsDropdown");
    if (dropdown) {
      dropdown.classList.toggle("show-dropdown");
    }
  }

  setupQuestionDropdown() {

    document.querySelectorAll('.question-option').forEach((option) => {
      option.addEventListener('click', (event) => {
        this.handleQuestionOptionClick(event);
      });
    });
  }

  handleQuestionOptionClick(event) {
    console.log("handleQuestionOptionClick");

    event.preventDefault();

    // var selectedQuestionId = event.currentTarget.getAttribute('data-question-id');
    // var specificDivToShow = document.querySelector(`.card.specific-question-graph.one-question[data-question-id="${selectedQuestionId}"]`);
    var selectedQuestionContent = event.currentTarget.getAttribute('data-question-content');
    var specificDivToShow = document.querySelector(`.card.specific-question-graph.one-question[data-question-content="${selectedQuestionContent}"]`);

    console.log("selectedQuestionContent", selectedQuestionContent);
    console.log("specificDivToShow", specificDivToShow);
    if (specificDivToShow) {
      specificDivToShow.classList.remove('hide');
      specificDivToShow.style.display = 'block';
    }

    var dropdown = document.getElementById("questionsDropdown");
    var genericGraph = document.querySelector('.general-graph');

    if (dropdown) {
      console.log("dropdown 72");
      console.log(dropdown);
      genericGraph.classList.add('hide');
      dropdown.classList.remove("show-dropdown");
    }

    if (!dropdown.contains(event.target) && event.target.id !== "dropdownsButtons") {
      dropdown.classList.remove("show-dropdown");
    }
    //console.groupEnd();
  }

  initializeOptions() {
    // Attach click event listeners to question options within the dropdown
    document.querySelectorAll('.options').forEach((option) => {
      option.addEventListener('click', (event) => this.handleOptionClick(event));
    });
  }

  handleOptionClick(event) {
    event.preventDefault(); // Prevent default action of the link

    // Hide all question divs first
    document.querySelectorAll('.specific-question-graph.one-question').forEach((div) => {
      div.classList.add('hide');
    });

    // Get the question content from the data attribute of the clicked option
    //let selectedQuestionId = event.currentTarget.getAttribute('data-question-id');
    let selectedQuestionContent = event.currentTarget.getAttribute('data-question-content');

    // Then, show only the div associated with the selected question content
    let specificDivToShow = document.querySelector(`.specific-question-graph.one-question[data-question-content="${selectedQuestionContent}"]`);
    let generalGraph = document.querySelector('.general-graph');

    if (specificDivToShow) {
      console.log("specificDivToShow 72");
      console.log(specificDivToShow);
      generalGraph.classList.add('hide');
      specificDivToShow.classList.remove('hide');
    }

    // Optionally, close the dropdown menu after selection
    document.querySelectorAll('.dropdown-content-modified').forEach(function(dropdown) {
      // If the click is outside the dropdown, hide it
      if (!dropdown.contains(event.target)) {
        dropdown.classList.remove("show-dropdown");
      }
    });
  }

  graphManipulation(event) {
    var dropdown = document.getElementById("questionsDropdown");

    // Attach click event listeners to all-graphs-buttons
    var buttons = document.querySelectorAll('.all-graphs-button');
    buttons.forEach(function(button) {
      button.addEventListener('click', function(event) {
          event.preventDefault(); // Prevent default action of the button

          // Show the general-graph for this question
          var generalGraph = document.querySelector('.card.general-graph');

          // Select all elements with the class '.specific-question-graph.one-question'
          var specificQuestionGraphs = document.querySelectorAll('.specific-question-graph.one-question');

          // Loop through each specific question graph element
          specificQuestionGraphs.forEach(function(specificQuestionGraph) {
            specificQuestionGraph.classList.add('hide');
          });

          // Show the general graph
          generalGraph.classList.remove('hide');
          dropdown.style.display = 'none';
      });
    });
  };
}
