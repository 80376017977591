import Cropper from 'cropperjs';
import { b64toBlob } from './_handleCropper';

function createFile(cropper, input) {
  let croppedCanvas = cropper.getCroppedCanvas();
  if (croppedCanvas === null) {
    return;
  }
  let croppedImage = croppedCanvas.toDataURL('image/png');
  // Separate base64 string
  const block = croppedImage.split(";")
  const contentType = block[0].split(":")[1]
  const realData = block[1].split(",")[1]
  const blob = b64toBlob(realData, contentType);

  // Create the image file with blob
  const file = new File([blob], "my_image.png", { type: "image/png" })

  const firstDataTransfer = new DataTransfer()
  firstDataTransfer.items.add(file);
  // Replace input file by new image file created
  input.files = firstDataTransfer.files
}

function saveCroppedImage(firstCropper, secondCropper, modal, firstInput, secondInput, firstInputLabel) {
  // create image portrait
  createFile(firstCropper, firstInput)
  // create image landscape
  createFile(secondCropper, secondInput)

  // Close the modal
  closeModal(modal)

  // show image inside the form after save and hide label
  showImage(firstInput, secondCropper)

  // submit the form for created question description
  addPicture(firstInputLabel)
}

function showImage(input, cropper) {
  let imageUrl = cropper.getCroppedCanvas()
  if (imageUrl === null) {
    return;
  }
  let questionImage = input.parentNode.parentNode.querySelector("#question-image")
  if (questionImage) {
    questionImage.src = imageUrl.toDataURL('image/png');
    let questionImageDiv = input.parentNode.parentNode.querySelector(".question-image-div")
    let questionLabel = input.parentNode.querySelector("label.btn-dropdown-answer-question");
    if (questionImageDiv.classList.contains("hidden")) {
      questionLabel.classList.add("hidden");
      questionImageDiv.classList.remove("hidden");
    }
  }
}

function closeModal(modal) {
  if (!modal.classList.contains('hidden')) {
    modal.classList.add('hidden')
  }
}

function previsulisation(imagePrev ,cropper, index) {
  if (imagePrev) {
    if (!modal.classList.contains("hidden")) {

      // get promize to get the crop div
      function getCropBox() {
        return new Promise((resolve) => {
          setTimeout(() => {
            // initialize previsualisation image
            if(cropper.getCroppedCanvas()){
              imagePrev.src = `${cropper.getCroppedCanvas().toDataURL('image/png')}`
            }
            const cropBox = document.querySelectorAll(".cropper-crop-box");
            resolve(cropBox[index]);
          }, 1000);
        });
      }

      // Utilisation de la promesse
      getCropBox().then((cropBox) => {
        // Utilisez la valeur de cropBox ici
        cropBox.addEventListener("click", () => {
          if(cropper.getCroppedCanvas()){
            let firstCroppedImage = cropper.getCroppedCanvas().toDataURL('image/png')
            imagePrev.src = `${firstCroppedImage}`
          }
        })

      });

    }
  }
}

function newCropper(image, width, height) {
  return new Cropper(image, {
    aspectRatio: width / height,
  })
}


function removePreviousCrop(image) {
  if (image.cropper ){
    image.cropper.destroy()
  }
}

function addPicture(label) {
  const submitBtn = label.parentNode.querySelector("#submit-button")
  if(submitBtn){
    submitBtn.click()
  }

}

const displayDoubleCropper = (firstInput, secondInput, firstInputLabel, event) => {

  const modal = document.getElementById('mount')
  loadAllElelements(modal)
  // add btn here | id= cropImageBtn
  const applyBtnContainer = document.getElementById("btn-container")
  applyBtnContainer.innerHTML = `<button id="crop-image-btn"  class="crop-image-btn btn-of-modal my-4 w-25"> ${applyBtnContainer.dataset.apply} </button>`

  // Show modal
  modal.classList.remove('hidden')

  let firstImage = document.getElementById('first-crop')
  let secondImage = document.getElementById('second-crop')
  let file  = event.target.files[0]
  let url = window.URL.createObjectURL(file)
  // Get blob url to show image in Cropper
  firstImage.src = url
  secondImage.src = url

  // Remove precedent cropper if there is one
  removePreviousCrop(firstImage)
  removePreviousCrop(secondImage)


  // Preview cropped image on previsualisation div without clicking on the button
  let firstImagePrev = document.getElementById("first-image-prev")
  let secondImagePrev = document.getElementById("second-image-prev")

  // Create first image cropper
  const firstCropper = newCropper(firstImage, 16, 7)

  // Create second image cropper
  const secondCropper = newCropper(secondImage, 16, 6)


  previsulisation(firstImagePrev ,firstCropper, 0)
  previsulisation(secondImagePrev ,secondCropper, 1)

  // Save the new cropper and close modal
  document.getElementById('crop-image-btn').addEventListener('click', () => {
    saveCroppedImage(firstCropper, secondCropper, modal, firstInput, secondInput, firstInputLabel)
  })

}

function loadAllElelements(modal) {
  function waitForElements(modal, selector, count, timeout = 50000, interval = 100) {
    return new Promise((resolve, reject) => {
      let elapsedTime = 0;
      const timer = setInterval(() => {
        const elements = modal.querySelectorAll(selector);
        if (elements.length === count) {
          clearInterval(timer);
          resolve(elements);
        } else {
          elapsedTime += interval;
          if (elapsedTime >= timeout) {
            clearInterval(timer);
            reject(new Error(`Timeout exceeded while waiting for elements matching selector '${selector}'`));
          }
        }
      }, interval);
    });
  }

  // Fonction pour ajouter l'effet de flou
  function addBlurEffect(modal) {
    document.body.style.cursor = "wait"
    modal.style.filter = 'blur(4px)';
  }

  // Fonction pour enlever l'effet de flou
  function removeBlurEffect(modal) {
      modal.style.filter = 'none';
  }

  // Utilisation :
  addBlurEffect(modal); // Ajoute l'effet de flou

  waitForElements(modal, '.cropper-container', 2)
    .then(() => {
        document.body.style.cursor = "auto"
        removeBlurEffect(modal); // Enlève l'effet de flou une fois que les éléments sont chargés
    })
    .catch(error => {
        console.error(error);
    });
}

const closeModalFt = () => {
  const modal = document.getElementById('mount')
  document.getElementById('close-modal-cropper').addEventListener('click', () => {
    // remove btn from the page to clear the event listenners
    const applyBtnContainer = document.getElementById("btn-container")
    applyBtnContainer.removeChild(applyBtnContainer.firstChild)
    // close the modal
    if (!modal.classList.contains('hidden')) {
      modal.classList.add('hidden')
    }
  })
}


export { displayDoubleCropper, closeModalFt }
