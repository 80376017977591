// Meal
const BtnValiderOrdreForDragDropMeal = () => {
  if (document.querySelector(".btn-validate-order-drag-drop-meals.hidden")) {

    if (document.querySelectorAll(".div_meals_for_js_toggle_validate_btn")){
      console.log("BtnValiderOrdreForDragDropMeal")
      const allQuestionsDiv = document.querySelectorAll(".div_meals_for_js_toggle_validate_btn")
      allQuestionsDiv.forEach((question) => {
        question.addEventListener('drop', (event) => {
          let categoryContent = event.currentTarget.dataset.catcontent.replace(/[\s]/, ".").replace(/[']/,"").replace(/[&]/,"")
          document.querySelector(`.btn-validate-order-drag-drop-meals.${categoryContent}`).classList.remove('hidden')
        })
      })
    }

  }

}

// CATEG
const BtnValiderOrdreForDragDropCategory = () => {
  if (document.querySelector(".btn-validate-order-drag-drop-category.hidden")) {
    if (document.querySelectorAll(".div_category_for_js_toggle_validate_btn")){
      const allQuestionsDiv = document.querySelectorAll(".div_category_for_js_toggle_validate_btn")
      console.log("BtnValiderOrdreForDragDropCategory")
      allQuestionsDiv.forEach((question) => {
        question.addEventListener('drop', (event) => {
          question.parentElement.parentElement.parentElement.querySelector(".btn-validate-order-drag-drop-category").classList.remove('hidden')
        })
      })
    }

  }
}


// BTN MASCAT ORDER
const BtnValiderOrdreForDragDropMascat = () => {
  if (document.querySelector(".btn-validate-order-drag-drop-mascats.hidden")) {
    if (document.querySelectorAll("[class*=sortablejs-mastercateg]")){
      const allMascatsDiv = document.querySelectorAll("[class*=sortablejs-mastercateg]")
      console.log("BtnValiderOrdreForDragDropMascats")

      allMascatsDiv.forEach((mascat) => {
        mascat.addEventListener('drop', (event) => {
          mascat.parentElement.parentElement.parentElement.querySelector(".btn-validate-order-drag-drop-mascats").classList.remove('hidden')
        })
      })
    }
  }
}

// GROUPES QUESTION - AJAX : FAIRE APPARAITRE LE BOUTON "VALIDER L'ORDRE" QUAND AJAX SUR LES ACTIONS: Activer/Desactiver un GQ + Supprimer un GQ
const BtnValiderOrdreForAjaxActifAndInactifBtn = () => {
  if (document.querySelector(".btn-validate-order-drag-drop.hidden")) {
    console.log('BtnValiderOrdreForAjaxActifAndInactifBtn')
    const allBtnForActivation = document.querySelectorAll(".btn-group-question")

    const allBtnForActivationExceptMealAndMenu = []
    allBtnForActivation.forEach((btn) => {
      if (!Array.prototype.slice.call(btn.classList).includes("mail-actif-btn-meal-nps")) {
        allBtnForActivationExceptMealAndMenu.push(btn)
      }
    })

    allBtnForActivationExceptMealAndMenu.forEach((gq) => {
      gq.addEventListener('click', (event) => {
        // on remet le if pour que l'action ne se refasse pas si le hidden a déjà été enlevé
        if (document.querySelector(".btn-validate-order-drag-drop.hidden")) {
          // si on peut cliquer sur le btn (car nombre de questions possibles à activer n'a as pas encore été atteint)
          if ( document.querySelector('[data-numberquestiongroupeallowed]').dataset.numberquestiongroupeallowed !== document.querySelector('[data-numberquestiongroupeallowed]').dataset.actualnumberquestiongroupe ||
            (document.querySelector('[data-numberquestiongroupeallowed]').dataset.numberquestiongroupeallowed === document.querySelector('[data-numberquestiongroupeallowed]').dataset.actualnumberquestiongroupe && event.currentTarget.innerText === 'Actif')
          ) {
            document.querySelector(".btn-validate-order-drag-drop").classList.remove('hidden')
          }
        }
      })
    })
  }
}

export { BtnValiderOrdreForDragDropMascat, BtnValiderOrdreForDragDropMeal, BtnValiderOrdreForDragDropCategory, BtnValiderOrdreForAjaxActifAndInactifBtn };
