import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["advancedForm"]
  connect() {
  }

  toggleAdvancedForm(e) {
    let identifier = e.currentTarget.dataset.code

    let advancedFormTarget = this.advancedFormTargets.find((target) =>
      target.dataset.code === identifier
    )
    advancedFormTarget.classList.toggle('hidden')
  }
}
