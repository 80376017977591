import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["masterCategory", "category", "tabPane"];

  connect() {
    this.lastClickedMasterCat = null;
    this.lastClickedCat = null;
  }

  // On affiche les categories de la master categorie selectionnée et on cache les autres master categories ( se réaffiche si on re-clique sur la même master categorie )
  toggleMasterCategory(event) {
    event.preventDefault();

    document.querySelectorAll('.tab-pane').forEach((elem) => elem.classList.add("hidden"));

    let clickedMasterCat = event.currentTarget.dataset.bsTarget.split("#c")[1].split("all-device")[0];

    this.tabPaneTargets.forEach((elem) => elem.classList.add("hidden"));
    document.querySelectorAll(`li[data-name-master-categ]`).forEach((elem) => elem.closest('ul').classList.add("hidden"));

    if (this.lastClickedMasterCat === clickedMasterCat) {
      this.masterCategoryTargets.forEach((elem) => elem.classList.remove("hidden"));
      let questionContainer = document.querySelector('.content-question-categ-for-js');
      if (questionContainer) questionContainer.classList.add("hidden");
      this.lastClickedMasterCat = null;
    } else {
      this.masterCategoryTargets.forEach((elem) => elem.classList.add("hidden"));
      event.currentTarget.classList.remove("hidden");
      document.querySelectorAll(`[data-name-master-categ='${clickedMasterCat}']`).forEach((elem) => elem.closest('ul').classList.remove("hidden"));
      let questionContainer = document.querySelector('.content-question-categ-for-js');
      if (questionContainer) questionContainer.classList.remove("hidden");
      this.lastClickedMasterCat = clickedMasterCat;
    }
  }

  // On affiche les produits de la categorie selectionnée et on cache les autres categories ( se réaffiche si on re-clique sur la même categorie )
  toggleCategory(event) {
    event.preventDefault();

    const clickedCat = event.currentTarget.closest('li').dataset.nameCateg;
    const hasMasterCategory = event.currentTarget.closest('li').hasAttribute('data-name-master-categ');
    if (hasMasterCategory) {
      const masterCat = event.currentTarget.closest('li').dataset.nameMasterCateg;
      this.tabPaneTargets.forEach((elem) => elem.classList.add("hidden"));

      if (this.lastClickedCat === clickedCat) {
        document.querySelectorAll(`li[data-name-master-categ='${masterCat}']`).forEach((elem) => elem.classList.remove("hidden"));
        document.querySelector(`#c${clickedCat}all-device`).classList.add("hidden");
        this.showCategoryDropdown(clickedCat);
        this.lastClickedCat = null;
      } else {
        document.querySelectorAll(`li[data-name-master-categ='${masterCat}']`).forEach((elem) => elem.classList.add("hidden"));
        event.currentTarget.closest('li').classList.remove("hidden");
        document.querySelector(`#c${clickedCat}all-device`).classList.remove("hidden");
        this.showCategoryDropdown(clickedCat);
        this.lastClickedCat = clickedCat;
      }
    } else {
      if (this.lastClickedCat === clickedCat) {
        document.querySelectorAll(`li[data-name-categ]`).forEach((elem) => elem.classList.remove("hidden"));
        document.querySelectorAll('.tab-pane').forEach((pane) => pane.classList.remove("hidden"));
        document.querySelectorAll(`[data-survey-all-device-target="category"]`).forEach((link) => {
          link.closest('li').classList.remove("active");
        });
        document.querySelector(`#c${clickedCat}all-device`).classList.add("hidden");
        this.showCategoryDropdown(clickedCat);
        this.lastClickedCat = null;
      } else {
        document.querySelectorAll(`li[data-name-categ]`).forEach((elem) => elem.classList.add("hidden"));
        document.querySelectorAll('.tab-pane').forEach((pane) => pane.classList.add("hidden"));
        document.querySelectorAll(`[data-survey-all-device-target="category"]`).forEach((link) => {
          link.closest('li').classList.remove("active");
        });
        event.currentTarget.closest('li').classList.remove("hidden");
        event.currentTarget.closest('li').classList.add("active");
        document.querySelector(`#c${clickedCat}all-device`).classList.remove("hidden");
        this.showCategoryDropdown(clickedCat);
        this.lastClickedCat = clickedCat;
      }
    }

  }

  showCategoryDropdown(categoryName) {
    const categoryDropdown = this.element.querySelector(`select[name='category[${categoryName}]']`);
    if (categoryDropdown) {
      categoryDropdown.parentElement.classList.remove("hidden");
    }
  }
}
