import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['sidebarWrapper', 'close']

  connect() {
    const mainItems = $('.main-item')
    const initalActiveMainItem = $('.main-item.active')
    const nestedItems = $('.sidebar-nested-items')
    const prevMainItem =  nestedItems.prev('li');
    const collapsibleItems = $('.collapsible')

    // Initial sidebar effect.
    this.displaySidebar()
    // If there is a nested item pre-selected when you reach the page.
    this.displayNestedItem()
    // If there is a nested nested item pre-selected when you reach the page.
    this.displayNestedNestedItem()

    // Main handler sidebar interaction effects.
    prevMainItem.click(function() {
      const closestNestedItem = $(this).next('.sidebar-nested-items');
      const nestedItems = $('.sidebar-nested-items')
      mainItems.removeClass('active')
      initalActiveMainItem.addClass('active')

      if (closestNestedItem.hasClass('pulled')) {
        nestedItems.removeClass('pulled')
      } else {
        $(this).addClass('active')
        nestedItems.removeClass('pulled')
        closestNestedItem.addClass('pulled')
      }

      if ($('.sidebar-nested-items.pulled').length == 1) {
        collapsibleItems.removeClass('main-pulled')
      } else {
        collapsibleItems.addClass('main-pulled')
      }
    })
  }

  displaySidebar() {
    const dashboard = document.querySelector('.right-dashboard')
    this.sidebarWrapperTarget.classList.add("hovered");
    dashboard.classList.add("hovered");
  }

  hideSidebar() {
    const dashboard = document.querySelector('.right-dashboard')
    dashboard.classList.remove("hovered");
    this.sidebarWrapperTarget.classList.remove("hovered");
  }

  toggleSidebar() {
    const self = this
    const sidebar = $('#wrapper')
    const closeBtn = this.closeTarget
    const collapsibleItems = $('.collapsible')

    if (closeBtn.classList.contains('close')) {
      closeBtn.classList.remove('close')
      this.hideSidebar()
      collapsibleItems.remove('main-pulled')
      sidebar.hover(
        function() {
          self.displaySidebar()
        },
        function() {
          self.hideSidebar()
        }
      );
    } else {
      closeBtn.classList.add('close')
      sidebar.hover(
        function() {
          self.displaySidebar()
        },
        function() {
          self.displaySidebar()
        }
      );
    }
  }

  displayNestedItem() {
    const activeNestedItem = $('.sidebar-nested-item.active')
    if (activeNestedItem.length > 0) {
      const parentActiveNestedItem = activeNestedItem.closest('.sidebar-nested-items')
      parentActiveNestedItem.addClass('pulled');
    }
  }

  displayNestedNestedItem() {
    const activeNestedNestedItem = $('.sidebar-nested-nested-item.active')
    const nativeActiveNestedNestedItem = activeNestedNestedItem[0]

    if (activeNestedNestedItem.length > 0) {
      const parentActiveNestedItem = activeNestedNestedItem.closest('.sidebar-nested-items')
      activeNestedNestedItem.closest('.sidebar-nested-item').find('.sidebar-nested-nested-item').removeClass('hidden');
      parentActiveNestedItem.removeClass('hidden');
      parentActiveNestedItem.prev().addClass('active')
    }

    if (nativeActiveNestedNestedItem) {
      nativeActiveNestedNestedItem.scrollIntoView({ block: "start", inline: "nearest",behavior: "smooth" });
    }
  }

  // Folding/unfolding nested-nested-items.
  toggleNestedNestedItems(e) {
    const parentElement = e.target.closest(".sidebar-nested-item");
    const childElements = parentElement.querySelectorAll('.sidebar-nested-nested-item')

    if (childElements.length > 0) {
      e.preventDefault(); // Prevent redirection.
      childElements.forEach((item) => {
        item.classList.toggle("hidden");
      });
    }

    if (parentElement) {

      const iconSrc = parentElement.querySelector('.dynamic-icon').src;

      if (iconSrc == "https://aws-picture-gds.s3.eu-west-3.amazonaws.com/arrow-right.svg") {
        const iconSpan = parentElement.querySelector('.dynamic-icon');
        iconSpan.classList.toggle('rotate-icon');
      }
    }
  }
}
