const alertRegexPhone = () => {
    if (document.querySelector('#regex-phone')) {
        const regex_phone_number = new RegExp(/^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/);
        const inputPhone = document.querySelector('input#phone_number');

        inputPhone.addEventListener('keyup', () => {
            if (inputPhone.value.match(regex_phone_number) == null) {
                document.querySelector('.alert-phone-number').classList.remove('hidden');
                document.querySelector('#regex-phone').classList.remove('hidden');
            } else if (inputPhone.value.match(regex_phone_number)) {
                document.querySelector('.alert-phone-number').classList.add('hidden');
                document.querySelector('#regex-phone').classList.add('hidden');
            }
        });
    }
};

export { alertRegexPhone };