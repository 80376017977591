const reponseCommentaireToggleRightTiny = () => {

  if (document.querySelector(".btn-write-email.gestion-mail")) {
    console.log('reponseCommentaireToggleRightTiny')
    const allBtnWriteMail = document.querySelectorAll(".btn-write-email")
    const tinyMail = document.getElementById("tiny-mail-for-js")
    // pour changer l'url action du form_tag pour lui donner le bon customer_id et le bon survey_id
    allBtnWriteMail.forEach((btn) => {
      btn.addEventListener('click', (event) => {
        const params = btn.dataset.urlAction
        tinyMail.classList.remove("hidden")
        btn.classList.add("hidden")
        if  (tinyMail.firstElementChild.action.includes("?customer_id")) {
          let url = tinyMail.firstElementChild.action
          let urlNoParams = url.slice(0, url.indexOf("?customer_id"))
          tinyMail.firstElementChild.action = `${urlNoParams}${params}`
        } else {
          tinyMail.firstElementChild.action = `${tinyMail.firstElementChild.action}${params}`
        }

      })
    })

    const contentCardAnswers = document.querySelectorAll(".content-card-answer");
    contentCardAnswers.forEach((remarque) => {
      remarque.addEventListener('click', (event) => {
        const groupSelectedAnswers = document.querySelector('.selected-answers.active')
        if (groupSelectedAnswers) {
          groupSelectedAnswers.classList.remove('active')
        }
      })
    })

    // pour hidden le tiny si on appuie sur un nouveau commentaire, et réafficher le btn "Ecrire un mail"
    const allRemarques = document.querySelectorAll(".all-remarques")
    allRemarques.forEach((btn_remarque) => {
      btn_remarque.addEventListener('click', (event) => {
        tinyMail.classList.add("hidden")
        allBtnWriteMail.forEach((btn) => {
          if (btn.classList.value.includes("hidden")) {
            btn.classList.remove("hidden")
          }
        })
      })
    })

    const allCheckBoxes = document.querySelectorAll('[name = "all_customer_ids[]"]')
    allCheckBoxes.forEach(checkbox => {
      checkbox.addEventListener('click', () => {
        //Empty all elements inside the div
        removeAllChildNodes(document.querySelector('.selected-answers'))

        let closestRemarque = checkbox.closest('.all-remarques')
        let checkedCheckBoxes = [...allCheckBoxes].filter(checkbox => checkbox.checked)

        /**
         * On enlève la bordure bleue sur le comment
         */
        if (checkedCheckBoxes.length == 0) {
          [...allRemarques].map(remarque => remarque.classList.remove('active'))
          if (document.querySelector('.all-info-on-survey-js.active')) {
            document.querySelector('.all-info-on-survey-js.active').classList.remove('active')
          }
          if (document.querySelector('.selected-answers.active')) {
            document.querySelector('.selected-answers').classList.remove('active')
          }
        }

        //toutes les divs que l'ont veut afficher à droite
        let allCheckedCommentDiv = checkedCheckBoxes.map(check => check.nextElementSibling.querySelector('.content-card-answer'))
        const button = document.querySelector('.gestion-mail-multi')

        let clone;
        let arr = [];
        allCheckedCommentDiv.forEach( div => {
          clone = div.cloneNode(true),
          arr.push(clone)
        })

        const buttonParams = document.querySelector('.gestion-mail-multi')
        const allCustomerIds = checkedCheckBoxes.map(check => check.value)
        const allSurveyIds = checkedCheckBoxes.map(check => check.dataset.surveyId)
        buttonParams.dataset.urlAction = `?all_customer_ids=${allCustomerIds.join(',')}&all_survey_ids=${allSurveyIds.join(',')}`

        /**
         * Ajout du front checked si cochée
         */

        if (checkbox.checked) {
          console.log('jai coché une checkbox')
          closestRemarque.classList.add('checked')
          // On enlève la partie de droite
          if (document.querySelector('.all-info-on-survey-js.active')) {
            document.querySelector('.all-info-on-survey-js.active').classList.remove('active')
            document.querySelector('.all-remarques > a.active').classList.remove('active')
          }
          // on affiche les comments
          document.querySelector('.selected-answers').classList.add('active')
          arr.forEach( a => document.querySelector('.selected-answers').insertBefore(a, button))
        } else {
          closestRemarque.classList.remove('checked')
          arr.forEach( a => document.querySelector('.selected-answers').insertBefore(a, button))
        }
      })
    })

  }

}

function removeAllChildNodes(parent) {
  while (parent.firstChild && parent.firstChild.tagName != 'BUTTON') {
    parent.removeChild(parent.firstChild);
  }
}

export {reponseCommentaireToggleRightTiny};
