import {escapeSelector} from '../ultilities'

export function chartRankingDistrib() {

  const rankColors = {
    1: '#6C6EA0',
    2: '#66C7F4',
    3: 'E7CFBC',
    4: '#86BBBD',
    5: '#544E61',
    6: '#85BAA1',
    7: '#76949F',
    8: '#CEEDDB',
    9: '#F9B5AC',
    10: '#F9B5AC',
    11: '#D7CDCC',
    12: '#59656F',
    "not_chosen": '#ddd'
  };

  	const dateTag = document.getElementById("date");
    const convertDate = (date) => {
      let newDate = date
      if (newDate == date) {
        newDate = date.split('.').reverse().join('.');
      }
      return newDate

    };

    const splitLabel = (label, maxLength) => {
      if (label.length <= maxLength) return [label];
      const words = label.split(' ');
      const lines = [];
      let currentLine = '';

      words.forEach(word => {
        if (currentLine.length + word.length + 1 <= maxLength) {
          currentLine += (currentLine ? ' ' : '') + word;
        } else {
          lines.push(currentLine);
          currentLine = word;
        }
      });

      if (currentLine) lines.push(currentLine);
      return lines;
    };

    const locale = window.location.href.match(/\/en\//) ? 'en' : 'fr'
    if (dateTag) {

      let date = new Date(convertDate(dateTag.value));

      console.log(date)

      // Récuperer la classe active (semaine, jour, mois, etc.)
      let locale = "fr"
      let format = "cumul"
      if (document.querySelector(".nav-filter")) {
        locale = document.querySelector(".nav-filter").dataset.locale
      }
      if (document.getElementById("date-data")) {
        format = document.getElementById("date-data").dataset.timeframe
      }
      let labelUn = "test"
      let labelDeux = "test"
      console.log(format)

      if (locale == "fr") {
        if (format === "day") {
          labelUn = "Aujourd'hui ";
          labelDeux = "Hier ";
        }
        else if (format === "week") {
          labelUn = "Cette semaine ";
          labelDeux = "La semaine dernière ";
        }
        else if (format === "month") {
          labelUn = "Ce mois-ci ";
          labelDeux = "Le mois dernier ";
        }
        else if (format === "year") {
          labelUn = "Cette année ";
          labelDeux = "L'année dernière ";
        }
        else if (format === "cumul") {
          labelUn = "Cumul ";
          labelDeux = " ";
        }
        else {
          labelUn = "Cette periode";
          labelDeux = "Periode précédente";
        }
      } else if (locale == "en") {
        if (format === "day") {
          labelUn = "Today ";
          labelDeux = "Yesterday ";
        }
        else if (format === "week") {
          labelUn = "This week ";
          labelDeux = "Last week ";
        }
        else if (format === "month") {
          labelUn = "This month ";
          labelDeux = "Last month ";
        }
        else if (format === "year") {
          labelUn = "This year ";
          labelDeux = "Last year ";
        }
        else if (format === "cumul") {
          labelUn = "Cumul ";
          labelDeux = " ";
        }
        else {
          labelUn = "Cette periode";
          labelDeux = "Periode précédente";
        }
      }
    }

  const classementScoreGq = document.querySelectorAll(".classement-score-gq");

		Array.prototype.forEach.call(classementScoreGq, function (classementScore) {
			if (classementScore) {
				const searchBy = escapeSelector(classementScore.dataset.searchBy);
				const data = JSON.parse(JSON.parse(document.querySelector(`.translated-tags-${searchBy}-score`).textContent.trim()));
				console.log("score data: ", data);
				const labels = Object.keys(data).map(tag => {
					if (tag.length > 30) {
						return tag.substring(0, 30) + '...';
					}
					return tag;
				});
				const fullLabels = Object.keys(data); // For tooltips

				// Load images from external URLs
				const images = {};
				let hasPictures = false;
				const promises = Object.keys(data).map(label => {
					return new Promise((resolve, reject) => {
						if (data[label].picture) {
							hasPictures = true;
							const img = new Image();
							img.src = data[label].picture;
							img.onload = () => {
								images[label] = img;
								resolve();
							};
							img.onerror = reject;
						} else {
							resolve(); // Resolve immediately if no picture
						}
					});
				});
				Promise.all(promises).then(() => {
					const classement_score_question_gq = new Chart(classementScore, {
						type: 'horizontalBar',
						plugins: [{
							afterDraw: chart => {
								if (!hasPictures) return; // Only draw images if they exist
								const ctx = chart.chart.ctx;
								const xAxis = chart.scales['x-axis-0'];
								const yAxis = chart.scales['y-axis-0'];
								// const imageHeight = window.innerHeight * 0.06; // 6vh in pixels - same as the size of the images in the tag questions' dashboards
								const barHeight = (chart.chartArea.bottom - chart.chartArea.top) / chart.data.labels.length; // Calculate bar height dynamically
            		const imageHeight = barHeight * 0.8; // Set image height to 80% of the bar height
								const padding = 0; // Padding between images
								chart.data.labels.forEach((label, index) => {
									const img = images[fullLabels[index]];
									if (img) {
										const y = yAxis.getPixelForTick(index) - imageHeight / 2 + (padding * index); // centers the image vertically
										const aspectRatio = img.width / img.height;
										const imageWidth = imageHeight * aspectRatio;
										const x = xAxis.left - imageWidth - 10; // vertical position -> +: move left | -: move right
										ctx.drawImage(img, x, y, imageWidth, imageHeight); // img draw here
										ctx.textAlign = 'left';
										ctx.textBaseline = 'middle';
										// ctx.fillText(labels[index], x + imageWidth + 5, y + imageHeight / 2); // text position here
									} else { // no image, just text
										const y = yAxis.getPixelForTick(index);
										const x = xAxis.left - 40; // text position here
										ctx.textAlign = 'left';
										ctx.textBaseline = 'middle';
										ctx.fillText(labels[index], x, y);
									}
								});
							}
						}],
						data: {
							labels: hasPictures ? labels.map(_label => ("")) : labels,
							datasets: [{
								data: fullLabels.map(tag => data[tag]?.percentage || 0),
								backgroundColor: '#5d78ff',
								hoverBackgroundColor: '#5d78ff',
								borderWidth: 1,
							}]
						},
						options: {
							layout: {
								padding: {
									left: hasPictures ? 140 : 0, // more space in the left of the charts for the pictures
									right: 0,
									top: 0,
									bottom: 0
								}
							},
							legend: {
								display: false,
							},
							plugins: {
								datalabels: {
									display: true, // Ensure data labels are displayed
									anchor: 'end',
									align: function (context) {
										const max_value = context.dataset.data[0];
										const percent_from_max = (context.dataset.data[context.dataIndex] * 100) / max_value ;

										return percent_from_max < 80 ? 'end' : 'start';
									},
									color: function (context) {
										const max_value = context.dataset.data[0];
										const percent_from_max = (context.dataset.data[context.dataIndex] * 100) / max_value ;
										return percent_from_max < 80 ? '#5d78ff' : 'white';
									},
									formatter: function (value, context) {
										return `${context.dataIndex + 1}`; // Display the rank based on the index
									},
								}
							},
							tooltips: {
								callbacks: {
									title: function (tooltipItems, data) {
										const idx = tooltipItems[0].index;
										const label = fullLabels[idx];
										return splitLabel(label, 50); // Split label into multiple lines with a max length of 50 characters per line
									},
									label: function (tooltipItem, data) {
										const rank = tooltipItem.index + 1;
										return locale == "fr" ? 'Rang ' + rank : 'Rank ' + rank; // Return the rank
									},
								},
							},
							scales: {
								xAxes: [{
									display: false, // Hide x-axis scale
									gridLines: {
										display: false
									},
								}],
								yAxes: [{
									gridLines: {
										display: false
									},
									ticks: {
										callback: function(value) {
											return (value.length < 45 ? value : value.substr(0, 45) + '...');
										},
									}
								}]
							}
						}
					});
					classement_score_question_gq.update();
					let chart = classement_score_question_gq;
					chart.options.animation = false;
				});

			}
		});



    const classementDistributionGq = document.querySelectorAll(".classement-distribution-gq");

		Array.prototype.forEach.call(classementDistributionGq, function (classementDistribution) {
			if (classementDistribution) {
				const searchBy = escapeSelector(classementDistribution.dataset.searchBy);
				const data = JSON.parse(JSON.parse(document.querySelector(`.translated-tags-${searchBy}-distribution`).textContent.trim()));
				console.log("distribution data:", data);
				const labels = Object.keys(data).map(tag => {
					if (tag.length > 30) {
						return tag.substring(0, 30) + '...';
					}
					return tag;
				});
				const fullLabels = Object.keys(data); // For tooltips

				const datasets = [];
				Object.keys(rankColors).forEach(rank => {
					const rankData = fullLabels.map(tag => data[tag]?.ranks[rank]?.percentage || 0);
					const hasData = rankData.some(percentage => percentage > 0);

					if (hasData) {
						let label = "";
						if (rank == "not_chosen") {
							label = locale == 'fr' ? "Non choisi" : "Not chosen";
						} else {
							label = locale == 'fr' ? `Rang ${rank}` : `Rank ${rank}`;
						}

						console.log("rankData", rankData)
						const dataset = {
							label: label,
							data: rankData,
							backgroundColor: rankColors[rank],
							stack: 'Stack 0'
						};
						datasets.push(dataset);
					}
				});

				// Load images from external URLs
				const images = {};
				let hasPictures = false;
				const promises = Object.keys(data).map(label => {
					return new Promise((resolve, reject) => {
						if (data[label].picture) {
							hasPictures = true;
							const img = new Image();
							img.src = data[label].picture;
							img.onload = () => {
								images[label] = img;
								resolve();
							};
							img.onerror = reject;
						} else {
							resolve(); // Resolve immediately if no picture
						}
					});
				});

				// Custom plugin to draw images inside the labels
				// const imagePlugin = {
				// 	afterDraw: function(chart) {
				// 		if (!hasPictures) return; // Only draw images if they exist
				// 		const ctx = chart.ctx;
				// 		chart.data.labels.forEach((label, index) => {
				// 			const img = images[fullLabels[index]];
				// 			const y = chart.scales['y-axis-0'].getPixelForTick(index) - 10; // Adjust the y position
				// 			const x = chart.scales['x-axis-0'].left - 30; // Adjust the x position
				// 			if (img) {
				// 				ctx.drawImage(img, x, y, 100, 100); // Adjust the size and position
				// 				ctx.fillText(labels[index], x + 30, y + 12); // Adjust the text position
				// 			} else {
				// 				ctx.fillText(labels[index], x, y + 12); // Adjust the text position
				// 			}
				// 		});
				// 	}
				// };

				// // Register the plugin
				// if (hasPictures) {
				// 	Chart.plugins.register(imagePlugin);
				// }

				// Wait for all images to load before creating the chart
				Promise.all(promises).then(() => {
					console.log("hasPictures", hasPictures)
					const classement_distribution_question_gq = new Chart(classementDistribution, {
						type: 'horizontalBar',
						plugins: [{
							afterDraw: chart => {
								if (!hasPictures) return; // Only draw images if they exist
								const ctx = chart.chart.ctx;
								const xAxis = chart.scales['x-axis-0'];
								const yAxis = chart.scales['left-y-axis'];
								// const imageHeight = window.innerHeight * 0.06; // 6vh in pixels - same as the size of the images in the tag questions' dashboards
								const barHeight = (chart.chartArea.bottom - chart.chartArea.top) / chart.data.labels.length; // Calculate bar height dynamically
            		const imageHeight = barHeight * 0.8; // Set image height to 80% of the bar height
								const padding = 0; // Padding between images
								chart.data.labels.forEach((label, index) => {
									const img = images[fullLabels[index]];
									if (img) {
										const y = yAxis.getPixelForTick(index) - imageHeight / 2 + (padding * index); // centers the image vertically
										const aspectRatio = img.width / img.height;
										const imageWidth = imageHeight * aspectRatio;
										const x = xAxis.left - imageWidth; // vertical position -> -: move left | +: move right
										ctx.drawImage(img, x, y, imageWidth, imageHeight); // img draw here
										ctx.textAlign = 'left';
										ctx.textBaseline = 'middle';
										// ctx.fillText(labels[index], x + imageWidth + 5, y + imageHeight / 2); // text position here
									} else { // no image, just text
										const y = yAxis.getPixelForTick(index);
										const x = xAxis.left - 0; // text position here
										ctx.textAlign = 'left';
										ctx.textBaseline = 'middle';
										ctx.fillText(labels[index], x, y);
									}
								});
							}
						}],
						data: {
							labels: hasPictures ? labels.map(_label => ("")) : labels,
							datasets: datasets
						},
						options: {
							layout: {
								padding: {
									left: hasPictures ? 130 : 0, // more space in the left of the charts for the pictures
									right: 0,
									top: 0,
									bottom: 0
								},
							},
							indexAxis: 'y',
							elements: {
								bar: {
									borderWidth: 1,
								}
							},
							maintainAspectRatio: false,
							plugins: {
								datalabels: {
									formatter: function (value, ctx) {
										let percentage = Math.round(value) + "%";
										return percentage;
									},
									color: '#fff',
									display: function (context) {
										return context.dataset.data[context.dataIndex] > 5;
									}
								}
							},
							tooltips: {
								callbacks: {
									title: function (tooltipItems, data) {
										const idx = tooltipItems[0].index;
										const label = fullLabels[idx];
										return splitLabel(label, 50); // Split label into multiple lines with a max length of 50 characters per line
									},
									label: function (tooltipItem, data) {
										const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
										const value = Math.round(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]) || 0;
										if (value !== 0) {
											return datasetLabel + ': ' + value + '%';
										}
									},
								},
							},
							legend: {
								display: true,
								position: 'bottom',
							},
							scales: {
								xAxes: [{
									gridLines: {
										display: false,
									},
									stacked: true,
									offset: true,
									ticks: {
										min: 0,
										max: 100,
									},
									barThickness: 4,
									barPercentage: 0.8,
									categoryPercentage: 0.9, // Adjust as needed
								}],
								yAxes: [{
									id: 'left-y-axis',
									ticks: {
										display: true,
										callback: function (value, index, values) {
											if (value.length > 30) {
												return value.substring(0, 30) + '...';
											}
											return value;
										}
									},
									stacked: true,
									offset: true,
									gridLines: {
										display: false,
									}
								}]
							},
						}
					});

					classement_distribution_question_gq.update();
					let chart = classement_distribution_question_gq;
					chart.options.animation = false;
				}).catch(error => {
					console.error('Failed to load images:', error);
				});
			}
		});

}
