import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

export default class extends Controller {
  static values = { downloadId: Number }

  connect() {
    this.channel = createConsumer().subscriptions.create(
      { channel: "DownloadChannel", id: this.downloadIdValue },
      { received: data => {
        // enlever téléchargement et remettre télécharger
        const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
        const downloadButton = document.getElementById(data.response.id)
        if (downloadButton) {
          // downloadButton.parentNode.classList.remove("show-loading-dots")
          downloadButton.innerHTML = `${mainDiv.dataset.download}`
          downloadButton.disabled = false
        }
        // créer l'url
        let url = "get_file/" + data.response.file + "/" + data.response.user
        // chercher le fichier
        window.location.href = url

      }}
    );


    function getDownloadBtnById(btnId) {
      return new Promise((resolve) => {
        setTimeout(() => {
          const downloadBtn = document.getElementById(btnId);
          resolve(downloadBtn);
        }, 50);
      });
    }

    function downloadingIcone(btn) {
      if(btn) {
        btn.addEventListener("click", () => {
          // chopper l'element sur lequel data-controller est mis, this.element ne marche pas
          const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
          btn.innerHTML = `<i class="fa-solid fa-download fa-beat-fade" style="color: #63E6BE;"></i>&nbsp <i class="ms-1"style="color: #63E6BE;">${mainDiv.dataset.downloading}</i>`;
          // attente pour que le requette ajax se fasse
          setTimeout(() => {
            btn.disabled = true;
          }, 50);
        });
      }
    }

    function showToast(btn) {
      if(btn) {
        btn.addEventListener("click", ()=>{
          const toastDiv = document.getElementById("toast-box")
          const mainDiv = document.querySelector(`[data-controller="download-subscription"]`);
          if (toastDiv.innerHTML.trim() === "") {
            let toast = document.createElement('div');
            toast.classList.add('toast_custom');
            toast.innerHTML = `<i class="fa-solid fa-download"></i><h5>${mainDiv.dataset.toastWait}</h5>`;
            toastDiv.appendChild(toast);
            setTimeout(() => {
              toast.remove()
            }, 3000);
          }
        })
      }
    }

    getDownloadBtnById("btn-download-report").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("btn-download-group-meal").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("base-client-btn").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("rgpd-btn").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("btn-download-group-meal-resto-price").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("download-question-enquetes-btn").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("download-group-meal-resto-admin-btn").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("download-enquetes-multisites-btn").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });

    getDownloadBtnById("download-enquetes-return-rate-btn").then((downloadBtn) => {
      downloadingIcone(downloadBtn);
      showToast(downloadBtn);
    });
  }
}
