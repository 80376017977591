// JS FOR survey edit and edit_reclam on btns cgu and popup sweetAlert
const alertEmail = () => {
  const email_checked_page = document.querySelector(".clients-info");
  // Verrue Old Wild West
  if (email_checked_page) {
    console.log("alertEmail")

    const english_oui_non = window.location.href.includes("/en/")
    const italian_oui_non = window.location.href.includes("/it/")
    const arabic_oui_non = window.location.href.includes("/ar/")
    const russia_oui_non = window.location.href.includes("/ru/")
    const portuguese_oui_non = window.location.href.includes("/pt/")
    const brazilian_oui_non = window.location.href.includes("/pt_br/")
    const kazakh_oui_non = window.location.href.includes("/kz/")
    const espagnol_oui_non = window.location.href.includes("/es/")
    const deutsch_oui_non = window.location.href.includes("/de/")
    const mongolian_oui_non = window.location.href.includes("/mn/")
    const resto = document.getElementById("page-edit-get-resto-id").dataset.currentContent
    const group = document.getElementById("page-edit-get-group-id").dataset.currentContent
    console.log(english_oui_non)
    let message1 = ""
    let message2 = ""
    let message3 = ""
    let message4 = ""
    let message5 = ""
    let message6 = ""
    let cancel = ""
    let validate = ""
		let containerClass = ""
    if (english_oui_non) {
      message1 = "Please note, you will not be able to receive your reward if you do not enter your email address."
      message2 = "Please note that you will not be able to receive your reward if you do not confirm the specific conditions of use."
      message3 = "Please note, we will not be able to count the item on your loyalty card if you do not enter your email address."
      message4 = "Please note, we will not be able to count the point on your loyalty card if you do not confirm the specific conditions of use."
      message5 = "Please note, if you do not leave your email address, we will not be able to send you our offers and communications."
      message6 = "Please note, without validation of the specific conditions, we will not be able to send you our offers and communication."
      cancel = "Return to the page"
      validate = "Proceed anyway"
    } else if (italian_oui_non) {
        message1 = "Tieni presente che non potrai ricevere il tuo premio se non inserisci il tuo indirizzo email."
        message2 = "Tieni presente che non potrai ricevere il tuo premio se non confermi le condizioni speciali di utilizzo."
        message3 = "Tieni presente che non saremo in grado di aggiungere il punto alla tua carta fedeltà se non inserisci il tuo indirizzo email."
        message4 = "Tieni presente che non saremo in grado di aggiungere il punto alla tua carta fedeltà se non confermi le condizioni speciali di utilizzo."
        message5 = "Ti ricordiamo che se non lasci il tuo indirizzo email non potremo inviarti le nostre offerte e comunicazioni."
        message6 = "Ti ricordiamo che se non convalidi le condizioni speciali, non potremo inviarti le nostre offerte e comunicazioni."
        cancel = "Torna alla pagina"
        validate = "Convalida comunque"
    } else if (arabic_oui_non) {
      message1 = "يرجى ملاحظة أنك لن تتمكن من استلام مكافأتك إذا لم تقم بإدخال عنوان بريدك الإلكتروني."
      message2 = "يرجى ملاحظة أنك لن تتمكن من استلام مكافأتك إذا لم تؤكد شروط الاستخدام الخاصة."
      message3 = "يرجى ملاحظة أننا لن نتمكن من إضافة النقطة إلى بطاقة الولاء الخاصة بك إذا لم تقم بإدخال عنوان بريدك الإلكتروني."
      message4 = "يرجى ملاحظة أننا لن نتمكن من إضافة النقطة إلى بطاقة الولاء الخاصة بك إذا لم تؤكد شروط الاستخدام الخاصة."
      message5 = "يرجى الانتباه، إذا لم تترك عنوان بريدك الإلكتروني، فلن نتمكن من إرسال عروضنا ورسائلنا إليك."
      message6 = "يُرجى ملاحظة أنه إذا لم تتحقق من صحة الشروط الخاصة ، فلن نتمكن من إرسال عروضنا واتصالاتنا إليك."
      cancel = "العودة إلى الصفحة"
      validate = "المضي قدما على أي حال"
			containerClass = "ar-font-style"
    } else if (russia_oui_non) {
      message1 = "Обратите внимание, что вы не сможете получить вознаграждение, если не укажете свой адрес электронной почты."
      message2 = "Обратите внимание, что вы не сможете получить свое вознаграждение, если не подтвердите особые условия использования."
      message3 = "Обратите внимание, что мы не сможем добавить балл на вашу карту лояльности, если вы не укажете свой адрес электронной почты."
      message4 = "Обратите внимание, что мы не сможем добавить балл на вашу карту лояльности, если вы не подтвердите особые условия использования."
      message5 = "Обратите внимание, если вы не оставите свой адрес электронной почты, мы не сможем отправлять вам наши предложения и сообщения."
      message6 = "Внимание, если вы не подтвердите особые условия, мы не сможем отправлять вам наши предложения и сообщения."
      cancel = "Вернуться на страницу"
      validate = "Все равно продолжайте"
    } else if (portuguese_oui_non) {
      message1 = "Observe que você não poderá receber sua recompensa se não fornecer seu endereço de e-mail."
      message2 = "Observe que você não poderá receber sua recompensa se não confirmar as condições especiais de uso."
      message3 = "Observe que não poderemos adicionar o ponto ao seu cartão de fidelidade se você não fornecer seu endereço de e-mail."
      message4 = "Observe que não poderemos adicionar o ponto ao seu cartão de fidelidade se você não confirmar as condições especiais de uso."
      message5 = "Por favor note que, se não deixar o seu e-mail, não poderemos lhe enviar as nossas ofertas e comunicações."
      message6 = "Atenção, se não validar as condições especiais, não poderemos enviar-lhe as nossas ofertas e comunicações."
      cancel = "Voltar à página"
      validate = "Continue mesmo assim"
    } else if (brazilian_oui_non) {
      message1 = "Observe que você não poderá receber sua recompensa se não fornecer seu endereço de e-mail."
      message2 = "Observe que você não poderá receber sua recompensa se não confirmar as condições especiais de uso."
      message3 = "Observe que não poderemos adicionar o ponto ao seu cartão de fidelidade se você não fornecer seu endereço de e-mail."
      message4 = "Observe que não poderemos adicionar o ponto ao seu cartão de fidelidade se você não confirmar as condições especiais de uso."
      message5 = "Por favor note que, se não deixar o seu e-mail, não poderemos enviar-lhe as nossas ofertas e comunicações."
      message6 = "Atenção, se não validar as condições especiais, não poderemos enviar-lhe as nossas ofertas e comunicações."
      cancel = "Voltar para a página"
      validate = "Continue mesmo assim"
    } else if (kazakh_oui_non) {
      message1 = "Электрондық пошта мекенжайыңызды көрсетпесеңіз, сыйақыны ала алмайтыныңызды ескеріңіз."
      message2 = "Пайдаланудың ерекше шарттарын растамасаңыз, сыйлығыңызды ала алмайтыныңызды ескеріңіз."
      message3 = "Электрондық пошта мекенжайыңызды көрсетпесеңіз, біз ұпайды адалдық картаңызға қоса алмайтынымызды ескеріңіз."
      message4 = "Пайдаланудың ерекше шарттарын растамасаңыз, біз сіздің адалдық картаңызға ұпай қоса алмайтынымызды ескеріңіз."
      message5 = "Электрондық пошта адресіңізді қалдырмасаңыз, біз сізге ұсыныстарымыз бен хабарламаларымызды жібере алмайтынымызды ескеріңіз."
      message6 = "Назар аударыңыз, егер сіз арнайы шарттарды растамасаңыз, біз сізге ұсыныстарымыз бен хабарламаларымызды жібере алмаймыз."
      cancel = "Бетке қайта оралу"
      validate = "Бәрібір жалғастырыңыз"
    } else if (espagnol_oui_non) {
      message1 = "Tenga en cuenta que no podrá recibir su recompensa si no proporciona su dirección de correo electrónico."
      message2 = "Tenga en cuenta que no podrá recibir su recompensa si no confirma las condiciones especiales de uso."
      message3 = "Tenga en cuenta que no podremos agregar el punto a su tarjeta de fidelización si no proporciona su dirección de correo electrónico."
      message4 = "Tenga en cuenta que no podremos añadir el punto a su tarjeta de fidelización si no confirma las condiciones especiales de uso."
      message5 = "Por favor, tenga en cuenta que si no deja su correo electrónico, no podremos enviarle nuestras ofertas y comunicaciones."
      message6 = "Atención, si no validas las condiciones particulares, no podremos enviarte nuestras ofertas y comunicaciones."
      cancel = "Volver a la pagina"
      validate = "Procede de todas maneras"
    } else if (deutsch_oui_non) {
      message1 = "Aufmerksamkeit, dass Sie Ihre Prämie nicht erhalten können, wenn Sie Ihre E-Mail-Adresse nicht angeben."
      message2 = "Aufmerksamkeit, dass Sie Ihre Prämie nicht erhalten können, wenn Sie die besonderen Nutzungsbedingungen nicht bestätigen."
      message3 = "Aufmerksamkeit, dass wir den Artikel nicht auf Ihrer Kundenkarte zählen können, wenn Sie Ihre E-Mail-Adresse nicht eingeben."
      message4 = "Aufmerksamkeit, dass wir den Punkt Ihrer Treuekarte nicht anrechnen können, wenn Sie die spezifischen Nutzungsbedingungen nicht bestätigen."
      message5 = "Aufmerksamkeit, dass wir Ihnen unsere Angebote und Mitteilungen nicht zusenden können, wenn Sie Ihre E-Mail-Adresse nicht hinterlassen."
      message6 = "Aufmerksamkeit, wenn Sie die spezifischen Bedingungen nicht bestätigen, können wir Ihnen unsere Angebote und Mitteilungen nicht zusenden."
      cancel = "Zurück zur Seite"
      validate = "Bestätigen Sie trotzdem"
    } else if (mongolian_oui_non) {
      message1 = "Хэрэв та имэйл хаягаа оруулаагүй бол шагналаа авах боломжгүй болохыг анхаарна уу."
      message2 = "Ашиглалтын тусгай нөхцлийг баталгаажуулаагүй тохиолдолд шагналаа авах боломжгүй гэдгийг анхаарна уу."
      message3 = "Хэрэв та имэйл хаягаа оруулахгүй бол бид таны үнэнч хэрэглэгчийн картанд оноо нэмэх боломжгүй гэдгийг анхаарна уу."
      message4 = "Хэрэв та ашиглалтын тусгай нөхцлийг баталгаажуулаагүй бол бид таны үнэнч картанд оноо нэмэх боломжгүй гэдгийг анхаарна уу."
      message5 = "Хэрэв та и-мэйл хаягаа үлдээхгүй бол бид танд төрөл бүрийн саналууд болон мэдээллүүд хүргэж чадахгүйг анхаарна уу."
      message6 = "Анхаарна уу, хэрэв та тусгай нөхцөлийг баталгаажуулаагүй бол бид танд санал, харилцаа холбоогоо илгээх боломжгүй болно."
      cancel = "Хуудас руу буцах"
      validate = "Үргэлжлүүлэх"
    } else {
      message1 = "Attention, vous ne pourrez pas recevoir votre récompense si vous ne renseignez pas votre adresse email."
      message2 = "Attention, vous ne pourrez pas recevoir votre récompense si vous ne confirmez pas les conditions particulières d'utilisation."
      message3 = "Attention, nous ne pourrons pas comptabiliser le point sur votre carte de fidélité si vous ne renseignez pas votre adresse email."
      message4 = "Attention, nous ne pourrons pas comptabiliser le point sur votre carte de fidélité si vous ne confirmez pas les conditions particulières d'utilisation."
      message5 = "Attention, si vous ne laissez pas votre adresse email, nous ne serons pas en mesure de vous envoyer nos offres et communications."
      message6 = "Attention, si vous ne validez pas les conditions particulières, nous ne pourrons pas vous envoyer nos offres et communications."
      cancel = "Revenir sur la page"
      validate = "Continuer quand même"
    }

      console.log(containerClass)
      console.log("edit survey from mobile device")
      const email_checked = document.querySelector(".clients-info");
      const email  = email_checked.querySelectorAll("input")[0];
      const first_name_c = document.querySelector("#first_name");
      const last_name_c = document.querySelector("#last_name");
      const phone_number = document.querySelector("#phone_number");
      const regex_email = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const cross = document.querySelector("#transparent");
      const text  = document.querySelector(".transparent-text");
      const cgu_style = document.querySelectorAll('.cgu-js input[type="checkbox"] + label')[0];
      const cgu = document.querySelectorAll('input[type="checkbox"]')[0];
      const actu = document.querySelectorAll('input[type="checkbox"]')[1];
      const off = document.querySelectorAll('input[type="checkbox"]')[2];

      email.addEventListener("keyup", () => {
        if (email.value === '') {
          cross.style.color = 'transparent';
          text.style.color = 'transparent';
          email.classList.remove("false");
        }
        else if (email.value.match(regex_email) == null) {
          email.classList.add("false");
          cross.style.color = '#fd397a';
          text.style.color = '#fd397a';
        }
        else {
          cross.style.color = 'transparent';
          text.style.color = 'transparent';
          email.classList.remove("false");
        }
      })


      cgu.addEventListener('click', (event) => {
        if (!cgu.checked && (actu.checked || off.checked )) {
          actu.checked = false
          off.checked = false
        }
      })
      actu.addEventListener('click', (event) => {
        if (!cgu.checked && actu.checked) {
          cgu.checked = true
        }
      })
      off.addEventListener('click', (event) => {
        if (!cgu.checked && off.checked) {
          cgu.checked = true
        }
      })

      // SI C'EST UNE RECOMPENSE DIRECTE
      if (document.querySelector(".btn-survey-validate-end.validate_btn_no_reward.final-btn-survey-mobile.directe")) {
        const btnNoRewardDirecte = document.querySelector(".btn-survey-validate-end.validate_btn_no_reward.final-btn-survey-mobile.directe").firstElementChild
        const btnWithRewardDirecte = document.querySelector(".btn-survey-validate-end.validate_btn_to_get_reward.final-btn-survey-mobile.directe").firstElementChild
        const form = document.querySelector(".survey-end-title.survey-end-title-mobile:not(.no-reward)").firstElementChild

        // listener sur le btn "valider sans récompense"
        btnNoRewardDirecte.addEventListener('click', (event) => {

          if (!email.value.match(regex_email) && group != '750') {
            btnNoRewardDirecte.disabled = true

            Swal.fire({
						customClass: {
							container: `alert-no-email`,
              icon: `alert-no-email-icon`
						},
             html: `${message1}`,
             icon: "warning",
             allowOutsideClick: false,
             showCancelButton: true,
             confirmButtonText: `${cancel}`,
             cancelButtonText: `${validate}`,
             closeOnConfirm: false,
             closeOnCancel: false
            }).then(function(value){
              console.log(value.dimiss)
              if(value.dismiss){
                form.submit();
              } else {
                btnNoRewardDirecte.disabled = false
              }
            });
              event.preventDefault();
          }

        });

        // listener sur le btn "valider avec récompense"
        btnWithRewardDirecte.addEventListener('click', (event) => {

          if (email.value.match(regex_email) && !cgu.checked && group != '750') {
            btnWithRewardDirecte.disabled = true

            Swal.fire({
						customClass: {
							container: `alert-no-email`,
              icon: `alert-no-email-icon`
						},
             html: `${message2}`,
             icon: "warning",
             allowOutsideClick: false,
             showCancelButton: true,
             confirmButtonText: `${cancel}`,
             cancelButtonText: `${validate}`,
             closeOnConfirm: false,
             closeOnCancel: false
            }).then(function(value){
              if(value.dismiss){
                form.submit();
              } else {
                btnWithRewardDirecte.disabled = false
              }
            });
              event.preventDefault();
          }

        })

      }

      // SI C'EST UNE CARTE DE FIDELITE
      if (document.querySelector(".btn-survey-validate-end.validate_btn_no_reward.final-btn-survey-mobile.carte-fid")) {
        const btnNoRewardFid = document.querySelector(".btn-survey-validate-end.validate_btn_no_reward.final-btn-survey-mobile.carte-fid").firstElementChild
        const btnWithRewardFid = document.querySelector(".btn-survey-validate-end.validate_btn_to_get_reward.final-btn-survey-mobile.carte-fid").firstElementChild
        const form = document.querySelector(".survey-end-title.survey-end-title-mobile:not(.no-reward)").firstElementChild

        // listener sur le btn "valider sans point"
        btnNoRewardFid.addEventListener('click', (event) => {

          if (!email.value.match(regex_email)) {
            btnNoRewardFid.disabled = true

            Swal.fire({
						customClass: {
							container: `alert-no-email`,
              icon: `alert-no-email-icon`
						},
             html: `${message3}`,
             icon: "warning",
             allowOutsideClick: false,
             showCancelButton: true,
             confirmButtonText: `${cancel}`,
             cancelButtonText: `${validate}`,
             closeOnConfirm: false,
             closeOnCancel: false
            }).then(function(value){
              if(value.dismiss){
                form.submit();
              } else {
                btnNoRewardFid.disabled = false
              }
            });
              event.preventDefault();
          }

        });


        // listener sur le btn "valider avec le point"
        btnWithRewardFid.addEventListener('click', (event) => {

          if (email.value.match(regex_email) && !cgu.checked) {
            btnWithRewardFid.disabled = true

            Swal.fire({
						customClass: {
							container: `alert-no-email`,
              icon: `alert-no-email-icon`
						},
             html: `${message4}`,
             icon: "warning",
             allowOutsideClick: false,
             showCancelButton: true,
             confirmButtonText: `${cancel}`,
             cancelButtonText: `${validate}`,
             closeOnConfirm: false,
             closeOnCancel: false
            }).then(function(value){
              if(value.dismiss){
                form.submit();
              } else {
                btnWithRewardFid.disabled = false
              }
            });
              event.preventDefault();
          }

        })

      }

      // SI PAS DE REWARD
      if (document.querySelector(".custom-btn.btn-no-reward-end-survey.no-reward-js")) {
        const btnValidateNoReward = document.querySelector(".custom-btn.btn-no-reward-end-survey.no-reward-js")
        const form = document.querySelector(".survey-end-title.survey-end-title-mobile.survey_form_no_reward_js").firstElementChild

        // listener sur le btn "valider sans point"
        btnValidateNoReward.addEventListener('click', (event) => {

          if ((!email.value.match(regex_email) && cgu.checked) || (!email.value.match(regex_email) && !cgu.checked)) {
            btnValidateNoReward.disabled = true

            Swal.fire({
						customClass: {
							container: `alert-no-email`,
              icon: `alert-no-email-icon`
						},
             html: `${message5}`,
             icon: "warning",
             allowOutsideClick: false,
             showCancelButton: true,
             confirmButtonText: `${cancel}`,
             cancelButtonText: `${validate}`,
             closeOnConfirm: false,
             closeOnCancel: false
            }).then(function(value){
              if(value.dismiss){
                form.submit();
              } else {
                btnValidateNoReward.disabled = false
              }
            });
              event.preventDefault();
          }
          else if (email.value.match(regex_email) && !cgu.checked) {
            btnValidateNoReward.disabled = true

            Swal.fire({
						customClass: {
							container: `alert-no-email`,
              icon: `alert-no-email-icon`
						},
             html: `${message6}`,
             icon: "warning",
             allowOutsideClick: false,
             showCancelButton: true,
             confirmButtonText: `${cancel}`,
             cancelButtonText: `${validate}`,
             closeOnConfirm: false,
             closeOnCancel: false
            }).then(function(value){
              if(value.dismiss){
                form.submit();
              } else {
                btnValidateNoReward.disabled = false
              }
            });
              event.preventDefault();
          }


        });
      }

  }
}

export {alertEmail};
