
// Paramètres carte: meal item create, toggle start date and end date if checkbox checked
const programDateMeal = (context = document) => {
  const dateProg = context.querySelector("#date-prog");
  if(dateProg) {
    console.log("programDateMeal");
    const checkDates = context.querySelectorAll("#check-date");
    checkDates.forEach((checkDate) => {
      checkDate.addEventListener('click', (event) => {
        const dateProgToToggle = event.currentTarget.parentElement.parentElement.querySelector("#date-prog")
        dateProgToToggle.classList.toggle('hidden');
      });
    })

  };
};

export { programDateMeal };
