import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const phoneInputField = document.querySelector("#client_phone");
    const phoneInput = window.intlTelInput(phoneInputField, {
      preferredCountries: ["fr"],
      utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js",
    });

    const indicatif = `+${phoneInput["s"]["dialCode"]}`
    document.querySelector("#client_phone").value = indicatif
    document.querySelector("#client_phone").innerText = indicatif
  }
}
