const backToTopBtnMenu = () => {

  const menu = document.getElementById("back-to-top-btn-menu")
  if (menu) {
    console.log('backToTopBtnMenu')
    const btn = $('#back-to-top-btn-menu');

    $(window).scroll(function() {
      if ($(window).scrollTop() > 300) {
        btn.addClass('show');
      } else {
        btn.removeClass('show');
      }
    });

    btn.on('click', function(e) {
      e.preventDefault();
      $('html, body').animate({scrollTop:0}, '300');
    });
  }
}

export { backToTopBtnMenu };
